import React, { useEffect,useState } from "react";
import { Badge } from "antd";
import Delayed from "../../../utils/Delayed";
import { getDynamicRoutesLink } from "../../../utils/portfolioRelationshipCommon";
import { useNavigate } from "react-router";
	 
const BusinessProcessListContent = ({moduleName,lists,filterText,sortBy,filterBy,filterLevel,ButtonComponents,loading,...props}) => {
    const [ListData, setListData] = useState(null)
    const navigate = useNavigate();
    useEffect(()=>{
        let tempList = generateList(lists);
        setListData(tempList || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[lists])

    const generateList = (items)=>{
        let temp = [];
        items?.forEach(item=>{
            temp.push(item);
            if(item?.child){
                let temp2 = generateList(item?.child);
                temp = [...temp,...temp2]
            }
        })
        return temp;
    }

    const getCard = (item) => {
        return (
            <div key={Math.random()} className="portfolio-listbx">
                <table width={`100%`}>
                    <tbody>
                        <tr>
                            <td width={`95%`}>
                                <div 
                                    className="my-1 portfolio-item cursor-pointer"
                                    onClick={()=>{  
                                        let newLink= getDynamicRoutesLink({moduleName:moduleName,id:item?.id});
                                        navigate(newLink);
                                    }}
                                >
                                    <div>
                                        <Badge count={item.LevelID?.toString()} className="fw-small badge_id" overflowCount={9999999999}/>
                                    </div>
                                    <div className="des-bx">
                                        <span className="fw-medium text-primary">{item?.DisplayName}</span>
                                        <small className="fs-small">{item?.Description}</small>{" "}
                                    </div>
                                </div>
                            </td>
                            <td width={`5%`} className="text-nowrap pr-2">
                                {ButtonComponents && <ButtonComponents {...props} item={item}/>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const filterData = (items) =>{
        
        if (filterText?.length > 0) {
            if (filterBy === "displayName") {
                items = items.filter((item) =>
                    item?.DisplayName?.includes(filterText)
                );
            } else if (filterBy === "level") {
                items = items.filter((item) =>
                    item?.Level?.includes(filterText)
                );
            } else if (filterBy === "description") {
                items = items.filter((item) =>
                    item?.DisplayName?.includes(filterText)
                );
            }
        }

        if(filterLevel){
            items = items.filter((item) =>
                item?.Level?.includes(filterLevel)
            );
        }

        items = items?.sort((a, b) => {
            return a?.LevelID?.localeCompare(b?.LevelID, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        })
        return items;
    }

    return (
        <div>
            {
                ListData && ListData?.length > 0 && filterData(ListData)?.map((item) => {
                    return getCard(item);
                }) || <Delayed waitBeforeShow={2000}><div className={`text-base font-bold mt-4 ${loading?'d-none':''}`}>No items found.</div></Delayed>
            }
        </div>
    );
};

export default BusinessProcessListContent;
