import React from 'react';
import { useState, useEffect } from 'react';
import { 
    Card,
    Tooltip,
} from "antd";

const cardStyles = {
    'className' : 'text-center',
    'style' : { width: 200,float:'left',marginRight:'10px',marginBottom:'10px' },
    'headStyle' : {border: '1px solid #ddd',padding:'0 10px',minHeight:'30px',textOverflow: 'ellipsis',},
    'bodyStyle' : {padding:'5px 10px',backgroundColor:'#2596be',color:'#fff',display: 'flex',alignItems: 'center',justifyContent: 'center'},//backgroundColor: props?.colors?.phaseIn,color:'#fff',
    'size':`small`,
}

const cardTextStyles = { width:'180px', textOverflow:'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }

const LGContentRowCards = (props) => {
    const [application,setApplication] = useState('');
    const [applicationId,setApplicationId] = useState('');

    useEffect(()=>{
        if(props?.cardsData?.application){
            setApplication(props?.cardsData?.application);
        }
        if(props?.cardsData?.application_id){
            setApplicationId(props?.cardsData?.application_id);
        }
    },[props]);

    return <>
        {
            props?.cardsData?.business_capability?.split(",")?.map((data)=>{
                return <Card 
                    key={Math.random()}
                    {...cardStyles}
                    title={<Tooltip title={data}>{data}</Tooltip> || <>&nbsp;</>}
                >
                    <div>
                        <Tooltip title={`App Id : `+applicationId || ""}>{ (application && <div style={cardTextStyles} title={application}>{application}</div>) || ""}</Tooltip>
                    </div>
                </Card>
            })
        }
    </>;
}

export default LGContentRowCards;