import React from 'react';

const CombineProviders = ({ providers, children }) => {
  // Wrap children with all providers
  const wrappedChildren = providers.reduceRight((acc, Provider) => {
    return <Provider>{acc}</Provider>;
  }, children);

  return <>{wrappedChildren}</>;
};

export default CombineProviders;