import React, { useEffect,useState } from "react";
import { Flex } from "antd";
	 
const IntegrationListContent = ({data,moduleName,loading,fetchData,hasMore,Buttons,overflowY}) =>{

    const RowData = ({ item, isLast }) => {
        return (
            <div className="portfolio-listbx">
                <table width={`100%`}>
                    <tbody>
                        <tr>
                            <td width={`95%`} className="my-1 portfolio-item">
                                <Flex gap={1} vertical>
                                    <div>{item?.ReferenceID}</div>
                                    <div className="text-primary fw-medium">{item?.DisplayName}</div>
                                    <Flex gap={8} justify='start'>
                                        <div>Source App:</div>
                                        <div className='fw-medium'>{item?.SourceApp?.DisplayName}</div>
                                    </Flex>
                                    <Flex gap={8} justify='start'>
                                        <div>Target App:</div>
                                        <div className='fw-medium'>{item?.TargetApp?.DisplayName}</div>
                                    </Flex>
                                </Flex>
                            </td>
                            <td width={`5%`} className="text-nowrap">
                                {<Buttons item={item}/>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const handleOnScroll =(e) => {
        const { scrollTop, scrollHeight, offsetHeight } = e.target;
        if (offsetHeight - scrollTop < offsetHeight) {
            fetchData();
        }
    }

    return (
        <div className='overflow-y' style={(overflowY?{maxHeight:window?.innerHeight-200}:{})} onScroll={(e)=>{handleOnScroll(e)}}>
            {
                (data?.length > 0 &&
                data?.map((item, index) => (
                    <React.Fragment key={index}>
                        <RowData  item={item} isLast={index === data.length - 1} />
                    </React.Fragment>
                ))) || <div className="text-base font-bold mt-4">No items found.</div>
            }
        </div>
    );
}

IntegrationListContent.defaultProps = {
    fetchData:()=>{},
    overflowY:false,
}
export default IntegrationListContent;
