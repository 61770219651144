import React from 'react';
import LGContentRowCards from './LGContentRowCards';

const LGContentRow = (props) => {
    return <>
        <tr>
            <th><span className='rotate-90'>{props?.heading}</span></th>
            <td>
                {
                    props?.rowData && props?.rowData?.map((list)=>{
                        return <LGContentRowCards cardsData={list} key={Math.random()}/>
                    })
                }
            </td>
        </tr>
    </>;
}

export default LGContentRow;