import { useState } from "react";
import PortfolioSettingServices from "../services/services/PortfolioSettingsService";
import { message } from "antd";

const usePortfolioFetcher = () => {
  const [loading, setLoading] = useState(false);

  const fetchPortfolio = async (dispatch, page = 1 , limit = 100) => {
    try {
      setLoading(true);
      const response = await PortfolioSettingServices.getList({ page, limit });
      const newData = response.data.data;
      await dispatch({ type: "SET_PORTFOLIO_SETTINGS", payload: newData });
      if (response?.data?.data?.length) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const fetchPortfolioNodesById = async (dispatch, id) => {
    try {
      
      console.log("ID.......................", id)
      setLoading(true);
      const response = await PortfolioSettingServices.fetchPortfolioNodesById(id);
      //const payload = response?.data?.data;
      const payload = response?.data ;
      
      //await dispatch({type: "SET_SINGLE_PORTFOLIO_SETTINGS", payload: payload});
      await dispatch({type: "SET_SINGLE_PORTFOLIO_CONNECTION", payload: payload});
      if (response?.data?.data?.length) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }

  }

  return { loading, fetchPortfolio, fetchPortfolioNodesById };
};

export default usePortfolioFetcher;
