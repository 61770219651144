import React, { createContext, useContext, useReducer } from "react";
const BusinessApplicationContext = createContext();
export const useBusinessApplication = () =>
  useContext(BusinessApplicationContext);

const initialState = {
  data: [],
  loading: false,
  error: false,
  allTags: [],
  isEdit: false,
  getOneBusinessApplication: {},
};

const businessApplicationReducer = (state, action) => {
  switch (action.type) {
    case "SET_BUSINESS_APPLICATION":
      // return {
      //   ...state,
      //   data: [...state.data, ...action.payload],
      // };
      return {
        state,
        data:  action.payload,
      };

      
    case "ADD_BUSINESS_APPLICATION":
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case "UPDATE_BUSINESS_APPLICATION":
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case "DELETE_BUSINESS_APPLICATION":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.id),
      };
    case "EMPTY_BUSINESS_APPLICATION":
      return {
        ...state,
        data: [],
      };
    case "SET_ALL_TAGS":
      return {
        ...state,
        allTags: action.payload, 
      };
    case "SET_IS_EDIT":
      return {
        ...state,
        isEdit: action.payload,
      };
    case "SET_ONE_BUSINESS_APPLICATION":
      return {
        ...state,
        getOneBusinessApplication: action.payload,
      };
    default:
      return state;
  }
};

export const BusinessApplicationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    businessApplicationReducer,
    initialState
  );

  return (
    <BusinessApplicationContext.Provider value={{ state, dispatch }}>
      {children}
    </BusinessApplicationContext.Provider>
  );
};
