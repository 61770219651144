import {
  AppstoreAddOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Pagination,
  Select,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PageTitleHeading,
  debounceTimeOut,
  default_pageSize,
  getSelectProperties,
  removeEmptyArrObjOrval,
} from "../../../utils/Common";
import FormFields from "../../../utils/FormFields";
import { staticSettingPortfolioList } from "../../../utils/settingCommon";
import PortfolioRelationshipServices from "../../../services/services/PortfolioRelationshipService";
import { normalizeString } from "../../../utils/linksCommon";
import FilterRightSideBar from "../../applicationlist/filter/FilterRightSideBar";
import useDebounce from "../../../helper/useDebounce";
import { dynamicRoutesLinks } from "../../../utils/portfolioRelationshipCommon";
import useDynamicNavigate from "../../../utils/navigateUtils";
import Delayed from "../../../utils/Delayed";

const { Option } = Select;

const styleBar = {
  overflow: "auto",
  minHeight: window.innerHeight / 2,
};

const rotate90 = {
  marginTop: "50px",
  marginLeft: "-10px",
};

const date = new Date();
var deadlines = [];
for (let index = date.getFullYear(); index <= date.getFullYear() + 2; index++) {
  for (let ind = 1; ind <= 4; ind++) {
    deadlines.push({
      value: "Q" + ind + "-" + index,
      label: "Q" + ind + "-" + index,
    });
  }
}

const changeTypeDummyValues = [
  { id: 1, label: "Retire", value: 1 },
  { id: 2, label: "Replace", value: 2 },
  { id: 3, label: "Upgrade", value: 3 },
  { id: 4, label: "Retain", value: 4 },
];

const ApplicationList = ({
  heading,
  setOpenPortfolioList,
  selectedModuleItems,
  selectedPortfolioItems,
  setSelectedPortfolioItems,
  // portfolioloading,
  // setPortfolioLoading,
  loading:portfolioloading,
  setLoading:setPortfolioLoading,
  setPage,
  page,
  limit,
  setLimit,
  scenarioId,
  setOpenChoosePortfolioType,
  portfolioTypeForm,
  onFormSubmit,
  selectedItemId,
  setSelectedItemId,
  changeTypeModal,
  setChangeTypeModal,
  changeTypeForm,
  changeTypeLoading,
  setChangeTypeLoading,
  portfolioItemIds,
  showConfirmDeleteScenarioItem,
  settingPortfolioList,
  setRelationshipPortfolioId,
  getItems,
  showFilter,
  setShowFilter,
  filterData,
  setFilterData,
  selectedFilters,
  setSelectedFilters,
  totalRecords,
  setTotalRecords,
  changeTypeData,
  ...props
}) => {
  const [openReplaceWithForm] = Form.useForm();
  const [isOpenReplaceWithModel, openReplaceWithModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [changeTypeFormData, setChangeTypeFormData] = useState({});
  const [addRelationhipModal, setRelationhipModal] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState([]);
  const [relationshipItems, setRelationshipItems] = useState({});
  const [relationsList, setRelationsList] = useState([]);
  const navigateTo = useDynamicNavigate();
  const [changeTypeName, setChangeTypeName] = useState("");

  //   Filtration states

  
  

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const getSortedRecords = (list) => {
    let records = [];

    if (list) {
      list?.forEach((level_1) => {
        records.push(level_1);
        level_1?.child?.forEach((level_2) => {
          records.push(level_2);
          level_2?.child?.forEach((level_3) => {
            records.push(level_3);
          });
        });
      });
    }

    return records?.sort((a, b) => {
      const levelID_A = a.LevelID || ""; // Handling undefined LevelID
      const levelID_B = b.LevelID || ""; // Handling undefined LevelID
      return levelID_A.localeCompare(levelID_B, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });
  };

  useEffect(() => {
    setList([]);
    setLoading(true);
    setTimeout(() => {
      if (
        selectedModuleItems?.name ===
        staticSettingPortfolioList.businessCapability
      ) {
        const sortedData = getSortedRecords(selectedPortfolioItems);
        setList(sortedData?.length > 0 ? sortedData : []);
      } else {
        setList(
          selectedPortfolioItems?.length > 0 ? selectedPortfolioItems : []
        );
      }
      setLoading(false);
    }, 1000);
  }, [selectedPortfolioItems]);

  useEffect(() => {
    setSelectedPortfolio(portfolioTypeForm?.getFieldValue()?.portfolioType);
  }, [portfolioTypeForm?.getFieldValue()?.portfolioType]);

  const onDynamicLinkClick = (id = null, moduleName = "", rest = {}) => {
    const redirectLink = dynamicRoutesLinks?.find(
      (item) => item?.type === moduleName
    );
    if (redirectLink?.link && redirectLink?.moduleName && id) {
      const newLink = redirectLink?.link + redirectLink?.moduleName + "/" + id;
      navigateTo(newLink, true);
    }
  };

  const pascalToSnakeCase = (str) => {
    return str.replace(
      /[A-Z]/g,
      (match, offset) => (offset ? "_" : "") + match.toLowerCase()
    );
  };

  const getAllConnectedRelations = async (
    id = null,
    selectedPortfolio = null
  ) => {
    try {
      setLoading(true);
      setRelationshipItems({});
      setRelationsList([]);
      const nodes = { nodeId: id, nodeTag: selectedPortfolio, isSnake: true };
      const response = await PortfolioRelationshipServices.getAllNode(nodes);
      if (response?.statusText !== "OK") {
        return message.error("Failed to fetch data.");
      }

      const newData = response?.data?.data;
      if (newData && Object.keys(newData)?.length > 0) {
        setLoading(false);
        setSelectedItemId(id);
        setRelationhipModal(true);
        setRelationshipItems(response?.data?.data);
      } else {
        message.info("No items found.");
      }
    } catch (error) {
      console.error("Error fetching Scenario items:", error);
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const GetAddOrDeleteBtn = ({
    portfolioItemIds,
    itemId,
    name,
    selectedPortfolioName = null,
    relations = [],
    portfolioSettingId = null,
  }) => {
    const portfolioName = selectedPortfolioName
      ? selectedPortfolioName
      : portfolioTypeForm?.getFieldValue()?.portfolioType;
    const findPortfolioItem = portfolioItemIds?.find(
      (item) => item?.itemId === itemId
    );
    if (!findPortfolioItem) {
      return (
        <Tooltip title="Add">
          <Button
            type={`primary`}
            disabled={portfolioloading}
            icon={<PlusOutlined />}
            onClick={() => {
              if (portfolioSettingId) {
                //   const currentValues = changeTypeForm.getFieldsValue();
                //   // Merge the new values with the previous ones
                //   const mergedValues = {
                //     ...currentValues,
                //     portfolioSettingsId: portfolioSettingId,
                //   };
                //   // Set the merged values to the form
                //   changeTypeForm.setFieldsValue(mergedValues);

                setRelationshipPortfolioId(portfolioSettingId);
              }
              if(changeTypeData?.length === 0){
                return message.error("Change type not found.")
              }
              setSelectedItemId(itemId);
              if (relations && relations?.length > 0) {
                setRelationsList(relations);
              }
              setChangeTypeModal(true);
              setChangeTypeName("");
            }}
          />
        </Tooltip>
      );
    } else {
      const { id = null, itemId = null } = findPortfolioItem;
      return (
        <Tooltip title="Added">
          <Button
            type={`default`}
            // disabled={portfolioloading}
            disabled={true}
            onClick={async () => {
              await showConfirmDeleteScenarioItem({
                id,
                name,
                itemId,
                portfolioName,
              });
            }}
          >
            Added
          </Button>
        </Tooltip>
      );
    }
  };

  const GetBPLContentProcess = ({
    LevelID,
    DisplayName,
    Description,
    id,
    rest,
  }) => {
    const { moduleName = "" } = rest;
    return (
      <div key={Math.random()} className="portfolio-listbx">
        <table width={`100%`} className="mb-1">
          <tbody>
            <tr>
              <td width={`95%`}>
                <div className="text-wrap">
                  <Badge
                    count={LevelID?.toString()}
                    className="fw-small  badge_id"
                    overflowCount={9999999999}
                  />
                  <span
                    className="rounded-sm text-wrap text-lg text-primary cursor-pointer"
                    onClick={() => {
                      onDynamicLinkClick(id, moduleName, {
                        DisplayName,
                        LevelID,
                      });
                    }}
                  >
                    {DisplayName}
                  </span>
                </div>
                <div className="des-bx">{Description}</div>
              </td>
              <td width={`5%`} className="text-nowrap">
                <GetAddOrDeleteBtn
                  portfolioItemIds={portfolioItemIds[selectedPortfolio]}
                  itemId={id}
                  name={DisplayName}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getBPLContentCapability = ({
    LevelID,
    DisplayName,
    Description,
    moduleName = "",
    id = null,
  }) => {
    return (
      <div key={Math.random()} className=" portfolio-listbx">
        <table width={`100%`} className="mb-1">
          <tbody>
            <tr>
              <td width={`95%`}>
                <div className="my-1 portfolio-item">
                  <div>
                    <Badge
                      count={LevelID?.toString()}
                      className="fw-small badge_id"
                      overflowCount={9999999999}
                    />
                  </div>
                  <div className="des-bx">
                    <span
                      className="fw-medium text-primary cursor-pointer"
                      onClick={() => {
                        onDynamicLinkClick(id, moduleName, {
                          DisplayName,
                          LevelID,
                        });
                      }}
                    >
                      {DisplayName}
                    </span>
                    <small className="fs-small">{Description}</small>{" "}
                  </div>
                </div>
              </td>
              <td width={`5%`} className="text-nowrap">
                <GetAddOrDeleteBtn
                  portfolioItemIds={portfolioItemIds[selectedPortfolio]}
                  itemId={id}
                  name={DisplayName}
                />
                &emsp;
                <Tooltip title="View and Add relationship">
                  <Button
                    type={`primary`}
                    disabled={loading}
                    loading={loading}
                    onClick={async () => {
                      await getAllConnectedRelations(id, selectedPortfolio);
                    }}
                    icon={<AppstoreAddOutlined />}
                  >
                    {/* View and Add relationship */}
                  </Button>
                </Tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const GetBPLContentPortfolios = ({
    referenceID,
    name,
    description,
    id,
    rest,
  }) => {
    const { moduleName = "" } = rest;
    return (
      <div key={Math.random()} className=" portfolio-listbx">
        <table width={`100%`} className="mb-1">
          <tbody>
            <tr>
              <td width={`95%`}>
                <div className="my-1 portfolio-item">
                  <div>
                    <Badge
                      count={referenceID?.toString()}
                      className="fw-small  badge_id"
                      overflowCount={9999999999}
                    />
                  </div>
                  <div className="des-bx">
                    <span
                      className="fw-medium text-primary cursor-pointer"
                      onClick={() => {
                        onDynamicLinkClick(id, moduleName, rest);
                      }}
                    >
                      {name}
                    </span>
                    <small className="fs-small">{description}</small>{" "}
                  </div>
                </div>
              </td>
              <td width={`5%`} className="text-nowrap">
                <GetAddOrDeleteBtn
                  portfolioItemIds={portfolioItemIds[selectedPortfolio]}
                  itemId={id}
                  name={name}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const ChangeType = ({
    type,
    name,
    label,
    formClassName,
    options,
    onChange,
    rules,
  }) => {
    return (
      <Form.Item
        label={label}
        name={name}
        className={formClassName}
        rules={rules}
      >
        <Select placeholder="Select" onChange={onChange}>
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  const getTitle = () => {
    const findItem = list?.find((item) => item?.id === selectedItemId);
    let title = "";
    if (findItem) {
      title = findItem?.DisplayName || findItem?.NameInSourceSystem || "";
    }
    return title;
  };

  //   Relationship card
  const TableItems = ({ item, index, moduleName, relations }) => {
    return (
      <div key={index} className=" portfolio-listbx">
        <table width="100%" className="mb-1">
          <tbody>
            <tr>
              <td width="95%">
                <div className="text-wrap">
                  {/* <span className="fw-medium">4245</span> */}
                  <span className="rounded-sm text-wrap text-lg text-primary">
                    {item?.displayName}
                  </span>
                </div>
                <div className="des-bx">{item?.description}</div>
              </td>
              <td width="5%" className="text-nowrap">
                <GetAddOrDeleteBtn
                  portfolioItemIds={portfolioItemIds[moduleName]}
                  itemId={item?.id}
                  name={item?.displayName}
                  selectedPortfolioName={moduleName}
                  relations={relations || []}
                  portfolioSettingId={item?.portfolioSettingId}
                />
                {/* <Button
                  type={`primary`}
                  onClick={() => {
                    setSelectedItemId(item?.id);
                    setChangeTypeModal(true);
                  }}
                >
                  <span>Add</span>
                </Button> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  const RelationshipCard = ({ title, items }) => {
    const moduleName = pascalToSnakeCase(title);
    const getColor =
      settingPortfolioList?.find(
        (item) => item?.moduleName?.toLowerCase() === moduleName?.toLowerCase()
      ) || {};
    const color = getColor && getColor?.color ? getColor?.color : "#000";
    return (
      <Card
        title={<span className="h4">{normalizeString(title)}</span>}
        style={{ backgroundColor: `${color}`, marginBottom: "10px" }}
        headStyle={{ backgroundColor: `${color}`, padding: "0px 10px" }}
        bodyStyle={{ backgroundColor: `#fff`, padding: "0px 10px" }}
      >
        {(items &&
          items?.length > 0 &&
          items?.map((item, index) => (
            <TableItems
              item={{
                ...item,
                portfolioSettingId: getColor?.id ? getColor?.id : null,
              }}
              index={index}
              moduleName={moduleName}
              relations={items}
            />
          ))) || <div>Relations not found.</div>}
      </Card>
    );
  };

  const getAllSelectionFields = () => {
    const currentPortfolio = settingPortfolioList?.find(
      (item) =>
        item?.name?.toLowerCase() === selectedModuleItems?.name.toLowerCase()
    );

    let properties = [];
    if (currentPortfolio) {
      properties = getSelectProperties(
        currentPortfolio?.portfolioSections,
        true
      );
    }

    return properties;
  };

  useEffect(() => {
    if (showFilter) {
      const getAllProperties = getAllSelectionFields() || [];
      setFilterData(getAllProperties);
    }
  }, [showFilter]);

  //   Filter
  const getListItems = async (selectedModuleItems, queryParams) => {
    await getItems(selectedModuleItems, {
      ...queryParams,
      page: 1,
      limit: default_pageSize,
    });
  };
  const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
  useEffect(() => {
    if (Object.keys(debouncedFilter)?.length > 0) {
      setPage(1);
      const removedItems = removeEmptyArrObjOrval(debouncedFilter);
      getListItems(selectedModuleItems, removedItems);
    }
    // fetchFilteredData(debouncedFilter);
  }, [debouncedFilter]);

  return (
    <div>
      <Layout>
        <Layout.Content className="p-2 rounded">
          <Typography.Title
            level={5}
            className="text-start py-2 mb-2 step-header header-size"
          >
            {heading + " List"}
            {/* <div className="ml-auto">
              <Input placeholder="Search" prefix={<SearchOutlined />} />
            </div> */}
          </Typography.Title>
          <Spin spinning={portfolioloading || loading}>
            <div
              className="overflow-y"
              style={{ height: window.innerHeight / 1.2 }}
              // onScroll={handleScroll}
            >
              {portfolioloading || loading ? (
                  <Delayed waitBeforeShow={2000}>
                    <div
                    className={`text-base font-bold mt-4 ${
                      portfolioloading || loading ? "d-none" : ""
                    }`}
                  >
                    {portfolioloading || loading ? "Loading..." : "No items found."}
                  </div>
                </Delayed>
              ) : list?.length > 0 ? (
                <>
                  {selectedModuleItems?.name ===
                  staticSettingPortfolioList.businessCapability ? (
                    list?.map((el, index) => {
                      return getBPLContentCapability(el);
                    })
                  ) : selectedModuleItems?.name ===
                    staticSettingPortfolioList.businessProcess ? (
                    list?.map(
                      (
                        {
                          ReferenceID = "",
                          NameInSourceSystem = "",
                          Description = "",
                          DisplayName = "",
                          LevelID = "",
                          id,
                          ...rest
                        },
                        index
                      ) => {
                        return (
                          <GetBPLContentProcess
                            Description={Description}
                            DisplayName={DisplayName || NameInSourceSystem}
                            LevelID={LevelID || ReferenceID}
                            id={id}
                            rest={rest}
                            index={index}
                          />
                        );
                      }
                    )
                  ) : selectedModuleItems?.name ? (
                    list?.map(
                      (
                        {
                          ReferenceID = "",
                          NameInSourceSystem = "",
                          DisplayName = "",
                          Description = "",
                          LevelID = "",
                          id = null,
                          ...rest
                        },
                        index
                      ) => {
                        return (
                          <GetBPLContentPortfolios
                            description={Description}
                            name={DisplayName || NameInSourceSystem}
                            referenceID={LevelID || ReferenceID}
                            id={id}
                            rest={rest}
                            index={index}
                          />
                        );
                      }
                    )
                  ) : (
                    <Delayed waitBeforeShow={2000}>
                      <div
                      className={`text-base font-bold mt-4 ${
                        portfolioloading || loading ? "d-none" : ""
                      }`}
                      >
                        {portfolioloading || loading ? "Loading..." : "No items found."}
                      </div>
                    </Delayed>
                  )}
                </>
              ) : (
                <Delayed waitBeforeShow={2000}>
                  <div
                    className={`text-base font-bold mt-4 ${
                      portfolioloading || loading ? "d-none" : ""
                    }`}
                  >
                    No items found.
                  </div>
                </Delayed>
              )}
            </div>
            {(list?.length && (
              <Pagination
                className="mt-2 text-right"
                total={totalRecords}
                showTotal={(total) => `Total ${total} items`}
                defaultPageSize={default_pageSize}
                defaultCurrent={page}
                showSizeChanger={false}
                // pageSizeOptions={[10, 20, 50, 100, 200, 500, 1000, "Show All"]}
                onChange={(p, ps) => {
                  setLimit(ps);
                  setPage(p);
                }}
              />
            )) || <div></div>}
          </Spin>
        </Layout.Content>
        {/* {(settingPortfolioList?.length > 0 && ( */}
        <Layout.Sider
          theme={`light`}
          width={showFilter ? `250px` : `60px`}
          className="border-left p-3 pt-0"
          style={styleBar}
        >
          <div className=" mt-2">
            {/* <Filter portfolioData={settingPortfolioList} /> */}
            <FilterRightSideBar
              activeGridStyle={1}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              portfolioData={filterData}
              selectedFilters={selectedFilters}
              setSelectedFilters={(value)=>{ setPage(1);setSelectedFilters(value); }}
              optionalLevel={
                selectedModuleItems?.name ===
                staticSettingPortfolioList.businessCapability
              }
              allowSlider={true}
            />
          </div>
        </Layout.Sider>
        {/* )) || <></>} */}
      </Layout>
      <Modal
        destroyOnClose
        header={false}
        footer={false}
        requiredMark={true}
        maskClosable={false}
        open={changeTypeModal}
        onCancel={() => {
          changeTypeForm?.resetFields();
          setChangeTypeLoading(false);
          setChangeTypeName("");
          setChangeTypeModal(false);
        }}
      >
        <Form
          form={changeTypeForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{}}
          className="mt-4"
          onFinish={async (formData) => {
            await setChangeTypeLoading(true);
            onFormSubmit(formData);
          }}
        >
          <ChangeType
            type={`select`}
            name={`changeTypeId`}
            label={`Change Type`}
            formClassName={`mb-2`}
            options={changeTypeData}
            rules={[
              {
                required: true,
                message: "Please select Change Type",
              },
            ]}
            onChange={(value) => {
              const selectedChangeType = changeTypeData.find((type) => type.value === value);
              setChangeTypeFormData({
                ...changeTypeFormData,
                ["changeType"]: value,
              });
              setChangeTypeName(selectedChangeType ? selectedChangeType.name : "");
            }}
          />
          {changeTypeName === "Upgrade" && (
            <FormFields
              type={`input`}
              name={`version`}
              label={`Version`}
              formClassName={`mb-2`}
              rules={[{ required: true, message: "Please enter Version!" }]}
            />
          )}
          {changeTypeName === "Replace" && (
            <>
              <FormFields
                type={`select`}
                width={`100%`}
                name={`replaceWith`}
                label={`Replace with`}
                formClassName={`mb-2`}
                rules={[
                  {
                    required: true,
                    message: "Please select replace with",
                  },
                ]}
                options={
                  !addRelationhipModal
                    ? list
                        ?.filter((item) => item?.id !== selectedItemId)
                        ?.map((el) => {
                          return {
                            ...el,
                            value: el?.id,
                            label: `${el?.LevelID || el?.ReferenceID} ${
                              el?.DisplayName || el?.NameInSourceSystem
                            }`,
                          };
                        })
                    : relationsList
                        ?.filter((item) => item?.id !== selectedItemId)
                        ?.map((el) => {
                          return {
                            ...el,
                            value: el?.id,
                            label:
                              el?.NameInSourceSystem ||
                              el?.DisplayName ||
                              el?.displayName ||
                              el?.nameInSourceSystem,
                          };
                        }) || []
                }
                columns={{ wrapperCol: { span: 24 } }}
                onChange={(value) => {
                  setChangeTypeFormData({
                    ...changeTypeFormData,
                    ["replaceWith"]: value,
                  });
                }}
              />
            </>
          )}
          <FormFields
            type={`textarea`}
            label={`Comment`}
            name={`comment`}
            formClassName={`mb-2`}
            rows={3}
          />
          <Form.Item
            label={`Deadline`}
            name="deadline"
            formClassName={`mb-2`}
            key={`deadline`}
            rules={[
              {
                required: false,
                message: "Please select deadline",
              },
            ]}
            onChange={(value) => {
              setChangeTypeFormData({
                ...changeTypeFormData,
                ["deadline"]: value,
              });
            }}
          >
            <DatePicker className="mb-2 text-start w-full" />
          </Form.Item>
          {/* <FormFields
            type={`select`}
            label={`Deadline`}
            name="deadline"
            formClassName={`mb-2`}
            options={deadlines}
            rules={[
              {
                required: true,
                message: "Please select deadline",
              },
            ]}
            onChange={(value) => {
              setChangeTypeFormData({
                ...changeTypeFormData,
                ["deadline"]: value,
              });
            }}
          /> */}
          {/* Hiding for now will be show in feature */}
          {/* {changeTypeFormData["changeType"] === 2 && (
            <Row gutter={8}>
              <Col span={24} className="bg-light text-left p-2">
                <label className="fw-medium">Reason</label>
                <FormFields
                  type={`checkbox`}
                  direction={`vertical`}
                  formClassName={`mb-2`}
                  columns={{ wrapperCol: { span: 24 } }}
                  options={[
                    {
                      value: 1,
                      label: "High maintenance cost",
                    },
                    { value: 2, label: "High Complexity" },
                    { value: 3, label: "Lack of support" },
                    {
                      value: 4,
                      label: "Security vulnerabilities",
                    },
                    {
                      value: 5,
                      label: "Compliance issues",
                    },
                    {
                      value: 6,
                      label: "Limited Functionality",
                    },
                  ]}
                  onChange={(value) => {
                    console.log("value", value);
                  }}
                />
              </Col>
            </Row>
          )} */}
          <Button
            type={`primary`}
            htmlType={`submit`}
            className="w-100"
            loading={changeTypeLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>
      <Modal
        destroyOnClose
        header={false}
        footer={false}
        maskClosable={false}
        open={isOpenReplaceWithModel}
        onCancel={() => {
          //   setChangeTypeModal(false);
          openReplaceWithModel(false);
        }}
        closable
      >
        <Form
          form={openReplaceWithForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{}}
          className="mt-4"
          onFinish={(formData) => {
            console.log("formData", formData);
          }}
        >
          <FormFields
            type={`input`}
            label={`Name`}
            name="replaceWith"
            formClassName={`my-4`}
          />
          <Button type={`primary`} htmlType={`submit`} className="w-100">
            Submit
          </Button>
        </Form>
      </Modal>

      {/* Business Capabilities */}
      <Modal
        destroyOnClose
        header={false}
        footer={false}
        maskClosable={false}
        title={getTitle()}
        open={addRelationhipModal}
        onCancel={() => {
          setRelationhipModal(false);
          setRelationshipPortfolioId(null);
        }}
        width={(window?.innerWidth * 80) / 100}
        closable
      >
        {(relationshipItems &&
          relationshipItems?.relations?.length > 0 &&
          relationshipItems?.relations?.map((item) => {
            return (
              <RelationshipCard
                title={item?.moduleName}
                items={item?.tags || []}
              />
            );
          })) || <></>}
        {/* <Card
          title={<span className="h4">Business Process</span>}
          style={{ backgroundColor: `#fff280`, marginBottom: "10px" }}
          headStyle={{ backgroundColor: `#fff280`, padding: "0px 10px" }}
          bodyStyle={{ backgroundColor: `#fff`, padding: "0px 10px" }}
        >
          <div>
            <table width="100%" className="mb-1">
              <tbody>
                <tr>
                  <td width="95%">
                    <div className="text-wrap">
                      <span className="fw-medium">4245</span>
                      <span className="rounded-sm text-wrap text-lg text-primary">
                        TBD (2026 Q1)
                      </span>
                    </div>
                    <div className="whitespace-normal overflow-hidden fs-small">
                      gghg
                    </div>
                  </td>
                  <td width="5%" className="text-nowrap">
                    <Button
                      type="button"
                      onClick={() => {
                        setChangeTypeModal(true);
                      }}
                    >
                      <span>Add</span>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
          </div>
          <div>
            <table width="100%" className="mb-1">
              <tbody>
                <tr>
                  <td width="95%">
                    <div className="text-wrap">
                      <span className="fw-medium">423</span>
                      <span className="rounded-sm text-wrap text-lg text-primary">
                        TBD (2025 Q1)
                      </span>
                    </div>
                    <div className="whitespace-normal overflow-hidden fs-small"></div>
                  </td>
                  <td width="5%" className="text-nowrap">
                    <Button
                      type="button"
                      onClick={() => {
                        setChangeTypeModal(true);
                      }}
                    >
                      <span>Add</span>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
          </div>
        </Card> */}
      </Modal>
    </div>
  );
};

ApplicationList.propTypes = {};

export default ApplicationList;
