import { Collapse } from "antd";
import React from "react";

const FilterIndicator = ({ lifeCycleStagesColors, ...props }) => {
  return (
    <>
      <Collapse
        className="life-cycle-stages-filter"
        defaultActiveKey={["1"]}
        expandIconPosition={`end`}
        items={[
          {
            key: "1",
            label: "Lifecycle Stages",
            children: (
              <>
                <label className="w-100 d-flex align-items-center">
                  <div
                    className="lcs-box"
                    style={{
                      background: lifeCycleStagesColors["active"],
                    }}
                  ></div>
                  Active
                </label>
                <label className="w-100 d-flex align-items-center">
                  <div
                    className="lcs-box"
                    style={{
                      background: lifeCycleStagesColors["phaseIn"],
                    }}
                  ></div>
                  Phase In
                </label>
                <label className="w-100 d-flex align-items-center">
                  <div
                    className="lcs-box"
                    style={{
                      background: lifeCycleStagesColors["phaseOut"],
                    }}
                  ></div>
                  Phase Out
                </label>
                <label className="w-100 d-flex align-items-center">
                  <div
                    className="lcs-box"
                    style={{
                      background: lifeCycleStagesColors["endOfLife"],
                    }}
                  ></div>
                  End Of Life
                </label>
              </>
            ),
          },
        ]}
      />
      <Collapse
        className="life-cycle-stages-filter"
        defaultActiveKey={["1"]}
        expandIconPosition={`end`}
        items={[
          {
            key: "1",
            label: "Application Type",
            children: null,
          },
        ]}
      />
      <Collapse
        className="life-cycle-stages-filter"
        defaultActiveKey={["1"]}
        expandIconPosition={`end`}
        items={[
          {
            key: "1",
            label: "Status",
            children: null,
          },
        ]}
      />
    </>
  );
};

export default FilterIndicator;
