import React from "react";
import FilterContent from "../../applicationlist/filter/FilterContent";

const BusinessProcessFilterContent = (props) => {

    return (
		<FilterContent {...props}/>
    );
};

export default BusinessProcessFilterContent;
