import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getList() {
    const url = `${API_BASE_URL}linktype`;
    let response = await http.get(url);
    return response;
  }

  
async function create_LinkType(data) {
    const url = `${API_BASE_URL}linktype`;
    let response = await http.post(url, data);
    return response;
  }

  
async function update_LinkType(data) {
    const {id, linkTypeName, description} = data;
    const url = `${API_BASE_URL}linktype/${id}`;
    let response = await http.put(url, data);
    return response;
  }
   
async function delete_LinkType(id) {
    const url = `${API_BASE_URL}linktype/`;
    let response = await http.delete(url + id);
    return response;
  }
  
  export default {
    getList,
    create_LinkType,
    update_LinkType,
    delete_LinkType 
  };