import React from 'react'
import { PageTitleHeading } from '../../utils/Common'
import { Button, Flex, Tooltip } from 'antd'
import ActionsBtn from '../applicationlist/ActionsBtn'
import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons'
import gridSvg from "./../../assets/Two columns layout.svg";
import { useNavigate, useParams } from 'react-router'
const BusinessProcessHeader = ({setActivePage,activePage,...props}) => {
    const {moduleName} = useParams();
    const navigate = useNavigate();
    return (
        <div className='mr-2'>
            <Flex wrap gap="small" className="ml-2 headingbx" justify='space-between'>
                <PageTitleHeading text={`Business Process`} />
                <Flex wrap gap="small" className="ml-2" align="center">
                    <Button 
                        onClick={()=>{setActivePage(1)}}
                        icon={<UnorderedListOutlined />}
                        {...(activePage === 1 ? {type:"primary",ghost:true}:{block:true})}
                        style={{width:'30px'}}
                    />
                    <Button 
                        onClick={()=>{setActivePage(2)}}
                        icon={<img src={gridSvg} alt="Grid" width={20}/>}
                        {...(activePage === 2 ? {type:"primary",ghost:true}:{block:true})}
                        style={{width:'30px'}}
                    />
                    <Tooltip title="Level 1">
                        <Button 
                            className='btn-level-2 mr-2' 
                            icon={<PlusOutlined />} 
                            onClick={() => { 
                                // props.openModal(); 
                                // props.setModalData({ level: 1 });
                                navigate('/business-process/'+moduleName+'/add' , { state: {Level:1,ParentID: 0, ParentName: '',underLevel: '', levelID:'' } })
                            }}
                        />
                    </Tooltip>
                    <ActionsBtn name={`Business Process`}/>
                </Flex>
            </Flex>
        </div>
    )
}

BusinessProcessHeader.defaultProps = {

}

export default BusinessProcessHeader