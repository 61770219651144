import React from 'react'
import AdminLayout from '../components/layouts/AdminLayout'
import ListScenario from '../components/scenario/ListScenario'

const ListScenarioPage = props => {
    return (
        <ListScenario/>
    )
}

export default ListScenarioPage