import './BusinessCapabilityEntry.scss';
import { Divider, Tabs,Typography } from "antd";   
import TabBCBasicInformation from './BCETabs/TabBCBasicInformation/TabBCBasicInformation';
import TabComments from "./BCETabs/TabBCComments/TabComments";
import TabConnections from "./BCETabs/TabBCConnections/TabConnections";
import React, { useState } from "react";
import TabLinks from '../portfoliosingleentry/portfolioTabs/tablinks/TabLinks';
import TabChangeLogAlerts from "../portfoliosingleentry/portfolioTabs/tabchangelogalerts/TabChangeLogAlerts";
import { useLocation, useParams } from "react-router";
import { applicationsList } from  "../../utils/businessApplication";
import { useTabContext } from "../portfoliosingleentry/TabProvider";
import TabPane from "antd/es/tabs/TabPane";
import { Link } from "react-router-dom";
import TabDiagrams from '../portfoliosingleentry/portfolioTabs/tabdiagrams/TabDiagrams';
import { PageTitleHeading } from '../../utils/Common';
import {useMenu} from "../../Context/MenuProvider";
import { useEffect } from 'react';
import { GoBack } from '../../utils/GoBackHistory';

const BusinessCapabilityEntry = (props) => {
    const location = useLocation();
    const { stateMenu, dispatchMenu } = useMenu();

    //let moduleName=applicationsList.businessCapability;
   // let moduleName=stateMenu?.MenuID?.moduleName;
     
    const [activeTabKey, setActiveTabKey] = useState("tab_information");
    const [businessCapabilityData, setBusinessCapabilityData] = useState({});
    const [portfolioId,setPortfolioId] = useState(null);
    // const { tabDisabled, toggleTabDisabled } = useTabContext();
    const { id=null, moduleName } = useParams();

    const handleTabChange = (key) => {
      console.log("key",key)
      setActiveTabKey(key);
    };

    useEffect(()=>{
        if(location?.state?.activeTabKey){
            handleTabChange(location?.state?.activeTabKey);
        }
        if(id?.toLowerCase()==="add"){
            setPortfolioId(null);
        }else{
            setPortfolioId(id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id])

    
    const tabsProps = {
        setActiveTabKey,
        handleTabChange, 
        // toggleTabDisabled,
        // tabDisabled,
        id:portfolioId,
        moduleName,
    };

    const BusinessCapabilityTabs = [
        {
            tab: `Information`,
            key: 'tab_information',
            content: <TabBCBasicInformation {...tabsProps} setBusinessCapabilityData ={setBusinessCapabilityData }/>,
        }  
        ,
        {
            tab: `Relations`,
            key: 'tab_connections',
            content: <TabConnections {...tabsProps}/>,
        }
        ,
        {
            tab: `External Links`,
            key: 'tab_links',
            // children: <TabBCExternalLinks/>,
            content: <TabLinks {...tabsProps}/> ,
        }
        ,
        {
            tab: `Diagram`,
            key: 'tab_diagram',
            content: <TabDiagrams {...tabsProps} titleName = {businessCapabilityData?.DisplayName}/>,
        }
        ,
        {
            tab: `Comments`,
            key: 'tab_comments',
            content: <TabComments {...tabsProps}/>,
        }
        ,
        // {
        //     tab: `Survey`,
        //     key: 'tab_survey',
        //     content: <TabBCBasicInformation {...tabsProps}/>,
        // }
        // ,
        {
            tab: `Change log/ Alerts`,
            key: 'tab_changelogAlert',
            content: <TabChangeLogAlerts {...tabsProps}/>,
        }
    ];

    return (
        <div className=' mb-5 text-left'>
            <div className='d-flex justify-content-between'>
                <div>
                    <PageTitleHeading text={
                            ((()=>{
                                if(businessCapabilityData?.DisplayName){
                                    return <span>Business Capability: {businessCapabilityData?.LevelID} {businessCapabilityData?.DisplayName}</span>;
                                }
                                return <span>Add New Business Capability</span>;
                            })())
                        }
                    />
                </div>
                <div className='my-3'>
                    <GoBack className={`ml-2 pull-right`}/>
                </div>
            </div>
            <Tabs
                type="card"
                className="business-capability-entry"
                activeKey={activeTabKey}
                 onChange={handleTabChange}
                // items={BusinessCapabilityTabs}
            >
                {
                    BusinessCapabilityTabs.map((tab) => (
                        // disabled={tabDisabled[tab.key]}
                        <TabPane tab={tab.tab} key={tab.key} >
                            {tab.content}
                        </TabPane>
                    ))
                }
                </Tabs>
        </div>
    );
}

export default BusinessCapabilityEntry;