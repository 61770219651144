import "./StartNewScenarioV2.scss";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Form, message, Spin, Flex, Pagination } from "antd";
import ActionsBtn from "../applicationlist/ActionsBtn";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import listSvg from "./../../assets/List.svg";
import gridSvg from "./../../assets/Two columns layout.svg";
import FormFields from "../../utils/FormFields";
import ApplicationList from "./portfolio/ApplicationList";
import { PageTitleHeading, debounceTimeOut, default_pageSize, removeEmptyArrObjOrval } from "../../utils/Common";
import ScenarioListData from "./ScenarioListData";
import BusinessProcessList from "./portfolio/BusinessProcessList";
import BusinessCapabilityList from "./portfolio/BusinessCapabilityList";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import usePortfolioFetcher from "../../hooks/usePortfolioFetcher";
import { normalizeString } from "../../utils/linksCommon";
import PortfolioRelationshipServices from "../../services/services/PortfolioRelationshipService";
import BusinessApplicationListCards from "../applicationlist/BusinessApplicationListCards";
import { applicationsList } from "../../utils/businessApplication";
import { staticSettingPortfolioList } from "../../utils/settingCommon";
import { useParams } from "react-router";
import ScenarioItemsServices from "../../services/services/ScenarioItemsService";
import useDebounce from "../../helper/useDebounce";
import { Link } from "react-router-dom";
import ScenarioServices from "../../services/services/ScenarioServices";
const { confirm } = Modal;

const choosePortfolioList = [
  { label: "Select", value: "" },
  { label: "Application", value: 1 },
  { label: "Business Process", value: 2 },
  { label: "Business Capabilities", value: 3 },
];

const ScenarioListV2 = (props) => {
  const [portfolioTypeForm] = Form.useForm();
  const [changeTypeForm] = Form.useForm();
  const [isOpenChoosePortfolioType, setOpenChoosePortfolioType] =
    useState(false);
  const [isOpenPortfolioList, setOpenPortfolioList] = useState(false);
  const [portfolioOpenRecord, setPortfolioOpenRecord] = useState({
    value: "",
    label: "",
  });
  const [recordData, addRecordData] = useState([]);
  const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();

  const [settingPortfolioList, setSettingPortfolioList] = useState([]);
  const [searchTextApplication, setSearchTextApplication] = useState("");
  const [selectedModuleItems, setSelectedModuleItems] = useState({});
  const [selectedPortfolioItems, setSelectedPortfolioItems] = useState([]);

  const [filterLevel, setFilterLevel] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [addedData, setAddedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changeTypeLoading, setChangeTypeLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(default_pageSize);
  const { id: scenarioId = null } = useParams();
  const [selectedItemId, setSelectedItemId] = useState([]);
  const [changeTypeModal, setChangeTypeModal] = useState(false);
  const [portfolioItemIds, setPortfolioItemIds] = useState({});
  const [relationshipPortfolioId, setRelationshipPortfolioId] = useState(null);

  //   Filter state
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const scenarioName = localStorage.getItem("scenarioName");
  const [changeTypeData, setChangeTypeData] = useState([]);



  const fetchAllChangeTypes = async ()=>{
    try {
      setLoading(true);
      const response =
        await ScenarioServices.getAllChangeTypes();
      if (response && response?.data?.code === 200) {
        const responseData = response?.data?.data || [];
        const options = responseData?.map((item)=>{return{...item,label: item?.name, value:Number(item?.id)} });
        setChangeTypeData(options);
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Scenario changes types not found.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Scenario changes types:", error);
      message.error("Error fetching Scenario changes types.");
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAllChangeTypes();
  }, []);

  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  const getBusinessPortfolioListByModuleName = async (
    selectedItem,
    filters = {}
  ) => {
    try {
      setLoading(true);
      setSelectedPortfolioItems([]);
      const { moduleName, name } = selectedItem;
      const list = await PortfolioRelationshipServices.getModuleData({
        moduleName: moduleName,
        filters: {...filters,limit:filters?.limit || limit,page: filters?.page || page},
      });
      if (list?.data?.data?.length > 0) {
        setTimeout(() => {
          setSelectedPortfolioItems(
            list?.data?.data?.map((item) => ({
              ...item,
              moduleName: moduleName,
            })) || []
          );
          setTotalRecords(list?.data?.totalCount);
          setOpenPortfolioList(true);
          setLoading(false);
          //   setOpenChoosePortfolioType(false);
        }, 300);
      } else {
        setTimeout(() => {
          setSelectedPortfolioItems([]);
          setLoading(false);
        }, 300);
        message.info(`${name} items not found`);
      }
    } catch (error) {
      setLoading(false);
      message.error(`Portfolio not found`);
      console.error(error);
    }
  };

  useEffect(() => {
    if (portfolioSettingState?.data?.length) {
      const extractedData = portfolioSettingState?.data.map((item) => ({
        ...item,
        name: item.name || "",
        moduleName: item.moduleName || "",
        id: item.id || null,
        color: item.color || "#000",
        value: item?.moduleName,
        label: normalizeString(item?.name),
      }));
      setSettingPortfolioList(extractedData);
    } else {
      fetchAllPortfolio();
    }
  }, [portfolioSettingState?.data]);

  useEffect(() => {
    if (selectedModuleItems?.moduleName) {
      const removedItems = removeEmptyArrObjOrval(selectedFilters);
      getBusinessPortfolioListByModuleName(selectedModuleItems,removedItems || {});
    }
  }, [selectedModuleItems, selectedModuleItems?.moduleName,page,limit]);
  
  

  const groupedItemIds = (data) => {
    return data?.reduce((acc, obj) => {
      const { item_id: itemId, id } = obj;
      const name = obj?.portfolioSettings?.moduleName;
      acc[name] = acc[name] || [];
      acc[name].push({ id, itemId });
      return acc;
    }, {});
  };

  const getAllScenariosItems = async () => {
    try {
      setLoading(true);
      const response =
        await ScenarioItemsServices.getScenariosItemsByScenarioId(scenarioId);
      if (response && response?.data?.code === 200) {
        const responseData = response?.data?.data || [];
        await addRecordData(responseData);
        await setPortfolioItemIds(await groupedItemIds(responseData));

        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Scenario items not found.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Scenario items:", error);
      message.error("Something went wrong.");
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllScenariosItems();
  }, [scenarioId]);

  const addNewValue = (id, itemId,portfolioSettingsId=null) => {
    let portfolioType = portfolioTypeForm?.getFieldValue()?.portfolioType;
    if (portfolioSettingsId) {
      const findType = portfolioSettingState?.data?.find((item)=> item?.id === portfolioSettingsId);
      if (findType) {
        portfolioType = findType?.moduleName;
      }
    }

    setPortfolioItemIds((prevState) => {
      // Retrieve the current array associated with the portfolio type
      const currentItems = prevState[portfolioType] || [];

      // Push the new value into the array
      const updatedItems = [...currentItems, { id: id, itemId: itemId }];

      // Return the updated state
      return {
        ...prevState,
        [portfolioType]: updatedItems,
      };
    });
  };

  const onFormSubmit = async (formData) => {
    try {
      setChangeTypeLoading(true);
      const formValues = {
        ...formData,
        scenarioId,
        itemId: selectedItemId,
        portfolioSettingsId: relationshipPortfolioId
          ? relationshipPortfolioId
          : selectedModuleItems?.portfolioId,
      };
      const arrayFormValues = { items: [formValues] };
      if (!scenarioId) {
        return message.error("Scenario not found.");
      }

      //   const response =
      //     scenarioId === null || scenarioId === undefined
      //       ? await ScenarioItemsServices.createScenarioItem(arrayFormValues)
      //       : await ScenarioItemsServices.updateScenarioItem({
      //           arrayFormValues,
      //           id: scenarioId,
      //         });

      const response = await ScenarioItemsServices.createScenarioItem(
        arrayFormValues
      );
      if (response?.data?.code === 200) {
        let responseVal = response?.data?.data;
        message.success("Change type added successfully.");
        if (responseVal?.length > 0) {
          await responseVal?.map(
            async (item) => await addNewValue(item?.id, item?.item_id,item?.portfolio_settings_id)
          );
        }
        setTimeout(() => {
          changeTypeForm.resetFields();
          //   portfolioTypeForm.resetFields();
          //   setOpenPortfolioList(false);
          setChangeTypeModal(false);
          //   setOpenChoosePortfolioType(false);
          setChangeTypeLoading(false);
        }, 1000);
      } else {
        message.error("Change type submitting failed.");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to submit form. Please try again.");
    } finally {
      setChangeTypeLoading(false);
    }
  };

  const removeValue = (idToRemove, portfolioType) => {
    // const portfolioType = portfolioTypeForm?.getFieldValue()?.portfolioType;

    setPortfolioItemIds((prevState) => {
      // Retrieve the current array associated with the portfolio type
      const currentItems = prevState[portfolioType] || [];

      // Filter out the item with the specified idToRemove
      const updatedItems = currentItems.filter(
        (item) => item.id !== idToRemove
      );

      // Return the updated state
      return {
        ...prevState,
        [portfolioType]: updatedItems,
      };
    });
  };

  const removeScenarioItemState = async ({ id, itemId, portfolioName }) => {
    await addRecordData((prevItems) =>
      prevItems.filter((item) => item.id !== id)
    );
    await removeValue(id, portfolioName);
  };

  const removeScenarioItem = async ({
    id = null,
    name = null,
    itemId = null,
    portfolioName = "",
  }) => {
    try {
      setLoading(true);
      const response = await ScenarioItemsServices.deleteScenarioItem(id);
      if (response && response?.data?.code === 200) {
        await removeScenarioItemState({ id, itemId, portfolioName });
        message.success(`${name} successfully removed.`);
      } else {
        message.error(`Failed to removed ${name}`);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error(error?.message || `Failed to delete ${name}`);
    }
  };

  const showConfirmDeleteScenarioItem = ({
    id = null,
    name = null,
    itemId = null,
    portfolioName = "",
  }) => {
    confirm({
      title: (
        <div className="font-normal text-wrap">
          Are you sure you wants to remove this{" "}
          <span className="font-semibold">{name}</span> from scenario list?
        </div>
      ),
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          await removeScenarioItem({
            id,
            name,
            itemId,
            portfolioName,
          });
        } catch (error) {
          console.error("error", error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div className="my-2 text-left scenario-list-v2">
      <div className="d-flex justify-content-between">
        <div>
          <PageTitleHeading
            text={`${scenarioName ? `${scenarioName}` : `Scenario`}`}
            width={50}
            wordBreak={true}
          />
          <div>
            <button
              type="button"
              className={`btn btn-sm btn-outline-secondary mr-1`}
            >
              <img src={listSvg} alt={`List Application`} width={`20px`} />
            </button>
            <button
              type="button"
              className={`btn btn-sm btn-outline-secondary mr-1`}
            >
              <img src={gridSvg} alt={`Grid Application`} width={`20px`} />
            </button>
          </div>
        </div>
        <Flex justify="end" align="top" className="my-3" gap={8}>
          <div>
            <ActionsBtn name={`Business Capability`} />
          </div>
          <div>
            <Link
              to="/list-scenario"
              className="btn btn-sm btn-outline-secondary"
            >
              <i className="fa fa-arrow-left"></i> Back
            </Link>
          </div>
        </Flex>
      </div>
      <hr className="my-2" />
      <div className="d-flex justify-content-between">
        <div>
          <PageTitleHeading text={`Item List`} size={4} className={`my-0`} />
          <div className="fw-lighter">
            Below is list of all portfolio entities that are part of this
            scenario
          </div>
        </div>
        <div className="ml-auto">
          <Button
            type="primary"
            onClick={() => {
              setOpenChoosePortfolioType(true);
            }}
          >
            Add Change Item
          </Button>
        </div>
      </div>
      <Spin size="large" spinning={loading}>
        {recordData?.length > 0 && (
          <div className="p-3">
            <ScenarioListData
              data={recordData}
              scenarioId={scenarioId}
              showConfirmDeleteScenarioItem={showConfirmDeleteScenarioItem}
            />
          </div>
        )}
      </Spin>
      {recordData?.length === 0 && !loading && (
        <Card bordered className={`bordered text-center rounded-0`}>
          <div className="text-center">
            <Button
              icon={<PlusOutlined />}
              shape="circle"
              className="text-center"
              onClick={() => {
                setOpenChoosePortfolioType(true);
              }}
            />
          </div>
        </Card>
      )}

      <Modal
        title="Choose Portfolio Type"
        open={isOpenChoosePortfolioType}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          portfolioTypeForm.resetFields();
          setOpenChoosePortfolioType(false);
          setSelectedModuleItems({});
          setPortfolioOpenRecord({ value: "", label: "" });
          getAllScenariosItems();
          setShowFilter(false);
          setFilterData([]);
          setSelectedFilters({});
        }}
        width={400}
        closable
      >
        <Form
          form={portfolioTypeForm}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{}}
          onFinish={async (formData) => {
            await fetchAllChangeTypes();
            let record = settingPortfolioList?.find(
              (f) => f.value === formData?.portfolioType
            );
            setSelectedModuleItems({
              moduleName: record?.moduleName,
              name: record?.name,
              portfolioId: record?.id,
            });
            setPage(1);
            setPortfolioOpenRecord(record);
            setShowFilter(false);
            setFilterData([]);
            setSelectedFilters({});
          }}
        >
          <FormFields
            type="select"
            name="portfolioType"
            options={settingPortfolioList}
            rules={[
              {
                required: true,
                message: "Please select Portfolio Type",
              },
            ]}
            formClassName="my-5"
          />
          <div className="d-flex justify-content-between">
            <div>
              <Button
                type={`primary`}
                htmlType="submit"
                className="w-100 mb-5"
                loading={loading}
              >
                Pick existing
              </Button>
            </div>
            <div>&emsp;OR&emsp;</div>
            <div>
              <Button htmlType="submit" className="w-100 mb-5" disabled>
                Add New
              </Button>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title={false}
        header={false}
        open={isOpenPortfolioList}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setShowFilter(false);
          setFilterData([]);
          setOpenPortfolioList(false);
          setSelectedModuleItems({});
          setSelectedFilters({});
        }}
        width={window.innerWidth / 1.3}
        className="cu-modal-p-0"
        closable
      >
        <div className="m-4 my-2">
          <ApplicationList
            heading={selectedModuleItems?.name}
            setOpenPortfolioList={setOpenPortfolioList}
            isOpenPortfolioList={isOpenPortfolioList}
            selectedModuleItems={selectedModuleItems}
            selectedPortfolioItems={selectedPortfolioItems}
            setSelectedPortfolioItems={setSelectedPortfolioItems}
            // portfolioloading={loading}
            loading={loading}
            setLoading={setLoading}
            // setPortfolioLoading={setLoading}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            isOpenChoosePortfolioType={isOpenChoosePortfolioType}
            setOpenChoosePortfolioType={setOpenChoosePortfolioType}
            scenarioId={scenarioId}
            portfolioTypeForm={portfolioTypeForm}
            onFormSubmit={onFormSubmit}
            selectedItemId={selectedItemId}
            setSelectedItemId={setSelectedItemId}
            changeTypeModal={changeTypeModal}
            setChangeTypeModal={setChangeTypeModal}
            changeTypeForm={changeTypeForm}
            changeTypeLoading={changeTypeLoading}
            setChangeTypeLoading={setChangeTypeLoading}
            portfolioItemIds={portfolioItemIds}
            showConfirmDeleteScenarioItem={showConfirmDeleteScenarioItem}
            settingPortfolioList={settingPortfolioList}
            setRelationshipPortfolioId={setRelationshipPortfolioId}
            getItems={getBusinessPortfolioListByModuleName}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            filterData={filterData}
            setFilterData={setFilterData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            totalRecords={totalRecords}
            setTotalRecords={setTotalRecords}
            changeTypeData={changeTypeData}
            {...props}
          />
        </div>
        {/* {portfolioOpenRecord[`value`] === 1 && (
          <ApplicationList
            heading={portfolioOpenRecord?.label}
            {...props}
            setOpenPortfolioList={setOpenPortfolioList}
          />
        )}
        {portfolioOpenRecord[`value`] === 2 && (
          <>
            <BusinessProcessList
              heading={portfolioOpenRecord?.label}
              {...props}
              setOpenPortfolioList={setOpenPortfolioList}
            />
          </>
        )}
        {portfolioOpenRecord[`value`] === 3 && (
          <>
            <BusinessCapabilityList
              heading={portfolioOpenRecord?.label}
              {...props}
              setOpenPortfolioList={setOpenPortfolioList}
            />
          </>
        )} */}
      </Modal>
    </div>
  );
};

export default ScenarioListV2;
