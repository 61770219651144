import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import AppHeader from "../shared/appHeader/AppHeader";
import SideBar from "../shared/sideBar/SideBar";
import SideMenu from "../shared/sideBar/SideMenuItems";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { bottom } from "@popperjs/core";
const { Content, Sider } = Layout;

const styleBar = {
  background : '#1f1f1f',
  overflow: "auto",
  //height: window.innerHeight,
  position: "sticky",
  top: 0,
  left: 0,
  height:"100vh",
};

const AdminLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [menu, setMenu] = useState([]);
 
  
  useEffect(  ()=>{
      
      let menuTemp=[]; 

      //menuTemp= await SideMenu();
       
        SideMenu().then((result)=>{
             menuTemp=result;
            //  console.log("menuTemp", menuTemp)
             setMenu(menuTemp?.length && menuTemp || []);
        }); 
  },[SideMenu])
   
   
  return ( 
    <>
      <Layout>
        <AppHeader />
        <Layout>
          <Sider
            id="cu-left-sidebar"
            width="235px"
            collapsible
            style={{...styleBar,}}
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              !collapsed ? <DoubleLeftOutlined /> : <DoubleRightOutlined />
            }
          >
            <SideBar menus={menu} collapsed={collapsed} />
          </Sider>
          <Content>
            <div className="w-100 container-fluid">{children}</div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminLayout;
