import React from 'react';
import {Select} from 'antd';
import { EyeFilled } from "@ant-design/icons";

const ApplicationCapabilityGrid = (props) => {

    return (
        <div className='application-capability-grid '>
            <div className='bg-aaaaaa6b card card-body p-2 border-0'>
                <div className='d-flex justify-content-end '>
                    <div className='d-flex align-items-center'>
                        <div>Level 1 Groupping &emsp;</div>
                        <Select style={{width:'200px'}} defaultValue="Area">
                            <Select.Option>Area</Select.Option>
                        </Select>
                    </div>
                    &emsp;
                    <div className='d-flex align-items-center'>
                        <div>Level 2 Groupping &emsp;</div>
                        <Select style={{width:'200px'}} defaultValue="Business Capabilities">
                            <Select.Option>Business Capabilities</Select.Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div>
                <table width={`100%`} className='acg-table'>
                    <tbody>
                        <tr>
                            <td className='td-heading'>
                                <span className='rotate-90'>
                                    Consolidation & Spare Parts
                                </span>
                            </td>
                            <td>
                                <div className='d-flex overflow-y swimlane_listbx' style={{width:window?.innerWidth-(props?.showFilter?750:470)}}>
                                    <div className='card' style={{background:props?.lifeCycleStagesColors['active']}}>
                                        <div className='card-header text-center bg-white'>
                                            <span className='fw-bold'>Consolidator</span>
                                        </div>
                                        <div className='card-body'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <div className='fw-bold'>Newcast</div>
                                                    <div>(App -751)</div>
                                                </div>
                                                <div>
                                                    <EyeFilled style={{ fontSize: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card' style={{background:props?.lifeCycleStagesColors['phaseIn']}}>
                                        <div className='card-header text-center bg-white'>
                                            <span className='fw-bold'>Spare Part Management</span>
                                        </div>
                                        <div className='card-body'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <div className='fw-bold'>Newcast</div>
                                                    <div>(App-573)</div>
                                                </div>
                                                <div>
                                                    <EyeFilled style={{ fontSize: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card' style={{background:props?.lifeCycleStagesColors['phaseOut']}}>
                                        <div className='card-header text-center bg-white'>
                                            <span className='fw-bold'>Spare Parts</span>
                                        </div>
                                        <div className='card-body'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <div className='fw-bold'>Newcast</div>
                                                    <div>(App-573)</div>
                                                </div>
                                                <div>
                                                    <EyeFilled style={{ fontSize: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className='td-heading'>
                                <span className='rotate-90'>
                                    Maintaince
                                </span>
                            </td>
                            <td>
                                <div className='d-flex overflow-y swimlane_listbx' style={{width:window?.innerWidth-(props?.showFilter?750:470)}}>
                                    <div className='card' style={{background:props?.lifeCycleStagesColors['endOfLife']}}>
                                        <div className='card-header text-center bg-white'>
                                            <span className='fw-bold'>3D Printing</span>
                                        </div>
                                        <div className='card-body'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <div className='fw-bold'>HP3D</div>
                                                    <div>(App-4188)</div>
                                                </div>
                                                <div>
                                                    <EyeFilled style={{ fontSize: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card' style={{background:props?.lifeCycleStagesColors['color6']}}>
                                        <div className='card-header text-center bg-white'>
                                            <span className='fw-bold'>Vibration Monitoring</span>
                                        </div>
                                        <div className='card-body'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <div className='fw-bold'>ISVM</div>
                                                    <div>(App-4190)</div>
                                                </div>
                                                <div>
                                                    <EyeFilled style={{ fontSize: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card' style={{background:props?.lifeCycleStagesColors['color7']}}>
                                        <div className='card-header text-center bg-white'>
                                            <span className='fw-bold'>Consolidator</span>
                                        </div>
                                        <div className='card-body'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <div className='fw-bold'>Newcast</div>
                                                    <div>(App-751)</div>
                                                </div>
                                                <div>
                                                    <EyeFilled style={{ fontSize: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className='td-heading'>
                                <span className='rotate-90'>
                                    Finance
                                </span>
                            </td>
                            <td>
                                <div className='d-flex overflow-y swimlane_listbx' style={{width:window?.innerWidth-(props?.showFilter?750:470)}}></div>
                            </td>
                        </tr>
                        <tr>
                            <td className='td-heading'>
                                <span className='rotate-90'>
                                    People Experience
                                </span>
                            </td>
                            <td>
                                <div className='d-flex overflow-y swimlane_listbx' style={{width:window?.innerWidth-(props?.showFilter?750:470)}}></div>
                            </td>
                        </tr>
                        <tr>
                            <td className='td-heading'>
                                <span className='rotate-90'>
                                    Order Planning Fulfillment & Scheduling Experience
                                </span>
                            </td>
                            <td>
                                <div className='d-flex overflow-y swimlane_listbx' style={{width:window?.innerWidth-(props?.showFilter?750:470)}}></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ApplicationCapabilityGrid