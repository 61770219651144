import React, { useState, useEffect } from "react";
import {
  Spin,
  Empty,
  Button,
  Row,
  Col,
  Modal,
  Divider,
  Input,
  Collapse,
  Tooltip,
  message,
  Pagination,
} from "antd";
import { CloseSquareFilled, PlusOutlined } from "@ant-design/icons";
import businessCapabilityServices from "../../../../services/services/BusinessCapabilityService";
import BusinessCapabilityListCards from "../../../businessCapabilityMaster/business-capability-list/BusinessCapabilityListCards";
import BCMFilter2 from "../../../businessCapabilityMaster/business-capability-list/BCMFilter2";
import usePortfolioRelationshipFetch from "../../../../hooks/usePortfolioRelationshipFetch";
import { usePortfolioRelationship } from "../../../../Context/portfolioRelationshipProvider";
import { useApplicationlink } from "../../../../Context/linkProvider";
import ConnectionsGraph from "./ConnectionsGraph";

import { applicationsList } from "../../../../utils/businessApplication";
import {
  transformData,
  bgColors,
  getNodesByModuleName,
  getPortfolioID,
} from "../../../../utils/portfolioRelationshipCommon";
import usePortfolioRelationshipModify from "../../../../hooks/usePortfolioRelationshipModify";
import PortfolioRelationshipServices from "../../../../services/services/PortfolioRelationshipService";
import BusinessApplicationServices from "../../../../services/services/BusinessApplicationService";
import BusinessApplicationListCards from "../../../applicationlist/BusinessApplicationListCards";
import { usePortfolioConnection } from "../../../../Context/portfolioConnectionProvider";
import usePortfolioFetcher from "../../../../hooks/usePortfolioFetcher";
import { useMenu } from "../../../../Context/MenuProvider";
import { PageTitleHeading, debounceTimeOut, deepFindByName, default_pageSize, default_pageSizeOptions, getSelectProperties, removeEmptyArrObjOrval, removeEmptyKeys, transformText } from "../../../../utils/Common";
import PortfolioSettingServices from "../../../../services/services/PortfolioSettingsService";
import { useParams } from "react-router-dom";
import { useBusinessApplication } from "../../../../Context/businessApplication";
import FilterRightSideBar from "../../../applicationlist/filter/FilterRightSideBar";
import { staticSettingPortfolioList } from "../../../../utils/settingCommon";
import { usePortfolioSetting } from "../../../../Context/portfolioSettingProvider";
import useDebounce from "../../../../helper/useDebounce";

const conBc = [
  {
    id: 1,
    title: "hello",
    description: "test",
  },
  {
    id: 2,
    title: "hello me",
    description: "test",
  },
  {
    id: 3,
    title: "hello me to",
    description: "test",
  },
];

const { Panel } = Collapse;
const modalContentHeight = window?.innerHeight-200;
const TabConnections = () => {
  // Access the portfolio relationship state using usePortfolioRelationship hook
  const { state, dispatch } = usePortfolioRelationship();
  const { fetchPortfolioNodesById } = usePortfolioFetcher();
  // const { state: portfolioSetting, dispatch: portfolioSettingDispatch } =
  //   usePortfolioSetting();

  const { state: portfolioConnection, dispatch: portfolioConnectionDispatch } =
    usePortfolioConnection();

  const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();

  let { moduleName: moduleNamePath } = useParams();

  const { state: linkState, dispatch: linkDispatch } = useApplicationlink();
  const [showApplicationListModal, setShowApplicationListModal] =
    useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterLevel, setFilterLevel] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [lists, setLists] = useState([]);
  const [addedData, setAddedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    nodeId: linkState?.businessApplicationId || null,
    nodeTag: linkState?.businessApplicationModel || "",
  });
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(default_pageSize);
  const [totalRecords, setTotalRecords] = useState(0);
  const [portfolioSettingData, setPortfolioSettingData] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]);
  const [searchTextCap, setSearchTextCap] = useState("");
  //Application module
  const [searchTextApplication, setSearchTextApplication] = useState("");
  const [listApplication, setListApplication] = useState([]);
  const [showListModal, setShowListModal] = useState(false);
  const [filterApplication, setFilterApplication] = useState([]);
  const [moduleName, setmoduleName] = useState();
  const [selectedCollapse, setSelectedCollapse] = useState(-1);
  const { stateMenu, dispatchMenu } = useMenu();
  const [portfolioList, setPortfolioList] = useState([]);
  const [moduleNameTemp, setmoduleNameTemp] = useState("");
  const [modelNameTemp, setmodelNameTemp] = useState("");

  const [selectedFilters, setSelectedFilters] = useState({});

  useEffect(() => {
    console.log("addedData", addedData);
  }, [addedData]);

  useEffect(() => {
    let page = 1;
    let limit = 100;
    PortfolioSettingServices.getList({ page, limit }).then((result) => {
      setPortfolioList(result?.data?.data);
    });
  }, []);

  useEffect(() => {
    if (!stateMenu?.MenuID || stateMenu?.MenuID == undefined) {
      // const menuDetail= JSON.parse(sessionStorage.getItem("sessionMenuID"));
      //  dispatchMenu({
      //   type: "MenuDetail",
      //   payload: menuDetail,
      // });

      setFilters({
        nodeId: linkState?.businessApplicationId || null,
        nodeTag: moduleNamePath || "",
      });
    }
  }, [stateMenu]);

  useEffect(() => {
    //getBusinessApplicationList();

    if (portfolioList.length) {
      const portfolioID = getPortfolioID(moduleNamePath, portfolioList);

      fetchPortfolioNodesById(portfolioConnectionDispatch, portfolioID);
    }
  }, [portfolioList]);

  useEffect(() => {
    if (moduleNameTemp) {
      getBusinessPortfolioList_Modulewise(moduleNameTemp, modelNameTemp);
    }
  }, [page, limit]);

  useEffect(() => {
    setPortfolioSettingData(portfolioConnection);
    setSelectedCollapse();
    //menuItems?.findIndex((obj) => obj.label === "Portfolio")
  }, [portfolioConnection]);

  const updateModuleName = (data, moduleName) => {
    // If data is an array, iterate through each item
    if (Array.isArray(data)) {
      return data.map((item) => {
        // Recursively update child
        if (item?.child?.length) {
          item.child = updateModuleName(item.child, moduleName);
        }
        // Update moduleName for the current item
        return { ...item, moduleName };
      });
    } else if (data instanceof Object) {
      // If data is an object
      // Recursively update child
      if (data?.child?.length) {
        data.child = updateModuleName(data.child, moduleName);
      }
      // Update moduleName for the current item
      return { ...data, moduleName };
    }
    // Return data if it's not an array or object
    return data;
  };

  const getBusinessCapabilityList = async () => {
    const list = await businessCapabilityServices.getList();

    setLists(
      updateModuleName(
        list?.data?.data,
        applicationsList?.businessCapability
      ) || []
      // list?.data?.data?.map((item) => {
      //   return { ...item, moduleName: applicationsList?.businessCapability };
      // }) || []
    );
  };

  // Application list
  const getBusinessApplicationList = async () => {
    const list = await BusinessApplicationServices.getList({
      limit: 100,
      page: 1,
    });
    setListApplication(
      list?.data?.data?.map((item) => ({
        ...item,
        moduleName: applicationsList?.businessApplication,
      })) || []
    );
    setTotalRecords(list?.data?.totalCount || 0);
    setFilterApplication(
      list?.data?.data?.map((item) => ({
        ...item,
        moduleName: applicationsList?.businessApplication,
      })) || []
    );
  };

  const addedPortfolioList = (modelName) => {
    const portfolio_tmp = transformText(modelName.replace("_", " "), "pascal");
    const addedPortfolio_arr = [];

    data &&
      data?.relations?.length &&
      Object.keys(
        getNodesByModuleName(
          data?.relations,
          portfolio_tmp
          // applicationsList.businessApplication
        )
      ).length > 0 &&
      getNodesByModuleName(
        data?.relations,
        portfolio_tmp
        //applicationsList.businessApplication
      )?.tags?.length &&
      getNodesByModuleName(
        data?.relations,
        portfolio_tmp
        //applicationsList.businessApplication
      )?.tags?.map((item, index) => {
        addedPortfolio_arr.push(item.id);
      });

    setAddedData(addedPortfolio_arr);
  };

  const getBusinessPortfolioList_Modulewise = async ({
    moduleName,
    modelName,
  }) => {
    if (!moduleName) {
      moduleName = moduleNameTemp;
    }
    setLoading(true);
    try {
      const list = await PortfolioRelationshipServices.getModuleData({
        moduleName: moduleName,
        //moduleName:"business_application",
        page: page,
        limit: limit,
      });
  
      //listApplication
      
      setListApplication(
        list?.data?.data?.map((item) => ({
          ...item,
          moduleName: transformText(
            moduleName.replace("_", " "),
            "pascal"
          ) || modelName,
        })) || []
      );

      // setListApplication(
      //   list?.data?.data?.map((item) => ({

      //     ...item,
      //     moduleName: moduleName || modelName,
      //   })) || []
      // );
      
      setTotalRecords(list?.data?.totalCount || 0);
      
      setFilterApplication(
        list?.data?.data?.map((item) => ({
          ...item,
          moduleName: transformText(
            moduleName.replace("_", " "),
            "pascal"
          ) || modelName,
          //moduleName: applicationsList?.businessApplication,
        })) || []
      );
      
      setFilterApplication(
        list?.data?.data?.map((item) => ({
          ...item,
          moduleName: transformText(
            moduleName.replace("_", " "),
            "pascal"
          ) || modelName,
          //moduleName: applicationsList?.businessApplication,
        })) || []
      );
      
      


    } catch (err) {
    } finally {
      setLoading(false);
    }

    //setShowApplicationListModal(!showApplicationListModal);
  };

  useEffect(() => {
    // setFilters({
    //   nodeId: linkState?.businessApplicationId || null,
    //   nodeTag: linkState?.businessApplicationModel || "",
    // });

    setFilters({
      nodeId: linkState?.businessApplicationId || null,
      nodeTag: moduleNamePath || "",
    });
  }, [linkState]);
 

  // Memoize the result of usePortfolioRelationshipFetch
  const loadingState = usePortfolioRelationshipFetch(filters);

  // Update the loading state only when it changes
  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  const filterArrayBySearchText = (array, searchText) => {
    const filterdArr = array.filter(
      (item) =>
        item?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
        item?.DisplayName?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
        item?.NameInSourceSystem?.toLowerCase()?.includes(
          searchText?.toLowerCase()
        )
    );
    return filterdArr;
  };

  // const filterArrayBySearchText = () => {
  //   let list;

  //   console.log("modelNameTemp ############",modelNameTemp);

  //   if (!modelNameTemp) {
  //     getBusinessPortfolioList_Modulewise(moduleNameTemp, modelNameTemp);
  //   }
  //   return list;
  // };

  //Application search changes
  useEffect(() => {
    const filteredList = filterArrayBySearchText(
      filterApplication,
      searchTextApplication
    );

    setListApplication(filteredList);
  }, [searchTextApplication]);

  useEffect(() => {
    console.log("state-->", state?.data, "loading", loading);
    if (state?.data) {
      setData(state?.data);
      if (lists?.length) {
        const connectedNodes = getNodesByModuleName(
          state?.data?.relations,
          applicationsList.businessCapability
        );
        console.log("connectedNodes", connectedNodes);
      }
    }
  }, [state, loading]);

  // Filter portfolios items start
  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    if (portfolioSettingState?.data?.length) {
      if (moduleName) {
        const portfolioValues = deepFindByName(
          portfolioSettingState?.data,
          moduleName,
          false
        );
        if (portfolioValues?.portfolioSections?.length) {
          setPortfolioData(
            getSelectProperties(portfolioValues?.portfolioSections, true)
          );
        } else {
          setPortfolioData([]);
        }
      }
    } else {
      fetchAllPortfolio();
    }
  }, [moduleName, portfolioSettingState?.data]);


  const fetchFilteredData = async (filter) => {
    try {
      setLoading(true);
      console.log("updatedFilter-data", filter);
      if (Object.keys(removeEmptyKeys(filter))?.length > 0) {
        // setList([])
        const portfolio = await deepFindByName(
          portfolioSettingState?.data,
          moduleName,
          false
        );
        const updatedFilter = {
          moduleName: portfolio?.moduleName || "",
          ...filter,
        };
        
        const response = await PortfolioSettingServices?.getFilteredPortfolios(updatedFilter,page,limit);
        console.log("Response-filter", response?.data?.data);
        if (response?.data?.data?.length) {
          setTimeout(() => {
            setLoading(false);
            setListApplication(
              response?.data?.data?.map((item) => ({
                ...item,
                moduleName: transformText(
                  portfolio?.moduleName.replace("_", " "),
                  "pascal"
                ) || transformText(
                  moduleName.replace("_", " "),
                  "pascal"
                ) || moduleName,
              })) || []
            );
            setTotalRecords(response?.data?.totalCount || 0);
          }, 500);
        } else {
          message.info("No records found.");
          setListApplication(
            response?.data?.data?.map((item) => ({
              ...item,
              moduleName: transformText(
                portfolio?.moduleName.replace("_", " "),
                "pascal"
              ) || transformText(
                moduleName.replace("_", " "),
                "pascal"
              ) || moduleName,
            })) || []
          );
          setTotalRecords(response?.data?.totalCount || 0);
        }
        console.log("response-filter-result", response);
      } else {
        setListApplication(listApplication);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Filter and debounce
  const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
  useEffect(() => {
    console.log("debouncedFilter",debouncedFilter)
    fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
  }, [debouncedFilter]);
  // Filter portfolios items end

  const ListItems = ({ item, isLast, isFirst, moduleName }) => {
    console.log(item, "=====item");
    return (
      <div className={`${isFirst && "mt-2"} `}>
        <div className="detailslist-bx">
          <div>
            <div className="h5">
              {item?.levelID || item?.referenceID || "-"}{" "}
              {item?.displayName || "-"}
            </div>
            <p className="whitespace-normal break-words">{item.description}</p> 
          </div>
          <div>
            <Tooltip title={`Unlink`} placement="right">
              <Button
                type="default"
                icon={<i className="fas fa-unlink"></i>}
                className="my-0"
                danger
                onClick={() => {
                  handleRemoveRelationship({
                    id: item.relationShipId,
                    moduleName,
                  });
                }}
              />
            </Tooltip>
          </div>
        </div>

        {/* <Divider className="my-4 bg-[#f7f7f7]" /> */}
      </div>
    );
  };

  const {
    addPortfolioRelationship,
    removePortfolioRelationship,
    dataLoading,
    error,
  } = usePortfolioRelationshipModify();

  const handleAddRelationship = async ({ id, moduleName }) => {
    try {
      const addedData = await addPortfolioRelationship(
        { id, moduleName },
        fetchDataAfterOperation
      );

      setListApplication(
        listApplication?.map((item) => {
          return { ...item, relationShipId: addedData[0]?.id || null };
        }) || []
      );
      setFilterApplication(
        filterApplication?.map((item) => {
          return { ...item, relationShipId: addedData[0]?.id || null };
        }) || []
      );
    } catch (error) {
      console.error("Error adding relationship:", error);
      console.error("Error adding relationship:", error);
    }
  };

  const handleRemoveRelationship = async ({ id, moduleName }) => {
    try {
      await removePortfolioRelationship(id, fetchDataAfterOperation);
    } catch (error) {
      console.error("Error removing relationship:", error);
    }
  };

  const fetchDataAfterOperation = async () => {
    try {
      setLoading(true);
      const response = await PortfolioRelationshipServices.getAllNode(filters);
      if (response?.statusText !== "OK") {
        throw new Error("Failed to fetch data");
      }
      const newData = response.data.data;
      dispatch({ type: "SET_PORTFOLIO_RELATIONSHIPS", payload: newData });
    } catch (error) {
      console.log("Error fetching data:", error);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const BusinessRows = (data) => {
    return (
      <div className=" my-1 p-2 max-h-[320px]overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
        {(data &&
          data?.relations?.length &&
          Object.keys(
            getNodesByModuleName(
              data?.relations,
              applicationsList.businessCapability
            )
          ).length > 0 &&
          getNodesByModuleName(
            data?.relations,
            applicationsList.businessCapability
          )?.tags?.length &&
          getNodesByModuleName(
            data?.relations,
            applicationsList.businessCapability
          )?.tags?.map((item, index) => {
            const newItem = {
              ...item,
              title: item.nameInSourceSystem,
              description: item.description,
            };

            return (
              <React.Fragment key={Math.random()}>
                <ListItems
                  key={index}
                  item={newItem}
                  isLast={index === conBc.length - 1}
                  isFirst={index === 0}
                  moduleName={applicationsList.businessCapability}
                />
              </React.Fragment>
            );
          })) || <div></div>}
      </div>
    );
  };

  const PortFolioItems = ({ item, data }) => {
    return (
      <div>
        <div className="card card-body relationdetail_bx">
          {item?.connectedNodes?.map((rec, index) => {
            let portfolio_tmp = "";

            portfolio_tmp = transformText(
              rec.moduleName.replace("_", " "),
              "pascal"
            );

            // if (rec.name === "Application") {
            //   portfolio_tmp = "BusinessApplication";
            // } else if (rec.name === "Business Capability") {
            //   portfolio_tmp = "BusinessCapability";
            // }

            let cnt =
              getNodesByModuleName(data?.relations, portfolio_tmp)?.tags
                ?.length || 0;

            return (
              <>
                <Collapse
                  defaultActiveKey={
                    selectedCollapse === index && `${selectedCollapse}`
                  }
                  onClick={() => {
                    setSelectedCollapse(index);
                  }}
                  expandIconPosition="start"
                  bordered={true}
                  className="settings-menus"
                >
                  <Panel
                    header={`${rec.name}    (${cnt})  `}
                    key={index}
                    style={{ width: "100%" }}
                    className="panel"
                    extra={
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={async () => {
                          addedPortfolioList(rec.name);
                          setmoduleName(rec.name);
                          addedPortfolioList(rec.moduleName);

                          setmoduleNameTemp(rec.moduleName);
                          setmodelNameTemp(portfolio_tmp);

                          setShowApplicationListModal(
                            !showApplicationListModal
                          );
                          setPage(1);
                          //setmoduleName(transformText(rec.moduleName.replace("_"," "), "pascal"));
                          //addedPortfolioList(transformText(rec.moduleName.replace("_"," "), "pascal"))
                          await getBusinessPortfolioList_Modulewise({
                            moduleName: rec.moduleName,
                            modelName: portfolio_tmp,
                          });
                        }}
                      />
                    }
                  >
                    {/* <legend className="mb-0"> {rec.name}</legend> */}
                    {/* <hr className="mt-0 mb-4" /> */}
                    {(data &&
                      data?.relations?.length &&
                      Object.keys(
                        getNodesByModuleName(
                          data?.relations,
                          portfolio_tmp
                          // applicationsList.businessApplication
                        )
                      ).length > 0 &&
                      getNodesByModuleName(
                        data?.relations,
                        portfolio_tmp
                        //applicationsList.businessApplication
                      )?.tags?.length &&
                      getNodesByModuleName(
                        data?.relations,
                        portfolio_tmp
                        //applicationsList.businessApplication
                      )?.tags?.map((item, index) => {
                        const newItem = {
                          ...item,
                          title: item.nameInSourceSystem,
                          description: item.description,
                        };

                        return (
                          <ListItems
                            key={index}
                            item={newItem}
                            isLast={index === conBc.length - 1}
                            isFirst={index === 0}
                            //moduleName={applicationsList.businessCapability}
                            moduleName={portfolio_tmp}
                          />
                        );
                      })) || <div></div>}
                  </Panel>
                </Collapse>
                {/* <Row>
                  <Col span={24}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={async () => {
                        addedPortfolioList(rec.name)
                        setmoduleName(rec.name);
                        await getBusinessPortfolioList_Modulewise({
                          moduleName: rec.moduleName,
                          modelName: portfolio_tmp,
                        });
                      }}
                    >
                      Connect {rec.name}
                    </Button>
                  </Col>
                </Row> */}
                {/* <br /> */}
                {/* <hr className="mt-1 mb-4" /> */}
              </>
            );
          })}
          <hr />
        </div>
      </div>
    );
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="line-height-normal">
          {/* <div className="fs-4">Relations</div> */}
          <small className="w-100">
            (this screen shows the relationship between current object and other
            objects in the portfolio)
          </small>
        </div>
        <hr />
        <ConnectionsGraph
          graphData={
            Object.keys(data).length
              ? transformData(data, bgColors, portfolioList)
              : []
          }
          portfolioList={portfolioList}
        />
        {/* <Empty 
                    description={
                        <>
                            <div className='line-height-normal my-5'>
                                <div className='fs-4'>No Current Connections</div>
                                <small className='w-100'>(You can create connections below)</small>
                            </div>
                        </>
                    }
                    image={false}
                    imageStyle={{ height: 0 }}
                /> */}
        <hr />
        {/* {(filters?.nodeTag === applicationsList?.businessApplication && ( */}

        <div>
          <div className="line-height-normal">
            <div className="fs-4">
              <PageTitleHeading text={`Details`} size={2} />
            </div>
          </div>
          {portfolioSettingData?.data && (
            <PortFolioItems item={portfolioSettingData?.data} data={data} />
          )}
        </div>
      </Spin>

      {/* <ModelPortfolioPopup showApplicationListModal1={showApplicationListModal}/> */}

      <Modal
        title={
          <div className="d-flex justify-content-between mb-2">
            <div>
              <PageTitleHeading
                text={moduleName + " List"}
                className={`my-0`}
              />
            </div>
            <div>
              {/* <Input.Search
                style={{ width: "150px", marginLeft: "20px" }}
                placeholder="Search"
                onChange={(e) =>
                  setSearchTextApplication(e?.target?.value || "")
                }
              ></Input.Search> */}

              <Button
                onClick={() => {
                  setPage(1);
                  setLimit(default_pageSize);
                  setSelectedFilters({});
                  setShowApplicationListModal(!showApplicationListModal);
                }}
                className="mr-2 ml-3"
              >
                Close
              </Button>
            </div>
          </div>
        }
        centered
        open={showApplicationListModal}
        onOk={() => setShowListModal(!showListModal)}
        onCancel={() => setShowListModal(!showListModal)}
        width={window.innerWidth - 200}
        footer={false}
        closeIcon={false}
      >
        <div className="d-flex justify-content-between">
          <div
            id="portfolioList"
            className="w-100 container-fluid  border-right"
          >
            <Row gutter={[8, 8]} className="mx-0 text-left mt-2">
              <Col span={24} className="p-2">
                <Spin size="small" spinning={loading}>
                <div className="overflow-y" style={{maxHeight:modalContentHeight}}>
                    <BusinessApplicationListCards
                      cardModuleName={moduleName?.replace(" ", "")}
                      moduleName={moduleNameTemp}
                      filterLevel={filterLevel}
                      filterText={filterText}
                      filterBy={filterBy}
                      lists={listApplication}
                      addRemove={true}
                      setAddedData={setAddedData}
                      addedData={addedData}
                      handleAddRelationship={handleAddRelationship}
                      handleRemoveRelationship={handleRemoveRelationship}
                      dataLoading={dataLoading}
                    />
                  </div>
                  {
                    totalRecords>0 &&
                    <Pagination
                      className="mt-2 text-right"
                      total={totalRecords}
                      showTotal={(total) => `Total ${total} items`}
                      defaultPageSize={default_pageSize}
                      defaultCurrent={page}
                      pageSizeOptions={default_pageSizeOptions}
                      showSizeChanger
                      onChange={(p, ps)=>{
                        setLimit(ps)
                        setPage(p);
                      }}
                    />
                  }
                </Spin>
              </Col>
            </Row>
          </div>
          <div>
            {/* filter  */}
            <FilterRightSideBar
              activeGridStyle={1}
              selectedFilters={selectedFilters}
              portfolioData={portfolioData || []}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              // setSelectedFilters={setSelectedFilters}
              setSelectedFilters={(value)=>{ setPage(1);setSelectedFilters(value); }}
              allowSlider={false}
              optionalLevel={
                moduleName === staticSettingPortfolioList.businessCapability
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TabConnections;
