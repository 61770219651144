import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import React from 'react'
import { useNavigate } from "react-router";

export default function BusinessProcessButtonsV1({moduleName,item,...props}) {

    const navigate = useNavigate();
    return (
        <div className="text-right d-flex">
            {props?.showButtons?.view && (
            <EyeOutlined
                className="cursor-pointer ml-2"
                onClick={() => {
                    navigate('/business-process/'+moduleName+'/'+item?.id  )
                }}
                style={{ fontSize: "20px" }}
            />
            )}
            {props?.showButtons?.edit && (
            <EditOutlined
                className="cursor-pointer ml-2 text-blue-500"
                onClick={() => {
                    navigate('/business-process/'+moduleName+'/'+item?.id  )
                }}
                style={{ fontSize: "20px" }}
            />
            )}
            {props?.showButtons?.delete && (
            <DeleteOutlined
                className="cursor-pointer ml-2 text-danger"
                onClick={() => {
                    props?.setItemDelete(item, { type: "delete" });
                }}
                style={{ fontSize: "20px" }}
            />
            )}
        </div>
    )
}
