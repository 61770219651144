import { Flex, List } from 'antd';
import React from 'react';
import { PageTitleHeading } from '../../utils/Common';

const contentsData = [
    {
        title:"Lab 95- Replacement",
        createdBy:"John Doe",
        createdAt:"01 Apr, 2024"
    },
    {
        title:"Customer order Change",
        createdBy:"John Doe",
        createdAt:"01 Apr, 2024"
    },
]
const SurveyRightCard = () => {
    return <div>
        <Flex className='mt-2 mb-3' vertical>
            <PageTitleHeading text={`Invitations`} size={`5`} className={`m-0`}/>
            <div className='fs-small'>(You have beeen invited to answer following surveys.)</div>
        </Flex>
        <List
            size="small"
            bordered
            dataSource={contentsData}
            renderItem={
                (item) => 
                    <List.Item>
                        <Flex gap={8} className='w-100' vertical>
                            <div>
                                <h6 className='mb-0 text-primary'>{item.title}</h6>
                                <div className='mb-0 fs-small'>Created by: {item.createdBy}</div>
                            </div>
                            <div className='fs-small'>
                                {item?.createdAt}
                            </div>
                        </Flex>
                    </List.Item>
                }
            />
    </div>;
}

SurveyRightCard.defaultProps = {};
// #endregion

export default SurveyRightCard;