const staticSettingPortfolioList = {
  application: "Application",
  businessCapability: "Business Capability",
  businessProcess: "Business Process",
  dataObject: "Data Object",
  information: "Information",
  initiative: "Initiative",
  objectiveOKR: "Objective (OKR)",
  organization: "Organization",
  technology: "Technology",
};
export { staticSettingPortfolioList };
