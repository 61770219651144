import React, { useState } from 'react';
import IntegrationHeader from './IntegrationHeader';
import "./Integration.scss";
import { Layout, Spin } from 'antd';
import IntegrationFilter from './IntegrationFilter';
import IntegrationList from './IntegrationList';
import { default_integration_lists } from './IntegrationCommon';
import IntegrationGrid from './IntegrationGrid';
import { useLocation } from 'react-router';

const lifeCycleStagesColors = {
    active: "#FFB381",
    phaseIn: "#FFF280",
    phaseOut: "#FC819E",
    endOfLife: "#B2B377",
    color5: "#B5C0D0",
    color6: "#BFEF82",
    color7: "#90DDF9",
};

const sortByValue = [
    { value: "Level", title: "Level" },
    { value: "name", title: "Name" },
];

const Integration = ({moduleName}) => {
    const location = useLocation();
    const [loading,setLoading] = useState(false);
    const [activePage,setActivePage] = useState(location?.state?.activePage || 1);
    const [showFilter, setShowFilter] = useState(false);
    const [portfolioData, setPortfolioData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    
    return (
        <Spin spinning={loading} className='integration-page'>
            <Layout>
                <Layout.Content className='border-right pr-2' style={{minHeight:window?.innerHeight}}>
                    <IntegrationHeader moduleName={moduleName} setActivePage={setActivePage} activePage={activePage}/>
                    <div className='p-2'>
                        {activePage ===1 && <IntegrationList lists={default_integration_lists} moduleName={moduleName}/>}
                        {activePage ===2 && <IntegrationGrid lists={default_integration_lists} moduleName={moduleName}/>}
                    </div>
                </Layout.Content>
                <Layout.Sider theme='light' width={(!showFilter)?50:250}>
                    <IntegrationFilter
                        lifeCycleStagesColors={lifeCycleStagesColors}
                        showFilter={showFilter}
                        setShowFilter={setShowFilter}
                        portfolioData={portfolioData}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        sortValues={sortByValue}
                        optionalLevel={false}
                    />
                </Layout.Sider>
            </Layout>
        </Spin>
    )
}

Integration.defaultProps = {
    moduleName:"Integration",
}

export default Integration