import React, { createContext, useContext, useReducer } from "react";

// Define the PortfolioRelationship context
const PortfolioRelationshipContext = createContext();

// Custom hook to use PortfolioRelationship context
export const usePortfolioRelationship = () =>
  useContext(PortfolioRelationshipContext);

// Initial state for portfolio relationships
const initialState = {
  data: [],
  loading: false,
  error: false,
};

// Reducer function to handle state updates
const portfolioRelationshipReducer = (state, action) => {
  switch (action.type) {
    case "SET_PORTFOLIO_RELATIONSHIPS":
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case "ADD_PORTFOLIO_RELATIONSHIP":
      return {
        ...state,
        data: Array.isArray(state.data)
          ? [...state.data, ...action.payload]
          : [...action.payload],
        loading: false,
        error: false,
      };
    case "UPDATE_PORTFOLIO_RELATIONSHIP":
      return {
        ...state,
        data: state.data.map((relationship) =>
          relationship.id === action.payload.id ? action.payload : relationship
        ),
        loading: false,
        error: false,
      };
    case "DELETE_PORTFOLIO_RELATIONSHIP":
      console.log("state.data",state.data)
      return {
        ...state,
        data: state?.data?.filter(
          (relationship) => relationship.id !== action.payload.id
        ),
        loading: false,
        error: false,
      };
    // Add more cases for other actions if needed
    default:
      return state;
  }
};

// Provider component to manage portfolio relationships state
export const PortfolioRelationshipProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    portfolioRelationshipReducer,
    initialState
  );

  return (
    <PortfolioRelationshipContext.Provider value={{ state, dispatch }}>
      {children}
    </PortfolioRelationshipContext.Provider>
  );
};
