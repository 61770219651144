import { Button, Flex, List } from 'antd';
import React from 'react';
import { PageTitleHeading } from '../../utils/Common';

const contentsData = [
    {
        title:"Daniel Mac",
        desc:"Customer Order Survey",
        createdAt:"03 Apr, 2024 14:30:25 PM"
    },
    {
        title:"Jermy Smith",
        desc:"Matlab Survey",
        createdAt:"03 Apr, 2024 14:30:25 PM"
    },
    {
        title:"VCK Smith",
        desc:"Matlab Survey",
        createdAt:"03 Apr, 2024 14:30:25 PM"
    },
]
const SurveyContentCard = () => {
    return <div>
        <Flex justify='space-between' align='center' className='mb-3'>
            <PageTitleHeading text={`Latest Response to my Survey`} size={`5`} className={`m-0`}/>
            <Button type="link">View All</Button>
        </Flex>
        <List
            size="small"
            bordered
            dataSource={contentsData}
            renderItem={
                (item) => 
                    <List.Item>
                        <Flex justify='space-between' className='w-100'>
                            <div>
                                <h6 className='mb-0'>{item.title}</h6>
                                <div className='mb-0 fs-small'>{item.desc}</div>
                            </div>
                            <div className='fs-small'>
                                {item?.createdAt}
                            </div>
                        </Flex>
                    </List.Item>
                }
            />
    </div>;
}

SurveyContentCard.defaultProps = {};
// #endregion

export default SurveyContentCard;