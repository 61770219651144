import React from "react";
import { Button, Col, Empty, Modal, Tooltip, message } from "antd";
import Tree from "react-d3-tree";
import { PaperClipOutlined } from "@ant-design/icons";
import "./ConnectionsGraph.scss";
import useDynamicNavigate from "../../../../utils/navigateUtils";
import { convertToNormalText, dynamicRoutesLinks ,getPortfolioColor,getPortfolioID} from "../../../../utils/portfolioRelationshipCommon";
import { useMenu } from "../../../../Context/MenuProvider";
import { useApplicationlink } from "../../../../Context/linkProvider";
import{transformText } from '../../../../utils/Common';
import { cleanData } from "jquery";


const myTreeData = [
  {
    title: "LAB-95",
    background: "#ffb484",
    children: [
      {
        title: "Application",
        background: "#ffb484",
        children: [],
      },
      {
        title: "Business Process",
        background: "#fff484",
        children: [
          {
            title: "BP ABC",
            background: "#fff484",
            children: [],
          },
          {
            title: "BP XYZ",
            background: "#fff484",
            children: [],
          },
          {
            title: "BP LNM",
            background: "#fff484",
            children: [],
          },
        ],
      },
      {
        title: "Business Domain",
        background: "#c0ec84",
        children: [],
      },
      {
        title: "Product",
        background: "#98dcfc",
        children: [],
      },
      {
        title: "Product Center",
        background: "#ffbce4",
        children: [],
      },
      {
        title: "Product Area",
        background: "#c0f4e4",
        children: [],
      },
      {
        title: "Initiative",
        background: "#e0b4fc",
        children: [],
      },
      {
        title: "Application Environment",
        background: "#88ace4",
        children: [],
      },
      {
        title: "Business Capabilities",
        background: "#58bcbc",
        children: [
          {
            title: "BC-1",
            background: "#58bcbc",
            children: [],
          },
          {
            title: "BC-2",
            background: "#58bcbc",
            children: [],
          },
        ],
      },
    ],
  },
];

const test = {
  shape: "rect",
  shapeProps: {
    width: 0,
    height: 0,
    x: -20,
    y: 20,
    stroke: "#2F80ED",
  },
};

const truncateText = (text, maxLength = 20) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
};
const isTextLong = (text) => {
  return text?.length > 25;
};

const handleLink = async (values, navigateTo, portfolioList, stateMenu, dispatchMenu) => {
   
  const colorPortfolio=getPortfolioColor(values?.parentTitle,portfolioList);
  const portfolioID=getPortfolioID(values?.parentTitle,portfolioList);
   

  const redirectLink = dynamicRoutesLinks?.find(
    (item) => item?.moduleName === values?.parentTitle
  );
  
  
  
  if (values?.parentTitle && redirectLink && values?.data?.id) {
     await dispatchMenu({
      type: "MenuDetail",
      payload: { Name: redirectLink?.link, MenuName: values?.parentTitle, ID: portfolioID, moduleName:values?.parentTitle,  color: colorPortfolio },
    });
      
    // dispatch({
    //   type: "SET_BUSINESS_APPLICATION_MODEL",
    //   //payload: applicationsList.businessApplication,
    //   payload:stateMenu?.MenuID?.moduleName,
    // });

    const newLink = redirectLink?.link +values?.parentTitle +'/'+ values?.data?.id  ;
    navigateTo(newLink, true);
  } else {
    message.error("Link not found.");
  }
  //   navigateTo("/business-capability-master",true);
};

const renderCard = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps = {},
  navigateTo,
  portfolioList,
  stateMenu, 
  dispatchMenu
}) => {
  let iconBtn;
 
  let caption=""; 


  portfolioList.map((item)=>{
  
    const portfolio_tmp = transformText(item.moduleName.replace("_", " "), "pascal");
    
    if (portfolio_tmp===nodeDatum.title || item.moduleName===nodeDatum.title  )
      {
        caption= item.name
      } 
  });



  const handleIconClick = () => {
   
    if (nodeDatum?.__rd3t?.depth === 2) {
      // Perform action only if node depth is 2
      handleLink(nodeDatum, navigateTo, portfolioList, stateMenu, dispatchMenu);
    }
  };

  if (nodeDatum?.__rd3t?.depth === 1) {
    iconBtn = (
      <span className="cu-border-22 pull-right">
        {nodeDatum.children.length === 0 ? 1 : nodeDatum.children.length}
      </span>
    );
  } else if (nodeDatum?.__rd3t?.depth === 2) {
    iconBtn = (
      <span className="pull-right">
        <PaperClipOutlined />
      </span>
    );
  }
 
  return (
    
    <React.Fragment>
      <foreignObject
        {...foreignObjectProps}
        width="225"
        height="300"
        x="-100"
        y="-18"
      >
        <div
          className="card card-body p-2 rounded-0"
          onClick={toggleNode}
          style={{ background: nodeDatum?.background }}
        >
          {nodeDatum?.__rd3t?.depth === 2 ? (
            <div
              className="mb-0 text-nowrap text-left"
              onClick={() => {
                <Modal></Modal>;
              }}
            >
              {isTextLong(nodeDatum.title) ? (
                <Tooltip title={convertToNormalText(nodeDatum.title)}>
                  <span>{truncateText(convertToNormalText(nodeDatum.title))}</span>
                </Tooltip>
              ) : (
                
                <span>{convertToNormalText(nodeDatum.title)}</span>
                 
              )}
              <span className="pull-right" onClick={handleIconClick}>
                <PaperClipOutlined />
              </span>
              {/* {nodeDatum.title} {iconBtn} */}
            </div>
          ) : (
            <div className="mb-0 text-nowrap text-left">
              {isTextLong(nodeDatum.title) ? (
                <Tooltip title={convertToNormalText(nodeDatum.title)}>
                  <span>{truncateText(convertToNormalText(nodeDatum.title))}</span>
                </Tooltip>
              ) : (
                // <span>{convertToNormalText(nodeDatum.title)}</span>
                nodeDatum?.__rd3t?.depth === 1 ? ( 
                <span>{convertToNormalText(caption)}</span>)
                : <span>{convertToNormalText(nodeDatum.title)}</span>
              )}
              {iconBtn}
              {/* {nodeDatum.title} {iconBtn} */}
            </div>
          )}
        </div>
      </foreignObject>
    </React.Fragment>
  );
};

const ConnectionsGraph = ({ graphData, portfolioList, ...props }) => {
  const navigateTo = useDynamicNavigate();
  console.log(graphData,"===graphData")
  
  const { stateMenu, dispatchMenu } = useMenu();
  const { state, dispatch } = useApplicationlink();
  
  const handleClick = (text) => alert(text);
  return (
    <div id="connection-charts">
      <div className="App">
        {(graphData?.length && (
          <div
            id="treeWrapper"
            style={{ width: "100%", height: "55vh" }}
            className="px-2"
          >
            <Tree
              data={graphData}
              nodeSvgShape={test}
              pathFunc="step"
              separation={{ siblings: 2, nonSiblings: 2 }}
              orientation="horizontal"
              translate={{ x: 100, y: 150 }}
              nodeSize={{ x: 350, y: 25 }}
              allowForeignObjects={false}
              initialDepth={0.02}
              zoomable={false}
              renderCustomNodeElement={(rd3tProps) =>
                renderCard({ ...rd3tProps, handleClick, navigateTo, portfolioList, stateMenu, dispatchMenu })
              }
            />
          </div>
        )) || (
          <Empty
            description={
              <>
                <div className="line-height-normal my-5">
                  <div className="fs-4">No Current Relations</div>
                  <small className="w-100">
                    (You can create relations below)
                  </small>
                </div>
              </>
            }
            image={false}
            imageStyle={{ height: 0 }}
          />
        )}
      </div>
    </div>
  );
};

export default ConnectionsGraph;
