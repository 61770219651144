import { useEffect } from 'react';
import Draggable from 'react-draggable';

const Legends = (props) => {
  const { legendsLabel } = props;

  return (
    <Draggable handle=".handle" defaultPosition={{ x: -15, y: -715 }}>
      <div className="handle">
        <div id="legend-container" className="legend-container label-legend">
          <div className="legend-span-item">Legend</div>
          {legendsLabel &&
            legendsLabel?.map((item, index) => {
              return (
                <>
                  <div className="icon">{item?.icon}</div>
                  <div className="label">{item?.label}</div>
                </>
              );
            })}
        </div>
      </div>
    </Draggable>
  );
};

export default Legends;
