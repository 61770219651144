import React from 'react';
import BusinessProcess from '../components/businessProcess/BusinessProcess';
import AdminLayout from '../components/layouts/AdminLayout';

const BusinessProcessPage = (props) => {
    return (
        <>
            <BusinessProcess/>
        </>
    )
}

BusinessProcessPage.propTypes = {}

export default BusinessProcessPage