import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from "react";
import {
    Select,
    Form,
    Row,
    Col,
    Upload,
    message,
    Button,
} from "antd";
import DataImportScenarioServices from '../../services/services/DataImportScenarioServices';

const styleFormAlign = {
    textAlign: "justify",
}

const fileTypeOptions = [
    { value: 'excel', label: 'Excel', fileType:'.xlsx' },
    { value: 'csv', label: 'CSV (Under Development)',fileType:'.csv'},
    { value: 'json', label: 'JSON (Under Development)',fileType:'.json'},
]

function DataImportForm1(props) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [fileAcceptType, setFileAcceptType] = useState();

    const uploadFile = {
        beforeUpload:()=> {return false; },
    };

    const onFinish = (formData) => {
        props?.setLoading(true);

        let postData = {};
        postData.image = formData?.upload_file?.file;

        DataImportScenarioServices
        .saveSheetAzure(postData)
        .then((response) => {
            if (response.data.code === 200) {
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                });
                form.resetFields();
            } else {
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                });
            }
            props?.setLoading(false);
        }).catch((err) => {
            console.log(err);
            props?.setLoading(false);
        });
    }

    return (
        <>
            {contextHolder}
            <Form
                layout="vertical"
                onFinish={onFinish}
                form={form}
            >
                <Row gutter={16} justify="start">
                    <Col xs={{ span: 24}} md={{ span: 6}}>
                        <Form.Item name="upload_type" label="Data" rules={[{ required: true }]} style={styleFormAlign}>
                            <Select
                                placeholder="Data"
                                allowClear
                            >
                                <Select.Option value="all">All</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24}} md={{ span: 6}}>
                        <Form.Item name="file_type" label="File Type" rules={[{ required: true }]} style={styleFormAlign}>
                            <Select
                                placeholder="File Type"
                                options={fileTypeOptions}
                                allowClear
                                onChange={(value,option)=>{
                                    setFileAcceptType(option?.fileType);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24}} md={{ span: 6}}>
                        <Form.Item 
                            name="upload_file" 
                            label={<div className='d-flex justify-content-between'><span>File Upload</span><span>&emsp;<a href="https://connected-architecture-qa.truerouta.com/assets/format/excel_format.xlsx" target="_blank" download>Download</a></span></div>} 
                            rules={[{ required: true ,message:"Please enter Upload"}]}  
                            style={styleFormAlign}
                        >
                            <Upload {...uploadFile} accept={fileAcceptType}>
                                <Button icon={<UploadOutlined />} style={{width:'100%'}} >Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24}} md={{ span: 6}}>
                        <Form.Item>
                            <Button htmlType="submit" className="w-100 mt-30" >
                                Import
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default DataImportForm1