import { Layout, Modal, Pagination, Spin, message } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import BusinessProcessListFilter from './list/BusinessProcessListFilter';
import { usePortfolioSetting } from '../../Context/portfolioSettingProvider';
import { debounceTimeOut, deepFindByName, default_pageSize, default_pageSizeOptions, getSelectProperties, removeEmptyArrObjOrval, removeEmptyKeys } from '../../utils/Common';
import { staticSettingPortfolioList } from '../../utils/settingCommon';
import usePortfolioFetcher from '../../hooks/usePortfolioFetcher';
import BusinessProcessListContent from './list/BusinessProcessListContent';
import BusinessProcessServices from '../../services/services/BusinessProcessServices';
import BusinessProcessButtonsV1 from './list/BusinessProcessButtons';
import useDebounce from '../../helper/useDebounce';
import PortfolioSettingServices from '../../services/services/PortfolioSettingsService';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { UpdateChangeLog } from '../../utils/ChangeLogs';
import LoadingBar from 'react-top-loading-bar'

const lifeCycleStagesColors = {
    active: "#FFB381",
    phaseIn: "#FFF280",
    phaseOut: "#FC819E",
    endOfLife: "#B2B377",
    color5: "#B5C0D0",
    color6: "#BFEF82",
    color7: "#90DDF9",
};

const sortByValue = [
    { value: "Level", title: "Level" },
    { value: "name", title: "Name" },
];
const BusinessProcessList = ({HeaderContnet,moduleName,...props}) => {
    const ref = useRef(null)
    const [showFilter, setShowFilter] = useState(false);
    const [portfolioData, setPortfolioData] = useState([]);
    const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } = usePortfolioSetting();
    const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
    const [selectedFilters, setSelectedFilters] = useState({});
    const [lists,setLists] = useState([]);
    const [loading,setLoading] = useState(true);
    const [filterChanges, setFilterChanges] = useState(false);

    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(default_pageSize);

    useEffect(() => {
        if (portfolioSettingState?.data?.length) {
            const portfolioValues = deepFindByName( portfolioSettingState?.data, staticSettingPortfolioList?.businessCapability, false );
            if (portfolioValues?.portfolioSections?.length) {
                setPortfolioData(getSelectProperties(portfolioValues?.portfolioSections, true));
            } else {
                setPortfolioData([]);
            }
        } else {
            fetchAllPortfolio();
        }
         
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState?.data]);

    const fetchAllPortfolio = async () => {
        try {
          const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
          if (response) {
            // message.success("Portfolio fetched successfully.");
          } else {
            message.error("Portfolio not found.");
          }
        } catch (error) {
          console.error("Error fetching portfolio:", error);
          message.error("Something went wrong.");
        }
    };

    const Buttons = (item) => {

        return <BusinessProcessButtonsV1 
            {...item} 
            setItemDelete={showConfirmDelete}
            moduleName={moduleName}
            showButtons={{
                view:true,
                edit:true,
                delete:true,
            }}
        />
    }

    useEffect(() => {
        setLoading(true);
        fetchFilteredData(selectedFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState]);

    // Filter and debounce
    const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
    useEffect(() => {
        fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFilter,page,limit]);

    const fetchFilteredData = async (filter) => {
        try {
            setLoading(true);
            if (portfolioSettingState?.data?.length) {
                filter  = removeEmptyKeys(filter);
                const portfolio = await deepFindByName(
                    portfolioSettingState?.data,
                    staticSettingPortfolioList?.businessProcess,
                    false
                );
                const updatedFilter = {
                    moduleName: portfolio?.moduleName || "business_process",
                    ...filter,
                };
                
                const response = await PortfolioSettingServices?.getFilteredPortfolios(updatedFilter,page,limit);
                if (response?.data?.data?.length) {
                    setTimeout(() => {
                        setLoading(false);
                        setLists(response?.data?.data);
                        setTotalRecords(response?.data?.totalCount || 0);
                    }, 500);
                } else {
                    message.info("No records found.");
                    setTimeout(() => {
                        setLists(response?.data?.data);
                        setTotalRecords(response?.data?.totalCount || 0);
                        setFilterChanges(true)
                    }, 500);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const showConfirmDelete = (item,type) => {
        const id = item?.id || 0;
        Modal.confirm({
            title: 'Are you sure to delete?',
            icon: <ExclamationCircleFilled />,
            async onOk() {
                try {
                    let result;
                    result = await BusinessProcessServices.deletebusinessProcess(id);
                    if (result) {
                        let obj = {
                            id:id,
                            NameInSourceSystem: item?.NameInSourceSystem || "",
                            DisplayName: item?.DisplayName || "",
                            Description:item?.Description || "",
                            LevelID:item?.LevelID || "",
                        } 

                        let field_OldData = {
                            DisplayName: item?.DisplayName || "",
                            Description:item?.Description || "",
                            LevelID:item?.LevelID || "",
                        }
                    
 
                        console.log("Change Log Data Awa ", obj);

                        await UpdateChangeLog(obj.id,field_OldData,obj, "Delete", moduleName)

                        await fetchFilteredData(selectedFilters);;
                        message.success(result?.data?.msg);
                    } else {
                        message.error(result?.data?.msg)
                    }
                } catch (error) {
                    message.error(error?.message)
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    
    return (
        <>
            <LoadingBar color='blue' ref={ref} />
            <Layout>
                <Layout.Content className='border-right'>
                    <Spin spinning={loading}>
                        <div className='container-fluid'>
                            {HeaderContnet}
                            <div className='overflow-y' style={{maxHeight:window?.innerHeight-200}}>
                                <BusinessProcessListContent
                                    lists = {lists}
                                    ButtonComponents = {Buttons}
                                    loading={loading}
                                    moduleName={moduleName}
                                />
                            </div>
                            {totalRecords>0 &&
                                <Pagination
                                    className="mt-2 text-right"
                                    total={totalRecords}
                                    showTotal={(total) => `Total ${total} items`}
                                    defaultPageSize={default_pageSize}
                                    defaultCurrent={page}
                                    pageSizeOptions={default_pageSizeOptions}
                                    showSizeChanger
                                    onChange={(p, ps)=>{
                                    setLimit(ps)
                                    setPage(p);
                                    }}
                                />
                            }
                        </div>
                    </Spin>
                </Layout.Content>
                <Layout.Sider theme='light'  width={(!showFilter)?50:250}>
                    <BusinessProcessListFilter
                        lifeCycleStagesColors={lifeCycleStagesColors}
                        showFilter={showFilter}
                        setShowFilter={setShowFilter}
                        portfolioData={portfolioData}
                        selectedFilters={selectedFilters}
                        // setSelectedFilters={setSelectedFilters}
                        setSelectedFilters={(value)=>{ setPage(1);setSelectedFilters(value); }}
                        sortValues={sortByValue}
                        optionalLevel={false}
                    />
                </Layout.Sider>
            </Layout>
        </>
    )
}

BusinessProcessList.defaultProps = {
    
};

export default BusinessProcessList;