
export const SaveRecentlyView_Portfolio=async(module, Url,modelName,modelID, color)=>{

    //localStorage.removeItem("recent_viewed_portfolio");

    let recentPortfolioArr=[];
    let recentPortfolio_Save = localStorage.getItem("recent_viewed_portfolio"); 
     
    console.log("JSON.parse(recentPortfolio_Save", JSON.parse(recentPortfolio_Save));
     

    if (recentPortfolio_Save)
    { 
        let arrList=JSON.parse(recentPortfolio_Save);

        recentPortfolioArr.push(...arrList);
    }

    //Search ID and Delete 
    recentPortfolioArr= await deleteObjectsById(recentPortfolioArr, modelID); 

    recentPortfolioArr.push({
        Module: module,
        URL:Url,
        ModelName:modelName,
        ModelID:modelID,    
        color:color
    });

    console.log("recentPortfolioArr ->>", recentPortfolioArr);

    localStorage.setItem(
        "recent_viewed_portfolio",
        JSON.stringify(recentPortfolioArr)
    );

    let recentPortfolio = localStorage.getItem("recent_viewed_portfolio"); 

    // console.log("recentPortfolio-->", recentPortfolio);

   //localStorage.removeItem("recent_viewed_portfolio");

}

const deleteObjectsById = (recentPortfolioArr, modelID) => {
    
    console.log("Main Data-->", recentPortfolioArr);

    const newData = recentPortfolioArr.filter(obj => obj.ModelID !== modelID);  
    
    console.log("Refresh Data Data-->", newData);

    return newData ;  
  };


const deleteSingleObject =async (recentPortfolioArr, modelID) => {
    
    console.log("Main Data-->", recentPortfolioArr);
    console.log("modelID", modelID);

    const index = recentPortfolioArr.findIndex(obj => obj.ModelID === modelID);

    console.log("index", index);

    if (index !== -1) {
      const newData = [...recentPortfolioArr];  
      newData.splice(index, 1);  
      //setData(newData);
      
    console.log("Refresh Data Data-->", newData);
  
      return newData;
    }
    return recentPortfolioArr;
  };
 