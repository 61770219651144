import { Button, Input,Upload ,  } from "antd";
import { PaperClipOutlined } from '@ant-design/icons';
import React from "react";
const { TextArea } = Input;


const CreateComment = ({ setActiveTabKey }) => {

  const uploadFiles = {
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        console.log(file, fileList);
      }
    },
    defaultFileList: [
      {
        uid: '1',
        name: 'xxx.png',
        status: 'uploading',
        url: 'http://www.baidu.com/xxx.png',
        percent: 33,
      },
      {
        uid: '2',
        name: 'yyy.png',
        status: 'done',
        url: 'http://www.baidu.com/yyy.png',
      },
      {
        uid: '3',
        name: 'zzz.png',
        status: 'error',
        response: 'Server Error 500',
        // custom error message to show
        url: 'http://www.baidu.com/zzz.png',
      },
    ],
  };

  return (
    <div className="flex flex-col items-start gap-4 p-2">
      <div className='line-height-normal'>
        {/* <div className='h4 mb-0'>Comments</div> */}
        <small className='w-100'>(this screen shows comments and replies left by users against the current object current object)</small>
      </div>

      <div className="w-full">
        <TextArea
          placeholder="Add Comments"
          className="w-full h-3/4 border border-2 rounded-lg shadow-md"
          autoSize={{ minRows: 6, maxRows: Infinity }}
        />
      </div>
      <div>
        <Upload {...uploadFiles}>
          <Button icon={<PaperClipOutlined />}>Uploaded Files</Button>
        </Upload>
      </div>
      <div className="flex justify-end gap-4">
        <Button
          type="default"
          className="px-4 text-base font-normal h-auto w-36"
        >
          Attach File
        </Button>
        <Button
          type="primary"
          className="px-4 text-base font-normal h-auto w-36"
        >
          Post
        </Button>
      </div>
    </div>
  );
};

export default CreateComment;
