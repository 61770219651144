import React from 'react'
import AdminLayout from '../components/layouts/AdminLayout'
import Integration from '../components/integration/Integration'
import { useParams } from 'react-router'
import IntegrationAdd from '../components/integration/IntegrationAdd'

const IntegrationPage = ({page}) => {
    const params = useParams();

    return (
        <>
                {page === "index" && <Integration {...params}/>}
                {page === "update" && params?.id==="add" && <IntegrationAdd {...params}/>}
        </>
    )
}

export default IntegrationPage