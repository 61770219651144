import { useState, useEffect } from "react";
import {
  UnorderedListOutlined,
  SlidersOutlined,
  ImportOutlined,
  ClusterOutlined,
  FundOutlined,
  PlusCircleOutlined,
  HomeOutlined,
  RightOutlined,
} from "@ant-design/icons";
import UserService from "../../../services/UserService";
import HomeSvg from "./../../../assets/home.svg";
import BriefcaseSvg from "./../../../assets/Briefcase.svg";
import ScenarioSvg from "./../../../assets/Scenario.svg";
import FlowDiagramSvg from "./../../../assets/FlowDiagram.svg";
import BusinessRreportSvg from "./../../../assets/BusinessRreport.svg";
import OthersSvg from "./../../../assets/others.svg";
import TrackSvg from "./../../../assets/Track.svg";
import DataImportGraphSvg from "./../../../assets/Return Delivery.svg";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
import {transformText } from "../../../utils/Common";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const portfolioRoute=({moduleName})=>
{ 
  if( moduleName=="business_application")
  {
    return "application-list";
  }
  if( moduleName=="business_capability")
  {
    return "business-capability-master";
  }
  if( moduleName=="business_processes")
  {
    return "business-process";
  }
  if( moduleName=="initiative")
  {
    return "portfolio-initiative"
  }
  if( moduleName=="integration")
  {
    return "portfolio-integration"
  }
}

const getPortfolioMenuList = async () => {
  let page = 1;
  let limit = 100;
  let finalMenu = [];
  let menuList = {};
  let SideMenuItems2 = [];

  await PortfolioSettingServices.getList({ page, limit }).then((result) => {
    menuList = result;

    menuList?.data?.data.map((item) => { 
      
      let mm = {
        //key: item.moduleName,
        key:portfolioRoute({moduleName:item.moduleName})+'/'+transformText( item.moduleName.replace("_"," "),"pascal"),
        label: item.name,
        icon: <RightOutlined />,
        moduleName: transformText( item.moduleName.replace("_"," "),"pascal"), 
        moduleID: item.id,
        color: item.color,
      };
      finalMenu.push(mm);
    });

    SideMenuItems2 = [
      {
        key: "",
        label: `Home`,
        icon: <img src={HomeSvg} alt={`Home`} className="side-menu-icon" />,
      },
      // {
      //   key: "city-map",
      //   navigate: false,
      //   label: `City Map`,
      //   icon: (
      //     <img src={TrackSvg} alt={`City Map`} className="side-menu-icon" />
      //   ),
      // },
      {
        key: "portfolio",
        label: `Portfolio`,
        icon: (
          <img
            src={BriefcaseSvg}
            alt={`Portfolio`}
            className="side-menu-icon"
          />
        ),
        children: 
        finalMenu,
        // [ 
        //   {
        //     key: "business-capability-master",
        //     label: `Business Capabilities`,
        //     icon: <RightOutlined />,
        //   },
        //   {
        //     key: "business-process",
        //     label: `Business Process`,
        //     icon: <RightOutlined />,
        //   },
        //   {
        //     key: "portfolio-information",
        //     label: `Information`,
        //     icon: <RightOutlined />,
        //   },
        //   {
        //     key: "application-list",
        //     navigate: false,
        //     label: `Application`,
        //     icon: <RightOutlined />,
        //   },
        //   {
        //     key: "portfolio-technology",
        //     navigate: false,
        //     label: `Technology`,
        //     icon: <RightOutlined />,
        //   },
        //   {
        //     key: "portfolio-initiative",
        //     navigate: false,
        //     label: `Initiative`,
        //     icon: <RightOutlined />,
        //   },
        //   {
        //     key: "portfolio-organization",
        //     navigate: false,
        //     label: `Organization`,
        //     icon: <RightOutlined />,
        //   },
        // ]
      },
      {
        key: "landscape-scenario",
        navigate: false,
        label: `Landscape`,
        icon: (
          <img src={ScenarioSvg} alt={`Scenarios`} className="side-menu-icon" />
        ),
        children: [
          {
            key: "add-new-scenario",
            navigate: false,
            label: `New`,
            icon: <RightOutlined />,
          },
          {
            key: "scenario-list",
            navigate: false,
            label: `List`,
            icon: <RightOutlined />,
          },
          {
            key: "compare-scenario",
            navigate: false,
            label: `Compare`,
            icon: <RightOutlined />,
          },
        ],
      },
      {
        key: "scenarios",
        navigate: false,
        label: `Scenarios`,
        icon: (
          <img src={ScenarioSvg} alt={`Scenarios`} className="side-menu-icon" />
        ),
        children: [
          {
            key: "start-new-scenario",
            navigate: false,
            label: `New`,
            icon: <RightOutlined />,
          },
          // {
          //   key: "scenario-list",
          //   navigate: false,
          //   label: `List`,
          //   icon: <RightOutlined />,
          // },
          {
            key: "list-scenario",
            navigate: false,
            label: `List`,
            icon: <RightOutlined />,
          },
          // {
          //   key: "compare-scenario",
          //   navigate: false,
          //   label: `Compare`,
          //   icon: <RightOutlined />,
          // },
          {
            key: "compare-scenario-v2",
            navigate: false,
            label: `Compare`,
            icon: <RightOutlined />,
          },
        ],
      },
      // {
      //   key: "diagram",
      //   navigate: false,
      //   label: `Diagram`,
      //   icon: (
      //     <img
      //       src={FlowDiagramSvg}
      //       alt={`Diagram`}
      //       className="side-menu-icon"
      //     />
      //   ),
      //   children: [
      //     {
      //       key: "diagram-editor",
      //       navigate: false,
      //       label: `Item 1`,
      //       icon: <RightOutlined />,
      //     },
      //     {
      //       key: "diagram-item2",
      //       navigate: false,
      //       label: `Item 2`,
      //       icon: <RightOutlined />,
      //     },
      //   ],
      // },
      {
        key: "reports",
        navigate: false,
        label: `Reports`,
        icon: (
          <img
            src={BusinessRreportSvg}
            alt={`Reports`}
            className="side-menu-icon"
          />
        ),
        children: [
          {
            key: "report-item1",
            navigate: false,
            label: `Report 1`,
            icon: <RightOutlined />,
          },
          {
            key: "report-item2",
            navigate: false,
            label: `Report 2`,
            icon: <RightOutlined />,
          },
        ],
      },
      {
        key: "others",
        navigate: false,
        label: `Others`,
        icon: <img src={OthersSvg} alt={`Others`} className="side-menu-icon" />,
        children: [
          {
            key: "others-settings",
            navigate: false,
            label: `Settings`,
            icon: <RightOutlined />,
          },
          {
            key: "others-surveys",
            navigate: false,
            label: `Surveys`,
            icon: <RightOutlined />,
          },
          {
            key: "others-help",
            navigate: false,
            label: `Help`,
            icon: <RightOutlined />,
          },
        ],
      },
      // {
      //   key: "data-import",
      //   navigate: false,
      //   label: `Data Import`,
      //   icon: (
      //     <img
      //       src={DataImportGraphSvg}
      //       alt={`Data Import`}
      //       className="side-menu-icon"
      //     />
      //   ),
      // },
    ];
  });

  ///////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////
  console.log("SideMenuItems2 in function", SideMenuItems2);
  return SideMenuItems2;
};

const SideMenu =  async  () => {
  const userRole = UserService?.hasRole(["app-manager"]);

  const SideMenuItems = UserService.hasRole(["Admin"])
    ? [
        {
          key: "",
          navigate: false,
          label: `Home`,
          icon: <HomeOutlined />,
        },
        {
          key: "city-map",
          navigate: false,
          label: `City Map`,
          icon: <FundOutlined />,
        },
        {
          key: "scenarios",
          navigate: false,
          label: `Scenarios`,
          icon: <ClusterOutlined />,
          children: [
            {
              key: "scenario-list",
              navigate: false,
              label: `Scenario List`,
              icon: <UnorderedListOutlined />,
            },
            {
              key: "start-new-scenario",
              navigate: false,
              label: `Start New`,
              icon: <PlusCircleOutlined />,
            },
            {
              key: "compare-scenario",
              navigate: false,
              label: `Compare`,
              icon: <SlidersOutlined />,
            },
          ],
        },
        {
          key: "data-import",
          navigate: false,
          label: `Data Import`,
          icon: <ImportOutlined />,
        },
        {
          key: "portfolio",
          navigate: false,
          label: `Portfolio`,
          icon: <FundOutlined />,
          children: [
            {
              key: "application-list",
              navigate: false,
              label: `Application`,
              icon: <PlusCircleOutlined />,
            },
            {
              key: "business-capability-master",
              navigate: false,
              label: `Business Capabilities`,
              icon: <PlusCircleOutlined />,
            },
          ],
        },
      ]
    : [
        {
          key: "",
          navigate: false,
          label: `Home`,
          icon: <HomeOutlined />,
        },
        {
          key: "city-map",
          navigate: false,
          label: `City Map`,
          icon: <FundOutlined />,
        },
        {
          key: "scenarios",
          navigate: false,
          label: `Scenarios`,
          icon: <ClusterOutlined />,
          children: [
            {
              key: "scenario-list",
              navigate: false,
              label: `Scenario List`,
              icon: <UnorderedListOutlined />,
            },
            {
              key: "compare-scenario",
              navigate: false,
              label: `Compare`,
              icon: <SlidersOutlined />,
            },
          ],
        },
      ];
  // const SideMenuItems = UserService.hasRole(['Admin']) ?
  //   [
  //     getItem('Home', '', <HomeOutlined />),
  //     getItem('City Map', 'city-map', <FundOutlined />),
  //     getItem('Scenarios', 'scenarios', <ClusterOutlined />,
  //     [
  //       getItem('Scenario List', 'scenario-list', <UnorderedListOutlined />),
  //       getItem('Start New', 'start-new-scenario', <PlusCircleOutlined />),
  //       getItem('Compare', 'compare-scenario', <SlidersOutlined />)
  //     ]),
  //     getItem('Data Import', 'data-import', <ImportOutlined />),
  // ]:[
  //   getItem('Home', '', <HomeOutlined />),
  //   getItem('City Map', 'city-map', <FundOutlined />),
  //   getItem('Scenarios', 'scenarios', <ClusterOutlined />,
  //   [
  //     getItem('Scenario List', 'scenario-list', <UnorderedListOutlined />),
  //     // getItem('Start New', 'start-new-scenario', <PlusCircleOutlined />),
  //     getItem('Compare', 'compare-scenario', <SlidersOutlined />)

  //   ]),
  // ];

  // const getMenulist=async()=>{

  //      let list1= await PortfolioSettingServices.getList();

  // }

  const SideMenuItems2 =   await getPortfolioMenuList();

  // const SideMenuItems2 = [
  //   {
  //     key: "",
  //     label: `Home`,
  //     icon: <img src={HomeSvg} alt={`Home`} className="side-menu-icon" />,
  //   },
  //   {
  //     key: "city-map",
  //     navigate: false,
  //     label: `City Map`,
  //     icon: <img src={TrackSvg} alt={`City Map`} className="side-menu-icon" />,
  //   },
  //   {
  //     key: "portfolio",
  //     label: `Portfolio`,
  //     icon: (
  //       <img src={BriefcaseSvg} alt={`Portfolio`} className="side-menu-icon" />
  //     ),
  //     children:
  //     [

  //       {
  //         key: "business-capability-master",
  //         label: `Business Capabilities`,
  //         icon: <RightOutlined />,
  //         moduleName:"business_capability",
  //         moduleID: 1,
  //         color: '#54BAB9',
  //       },
  //       {
  //         key: "business-process",
  //         label: `Business Process`,
  //         icon: <RightOutlined />,
  //       },
  //       {
  //         key: "portfolio-information",
  //         label: `Information`,
  //         icon: <RightOutlined />,
  //       },
  //       {
  //         key: "application-list",
  //         navigate: false,
  //         label: `Application`,
  //         icon: <RightOutlined />,
  //         moduleName:"business_application",
  //         moduleID: 4,
  //         color: '#FFB381',
  //       },
  //       {
  //         key: "portfolio-technology",
  //         navigate: false,
  //         label: `Technology`,
  //         icon: <RightOutlined />,
  //       },
  //       {
  //         key: "portfolio-initiative",
  //         navigate: false,
  //         label: `Initiative`,
  //         icon: <RightOutlined />,
  //       },
  //       {
  //         key: "portfolio-organization",
  //         navigate: false,
  //         label: `Organization`,
  //         icon: <RightOutlined />,
  //       },
  //     ]
  //   },
  //   {
  //     key: "scenarios",
  //     navigate: false,
  //     label: `Scenarios`,
  //     icon: (
  //       <img src={ScenarioSvg} alt={`Scenarios`} className="side-menu-icon" />
  //     ),
  //     children: [
  //       {
  //         key: "start-new-scenario",
  //         navigate: false,
  //         label: `New`,
  //         icon: <RightOutlined />,
  //       },
  //       {
  //         key: "scenario-list",
  //         navigate: false,
  //         label: `List`,
  //         icon: <RightOutlined />,
  //       },
  //       {
  //         key: "compare-scenario",
  //         navigate: false,
  //         label: `Compare`,
  //         icon: <RightOutlined />,
  //       },
  //     ],
  //   },
  //   {
  //     key: "diagram",
  //     navigate: false,
  //     label: `Diagram`,
  //     icon: (
  //       <img src={FlowDiagramSvg} alt={`Diagram`} className="side-menu-icon" />
  //     ),
  //     children: [
  //       {
  //         key: "diagram-editor",
  //         navigate: false,
  //         label: `Item 1`,
  //         icon: <RightOutlined />,
  //       },
  //       {
  //         key: "diagram-item2",
  //         navigate: false,
  //         label: `Item 2`,
  //         icon: <RightOutlined />,
  //       },
  //     ],
  //   },
  //   {
  //     key: "reports",
  //     navigate: false,
  //     label: `Reports`,
  //     icon: (
  //       <img
  //         src={BusinessRreportSvg}
  //         alt={`Reports`}
  //         className="side-menu-icon"
  //       />
  //     ),
  //     children: [
  //       {
  //         key: "report-item1",
  //         navigate: false,
  //         label: `Report 1`,
  //         icon: <RightOutlined />,
  //       },
  //       {
  //         key: "report-item2",
  //         navigate: false,
  //         label: `Report 2`,
  //         icon: <RightOutlined />,
  //       },
  //     ],
  //   },
  //   {
  //     key: "others",
  //     navigate: false,
  //     label: `Others`,
  //     icon: <img src={OthersSvg} alt={`Others`} className="side-menu-icon" />,
  //     children: [
  //       {
  //         key: "others-settings",
  //         navigate: false,
  //         label: `Settings`,
  //         icon: <RightOutlined />,
  //       },
  //       {
  //         key: "others-surveys",
  //         navigate: false,
  //         label: `Surveys`,
  //         icon: <RightOutlined />,
  //       },
  //       {
  //         key: "others-help",
  //         navigate: false,
  //         label: `Help`,
  //         icon: <RightOutlined />,
  //       },
  //     ],
  //   },
  //   {
  //     key: "data-import",
  //     navigate: false,
  //     label: `Data Import`,
  //     icon: (
  //       <img
  //         src={DataImportGraphSvg}
  //         alt={`Data Import`}
  //         className="side-menu-icon"
  //       />
  //     ),
  //   },
  // ];

  if (!UserService.hasRole(["Admin"])) {
    SideMenuItems2.forEach((item, index, object) => {
      if (item.key === "data-import") {
        object.splice(index, 1);
      } else {
        if (item?.children) {
          item?.children.forEach((item1, index1, object1) => {
            if (item1.key === "start-new-scenario") {
              object1.splice(index1, 1);
            }
          });
        }
      }
    });
  }
   return SideMenuItems2;
};

export default SideMenu;
