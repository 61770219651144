import React from 'react'; 
import CreateComment from './CreateComment';
import { Divider } from 'antd';
import CommentList from './CommentList';
const TabComments = ({ setActiveTabKey }) => {
    return <>
        <CreateComment setActiveTabKey={setActiveTabKey}/>
        <Divider className="mt-3 bg-[#e3e3e3]"/>
        <CommentList setActiveTabKey={setActiveTabKey}/>
    </>;
}

export default TabComments;