import React from 'react';
import AdminLayout from '../components/layouts/AdminLayout';

const LayoutPage = ({component,...props}) => {
    return (
        <>
                {component}
        </>
    )
}

export default LayoutPage