import React,{useState} from 'react';
import {
    Button,
    Drawer,
    Select,
    Card
} from "antd";

const LGIndicators = (props) => {
    const [drawerOpen,setDrawerOpen] = useState(false)
    const [colors] = useState(props?.colors)
    
    return (
        <>
            <Button className="indicator-btn btn-theme"  onClick={()=>{setDrawerOpen(true)}}> Indicators </Button>
            <Drawer 
                title={false} 
                width={250} 
                closable={false} 
                open={drawerOpen} 
                onClose={()=>{setDrawerOpen(false)}}
                autoFocus={false}
            >
                <div className='d-flex align-items-center mt-5'>
                    <Card className={'border-theme w-100'}>
                        <div>Indicator</div>
                        <Select 
                            defaultValue={'life-cycle'} 
                            options={[{ value: 'life-cycle', label: <span>Life Cycle Status</span> }]} 
                            style={{width:'100%'}}
                        />
                        <ul className='list-group list-group-flush'>
                            <li className="d-flex mt-3"><div className='status-box' style={{background:colors?.active}}></div> Active</li>
                            <li className="d-flex mt-3"><div className='status-box' style={{background:colors?.phaseIn}}></div> Phase In</li>
                            <li className="d-flex mt-3"><div className='status-box' style={{background:colors?.phaseOut}}></div> Phase Out</li>
                            <li className="d-flex mt-3"><div className='status-box' style={{background:colors?.endOfLife}}></div> End Of Life</li>
                        </ul>
                    </Card>
                </div>
            </Drawer>
        </>
    )
}

export default LGIndicators
