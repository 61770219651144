import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Spin, message } from "antd";
import ScenarioServices from "../../services/services/ScenarioServices";
import ScenarioListCompare from "./compare/ScenarioListCompare";

const CompareScenarioViewerV2 = () => {
  const location = useLocation();
  const [activePage, setActivePage] = useState(1);
  const [properties, setProperties] = useState({});
  const [compareData, setCompareData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const prevScenarioCompare = JSON.parse(
      sessionStorage.getItem("prevScenarioCompare")
    );
    const newStateCompare = location?.state?.compare;

    if (newStateCompare) {
      sessionStorage.setItem(
        "prevScenarioCompare",
        JSON.stringify(newStateCompare)
      );
      setProperties(newStateCompare);
    } else if (prevScenarioCompare) {
      setProperties(prevScenarioCompare);
    }
  }, [location]);

  useEffect(() => {
    if (Object.keys(properties).length > 0) {
      getAllCompareScenario(properties);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties]);

  let callValues = 1;
  const getAllCompareScenario = async (properties) => {
    try {
      setLoading(true);
      const obj = {
        isScenarioItems: true,
        isUser: true,
        compareIds: properties?.compareIds,
        scenarioId: properties?.scenarioId,
        grouped: true,
      };
      const response = await ScenarioServices?.getAllComparePortfolio(obj);
      if (response?.data?.data) {
        // callValues === 1 && message.success(response?.data?.message);
        callValues = callValues + 1;
        setCompareData(response?.data?.data);
      }
    } catch (error) {
      console.error(error?.message);
      message.error(error?.message || "Scenarios not found.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="compare-scenario-viewer-v2">
      {activePage === 1 && (
        <Spin size="small" spinning={loading}>
          <ScenarioListCompare
            activePage={activePage}
            setActivePage={setActivePage}
            data={compareData}
            properties={properties}
            
          />
        </Spin>
      )}
    </div>
  );
};

export default CompareScenarioViewerV2;
