import React from "react";
import { Form, InputNumber, Button } from "antd";

const CustomNumberInput = ({ value, onChange }) => {
  const handleValueChange = (newValue) => {
    if (!isNaN(newValue)) {
      onChange(newValue);
    }
  };
  const inputNumberStyle = {
    width: "100%",
    // Hide the increase/decrease buttons
    "antInputNumberHandlerWrap": {
      display: "none",
    },
  };
  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Form.Item>
      <InputNumber
        value={value}
        onChange={handleValueChange}
        formatter={(value) => (value === "" ? "" : `${value}`)}
        parser={(value) => (isNaN(value) ? "" : value)}
        step={1}
        onKeyPress={handleKeyPress}
        style={inputNumberStyle}
      />
    </Form.Item>
  );
};

export default CustomNumberInput;
