import { Button, Card, Col, Flex, Input, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { PageTitleHeading } from '../../utils/Common';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
 
const SurveyAdd = () => {
    const navigation = useNavigate();
    const [loading,setLoading] = useState(false);

    console.log(navigation)

    return <div className='surveys-page'>
        <Spin size="small" spinning={loading}>
            <Flex gap={'small'} justify='space-between' align="center">
                <PageTitleHeading text={`Create Survey`}/>
                <div><Link to="/others-surveys" className='btn btn-sm btn-outline-secondary'><i className="fa fa-arrow-left" ></i> Back</Link></div>
            </Flex>
            
            <Flex gap={16} align='center' className='col-lg-6 mb-4 p-0 '>
                <div>Name: </div>
                <Input placeholder='Survey Name'/>
                <Button type="primary">
                    <Flex gap={16} justify='center' align='center'>
                        <div>Link Survey </div>
                        <PlusOutlined/>
                    </Flex>
                </Button>
            </Flex>
            
            <Card>
                <h5 className='mb-3'>Questions</h5>
                <Card className='mb-2'>
                    <Flex gap={8} vertical className='mb-2'>
                        <Row gutter={8}>
                            <Col span={1} className='fw-medium'>Q.</Col>
                            <Col span={23} className='fw-medium'>Are you satisfied with me ?</Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={1} className='fw-medium'>Ans.</Col>
                            <Col span={23}><Input.TextArea rows={2}/></Col>
                        </Row>
                    </Flex>
                    <div className='pull-right'>
                        <Button type='primary'>Change Answer Type</Button>
                    </div>
                </Card>
                <Button type='primary' icon={<PlusOutlined/>}>Add New Question</Button>
            </Card>
        </Spin>
    </div>;
}

SurveyAdd.defaultProps = {};
// #endregion

export default SurveyAdd;