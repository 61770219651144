import AdminLayout from "../layouts/AdminLayout";
import {
    Card,
    Spin,
    Typography,
    Divider,
  } from "antd";
import { useState } from "react";

// import { useImportHook } from '.useImportHook/useImportHookjs'
import "./DataImport.scss";
import DataImportForm1 from "./DataImportForm1";
import DataImportForm2 from "./DataImportForm2";
import DataImportForm3 from "./DataImportForm3";
const { Title } = Typography;


const DataImport = (props) => {
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Title level={5} className="text-start py-2">
                Data Import
            </Title>
            <Spin spinning={loading}>
                <Card>
                    <DataImportForm1 setLoading={setLoading}/>
                    <Divider className="mt-0 mb-3"></Divider>
                    <DataImportForm2 setLoading={setLoading}/>
                    <Divider className="mt-0 mb-3"></Divider>
                    <DataImportForm3 setLoading={setLoading}/>
                </Card>
            </Spin>
        </>
    );
}

export default DataImport;

