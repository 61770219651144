import React from "react";
import { Form,Input, InputNumber, Button } from "antd";

const CustomLevelComponent = ({ name="" }) => {
  
    //const pattern1 = /^(\d{1,2})\.(\d{1,2})\.(\d{1,2})$/; // Matches 1.1.1 or 11.1.1
    const pattern1 = /^(?:\d{1,2})(?:\.\d{1,2})?(?:\.\d{1,2})?$/;

  const validatePattern = (_, value) => {
    //if (pattern1.test(value) || pattern2.test(value)) {
    if (pattern1.test(value)  ) {
        
    return Promise.resolve();
    }
    return Promise.reject(new Error("Input does not match required pattern"));
  };
 

  return (
    // <Form.Item>
    //   <InputNumber
    //     value={value}
    //     onChange={handleValueChange}
    //     formatter={(value) => (value === "" ? "" : `${value}`)}
    //     parser={(value) => (isNaN(value) ? "" : value)}
    //     step={1}
    //     style={inputNumberStyle}
    //   />
    // </Form.Item>

    <Form.Item name={name} step={1} rules={[{ validator: validatePattern }]}>
      <Input
        formatter={(value) => (value === "" ? "" : `${value}`)}
        placeholder="Enter pattern e.g., 1.1.1, 11.1.1, 11.2.10"
      />
    </Form.Item>
  );
};

export default CustomLevelComponent;
