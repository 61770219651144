import React from 'react';
import { PageTitleHeading } from '../../utils/Common';
import { Button, Card, Flex } from 'antd';
import { RightOutlined } from '@ant-design/icons';

let cardLists = [
    "Customer Order Survey",
    "Matlab Survey",
    "VCK Survey"
];
const SurveyLeftCard = () => {
    return <div>
        <Flex justify='space-between' align='center' className='mb-3'>
            <PageTitleHeading text={`My Survey`} size={`5`} className={`m-0`}/>
            <Button type="link">View All</Button>
        </Flex>
        <Flex gap={8} vertical className='mb-3'>
        {
            cardLists?.map(item=>{
                return <Card
                    key={Math.random()}
                    title={<h5>{item}</h5>}
                    bodyStyle={{padding:0}}
                    headStyle={{minHeight:"40px",borderRadius:"5px",padding:"5px 10px"}}
                    extra={<RightOutlined />}
                />
            })
        }
        </Flex>
    </div>;
}
SurveyLeftCard.defaultProps = {};
// #endregion

export default SurveyLeftCard;