import React, { useEffect, useState } from 'react'
import ApplicationListCompare from "./ApplicationListCompare"
import { PageTitleHeading } from '../../../utils/Common'
import { Button, Flex, Switch, Tooltip } from 'antd'
import ActionsBtn from '../../applicationlist/ActionsBtn'
import { Link } from 'react-router-dom'
import ListSvg from "./../../../assets/List.svg";
import { GoBack } from '../../../utils/GoBackHistory'
// import TwoColumnSvg from "./../../../assets/Two columns layout.svg";
const ScenarioListCompare = ({activePage,setActivePage,...props}) => {
    const [showDifferene,setShowDifference] = useState(true);
    // const [title,setTitle] = useState("");

    useEffect(()=>{
        // let lists = [props?.properties?.scenarioId,...props?.properties?.compareIds || []];

        // let listCompare = [];
        // lists?.forEach(el => {
        //     let temp;
        //     Object.keys(props?.data)?.forEach((name) => {
        //         const { items } = props?.data[name];
        //         temp = items.find(f=>f.id?.toString()===el?.toString());
        //         if(temp){
        //             listCompare.push(temp?.name);
        //         }
        //     })
        // });
        // setTitle([...new Set(listCompare)]?.join(" vs "))
    },[props])
    return (
        <div>
            <div className='d-flex justify-content-between align-items-top my-3'>
                <div>
                    <PageTitleHeading text={`Compare Summary`} className={`mb-2`}/>
                    <Flex gap={'small'} justify='flex-start'>
                        <Tooltip text={`List`}>
                            <Button {...(activePage===1 && {type:"primary",ghost:true})} icon={<img src={ListSvg} width={"25px"} alt='List'/>} onClick={()=>setActivePage(1)}/>
                        </Tooltip>
                        {/* <Tooltip text={`Grid`}>
                            <Button {...(activePage===2 && {type:"primary",ghost:true})} icon={<img src={TwoColumnSvg} width={"25px"} alt='Grid'/>} onClick={()=>setActivePage(2)}/>
                        </Tooltip> */}
                    </Flex>
                </div>
                <div>
                    <Flex gap={8} justify="flex-end">
                        <ActionsBtn/>
                        <div><GoBack className={'pull-right ml-2'}/></div>
                    </Flex>
                    <Flex gap={8} justify="flex-end">
                        <div>Show only difference</div>
                        <div><Switch onChange={(value)=>{ setShowDifference(value); }} value={showDifferene}/></div>
                    </Flex>
                    <small>(Difference are shown highlighted in yellow)</small>
                </div>
            </div>
            <ApplicationListCompare {...props} showDifferene={showDifferene}/>
        </div>
    )
}

export default ScenarioListCompare