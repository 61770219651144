import React, { useState, useRef, useEffect } from 'react';
import { Collapse, List, Tooltip, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import BCMLevel3 from './BCMLevel3';
import { useNavigate } from "react-router";
import {  useParams } from "react-router-dom";
function BCMLevel2(props) {
    const [hovered, setHovered] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const { moduleName:moduleNamePath } = useParams();
    
    
    useEffect(() => {

         //console.log("BCMLevel2 underLevel", props?.underLevel);

        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdown(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const onChange = (key) => {
        console.log(key);
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        props?.setItem(props.data, { type: 'delete' });
        props?.setModalData({ level: 2, ParentID: props?.data?.id, DisplayName:props?.data?.DisplayName });
    };

    const handleEdit = (e) => {
        e.stopPropagation();
        props?.setItem(props.data, { type: 'edit' });
        props?.setModalData({ level: 2, ParentID: props?.data?.id });
    };

    return (
        <>
            <Collapse
                style={{ backgroundColor: '#fff' }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onChange={onChange}
                onClick={() => { props?.setItem(props.data); props?.setModalData({ level: 2, ParentID: props?.data?.id }); }}
                expandIconPosition={'start'}
                items={[
                    {
                        key: props.key,
                        label: <>
                            <div
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                                className='d-flex'
                            >
                                <Tooltip title={props?.data.DisplayName}>{props.data.LevelID} {props.data.DisplayName}</Tooltip>
                                 {/* {console.log(props?.data)} */}
                            </div>
                        </>,
                        children: <>
                            <List
                                header={false}
                                size={`small`}
                                footer={
                                    <>
                                        <Tooltip title="Level 3" placement={`rightBottom`}>
                                            {/* <Button shape="circle" className='btn-level-2' icon={<PlusOutlined />} onClick={() => { props?.openModal(!props?.visibleModal); props?.setModalData({ level: 3, ParentID: props?.data?.id }); }}></Button> */}
                                            <Button shape="circle" className='btn-level-2' icon={<PlusOutlined />} onClick={() => {
                                                navigate('/business-capability-master/'+moduleNamePath+"/add", { state: {Level:3,ParentID: props?.data?.id, ParentName: props?.data?.DisplayName, underLevel:props?.data?.DisplayName, levelID: props?.data?.LevelID } })
                                            } }></Button>
                                        </Tooltip>
                                    </>
                                }
                                bordered
                                dataSource={props.data.child}
                                renderItem={(item, index) => (
                                     
                                    <BCMLevel3 {...props} defaultActiveKey={index === 0 ? 1 : 0} index={index} key={index} data={item} underLevel={props?.data.DisplayName} underLevelID={props?.data.LevelID}/>
                                )}
                            />
                        </>,
                        extra: <>
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle p-0" type="button" onClick={(e) => {
                                    e.stopPropagation();
                                    setDropdown(!dropdown);
                                }}>
                                    <i className="fa fa-ellipsis-v edit" style={{ color: 'black' }} />
                                </button>
                                {
                                     dropdown && (

                                        <ul className="dropdown-menu" ref={dropdownRef}>
                                            <li><Button onClick={handleDelete} className='btn-icon'><i className="fa fa-trash-o delete" /></Button></li>
                                            {/* <li><Button onClick={handleEdit} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li> */}

                                            {/* <li><Button onClick={() => { navigate('/business-capability-master', { state: { type: 'edit',Level:2, ParentID: props?.data?.id , ParentName:props?.data?.DisplayName, underLevel: props.underLevel} })}} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li> */}
                                            
                                            <li><Button onClick={() => { navigate('/business-capability-master/'+moduleNamePath+'/'+props?.data?.id  )}} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li>

                                           
                                            
                                        </ul>
                                    )
                                }
                            </div>
                        </>
                    },
                ]}
                className='collapse-level-2 mb-2'
            />

        </>
    )
}

export default BCMLevel2