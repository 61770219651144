import AdminLayout from "../components/layouts/AdminLayout"
import StartNewScenarioV2 from "../components/scenario/StartNewScenarioV2"
import StartNewScenario from "../components/scenario/StartNewScenario"

const NewScenarioPage = () => {
  return (
    <StartNewScenarioV2 />
    // <StartNewScenario />
    // <AdminLayout children={<StartNewScenarioV2 />}/>    
  )
}

export default NewScenarioPage