/* eslint-disable react/react-in-jsx-scope */

import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import AnonymousRoutes from "./routes/AnonymousRoutes";
import UserService from "./services/UserService";
import anonymousRoutesJson from "./routes/anonymousRoutesJson";
import AuthenticatedRoutes from './routes/AuthenticatedRoutes';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useEffect } from 'react';
import { message } from 'antd';


function App() {
	const [messageApi] = message.useMessage();
	const anonymousRoutes = anonymousRoutesJson;
	let pathname = window.location.pathname
	pathname = pathname.split("/");
	pathname = pathname.filter((f) => f != '');
	useEffect(() => {
		if (UserService.isLoggedIn()) {
			let userDetails = UserService.getUserdetails();
			console.log(userDetails,"====userDetails")
			localStorage.setItem('email', userDetails.email)
			localStorage.setItem('userData', JSON.stringify({
				name: userDetails.name,
				email: userDetails.email
			}))
			UserService
				.saveUserData({
					name: userDetails.name,
					email: userDetails.email
				})
				.then((response) => {
					if (response.data.code === 200) {
						messageApi.open({
							type: 'success',
							content: response.data.message,
						});
					} else {
						messageApi.open({
							type: 'error',
							content: response.data.message,
						});
					}
				}).catch((err) => {
					console.log(err);
				});
		}

	}, [UserService.isLoggedIn()])
	return (
		<div className="App">
			<BrowserRouter>
				{anonymousRoutes.includes(pathname[0])?<AnonymousRoutes/>:(UserService.isLoggedIn() ? <AuthenticatedRoutes /> :null) }
				{/* {<AuthenticatedRoutes />} */}
			</BrowserRouter>
		</div>
	);
}

export default App;
