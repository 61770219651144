import { encodeQueryData } from "../../utils/Common";
import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getdiagrams({ limit = 20, page = 1 }) {
  const url = `${API_BASE_URL}diagrams?page=${page}&limit=${limit}`;
  let response = await http.get(url);
  return response;
}

async function creatediagram(data) {
  const url = `${API_BASE_URL}diagrams`;
  let response = await http.post(url, data);
  return response;
}

async function updatediagram(data) {
  const {id} = data;
  const url = `${API_BASE_URL}diagrams/${id}`;
  let response = await http.put(url, data);
  return response;
}

async function deletediagram(id) {
  const url = `${API_BASE_URL}diagrams/`;
  let response = await http.delete(url + id);
  return response;
}

//by module
async function getListByModule({ moduleName, moduleID }) {
  const url = `${API_BASE_URL}diagrams/by-module?moduleName=${moduleName}&moduleID=${moduleID}`;
  let response = await http.get(url);
  return response;
}

async function getDiagram(id) {
  const url = `${API_BASE_URL}diagrams/${id}`;
  let response = await http.get(url);
  return response;
}

const diagramServices = {
  getdiagrams,
  creatediagram,
  updatediagram,
  deletediagram,
  getListByModule,
  getDiagram,
};

export default diagramServices;
