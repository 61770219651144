import React from 'react'; 
import AdminLayout from '../components/layouts/AdminLayout';
import ApplicationList from '../components/applicationlist/ApplicationList';
const ApplicationListPage = () => {
    return (
        <>
            <ApplicationList/>
        </>
    );
}

export default ApplicationListPage;