import React, { useEffect } from "react";
import { useState } from "react";
import {
    Row,
    Col,
    Tooltip,
    Button,
    message,
    Flex,
    Spin,
    Pagination,
    Modal,
} from "antd";
import {
    UnorderedListOutlined,
    PlusOutlined,
    ExclamationCircleFilled,
} from "@ant-design/icons";
import BusinessCapabilityListCards from "./business-capability-list/BusinessCapabilityListCards";
import ActionsBtn from "../applicationlist/ActionsBtn";
import { useNavigate } from "react-router";
import FilterRightSideBar from "../applicationlist/filter/FilterRightSideBar";
import usePortfolioFetcher from "../../hooks/usePortfolioFetcher";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import {
    PageTitleHeading,
    debounceTimeOut,
    deepFindByName,
    default_pageSize,
    default_pageSizeOptions,
    getSelectProperties,
    removeEmptyArrObjOrval,
    removeEmptyKeys,
} from "../../utils/Common";
import { staticSettingPortfolioList } from "../../utils/settingCommon";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import businessCapabilityServices from "../../services/services/BusinessCapabilityService";
import useDebounce from "../../helper/useDebounce";
import { useParams } from "react-router-dom";
import gridSvg from "./../../assets/Two columns layout.svg";
import { UpdateChangeLog } from "../../utils/ChangeLogs";
import { useMenu } from "../../Context/MenuProvider";

const lifeCycleStagesColors = {
    active: "#FFB381",
    phaseIn: "#FFF280",
    phaseOut: "#FC819E",
    endOfLife: "#B2B377",
    color5: "#B5C0D0",
    color6: "#BFEF82",
    color7: "#90DDF9",
};

const sortByValue = [
    { value: "Level", title: "Level" },
    { value: "name", title: "Name" },
];

const BusinessCapabilityList = ({ ...props }) => {
    const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
    const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
        usePortfolioSetting();
    const navigate = useNavigate();
    const [filterLevel, setFilterLevel] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [filterBy, setFilterBy] = useState("displayName");
    const [showFilter, setShowFilter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [portfolioData, setPortfolioData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(default_pageSize);
    const [lists, setLists] = useState([]);
    const { moduleName: moduleNamePath } = useParams();
    const { stateMenu, dispatchMenu } = useMenu();

    //Get portfolios
    //Get all settings
    const fetchAllPortfolio = async () => {
        try {
            const response = await fetchPortfolio(
                portfolioSettingDispatch,
                1,
                100,
            );
            if (response) {
                // message.success("Portfolio fetched successfully.");
            } else {
                message.error("Portfolio not found.");
            }
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    useEffect(() => {
        if (portfolioSettingState?.data?.length) {
            const portfolioValues = deepFindByName(
                portfolioSettingState?.data,
                staticSettingPortfolioList?.businessCapability,
                false,
            );
            if (portfolioValues?.portfolioSections?.length) {
                setPortfolioData(
                    getSelectProperties(
                        portfolioValues?.portfolioSections,
                        true,
                    ),
                );
            } else {
                setPortfolioData([]);
            }
        } else {
            fetchAllPortfolio();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState?.data]);

    const fetchFilteredData = async (filter) => {
        try {
            setLoading(true);
            if (portfolioSettingState?.data?.length) {
                setLists([]);
                filter = removeEmptyKeys(filter);
                const portfolio = await deepFindByName(
                    portfolioSettingState?.data,
                    staticSettingPortfolioList?.businessCapability,
                    false,
                );
                const updatedFilter = {
                    moduleName: portfolio?.moduleName || "",
                    ...filter,
                };
                const response =
                    await PortfolioSettingServices?.getFilteredPortfolios(
                        updatedFilter,
                        page,
                        limit,
                    );
                if (response?.data?.data?.length) {
                    setTimeout(() => {
                        setLoading(false);
                        setTotalRecords(response?.data?.totalCount);
                        setLists(response?.data?.data);
                    }, 500);
                } else {
                    message.info("No records found.");
                    setTotalRecords(response?.data?.totalCount || 0);
                    setLists(response?.data?.data);
                }
            } else {
                setLists([]);
                setTotalRecords(0);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLists([]);
        setTotalRecords(0);
        fetchFilteredData(selectedFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState]);

    // Filter and debounce
    const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
    useEffect(() => {
        fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFilter, page, limit]);

    const setItem = (item, type) => {
        if (type.type === "edit") {
        } else {
            showConfirm(item);
        }
    };
    const showConfirm = (item) => {
        let id = item?.id;
        Modal.confirm({
            title: "Are you sure you want to delete?",
            icon: <ExclamationCircleFilled />,
            async onOk() {
                try {
                    let result;
                    result =
                        await businessCapabilityServices.deleteBusinessCapability(
                            id,
                        );
                    if (result) {
                        let obj = {
                            id: localStorage.getItem("id"),
                            NameInSourceSystem: "",
                            DisplayName: localStorage.getItem("DisplayName"),
                            Description: "",
                            LevelID: "",
                        };

                        let field_OldData = {
                            DisplayName: item?.DisplayName || "",
                            Description: item?.Description || "",
                            LevelID: item?.LevelID || "",
                        };

                        await UpdateChangeLog(
                            obj.id,
                            field_OldData,
                            obj,
                            "Delete",
                            stateMenu?.MenuID?.moduleName,
                        );

                        setLists([]);
                        fetchFilteredData(selectedFilters);
                        setTotalRecords(0);
                        message.success(result?.data?.msg);
                        // setId(null)
                    } else {
                        message.error(result?.data?.msg);
                    }
                } catch (error) {
                    message.error(error?.message);
                }
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };
    return (
        <div className="d-flex justify-content-between">
            <div className="w-100 container-fluid  border-right">
                <div className="headingbx">
                    <PageTitleHeading text={`Business Capability`} />
                    <Flex wrap gap="small" className="ml-2">
                        <Button
                            onClick={() => {
                                props.setActiveGridStyle(1);
                            }}
                            icon={<UnorderedListOutlined />}
                            {...(props.activeGridStyle === 1
                                ? { type: "primary", ghost: true }
                                : { block: true })}
                            style={{ width: "30px" }}
                        />
                        <Button
                            onClick={() => {
                                props.setActiveGridStyle(2);
                            }}
                            icon={<img src={gridSvg} alt="Grid" width={20} />}
                            {...(props.activeGridStyle === 2
                                ? { type: "primary", ghost: true }
                                : { block: true })}
                            style={{ width: "30px" }}
                        />
                        <Tooltip title="Level 1">
                            <Button
                                className="btn-level-2 mr-2"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    navigate(
                                        "/business-capability-master/" +
                                            moduleNamePath +
                                            "/add",
                                        {
                                            state: {
                                                Level: 1,
                                                ParentID: 0,
                                                ParentName: "",
                                                underLevel: "",
                                                levelID: "",
                                            },
                                        },
                                    );
                                }}
                            />
                        </Tooltip>
                        <ActionsBtn name={`Business Capability`} />
                    </Flex>
                </div>
                <Row gutter={[8, 8]} className="mx-0 text-left mt-2">
                    <Col span={24} className="p-2">
                        <Spin size="small" spinning={loading}>
                            <div
                                className="overflow-y pr-2"
                                style={{ maxHeight: window?.innerHeight - 200 }}
                            >
                                <BusinessCapabilityListCards
                                    {...props}
                                    setItem={setItem}
                                    setModalData={() => {}}
                                    lists={lists}
                                    filterLevel={filterLevel}
                                    filterText={filterText}
                                    filterBy={filterBy}
                                    loading={loading}
                                />
                            </div>
                            {totalRecords > 0 && (
                                <Pagination
                                    className="mt-2 text-right"
                                    total={totalRecords}
                                    showTotal={(total) =>
                                        `Total ${total} items`
                                    }
                                    defaultPageSize={default_pageSize}
                                    defaultCurrent={1}
                                    pageSizeOptions={default_pageSizeOptions}
                                    showSizeChanger
                                    onChange={(p, ps) => {
                                        setLimit(ps);
                                        setPage(p);
                                    }}
                                />
                            )}
                        </Spin>
                    </Col>
                </Row>
            </div>
            <div>
                <>
                    <FilterRightSideBar
                        activeGridStyle={props.activeGridStyle}
                        lifeCycleStagesColors={lifeCycleStagesColors}
                        showFilter={showFilter}
                        setShowFilter={setShowFilter}
                        portfolioData={portfolioData}
                        selectedFilters={selectedFilters}
                        // setSelectedFilters={setSelectedFilters}
                        setSelectedFilters={(value) => {
                            setPage(1);
                            setSelectedFilters(value);
                        }}
                        sortValues={sortByValue}
                        optionalLevel={true}
                    />
                </>
            </div>
        </div>
    );
};

export default BusinessCapabilityList;
