import { encodeQueryData, removeEmptyKeys } from "../../utils/Common";
import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getList({ limit = 20, page = 1 }) {
  const url = `${API_BASE_URL}portfoliosetting?page=${page}&limit=${limit}`;
  let response = await http.get(url);
  return response;
}

async function fetchPortfolioNodesById(id) {
  const url = `${API_BASE_URL}portfoliosetting/getnodes/${id}`;
  let response = await http.get(url);
  return response;
}

async function create(data) {
  const url = `${API_BASE_URL}portfoliosetting`;
  let response = await http.post(url, data);
  return response;
}

async function updateSectionOrdering(data) {
  const url = `${API_BASE_URL}portfoliosection/updateSectionOrdering`;
  let response = await http.post(url, data);
  return response;
}

async function updatePropertiesOrdering(data) {
  const url = `${API_BASE_URL}portfolioproperty/updatePropertiesOrdering`;
  let response = await http.post(url, data);
  return response;
}

async function update(id, data) {
  const url = `${API_BASE_URL}portfoliosetting/${id}`;
  let response = await http.put(url, data);
  return response;
}

async function deletePortfolioSetting(id) {
  const url = `${API_BASE_URL}portfoliosetting/`;
  let response = await http.delete(url + id);
  return response;
}

async function getById(id) {
  const url = `${API_BASE_URL}portfoliosetting/`;
  let response = await http.get(url + id);
  return response;
}

async function getFilteredPortfolios(filter,page,limit) {
  let params = {
    page,
    limit,
  }
  params = removeEmptyKeys(params);
  const url = `${API_BASE_URL}portfoliosetting/filter?`+encodeQueryData(params);
  const response = await http.post(url, filter);
  return response;
}

const PortfolioSettingServices = {
  getList,
  create,
  update,
  deletePortfolioSetting,
  getById,
  fetchPortfolioNodesById,
  getFilteredPortfolios,
  updateSectionOrdering,
  updatePropertiesOrdering,
};

export default PortfolioSettingServices;
