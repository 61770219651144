import { Button, Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import DeleteSvg from "../../assets/Remove.svg";
import { convertToNormalText, dynamicRoutesLinks } from "../../utils/portfolioRelationshipCommon";
import useDynamicNavigate from "../../utils/navigateUtils";

const ScenarioListData = ({
  data,
  scenarioId,
  showConfirmDeleteScenarioItem,
  ...props
}) => {
  const [scenarioItems, setScenarioItems] = useState([]);
  const navigateTo = useDynamicNavigate();
  const groupedData = (data) => {
    return data?.reduce((acc, obj) => {
      const { item_id: itemId, portfolioSettings } = obj;
      const name = portfolioSettings?.name;
      if (itemId && portfolioSettings?.portfolios) {
        acc[name] = acc[name] || [];
        acc[name].push(obj);
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    if (data?.length > 0) {
      setScenarioItems(groupedData(data));
    }
  }, [data]);


  //   Group data of portfolios
  const groupAndExtractNames = (array) => {
    const result = {};

    array?.forEach(({ moduleName, NameInSourceSystem, DisplayName }) => {
      if (!result[moduleName]) {
        result[moduleName] = { list: [] };
      }

      // Push either NameInSourceSystem or DisplayName into the list array
      if (DisplayName) {
        result[moduleName].list.push(DisplayName);
      }
    });

    return result;
  };

  const WithChangeType = ({
    id = null,
    replaceWith = null,
    version = null,
    changeTypeId = null,
    replacedPortfolio = {},
    portfolios = {},
    portfolioName = {},
  }) => {
    const checkOperation = replaceWith ? 2 : version ? 3 : changeTypeId === 1 ? 1 : changeTypeId === 4 ? 4 : 1;
    return (
      <>
        <div className="fw-medium scenario_opt">
          {/* {checkOperation === 2
              ? "Replace with"
              : checkOperation === 3
              ? "Upgraded to"
              : checkOperation === 1
              ? "Retire"
              : "Retain"} */}
          {checkOperation === 2 ? (
            <span className="scenario-replacewith">Replace with</span>
          ) : checkOperation === 3 ? (
            <span className="scenario-upgradeto">Upgraded to</span>
          ) : checkOperation === 1 ? (
            <span className="scenario-retire">Retire</span>
          ) : (
            <span className="scenario-retain">Retain</span>
          )}
        </div>

        <div>
          <div className="d-flex fs-6 align-items-center">
            {checkOperation === 2 ? (
              (replaceWith && replacedPortfolio && (
                <>
                  <div className="h6 mb-0">
                    {replacedPortfolio?.DisplayName ||
                      replacedPortfolio?.NameInSourceSystem}
                  </div>
                </>
              )) || <></>
            ) : checkOperation === 3 ? (
              (
                <>
                  <div className="h6 mb-0">
                    Version <span className="font-bold">{version}</span>
                  </div>
                </>
              ) || <></>
            ) : checkOperation === 1 ? (
              <>{/* <div className="h6 mb-0">Removed</div> */}</>
            ) : (
              <>{/* <div className="h6 mb-0">Retain</div> */}</>
            )}
            <span>&emsp;&emsp;</span>
            {/* <div className="h6 mb-0">
              <Button
                shape={`circle`}
                icon={<img src={DeleteSvg} alt={`TCP`} width={`20px`} />}
                onClick={async () => {
                  const { NameInSourceSystem: name, id: itemId } = portfolios;
                  await showConfirmDeleteScenarioItem({
                    id,
                    name,
                    itemId,
                    portfolioName,
                  });
                }}
              />
            </div> */}
          </div>
        </div>
      </>
    );
  };

  const ConnectedPortfoliosItems = ({ relationship }) => {
    const portfoliosItems = groupAndExtractNames(relationship);
    return (
      <>
        {Object.keys(portfoliosItems).map((name) => (
          <Row>
            <Col span={4}>
              <span className="fw-medium">{convertToNormalText(name)}</span> :
            </Col>
            <Col span={20}>
              <span>{portfoliosItems[name]?.list.join(",")}</span>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const onDynamicLinkClick = (id = null, moduleName = "", rest = {}) => {
    const redirectLink = dynamicRoutesLinks?.find(
      (item) => item?.type === moduleName
    );
    if (redirectLink?.link && redirectLink?.moduleName && id) {
      const newLink = redirectLink?.link + redirectLink?.moduleName + "/" + id;
      navigateTo(newLink, true);
    }
  };

  return (
    <div className="scenariolistbx">
      {Object.keys(scenarioItems).map((name) => (
        <Card
          key={name}
          title={<span className="h4">{name}</span>}
          style={{
            background: `${scenarioItems[name][0]?.portfolioSettings?.color}`, 
            marginBottom: "20px",
          }}
          headStyle={{
            backgroundColor: `${scenarioItems[name][0]?.portfolioSettings?.color}`,
            padding: "10px 10px",
          }}
        >
          <div className="overflow-y-auto">
            {scenarioItems[name]?.map((item, index) => {
              const {
                portfolioSettings,
                id,
                comment,
                deadline,
                replace_with: replaceWith,
                version,
                change_type_id: changeTypeId,
              } = item;
              const {
                portfolios,
                replacedPortfolio,
                NameInSourceSystem,
                moduleName,
                relationship = [],
              } = portfolioSettings;
              return (
                <div key={index} className="card mb-2">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <div>
                        <h6 className="h6 mb-0 cursor-pointer" onClick={()=>{
                          const payload = {DisplayName: portfolios?.DisplayName}
                          onDynamicLinkClick(item?.item_id, moduleName, payload);
                        }}>
                          {portfolios?.DisplayName ||
                            portfolios?.NameInSourceSystem}
                        </h6>
                      </div>
                      <WithChangeType
                        id={id}
                        replaceWith={replaceWith}
                        version={version}
                        changeTypeId={changeTypeId}
                        replacedPortfolio={replacedPortfolio}
                        portfolios={portfolios}
                        portfolioName={moduleName}
                      />
                    </div>
                  </div>
                  {relationship?.length > 0 && (
                    <div className="flex  flex-col gap-2 card-header bg-white">
                      <ConnectedPortfoliosItems relationship={relationship} />
                    </div>
                  )}

                  <div className="card-body">
                    <Row>
                      <Col span={4}>
                        <span className="fw-medium">Comment</span>:
                      </Col>
                      <Col span={20}>
                        <div>{comment}</div>
                        {/* <div>
                        <span className="fw-bold">*</span> Improve vehicle SW
                        management.
                      </div>
                      <div>
                        <span className="fw-bold">*</span> Enable aftermarket
                        reconfiguration of vehicles.
                      </div>
                      <div>
                        <span className="fw-bold">*</span> Improve global
                        manufacturing capabilities.
                      </div> */}
                      </Col>
                    </Row>
                    <div className="scenario_listbtn">
                     <div className="btnbx">
                     
                      <button className="btn btn-sm btn-dark mr-2">
                        Impact Analysis
                      </button>
                      <button className="btn btn-sm btn-dark mr-2">
                        Change Management
                      </button>
                      </div>

                      <div className="btnbx">
              <Button
                shape={`circle`}
                icon={<img src={DeleteSvg} alt={`TCP`} width={`20px`} />}
                onClick={async () => {
                  const { NameInSourceSystem: name, id: itemId } = portfolios;
                  await showConfirmDeleteScenarioItem({
                    id,
                    name,
                    itemId,
                    portfolioName:moduleName,
                    });
                }}
              />
            </div>


                    </div>
                    
                  </div>
                </div>
              );
            })}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default ScenarioListData;
