import React from 'react'
import FilterButtonToggle from '../../layouts/FilterButtonToggle'
import BusinessProcessFilterContent from './BusinessProcessFilterContent'

const BusinessProcessListFilter = ({showFilter,setShowFilter,lifeCycleStagesColors,activeGridStyle,data,selectedFilters,setSelectedFilters,sortValues,optionalLevel}) => {
  return (
    <div className='p-2'>
        <FilterButtonToggle
            showFilter={showFilter}
            setShowFilter={setShowFilter}
        />
        {
            showFilter && (
            <BusinessProcessFilterContent
              lifeCycleStagesColors={lifeCycleStagesColors}
              activeGridStyle={activeGridStyle}
              portfolioData={data}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              sortValues={sortValues}
              optionalLevel={optionalLevel}
            />)
        }
    </div>
  )
}

BusinessProcessListFilter.propTypes = {}

export default BusinessProcessListFilter