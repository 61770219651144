import OgmaLib from '@linkurious/ogma';
import { NodeGrouping, NodeStyleRule, Ogma } from '@linkurious/ogma-react';
import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { createDriver, useReadCypher, useReadSession } from 'use-neo4j';
import { alterEdges, getColorCodeNode, getLabelIcon } from '../../utils/CityMapCommon';
import ExportBtn from './ExportBtn';
import GraphLayout from './GraphLayout';
import Legends from './Legends';

const GraphContainer = (props) => {
  const [query, setQuery] = useState("Matrix")
  const {error, loading, records, run } = useReadCypher(props?.cityMapQuery, { query })
  const {formData, setLoading, setFilterNodes, selectedFilterNodes, setCityMapQuery} = props;
  const [graph, setGraph] = useState()
  const [graphLayout, setGraphLayout] = useState(false)
  const [legendsLabel, setlegendsLabel] = useState([]);
  const [dblClickEvent, setDblClickEvent] = useState(false)
  const [ogmaDblClickData, setOgmaDblClickData] = useState([])
  const [exportGraphFormat, setExportGraphFormat] = useState('')

 const ogmaOptions = {
    backgroundColor:"transparent",
    // zoom: {
    //   maxValue: "50%"
    // },
    minimumHeight: 700,
 }
  
  useEffect(() => {
    setLoading(true)
    run({ query })
    .then(async (readResult) => {
      if(dblClickEvent) {
        return OgmaLib.parse.neo4j(readResult)
      } else {
        createQuery(readResult);
      }
    })
    .then(graph => {
      if(graph && Object.keys(graph).length > 0){
        const newNodes = graph.nodes.map(item => {
                        let labelName = item?.data?.neo4jLabels[0];
                        let iconName = getLabelIcon(labelName)
                          item.label = labelName
                          item.data = {
                              label: labelName,
                              neo4jLabels: labelName,
                              neo4jProperties: item?.data?.neo4jProperties
                            }
                          item.attributes= {
                              text: item?.data?.neo4jProperties?.name,
                              icon: {
                                content: iconName,
                                font: 'Font Awesome 5 Free',
                                color: 'black',
                                style: 'bold'
                              },
                              outerStroke: {
                                color: '#204829',
                                width: 2
                              },
                              color: "white",
                            }

                        return item;
                      })
        
        graph.nodes = newNodes
        const newEdges = graph.edges.map(item => {
                        item.type = item?.data?.neo4jType;
                        item.attributes = {
                                text: item?.data?.neo4jType,
                                color: "green",
                                shape: {
                                  head: "arrow",
                                },
                                width: 1
                              }

                        return item;
                      })
        graph.edges = newEdges

        setTimeout(()=> {
          setOgmaDblClickData(graph);
        },1000)
        setDblClickEvent(false);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }, [props?.cityMapQuery, formData])

  const createQuery = async (readResult) => {

    // if (selectedFilterNodes.length > 0 ) {
    //   readResult = await alterEdges(readResult)
    // }
    let nodeLabelData = []
    let nodeRelationsData = []
    let uniqueNode = [];
    let uniqueRelation = [];
    let newGraph = {};
    if(readResult){
      if (formData?.usecase === '1') {
        if(readResult.records.length){
          var labelName = readResult.records[0]._fields[0].start.labels[0];
          var iconName = getLabelIcon(labelName)
          var nodeColor = readResult.records[0]._fields[0].start.properties.referenceId === formData?.city_map_node ? "#e39662" : "white";
        }
        
        readResult.records.forEach(record => {
          if(record._fields.length > 1 && record._fields[1].length > 0){
            record._fields[0].forEach(zeroRec => {
              let labelName = zeroRec.labels[0];
              let iconName = getLabelIcon(labelName)
              let nodeColor = zeroRec.properties.referenceId === formData?.city_map_node ? "#e39662" : "white";
              if(!nodeLabelData.includes(labelName)){
                nodeLabelData.push(labelName)
              }
              const newNode = {
                label: zeroRec.labels[0],
                id: zeroRec.identity.low,
                data: {
                  label: labelName,
                  neo4jLabels: labelName,
                  neo4jProperties: zeroRec.properties
                },
                attributes: {
                  text: zeroRec.properties.name,
                  icon: {
                    content: iconName,
                    font: 'Font Awesome 5 Free',
                    color: 'black',
                    style: 'bold'
                  },
                  outerStroke: {
                    color: '#204829',
                    width: 2
                  },
                  color: nodeColor,
                }, 
              };
              // Check if the same ID already exists in uniqueNode
              let isDuplicate = uniqueNode.some(node => node.id === newNode.id);
              if (!isDuplicate) {
                uniqueNode.push(newNode);
              }
            });
            record._fields[1].forEach(oneRec => {
              const newNode = {
                id: oneRec.identity.low,
                type: oneRec.type,
                source: oneRec.start.low,
                target: oneRec.end.low,
                attributes: {
                  text: oneRec.type,
                  color: "green",
                  shape: {
                    head: "arrow",
                  },
                  width: 1
                },
                
              };
    
              // let isDuplicate = uniqueRelation.some(node => node.end === newNode.end && node.start === newNode.start);
              let isDuplicate = uniqueRelation.some(node => node.id === newNode.id);
    
              if (!isDuplicate) {
                uniqueRelation.push(newNode);
              }
            });
          }else{
            record._fields.forEach(zeroRec => {
              uniqueNode.push({
                label: zeroRec.start.labels[0],
                id: zeroRec.start.identity.low,
                data: {
                  label: labelName,
                  neo4jLabels: labelName,
                  neo4jProperties: zeroRec.start.properties
                },
                attributes: {
                  text: zeroRec.start.properties.name,
                  icon: {
                    content: iconName,
                    font: 'Font Awesome 5 Free',
                    color: 'black',
                    style: 'bold'
                  },
                  outerStroke: {
                    color: '#204829',
                    width: 2
                  },
                  color: nodeColor,
                },
              })
              let endlabelName = zeroRec.end.labels[0];
              let endiconName = getLabelIcon(endlabelName)
              let endnodeColor = "white";

              const newNode = {
                label: zeroRec.end.labels[0],
                id: zeroRec.end.identity.low,
                data: {
                  label: endlabelName,
                  neo4jLabels: endlabelName,
                  neo4jProperties: zeroRec.end.properties
                },
                attributes: {
                  text: zeroRec.end.properties.name,
                  icon: {
                    content: endiconName,
                    font: 'Font Awesome 5 Free',
                    color: 'black',
                    style: 'bold'
                  },
                  outerStroke: {
                    color: '#204829',
                    width: 2
                  },
                  color: endnodeColor,
                },
                
              };
              // Check if the same ID already exists in uniqueNode
              let isDuplicate = uniqueNode.some(node => node.id === newNode.id);
              if (!isDuplicate) {
                uniqueNode.push(newNode);
              }
              const newNode1 = {
                id: zeroRec.segments[0].relationship?.identity.low,
                type: zeroRec.segments[0].relationship?.type,
                source: zeroRec.start.identity.low,
                target: zeroRec.end.identity.low,
                attributes: {
                  text: zeroRec.segments[0].relationship?.type,
                  color: "green",
                  shape: {
                    head: "arrow",
                  },
                  width: 1
                },
              };
              let isDuplicateRelation = uniqueRelation.some(node => node.id === newNode1.id);
              if (!isDuplicateRelation) {
                uniqueRelation.push(newNode1);
              }
            });
          }
        });
        // console.log(labelName,"=====labelName")

        nodeLabelData = uniqueNode.reduce((acc, node) => {
          // console.log(acc, node,"=====acc, node")
          if(node.label != labelName){
            const existingLabel = acc.find(item => (item.label === node.label));
      
            if (existingLabel) {
              existingLabel.count++;
            } else {
              acc.push({ label: node.label, count: 1, color: getColorCodeNode(node.label), icon: getLabelIcon(node.label) });
            }
      
          }
          return acc;
        }, []);
        
        nodeRelationsData = uniqueRelation.reduce((acc, node) => {
          const existingType = acc.find(item => item.type === node.type);
          if (existingType) {
            existingType.count++;
          } else {
            acc.push({ type: node.type, count: 1 });
          }
    
          return acc;
        }, []);

        newGraph.nodes = uniqueNode
        newGraph.edges = uniqueRelation
        setGraph(newGraph);
        if(selectedFilterNodes.length === 0){
          setFilterNodes(nodeLabelData);
        } 
        setlegendsLabel(nodeLabelData);
        if(nodeLabelData.length === 0){
          props?.setShowGraph(false)
        }
        // console.log(uniqueNode,"====uniqueNode");
        // console.log(uniqueRelation,"====uniqueRelation");
      }
    }
  }

  // City Map Graph loader
  setLoading(loading)

  return (
    <Card className='mt-2' style={{ height: "800px" }}>
      <Ogma graph={graph}
        options={ogmaOptions} >
          <NodeGrouping
            selector={node => node.getAttribute('id') === '3383'}
            groupIdFunction={node => node.getAttribute('id')}
            disabled={false}
          />
        <GraphLayout 
          graphLayout={graphLayout} 
          graph={graph} 
          setLoading={setLoading} 
          run={run}
          setCityMapQuery={setCityMapQuery}
          setDblClickEvent={setDblClickEvent}
          ogmaDblClickData={ogmaDblClickData}
          exportGraphFormat={exportGraphFormat}
          setExportGraphFormat={setExportGraphFormat}
          />
      </Ogma>
      <Legends legendsLabel={legendsLabel} />
      <ExportBtn setExportGraphFormat={setExportGraphFormat} />
    </Card>
  );
}

export default GraphContainer 