import React from 'react';
import AdminLayout from '../components/layouts/AdminLayout';
import SurveyAdd from '../components/surveys/SurveyAdd';

const SurveysAddPage = (props) => {
    return (
        <>
            <SurveyAdd/>
        </>
    )
}

export default SurveysAddPage