import React, { createContext, useContext, useReducer } from "react";
const LinkApplicationContext = createContext();
export const useApplicationlink = () => useContext(LinkApplicationContext);

const initialState = {
  links: [],
  loading: false,
  error: false,
  businessApplicationId: null,
  businessApplicationModel: "",
};

const linkReducer = (state, action) => {
  switch (action.type) {
    case "SET_LINK":
      // Filter out duplicate links by id
      const uniqueLinks = action.payload.reduce((acc, link) => {
        // Check if the link id already exists in the state
        if (!state.links.some((item) => item.id === link.id)) {
          acc.push(link);
        }
        return acc;
      }, []);
      return {
        ...state,
        links: [...state.links, ...uniqueLinks],
      };
    case "ADD_LINK":
      return {
        ...state,
        links: [...state.links, action.payload],
      };
    case "UPDATE_LINK":
      return {
        ...state,
        links: state.links.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case "DELETE_LINK":
     
      return {
        ...state,
        links: state.links.filter((item) => item.id !== action.payload.id),
       
      };
    case "EMPTY_LINK":
      return {
        ...state,
        links: [],
        loading: false,
        error: false,
        businessApplicationId: null,
        businessApplicationModel: "",
      };
    case "SET_BUSINESSAPPLICATIONID":
      return {
        ...state,
        businessApplicationId: action.payload,
      };
    case "SET_BUSINESS_APPLICATION_MODEL":
      return {
        ...state,
        businessApplicationModel: action.payload,
      };
    default:
      return state;
  }
};

export const LinkProvider = ({ children }) => {
  const [state, dispatch] = useReducer(linkReducer, initialState);

  return (
    <LinkApplicationContext.Provider value={{ state, dispatch }}>
      {children}
    </LinkApplicationContext.Provider>
  );
};
