import React, { createContext, useContext, useReducer } from "react";

// Define the PortfolioSetting context
const PortfolioConnectionContext = createContext();

// Custom hook to use PortfolioSetting context
export const usePortfolioConnection = () => useContext(PortfolioConnectionContext);

// Initial state for portfolio settings
const initialState = {
  data: [],
  loading: false,
  error: false,
};

// Reducer function to handle state updates
const portfolioConnectionReducer = (state, action) => {
  switch (action.type) {
    case "SET_PORTFOLIO_SETTINGS":

    console.log("SET_PORTFOLIO_SETTINGS", action.payload);

      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case "SET_SINGLE_PORTFOLIO_CONNECTION":
      
    console.log("SET_SINGLE_PORTFOLIO_CONNECTION", action.payload);


      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case "ADD_PORTFOLIO_SETTING":
      return {
        ...state,
        data: Array.isArray(state.data)
          ? [...state.data, ...action.payload]
          : [...action.payload],
        loading: false,
        error: false,
      };
    case "UPDATE_PORTFOLIO_SETTING":
      return {
        ...state,
        data: state.data.map((setting) =>
          setting.id === action.payload.id ? action.payload : setting
        ),
        loading: false,
        error: false,
      };
    case "DELETE_PORTFOLIO_SETTING":
      console.log("state.data", state.data);
      return {
        ...state,
        data: state?.data?.filter(
          (setting) => setting.id !== action.payload.id
        ),
        loading: false,
        error: false,
      };

      case "SET_BUSINESS_APPLICATION":
      return {
        ...state,
        data: [...state.data, ...action.payload],
      };
    // Add more cases for other actions if needed
    default:
      return state;
  }
};

// Provider component to manage portfolio settings state
export const PortfolioConnectionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(portfolioConnectionReducer , initialState);

  return (
    <PortfolioConnectionContext.Provider value={{ state, dispatch }}>
      {children}
    </PortfolioConnectionContext.Provider>
  );
};
