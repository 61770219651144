import React, { createContext, useContext, useState } from "react";
import { tabsList } from "../../utils/linksCommon";

const TabContext = createContext();

export const TabProvider = ({ children }) => {
  const [tabDisabled, setTabDisabled] = useState(() => {
    const initialState = {};
    tabsList.forEach((tab) => {
      initialState[tab.name] = tab.disabled;
    });
    return initialState;
  });
  // const [tabDisabled, setTabDisabled] = useState({
  //   tab_information: false,
  //   tab_connections: true,
  //   tab_links: true,
  //   tab_diagram: true,
  //   tab_comments: true,
  //   tab_survey: true,
  //   tab_logalerts: true,
  // });

  const toggleTabDisabled = (tabKey, disabled) => {
    setTabDisabled((prevTabDisabled) => ({
      ...prevTabDisabled,
      [tabKey]: disabled,
    }));
  };

  return (
    <TabContext.Provider value={{ tabDisabled, toggleTabDisabled }}>
      {children}
    </TabContext.Provider>
  );
};

export const useTabContext = () => useContext(TabContext);
