import React from 'react'
import { useNavigate } from 'react-router'
import { dynamicRoutesLinks } from './portfolioRelationshipCommon';

const goBackKey = "go-back-location";
// ------------------------------------------------------------------------------
// GoBackHistory Save History
export const GoBackHistory = (location) => {
    let records = [];
    let goBackLocation = sessionStorage.getItem(goBackKey);
    goBackLocation = JSON.parse(goBackLocation);
    if(goBackLocation?.length>0){
        let lastLocation = goBackLocation[goBackLocation.length-1];
        if(lastLocation?.pathname!==location?.pathname){
            goBackLocation.push(location);
            records= goBackLocation;
            if(records.length>50){
                records.splice(0, 1);
            }
        }
    }else{
        records= [location];
    }
    if(records?.length>0){
        records= JSON.stringify(records);
        sessionStorage.setItem(goBackKey, records);
    }
    return 
}

GoBackHistory.propTypes = {}
// ------------------------------------------------------------------------------
// GoBack
export const GoBack = ({path,state,className}) =>{
    let goBackLocation = sessionStorage.getItem(goBackKey);
    goBackLocation = JSON.parse(goBackLocation);

    let navigate = useNavigate();

    const onClickHandle = () => {
        let lastRecord = goBackLocation[goBackLocation?.length-2];
        goBackLocation = goBackLocation.slice(0,-1);
        goBackLocation= JSON.stringify(goBackLocation);
        sessionStorage.setItem(goBackKey, goBackLocation);
        let pathname=path;
        if(lastRecord?.pathname===path){
            pathname=lastRecord?.pathname;
        }else if(path===-1){
            pathname=lastRecord?.pathname;
        }
        setTimeout(() => {
            navigate(pathname, { state: state })    
        }, 100);
    }
    
    return  <React.Fragment>
        <button 
            className={`btn btn-sm btn-outline-secondary ${className}` }
            onClick={onClickHandle}
        >
            <i className="fa fa-arrow-left"></i> Back
        </button>
    </React.Fragment>;
}

GoBack.defaultProps = {
    path:-1,
    state:null,
    className:"",
}