import React, { useEffect, useRef, useState } from 'react';
import { Button, List } from 'antd';
import { useNavigate } from "react-router";
import {  useParams } from "react-router-dom";

const BPMLevel3 = (props) => {
    const [dropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
   
    const { moduleName:moduleNamePath } = useParams();
    
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdown(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return <>
        <List.Item className='level-3'>
        {props.data.LevelID} {props.data.DisplayName}
            <div className='level-btn' style={{ position: 'relative' }}>
                <button className="btn btn-primary dropdown-toggle p-0" type="button" onClick={(e) => {
                    setDropdown(!dropdown);
                }}>
                    <i className="fa fa-ellipsis-v edit" style={{ color: 'black' }} />
                </button>
                {
                    dropdown && (
                        <ul className="dropdown-menu" ref={dropdownRef}>
                            <li><Button onClick={() => { props?.setItemDelete(props?.data, { type: 'delete' });  }} className='btn-icon'><i className="fa fa-trash-o delete" /></Button></li>
                            <li><Button onClick={() => { navigate('/business-process/'+moduleNamePath+'/'+props?.data?.id  )}} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li>
                            
                        </ul>
                    )
                }
            </div>
        </List.Item>
    </>;
}

export default BPMLevel3;