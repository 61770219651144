import { PlusOutlined } from "@ant-design/icons"
import { Button } from "antd"

export const SelectButtonCompareScenarioV1 = ({id,...props}) => {
    return (
        <div>
            {
                ((()=>{
                    if(props?.selectedScenario?.includes(id)){
                        return <Button type="default" disabled>Added</Button>
                    }else{
                        return <Button type="primary" icon={<PlusOutlined/>} onClick={()=>{props?.selectScenario(id)}}/>
                    }
                })())
            }
        </div>
    )
}