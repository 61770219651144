import { Button, Card, Col, Divider, Drawer, Row, Space, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import diagramServices from '../../../../services/services/diagramServices';
import TextWithTooltip from '../../../../utils/TextWithTooltip';
import { CloseOutlined, EditOutlined, FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import AddDiagram from './AddDiagram';
import { useLocation, useNavigate } from 'react-router';
import { PageTitleHeading } from '../../../../utils/Common';

const DiagramScreen = ({titleName,setLoading,moduleName,modelId,moduleData,...props}) => {
    const location = useLocation();
    let navigate = useNavigate();
    const [diagramsData,setDiagramsData] = useState([]);
    const [selectdiagramData,setSelectDiagramData] = useState();
    const [imageScreen,setImageScreen] = useState(false);



    useEffect(() => {
        getDiagrams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const getDiagrams = async () => {
        try {
          setLoading(true);
          const response = await diagramServices.getListByModule({
            moduleName:moduleName,
            moduleID:modelId,
          });
          setDiagramsData(response?.data?.data || [])
          setLoading(false)
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
    };

    const getImageScreen = () =>{
        if(!selectdiagramData?.svg) return;
        if(!imageScreen){
            return <div className='card w-100 border-0'>
                <div className='card-header d-flex justify-content-between align-items-center bg-none border-0 py-0 border-bottom'>
                    <PageTitleHeading text={selectdiagramData?.name} size={4}/>
                    <Button onClick={()=>{setImageScreen(true)}} icon={<FullscreenOutlined />} className='pull-right'/>
                </div>
                <div className='card-body'>
                {
                    selectdiagramData?.svg && <img src={selectdiagramData?.svg} alt={selectdiagramData?.name} className='img-fluid' style={{maxHeight:window?.innerHeight/2}}/>
                }
                </div>
            </div>
        }else{
            return <Drawer
                title={selectdiagramData?.name || ""}
                placement="top"
                width={window?.innerWidth}
                height={window?.innerHeight}
                onClose={()=>{setImageScreen(false)}}
                open={imageScreen}
                extra={
                    <Space>
                        <Button onClick={()=>{setImageScreen(false)}} icon={<FullscreenExitOutlined />}/>
                    </Space>
                }
          >
            {
                selectdiagramData?.svg && <img src={selectdiagramData?.svg} alt={selectdiagramData?.name} className='img-fluid'/>
            }
          </Drawer>
        }   
    }

    const deleteDiagram =async (id) =>{
        try {
            setLoading(true);
            const response = await diagramServices.deletediagram(id);
            const newData = response?.data || null;
            // Handle success response
            message.success("Diagram deleted successfully");
            setTimeout(() => {
                setLoading(false);
                getDiagrams();
            }, 1000);
        } catch (error) {
            console.log("catcheEror",error)
            setLoading(false);
        }
       
    }

    const editDiagram = (id) =>{
        navigate(`/diagram-editor/${id}`,{state:{previousPath:location?.pathname,titleName:titleName,moduleName:moduleName}})
    }

    return (
        <div>
            <div className="w-100">(This screen shows diagrams created for <span className='diagramtxtcolr' style={{color:'#ffc107'}}>{`"${titleName || ""}"`}</span> .It also shows other diagram which references <span className='diagramtxtcolr' style={{color:'#ffc107'}}>{`"${titleName || ""}"`}</span>.)</div>
            <hr className="my-3"/>
            <Row gutter={8}>
                <Col span={11}>
                    <div className="h4 mb-3"><span  className='diagramtxtcolr' style={{color:'#ffc107'}}>"{titleName || ""}"</span> Diagram</div>
                    <div className='overflow-y pr-3 diagramlist' style={{maxHeight:200}}>
                        {
                            diagramsData?.map((diagram,index)=>{
                                return <div className={`card card-body p-0 mb-2`} key={Math.random()}>
                                    <div className="d-flex justify-content-between align-items-center" >
                                        <div type={`text`} className='w-100 p-1 cursor-pointer' onClick={()=>{ setSelectDiagramData(diagram); }}>
                                            <div className='w-100 d-flex'>
                                                <div className='text-left w-100 px-2'><TextWithTooltip text={diagram?.name} characterLimit={50}/></div>
                                            </div>
                                        </div>
                                        <Tooltip title="Edit Diagram">
                                            <Button type="text" icon={<EditOutlined />}  size={`large`} onClick={()=>{ editDiagram(diagram?.id) }}/>
                                        </Tooltip>
                                        <Tooltip title="Delete Diagram" className='ml-2'>
                                            <Button type="text" icon={<CloseOutlined />}  size={`large`} onClick={()=>{ deleteDiagram(diagram?.id) }}/>
                                        </Tooltip>
                                    </div>
                                </div>
                            }) || ""
                        }
                    </div>
                    <div className='d-flex justify-content-between my-2'>
                        <AddDiagram
                            businessApplicationId={modelId}
                            moduleName={moduleName}
                            onListUpdate = {getDiagrams}
                            titleName={titleName}
                        />
                    </div>
                    <hr/>
                    <div className='d-none'>
                        <div className="h4 mb-3"><span style={{color:'#ffc107'}}>"{titleName}"</span> is referenced in following diagrams</div>
                        <div className={`card card-body p-0`} key={Math.random()}>
                            <div className="d-flex justify-content-between align-items-center" >
                                <div type={`text`} className='w-100 p-1 cursor-pointer' onClick={()=>{  }}>
                                    <div className='w-100 d-flex'>
                                        <div style={{width:40,borderRight:"1px solid #000",textAlign:"center"}} className='px-2'>{1}</div>
                                        <div className='text-left w-100 px-2'>Online Payment</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`card card-body p-0`} key={Math.random()}>
                            <div className="d-flex justify-content-between align-items-center" >
                                <div type={`text`} className='w-100 p-1 cursor-pointer' onClick={()=>{  }}>
                                    <div className='w-100 d-flex'>
                                        <div style={{width:40,borderRight:"1px solid #000",textAlign:"center"}} className='px-2'>{1}</div>
                                        <div className='text-left w-100 px-2'>Refund</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={1} className='text-center'>
                    <Divider type="vertical" style={{height:window?.innerHeight-300}} plain/>
                </Col>
                <Col span={12} className='p-0'>
                    {getImageScreen()}
                </Col>
            </Row>
            
        </div>
    )
}

DiagramScreen.propTypes = {}

export default DiagramScreen