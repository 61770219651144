import React from "react";
import { Select } from "antd";
import { EyeFilled } from "@ant-design/icons";

const ApplicationCapabilityMatrix = (props) => {
    return (
        <div className="application-capability-matrix ">
            <div className="bg-aaaaaa6b card card-body p-2 border-0 card card-body p-2 border-0-body p-2 border-0">
                <div className="d-flex justify-content-end ">
                    <div className="d-flex align-items-center">
                        <div>X-Axis &emsp;</div>
                        <Select
                            style={{ width: "200px" }}
                            defaultValue="Business capability"
                        >
                            <Select.Option>Business capability</Select.Option>
                        </Select>
                    </div>
                    &emsp;
                    <div className="d-flex align-items-center">
                        <div>Y-Axis &emsp;</div>
                        <Select
                            style={{ width: "200px" }}
                            defaultValue="Region"
                        >
                            <Select.Option>Region</Select.Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div className="table-responsive overflow-y" style={{width:(window?.innerWidth-(props?.showFilter?650:370))}}>
                <table width={`100%`} className="acg-table">
                    <tbody>
                        <tr>
                            <td></td>
                            <td >
                                <span>Business capability 1</span>
                            </td>
                            <td >
                                <span>Business capability 2</span>
                            </td>
                            <td >
                                <span>Business capability 3</span>
                            </td>
                            <td >
                                <span>Business capability 4</span>
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <span className="rotate-90">Asia</span>
                            </td>
                            <td>
                                <div className="card card-body p-2 border-0" style={{background:props?.lifeCycleStagesColors["active"],}} >
                                    <div className="text-center">
                                        <span >App 1</span>
                                    </div>
                                </div>
                                <div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["active"],}} >
                                    <div className="text-center">
                                        <span >App 2</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["phaseIn"],}} >
                                    <div className="text-center">
                                        <span >App 3</span>
                                    </div>
                                </div>
                                <div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["phaseIn"],}} >
                                    <div className="text-center">
                                        <span >App 4</span>
                                    </div>
                                </div>
                                <div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["phaseIn"],}} >
                                    <div className="text-center">
                                        <span >App 5</span>
                                    </div>
                                </div>
                            </td>
							<td>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["color7"],}} >
                                    <div className="text-center">
                                        <span >App 6</span>
                                    </div>
                                </div>
							</td>
							<td>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["color5"],}} >
                                    <div className="text-center">
                                        <span >App 5</span>
                                    </div>
                                </div>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["color5"],}} >
                                    <div className="text-center">
                                        <span >App 4</span>
                                    </div>
                                </div>
							</td>
                        </tr>
                        <tr>
                            <td >
                                <span className="rotate-90">Europe</span>
                            </td>
                            <td>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["phaseIn"],}} >
                                    <div className="text-center">
                                        <span >App 10</span>
                                    </div>
                                </div>
                            </td>
                            <td>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["active"],}} >
                                    <div className="text-center">
                                        <span >App 11</span>
                                    </div>
                                </div>
							</td>
                            <td>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["phaseIn"],}} >
                                    <div className="text-center">
                                        <span >App 7</span>
                                    </div>
                                </div>
                                <div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["phaseIn"],}} >
                                    <div className="text-center">
                                        <span >App 8</span>
                                    </div>
                                </div>
                            </td>
							<td>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["color7"],}} >
                                    <div className="text-center">
                                        <span >App 11</span>
                                    </div>
                                </div>
							</td>
                        </tr>
                        <tr>
                            <td >
                                <span className="rotate-90">North America</span>
                            </td>
                            <td>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["phaseOut"],}} >
                                    <div className="text-center">
                                        <span >App 12</span>
                                    </div>
                                </div>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["color7"],}} >
                                    <div className="text-center">
                                        <span >App 13</span>
                                    </div>
                                </div>
                            </td>
                            <td>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["phaseIn"],}} >
                                    <div className="text-center">
                                        <span >App 11</span>
                                    </div>
                                </div>
                            </td>
                            <td>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["phaseOut"],}} >
                                    <div className="text-center">
                                        <span >App 4</span>
                                    </div>
                                </div>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["phaseOut"],}} >
                                    <div className="text-center">
                                        <span >App 2</span>
                                    </div>
                                </div>
                            </td>
                            <td>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["color7"],}} >
                                    <div className="text-center">
                                        <span >App 2</span>
                                    </div>
                                </div>
								<div className="card card-body p-2 border-0" style={{ background: props?.lifeCycleStagesColors["color5"],}} >
                                    <div className="text-center">
                                        <span >App 4</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ApplicationCapabilityMatrix;
