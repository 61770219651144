import { DeleteOutlined, EditOutlined, ExclamationCircleFilled, EyeOutlined } from '@ant-design/icons';
import { Badge, Button, Checkbox, Divider, Flex, Layout, Modal, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router";
import IntegrationServices from '../../services/services/IntegrationServices';
import { UpdateChangeLog } from '../../utils/ChangeLogs';
import FilterButtonToggle from '../layouts/FilterButtonToggle';
import { PageTitleHeading, debounceTimeOut, deepFindByName, getSelectProperties, removeEmptyArrObjOrval } from '../../utils/Common';
import FilterContent from '../applicationlist/filter/FilterContent';
import BusinessApplicationServices from '../../services/services/BusinessApplicationService';
import { useBusinessApplication } from '../../Context/businessApplication';
import { usePortfolioSetting } from '../../Context/portfolioSettingProvider';
import { staticSettingPortfolioList } from '../../utils/settingCommon';
import PortfolioSettingServices from '../../services/services/PortfolioSettingsService';
import useDebounce from '../../helper/useDebounce';
import usePortfolioFetcher from '../../hooks/usePortfolioFetcher';

const lifeCycleStagesColors = {
    active: "#FFB381",
    phaseIn: "#FFF280",
    phaseOut: "#FC819E",
    endOfLife: "#B2B377",
    color5: "#B5C0D0",
    color6: "#BFEF82",
    color7: "#90DDF9",
};

const sortByValueApplication = [
    { value: "id", title: "ID" },
    { value: "name", title: "Name" },
];

export default function IntegrationButtonsV1({moduleName,item,...props}) {

    const navigate = useNavigate();
    return (
        <div className="text-right d-flex">
            {props?.showButtons?.view && (
            <EyeOutlined
                className="cursor-pointer ml-2"
                onClick={() => {
                    navigate('/portfolio-integration/'+moduleName+'/'+item?.id  )
                }}
                style={{ fontSize: "20px" }}
            />
            )}
            {props?.showButtons?.edit && (
            <EditOutlined
                className="cursor-pointer ml-2 text-blue-500"
                onClick={() => {
                    navigate('/portfolio-integration/'+moduleName+'/'+item?.id  )
                }}
                style={{ fontSize: "20px" }}
            />
            )}
            {props?.showButtons?.delete && (
            <DeleteOutlined
                className="cursor-pointer ml-2 text-danger"
                onClick={() => {
                    props?.setItemDelete(item, { type: "delete" });
                }}
                style={{ fontSize: "20px" }}
            />
            )}
        </div>
    )
}

export const showConfirmDelete = ({item,type,moduleName,getIntegrationList}) => {
    const id = item?.id || 0;
    Modal.confirm({
        title: 'Are you sure to delete?',
        icon: <ExclamationCircleFilled />,
        async onOk() {
            try {
                let result;
                result = await IntegrationServices.deleteIntegration(id);
                if (result) {
                    let obj = {
                        id:id,
                        NameInSourceSystem: item?.NameInSourceSystem || "",
                        DisplayName: item?.DisplayName || "",
                        Description:item?.Description || "",
                        LevelID:item?.LevelID || "",
                    } 

                    let field_OldData = {
                        DisplayName: item?.DisplayName || "",
                        Description:item?.Description || "",
                        LevelID:item?.LevelID || "",
                    }

                    await UpdateChangeLog(obj.id,field_OldData,obj, "Delete", moduleName)

                    await getIntegrationList();
                    message.success(result?.data?.msg);
                } else {
                    message.error(result?.data?.msg)
                }
            } catch (error) {
                message.error(error?.message)
            }
        },
        onCancel() {
            console.log('Cancel');
        },
    });
};

export const ApplicationModal = ({open,setOpen,Buttons}) => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [limit] = useState(2000);
    const [showFilter, setShowFilter] = useState(false);
    const [portfolioData, setPortfolioData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const { state, dispatch } = useBusinessApplication();
    const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } = usePortfolioSetting();
    const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
    const [hasMore, setHasMore] = useState(true);
    useEffect(() => {
        fetchTagsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchTagsData = async () => {
        try {
            setLoading(true);
            const response = await BusinessApplicationServices.getAllTagsList();
            const newData = response.data.data;
            await dispatch({ type: "SET_ALL_TAGS", payload: newData });
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    const fetchFilteredData = async (filter) => {
        try {
            setLoading(true);
            if (Object.keys(removeEmptyKeys(filter))?.length > 0) {
                const portfolio = await deepFindByName(
                    portfolioSettingState?.data,
                    staticSettingPortfolioList?.application,
                    false
                );
                const updatedFilter = {
                    moduleName: portfolio?.moduleName || "",
                    ...filter,
                };

                const response = await PortfolioSettingServices?.getFilteredPortfolios(updatedFilter);
                if (response?.data?.data?.length) {
                    setList(response?.data?.data);
                } else {
                    message.info("No records found.");
                    setList(response?.data?.data);
                }
            } else {
                setList(state?.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    // Filter and debounce
    const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        try {
            if(hasMore){
                setLoading(true);
                let tempPage = page+1;
                setPage(tempPage);
                let response = await BusinessApplicationServices.getList({page:tempPage,limit,});
                let newData = response.data.data;
                if(tempPage===response?.data?.totalPages){
                    setHasMore(false)
                }
                if(newData?.length > 0 && Object.keys(selectedFilters)?.length===0){
                    newData = [...new Map([...list,...newData].map(item =>[item['id'], item])).values()];
                    await dispatch({ type: "SET_BUSINESS_APPLICATION", payload: newData });
                }
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setList(state?.data);
            setLoading(false);
        }, 1000);
    }, [state]);

    //Get portfolios
  //Get all settings
    const fetchAllPortfolio = async () => {
        try {
            const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
        if (response) {
            // message.success("Portfolio fetched successfully.");
        } else {
            message.error("Portfolio not found.");
        }
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    useEffect(() => {
        if (portfolioSettingState?.data?.length) {
            const portfolioValues = deepFindByName(
                portfolioSettingState?.data,
                staticSettingPortfolioList?.application,
                false
            );
          if (portfolioValues?.portfolioSections?.length) {
                setPortfolioData(
                    getSelectProperties(portfolioValues?.portfolioSections, true)
                );
          } else {
            setPortfolioData([]);
          }
        } else {
          fetchAllPortfolio();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState?.data]);

    useEffect(() => {
        fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFilter]);

    return <>
        <Modal
            open={open}
            className='cu-modal-p-5'
            onCancel={()=>{setOpen(!open);}}
            footer={false}
            width={window?.innerWidth-200}
            destroyOnClose={true}
        >
            <Spin spinning={loading}>
                <Layout>
                    <Layout.Content className='border-right p-2'>
                        <PageTitleHeading text={`Application`} className={`my-1`}/>
                        <Divider style={{ border:"0.8px solid #ddd",}}  className='my-2'/>
                        <div className='pl-2 pt-2'>
                            <ApplicationList data={list || []} className="BusinessApplication" loading={loading} fetchData={fetchData} hasMore={true} Buttons={Buttons}/>
                        </div>
                    </Layout.Content>
                    <Layout.Sider theme='light' width={showFilter?250:50} className='p-2'>
                        <FilterButtonToggle
                            showFilter={showFilter}
                            setShowFilter={setShowFilter}
                        />
                        {
                            showFilter && <div className='overflow-y pr-2' style={{height:window?.innerHeight-300}}>
                                <FilterContent
                                    lifeCycleStagesColors={lifeCycleStagesColors}
                                    activeGridStyle={1}
                                    portfolioData={portfolioData}
                                    selectedFilters={selectedFilters}
                                    setSelectedFilters={setSelectedFilters}
                                    sortValues={sortByValueApplication}
                                    optionalLevel={false}
                                />
                            </div>
                        }
                    </Layout.Sider>
                </Layout>
            </Spin>
        </Modal>
    </>
}

export const ApplicationList = ({data,moduleName,loading,fetchData,hasMore,Buttons}) =>{

    const RowData = ({ item, isLast }) => {
        return (
            <div className="portfolio-listbx">
                <table width={`100%`}>
                    <tbody>
                        <tr>
                            <td width={`95%`}>
                                <div className="my-1 portfolio-item">
                                <div>
                                    <Badge count={item.ReferenceID?.toString()} className="fw-small  badge_id" overflowCount={9999999999}/>
                                </div>
                                <div className="des-bx">
                                    <span className="fw-medium text-primary">{item?.DisplayName}</span>
                                    <small className="fs-small">{item?.Description}</small>{" "}
                                </div>
                                </div>
                            </td>
                            <td width={`5%`} className="text-nowrap">
                                {<Buttons item={item}/>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const handleOnScroll =(e) => {
        const { scrollTop, scrollHeight, offsetHeight } = e.target;
        if (offsetHeight - scrollTop < offsetHeight) {
            fetchData();
        }
    }

    return (
        <div className='overflow-y' style={{maxHeight:window?.innerHeight-200,overflowY:"auto"}} onScroll={(e)=>{handleOnScroll(e)}}>
            {
                (data?.length > 0 &&
                data?.map((item, index) => (
                    <React.Fragment key={index}>
                        <RowData  item={item} isLast={index === data.length - 1} />
                    </React.Fragment>
                ))) || <div className="text-base font-bold mt-4">No items found.</div>
            }
        </div>
    );
}

export const removeEmptyKeys = (obj) => {
    for (const key in obj) {
      if (obj[key] === "") {
        delete obj[key];
      }
    }
    return obj;
};

export const default_data_lists = [
    {value:"1",label:"Data 1"},
    {value:"2",label:"Data 2"},
    {value:"3",label:"Data 3"},
    {value:"4",label:"Data 4"},
    {value:"5",label:"Data 5"},
    {value:"6",label:"Data 6"},
    {value:"7",label:"Data 7"},
    {value:"8",label:"Data 8"},
    {value:"9",label:"Data 9"},
    {value:"10",label:"Data 10"},
    {value:"11",label:"Data 11"},
    {value:"12",label:"Data 12"},
    {value:"13",label:"Data 13"},
]

export const default_technology_lists = [
    {value:"1", label:"Technology 1"},
    {value:"2", label:"Technology 2"},
    {value:"3", label:"Technology 3"},
    {value:"4", label:"Technology 4"},
    {value:"5", label:"Technology 5"},
    {value:"6", label:"Technology 6"},
    {value:"7", label:"Technology 7"},
    {value:"8", label:"Technology 8"},
    {value:"9", label:"Technology 9"},
    {value:"10",label:"Technology 10"},
    {value:"11",label:"Technology 11"},
    {value:"12",label:"Technology 12"},
    {value:"13",label:"Technology 13"},
]

export const default_integration_lists = [
    {
        ReferenceID: "INT1104",
        DisplayName: "Goods Receive Activities App 2184-SAP Finance",
        SourceApp: {
            DisplayName: "Newcast",
        },
        TargetApp: {
            DisplayName: "SAP Finance",
        },
    },
    {
        ReferenceID: "INT1118",
        DisplayName: "Factory Complete Send and Reply",
        SourceApp: {
            DisplayName: "CIP",
        },
        TargetApp: {
            DisplayName: "PIE",
        },
    },
    {
        ReferenceID: "INT820",
        DisplayName: "Goods Receive Activities App 2184-SAP Finance",
        SourceApp: {
            DisplayName: "Chimas",
        },
        TargetApp: {
            DisplayName: "SAP Finance",
        },
    },
    {
        ReferenceID: "INT193",
        DisplayName: "OHF-C Production Plan (10 Day Fix Plan)",
        SourceApp: {
            DisplayName: "OHF",
        },
        TargetApp: {
            DisplayName: "VDW",
        },
    },
    {
        ReferenceID: "INT524",
        DisplayName: "VCC524-Follow-UP per Car, Used Torque (Bolting Data)",
        SourceApp: {
            DisplayName: "XPS",
        },
        TargetApp: {
            DisplayName: "CPSJ",
        },
    },
    {
        ReferenceID: "INT2396",
        DisplayName: "GR Trigger App 2184- SAP Finance",
        SourceApp: {
            DisplayName: "FLS",
        },
        TargetApp: {
            DisplayName: "SAP Finance",
        },
    },
]