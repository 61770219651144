
import ScenarioSvg from './../../assets/Scenario.svg';
import CreatingDiagramSvg from './../../assets/FlowDiagram.svg';
import BusinessCapabilityMappingSvg from './../../assets/BusinessCapabilityMapping.svg';
import SettingSvg from './../../assets/Setting.svg';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
const HomeQuickLink = ( {person}) => {
    // const encode = plantumlEncoder.encode(props.src);
  

    return (
    <> 
        <div className='quickGuid'>
            <h3 className='my-3'>Quick Links</h3>
            <Link to="/start-new-scenario" className='fs-6'>
                <Row className="align-items-center">
                    <Col span={4}><img src={ScenarioSvg} alt={`Create Scenario`} className="image-size mr-2 ml-1" style={{width:'50px'}}/></Col>
                    <Col span={20} className="fs-6">Create Scenario</Col>
                </Row>
            </Link>
            <Link to="/diagram-editor" className='fs-6'>
                <Row className="align-items-center">
                    <Col span={4}><img src={CreatingDiagramSvg} alt={`Create Diagram`} className="image-size mr-2" style={{width:'50px'}}/></Col>
                    <Col span={20} className="fs-6">Create Diagram</Col>
                </Row>
            </Link>
            <Link to="/business-capability-master" className='fs-6'>
                <Row className="align-items-center">
                    <Col span={4}><img src={BusinessCapabilityMappingSvg} alt={`View Business Capability`} className="image-size mr-2" style={{width:'50px'}}/></Col>
                    <Col span={20} className="fs-6">View Business Capability</Col>
                </Row>
            </Link>
            <Link to="/other-settings" className='fs-6'>
                <Row className="align-items-center">
                    <Col span={4}><img src={SettingSvg} alt={`Settings`} className="image-size mr-2" style={{width:'50px'}}/></Col>
                    <Col span={20} className="fs-6">Settings</Col>
                </Row>
            </Link>
        </div>
        </>
    );
};
export default HomeQuickLink;