import React, { useState, useRef, useEffect } from "react";
import { RightCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Tooltip, message } from "antd";
import ImportApplicationData from "./ImportApplicationData";
import ImportSvg from "./../../assets/import.svg";
import ExportSvg from "./../../assets/Export.svg";
import PdfFileSvg from "./../../assets/Pdf File.svg";
import ExcelFileSvg from "./../../assets/Excel File.svg";
import CsvFileSvg from "./../../assets/Csv File.svg";
import DataImportScenarioServices from "../../services/services/DataImportScenarioServices";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";

import { render } from 'react-dom';
import ReactToPdf from 'react-to-pdf';

//const ref = React.createRef();


const ActionsBtn = (props) => {
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);
  
  let { moduleName } = useParams();
   
  useEffect(()=>{

  },[openPDFModal])
  // const items = [
  //     {
  //         key: Math.random(),
  //         value:'import_application',
  //         label: <Button type="link" block onClick={()=>{setOpenImportModal(!openImportModal)}} className="text-dark"> Import </Button>,
  //     },
  //     {
  //         type: 'divider',
  //     },
  //     {
  //         key: Math.random(),
  //         value:'export_application',
  //         label: <Button type="link" block onClick={()=>{setShowExportSubMenu(!showExportSubMenu)}} className="text-dark"> Export </Button>,
  //         children:[
  //             {
  //                 key: 'export_to_pdf',
  //                 label: <Button type="link" className="text-dark" block> PDF </Button>,
  //             },
  //             {
  //                 key: 'export_to_xlx',
  //                 label: <Button type="link" className="text-dark" block> XLS </Button>,
  //             },
  //             {
  //                 key: 'export_to_csv',
  //                 label: <Button type="link" className="text-dark" block> CSV </Button>,
  //             },
  //         ],
  //     },
  // ]

  // Set auto-width for columns
  const autoWidth = async (ws) => {
    const colWidth = ws['!cols'] || [];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    data.forEach(row => {
      row.forEach((cell, i) => {
        const length = cell ? cell.toString().length : 10;
        colWidth[i] = { wch: Math.max(colWidth[i] ? colWidth[i].wch : 0, length) };
      });
    });
    ws['!cols'] = colWidth;
  };

  // Apply styles
  const setSheetStyles = async (ws) => {
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (!ws[cell_ref]) continue;
        if (R === 0) { // Header row
  
          ws[cell_ref].s = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "000000" } },
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } }
            }, 
            alignment: { horizontal: "center", vertical: "center" }
          };
           
        } else { // Data cells
          ws[cell_ref].s = {
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } }
            }
          };
        }
      }
    }
  };

  const exportExcelFile = async (type) => {
    try {
      let mmm = "";
      if (moduleName == "BusinessApplication") {
        mmm = "Application";
        //setModel("Application");
      } else if (moduleName == "BusinessCapability") {
        mmm = "Business Capability";
        //setModel("Business Capability");
      }

      const response = await DataImportScenarioServices.ExportAllPortfolio({
        modelName: mmm,
        tableName: moduleName,
      });

      if (type == "xlsx" || type == "csv") {
        // Define cell styles
        
        const worksheet = XLSX.utils.json_to_sheet(response?.data?.data);

        // Apply auto-width and styles to the worksheet
        await autoWidth(worksheet);
        await setSheetStyles(worksheet);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, mmm);
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

         

        XLSX.writeFile(workbook, mmm + "." + type);
      } else if (type == "pdf") {
        
        setOpenPDFModal(true)

        console.log("PDF", type);

      }
    } catch (error) {
      console.error("Error fetching Template Data:", error);
      message.error("Something went wrong.");
    }
  };

  const items2 = [
    // {
    //   key: "export_to_pdf",
    //   label: (
    //     <Button
    //       type="link"
    //       icon={<img src={PdfFileSvg} alt={`PDF`} width={16} />}
    //       className="text-dark"
    //       block
    //       onClick={() => {
    //         exportExcelFile("pdf");
    //       }}
    //     >
    //       {" "}
    //       PDF{" "}
    //     </Button>
    //   ),
    // },
    {
      key: "export_to_xlx",
      label: (
        <Button
          type="link"
          icon={<img src={ExcelFileSvg} alt={`XLS`} width={16} />}
          className="text-dark"
          block
          onClick={() => {
            exportExcelFile("xlsx");
          }}
        >
          {" "}
          XLS{" "}
        </Button>
      ),
    },
    {
      key: "export_to_csv",
      label: (
        <Button
          type="link"
          icon={<img src={CsvFileSvg} alt={`CSV`} width={16} />}
          className="text-dark"
          block
          onClick={() => {
            exportExcelFile("csv");
          }}
        >
          {" "}
          CSV{" "}
        </Button>
      ),
    },
  ];

  return (
    <>
      <Space nowrap={"true"} direction="horizontal">
        <Tooltip title={`Import`}>
          <Button
            className=""
            icon={<img src={ImportSvg} alt={`Import`} width={25} />}
            onClick={() => {
              setOpenImportModal(!openImportModal);
            }}
          />
        </Tooltip>
        <Dropdown menu={{ items: items2 }} placement="bottom">
          <Tooltip title={`Export`}>
            <Button icon={<img src={ExportSvg} alt={`Export`} width={25} />} />
          </Tooltip>
        </Dropdown>
      </Space>
      <ImportApplicationData
        open={openImportModal}
        setOpen={setOpenImportModal}
        setOpenPDF={setOpenPDFModal}
        openPDF={openPDFModal}
        {...props}
      />
    </>
  );
};

export default ActionsBtn;
