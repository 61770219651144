import HomeLayout from "../components/home/Home";

function HomePage(props) {

    return (
        <div className="vp">
        <HomeLayout props={props} />
        </div>
    );
}

export default HomePage;