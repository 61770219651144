import { Divider } from "antd";
import React from "react";

const dummyData = [
  // {
  //   name: "Marvin Mckinney",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment:
  //     "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
  // },
  // {
  //   name: "Marvin Mckinney 1",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment:
  //     "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
  // },
  // {
  //   name: "Marvin Mckinney 2",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment:
  //     "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
  // },
  // {
  //   name: "Marvin Mckinney 3",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment:
  //     "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
  // },
  // {
  //   name: "Marvin Mckinney 4",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment:
  //     "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
  // },
  // {
  //   name: "Marvin Mckinney 5",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment:
  //     "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
  // },
  // {
  //   name: "Marvin Mckinney 5",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment:
  //     "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
  // },
  // {
  //   name: "Marvin Mckinney 5",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment:
  //     "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
  // },
  // {
  //   name: "Marvin Mckinney 5",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment:
  //     "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
  // },
  // {
  //   name: "Marvin Mckinney 5",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment:
  //     "sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj sjfhkshgjgsd hjghghjdhgf ghghjgdhfghjfg hjghjghjef.jhsjfhj hjhjshjdj",
  // },
];

const CommentList = ({ setActiveTabKey }) => {
  const Row = ({ item, isFirst, isLast, key }) => {
    return (
      <div
        key={key}
        className={`flex flex-col items-start bg-[#f7f7f7] p-2  ${
          isFirst ? "mt-4" : "mt-3"
        }`}
      >
        <div className="flex items-start justify-center">
          <div className="text-sm font-bold">{item.name}</div>
          <div>
            <Divider type="vertical" className="bg-[#e3e3e3]" />
          </div>
          <div className="text-sm font-normal">{item.updatedAt}</div>
        </div>
        <p className="mt-2 overflow-auto text-wrap">{item.comment}</p>
      </div>
    );
  };
  return (
    <div className="flex flex-col">
      <div className="text-sm font-bold">Previous Comments</div>
      <div className="max-h-[800px] overflow-y-auto">
        {dummyData?.map((item, index) => (
          <Row
            key={index}
            isFirst={index === 0}
            item={item}
            isLast={index === dummyData.length - 1}
          />
        )) || <div className="text-base font-bold mt-4">No items found.</div>}
      </div>
    </div>
  );
};

export default CommentList;
