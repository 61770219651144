import "./ApplicationList.scss";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { message, Modal, Button, Upload, Select, Form } from "antd";
import * as XLSX from "xlsx";

import DataImportScenarioServices from "../../services/services/DataImportScenarioServices";
import {
  addIsTextToProperties,
  deepFindByName,
  objectToQueryParams,
} from "../../utils/Common";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import { staticSettingPortfolioList } from "../../utils/settingCommon";
 
const tableData = [
  ["App ID", "App Name", "Status", "Application Type", "TBD title"],
  ["App-126", "CF", "Approved", "Web Application", "TBD"],
  ["App-116", "", "Approved", "Client Server", "TBD"],
];

const samplejson1 = [
  {
    NameInSourceSystem: "",
    DisplayName: "",
    // Attributes: "",
    ParentID: "",
    SourceID: "",
    Level: "",
    LevelID: "",
    Description: "",
  },
];

const ImportApplicationData = (props) => {
  const [form] = Form.useForm();
  const [messageApi] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [formModalData, setFormModalData] = useState({});
  const [openPreDefinedFormModal, setOpenPreDefinedFormModal] = useState(false);
  const [previewDataModal, setPreviewDataModal] = useState(false);
  const [openOwnFormatFormModal, setOpenOwnFormatFormModal] = useState(false);
  const [errorCells, setErrorCells] = useState([[2, 1]]);
  const [openOwnFormatFormMappingModal, setOpenOwnFormatFormMappingModal] =
    useState(false);
  const [errorData, setErrorData] = useState([]);

  const [model, setModel] = useState("");

  const [formItems, setFormItems] = useState([]);
  const [portfolioData, setPortfolioData] = useState({});
  let { moduleName } = useParams();
  const [templateData, setTemplateData] = useState([]);
 
  //Get all settings
  const fetchAllPortfolio = async () => {
    try {
      const response = await PortfolioSettingServices.getList({
        page: 1,
        limit: 200,
      });
      setPortfolioData(response);
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  //Get Template
  const getExcelTemplate = async () => {
    console.log("Data....................awa model", model);
    try {
      const response = await DataImportScenarioServices.getExcelTemplate({
        moduleName: model,
      });

      setTemplateData(response);
      console.log("Data....................awa", response);
    } catch (error) {
      console.error("Error fetching Template Data:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    if (moduleName == "BusinessApplication") {
      setModel("Application");
    } else if (moduleName == "BusinessCapability") {
      setModel("BusinessCapability");
    }
  }, []);

  useEffect(() => {
    fetchAllPortfolio();
  }, []);

  useEffect(() => {
    getExcelTemplate();
  }, []);

  useEffect(() => {
    console.log("formData..... formItems Use Effect", formItems);
  }, [formItems]);

  //staticSettingPortfolioList?.businessCapability,
  useEffect(() => {
    let moduleTemp = "";
    if (moduleName == "BusinessApplication") {
      moduleTemp = "Application";
      setModel("Application");
    } else if (moduleName == "BusinessCapability") {
      moduleTemp = "Business Capability";
      setModel("Business Capability");
    }

    if (portfolioData?.data?.data?.length) {
      const portfolioValues = deepFindByName(
        portfolioData?.data?.data,
        moduleTemp,
        false
      );

      const formItem = [];
      portfolioValues?.portfolioSections.map((section, index) => {
        section?.properties?.length > 0 &&
          section?.properties?.map((property, index) => {
            formItem.push(property);
          });
      });
      setFormItems(formItem);
    }
  }, [portfolioData?.data?.data]);

  // const downloadExcel = (data, name) => {

  //   const worksheet = XLSX.utils.json_to_sheet(data);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //   //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  //   XLSX.writeFile(workbook, name + ".xlsx");
  // };

  const checkTextCase = (name) => {
    if (isPascalCase(name) || isCamelCase(name)) {
      return name; // If already in PascalCase or camelCase, return unchanged
    } else {
      return transformText(name, "camel"); // Convert to camelCase otherwise
    }
  };

  const isPascalCase = (text) => {
    return /^[A-Z][a-zA-Z0-9]*$/.test(text);
  };

  const isCamelCase = (text) => {
    return /^[a-z][a-zA-Z0-9]*$/.test(text);
  };

  const transformText = (text, caseType) => {
    switch (caseType) {
      case "snake":
        return text?.toLowerCase()?.replace(/\s+/g, "_");
      case "camel":
        return text
          ?.toLowerCase()
          ?.replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr?.toUpperCase());
      case "kebab":
        return text?.toLowerCase()?.replace(/\s+/g, "-");
      default:
        return text;
    }
  };
  const downloadExcelTemplate = async () => {
    try {
      let mmm = "";
      if (moduleName == "BusinessApplication") {
        mmm = "Application";
        setModel("Application");
      } else if (moduleName == "BusinessCapability") {
        mmm = "Business Capability";
        setModel("Business Capability");
      }

      const response = await DataImportScenarioServices.getExcelTemplate({
        moduleName: mmm,
      });

      setTemplateData(response);

      // Define cell styles
      const headerStyle = {
        font: { bold: true, color: { rgb: "FFF000" } },
        fill: { fgColor: { rgb: "0000FF" } },
        alignment: { horizontal: "center", vertical: "center" },
      };

      const cellStyle = {
        alignment: { horizontal: "center", vertical: "center" },
      };

      const worksheet = XLSX.utils.json_to_sheet([response?.data?.data]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, mmm);
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

      // Apply styles to headers
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_cell({ r: 0, c: C });
        if (!worksheet[address]) continue;
        worksheet[address].s = headerStyle;
      }

      // Apply styles to all cells
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_cell({ r: R, c: C });
          if (!worksheet[address]) continue;
          worksheet[address].s = cellStyle;
        }
      }

      XLSX.writeFile(workbook, mmm + ".xlsx");
    } catch (error) {
      console.error("Error fetching Template Data:", error);
      message.error("Something went wrong.");
    }
  };

  const uploadFile = {
    name: "file",
    multiple: false,
    accept:
      "xls,xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    beforeUpload: () => {
      return false;
    },

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onFinish = (formData) => {
    setLoading(true);
    const errData = [];
    const arrData = [];
    setFormModalData(formData);

    const file = formData?.upload_file?.file;
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      ///Compare Columns

      let missngField = "";
      const errorObj = [];
      let flag = false;
      let optionValueMissing = "";

      formItems.map((prop) => {
        if (jsonData.length) {
          const excelField = Object.keys(jsonData[0]);
          if (prop.isRequired) {
            if (excelField.includes(prop.name)) {
            } else {
              errorObj.push({
                ErrorType: "Missing Field",
                Error: prop.name.trim(),
                Row: "",
                Column: prop.name.trim(),
              });

              if (missngField.length) {
                missngField = missngField + ", " + prop.name.trim();
              } else {
                missngField = prop.name;
              }
            }
          }
        }
      });

      //if (missngField == "") {
      let portfolioValues;
      portfolioValues = deepFindByName(portfolioData?.data?.data, model, false);

      const formItem = [];
      portfolioValues?.portfolioSections.map((section, index) => {
        section?.properties?.length > 0 &&
          section?.properties?.map((property, index) => {
            formItem.push(property);
          });
      });
      setFormItems(formItem);

      //getting prop columns Name
      const objData = {};
      formItem.forEach((item) => {
        objData[item.name] = "";
      });

      const columns = Object.keys(objData);

      // transformText(portfolioProperties?.name, "camel")

      jsonData.map((jData, iRow) => {
        let sData = {};

        columns.map((prop, colIndex) => {
          //let portfColumn=transformText( prop,"camel")
          let portfColumn = prop.trim().replaceAll(" ", "");

          if (jData[prop] === undefined) {
            portfolioValues?.portfolioSections.map((section, index) => {
              section?.properties?.length > 0 &&
                section?.properties?.map((property, index) => {
                  if (checkTextCase(prop) == checkTextCase(property.name)) {
                    if (property.isRequired) {
                      //Check
                      errorObj.push({
                        ErrorType: "Required Field",
                        Error: property.name,
                        Row: "",
                        Column: property.name,
                      });
                    }

                    if (property?.type === "Single select") {
                      sData[portfColumn] = null;
                    } else if (property?.type === "Multi select") {
                      sData[portfColumn] = null;
                    } else {
                      //it will change as blank
                      console.log("Undefined property.name", property.name);

                      sData[portfColumn] = null;
                    }
                  } else {
                  }
                });
            });
          } else {
            //sData[prop] = jData[prop];
            let jsonObject = {};
            flag = false;
            //Checking Prop and Its Type And  Popuate the Property
            portfolioValues?.portfolioSections.map((section, index) => {
              section?.properties?.length > 0 &&
                section?.properties?.map((property, index) => {
                  if (checkTextCase(prop) === checkTextCase(property.name)) {
                    if (property?.type === "Single select") {
                      if (property?.isRequired) {
                        if (jData[prop] != "") {
                          property?.propertyOptions.map((propvalue) => {
                            if (propvalue?.name == jData[prop]) {
                              //oldList[dataItem] = propvalue?.name;
                              sData[portfColumn] = propvalue?.id;
                              //sData[transformText(prop, "camel")] = propvalue?.id;

                              flag = true;
                            }
                          });
                        } else {
                          errorObj.push({
                            ErrorType: "Wrong Value",
                            Error: jData[prop],
                            Row: parseInt(iRow, 10) + 1,
                            Column: prop,
                          });
                        }
                      } else {
                        if (jData[prop] != "") {
                          property?.propertyOptions.map((propvalue) => {
                            if (propvalue?.name == jData[prop]) {
                              //oldList[dataItem] = propvalue?.name;
                              sData[portfColumn] = propvalue?.id;
                              //sData[transformText(prop, "camel")] = propvalue?.id;

                              flag = true;
                            }
                          });
                        } else {
                          flag = true;
                        }
                      }

                      if (!flag) {
                        sData[portfColumn] = 0;
                        errorObj.push({
                          ErrorType: "Wrong Value",
                          Error: jData[prop],
                          Row: parseInt(iRow, 10) + 1,
                          Column: prop,
                        });
                        //if (property?.isRequired) {
                        if (optionValueMissing.length) {
                          optionValueMissing =
                            optionValueMissing +
                            "<br/> Row No: " +
                            (parseInt(iRow, 10) + 1) +
                            "  Column " +
                            prop +
                            "  " +
                            jData[prop] +
                            " option(s) are wrong ";
                        } else {
                          optionValueMissing =
                            "Row No: " +
                            (parseInt(iRow, 10) + 1) +
                            "  Column " +
                            prop +
                            "  " +
                            jData[prop] +
                            " option(s) are wrong ";
                        }
                        //}
                      }
                    } else if (property?.type === "Multi select") {
                      //let lst = data[dataItem];
                      flag = false;

                      let lst = jData[prop].split(",");
                      if (property?.isRequired) {
                        if (lst?.length) {
                          let propValue = "";

                          for (let i = 0; i < lst?.length; i++) {
                            flag = false;

                            property?.propertyOptions.map((propvalue) => {
                              if (
                                checkTextCase(propvalue?.name) ===
                                checkTextCase(lst[i])
                              ) {
                                flag = true;
                                if (propValue == "") {
                                  propValue = propvalue?.id;
                                } else {
                                  propValue = propValue + ", " + propvalue?.id;
                                }
                              }
                            });

                            ///message
                            if (!flag) {
                              //if (property?.isRequired) {
                              errorObj.push({
                                ErrorType: "Wrong Value",
                                Error: jData[prop],
                                Row: parseInt(iRow, 10) + 1,
                                Column: prop,
                              });

                              if (optionValueMissing.length) {
                                optionValueMissing =
                                  optionValueMissing +
                                  "<br/> Row No: " +
                                  (parseInt(iRow, 10) + 1) +
                                  "  Column " +
                                  prop +
                                  "  " +
                                  lst[i] +
                                  " option(s) are wrong ";
                              } else {
                                optionValueMissing =
                                  "Row No: " +
                                  (parseInt(iRow, 10) + 1) +
                                  "  Column " +
                                  prop +
                                  "  " +
                                  lst[i] +
                                  " option(s) are wrong ";
                              }
                              //}
                            }
                            ///end Message
                          }
                          const str = "{" + propValue + "}";
                          sData[portfColumn] = str.replace(/"/g, "");
                          //sData[transformText(prop, "camel")]=str.replace(/"/g, "");
                        } else {
                          sData[portfColumn] = "";

                          errorObj.push({
                            ErrorType: "Wrong Value",
                            Error: jData[prop],
                            Row: parseInt(iRow, 10) + 1,
                            Column: prop,
                          });
                          //Error
                        }
                      } else {
                        if (lst?.length) {
                          let propValue = "";

                          for (let i = 0; i < lst?.length; i++) {
                            flag = false;

                            if (checkTextCase(lst[i]) != "") {
                              property?.propertyOptions.map((propvalue) => {
                                if (
                                  checkTextCase(propvalue?.name) ==
                                  checkTextCase(lst[i])
                                ) {
                                  flag = true;
                                  if (propValue == "") {
                                    propValue = propvalue?.id;
                                  } else {
                                    propValue =
                                      propValue + ", " + propvalue?.id;
                                  }
                                }
                              });
                            } else {
                              flag = true;
                            }

                            ///message
                            if (!flag) {
                              //if (property?.isRequired) {
                              errorObj.push({
                                ErrorType: "Wrong Value",
                                Error: jData[prop],
                                Row: parseInt(iRow, 10) + 1,
                                Column: prop,
                              });

                              if (optionValueMissing.length) {
                                optionValueMissing =
                                  optionValueMissing +
                                  "<br/> Row No: " +
                                  (parseInt(iRow, 10) + 1) +
                                  "  Column " +
                                  prop +
                                  "  " +
                                  lst[i] +
                                  " option(s) are wrong ";
                              } else {
                                optionValueMissing =
                                  "Row No: " +
                                  (parseInt(iRow, 10) + 1) +
                                  "  Column " +
                                  prop +
                                  "  " +
                                  lst[i] +
                                  " option(s) are wrong ";
                              }
                              //}
                            }
                            ///end Message
                          }
                          const str = "{" + propValue + "}";
                          sData[portfColumn] = str.replace(/"/g, "");
                          //sData[transformText(prop, "camel")]=str.replace(/"/g, "");
                        } else {
                          flag = true;
                        }

                        console.log("object ss", sData);
                      }
                    } else {
                      //Text and Nemeric

                      //sData[prop] = jData[prop];
                      portfolioValues?.portfolioSections.map(
                        (section, index) => {
                          section?.properties?.length > 0 &&
                            section?.properties?.map((property, index) => {
                              if (
                                checkTextCase(prop) ===
                                checkTextCase(property.name)
                              ) {
                                if (property?.type === "Numeric") {
                                  if (jData[prop] == "") {
                                    if (property.isRequired) {
                                      //Error
                                    } else {
                                      sData[portfColumn] = "0";
                                      //sData[transformText(prop, "camel")] = "0";
                                    }
                                  } else {
                                    sData[portfColumn] = jData[prop];
                                    //sData[transformText(prop, "camel")] = jData[prop];
                                  }
                                } else {
                                  sData[portfColumn] = jData[prop];
                                  //sData[transformText(prop, "camel")] = jData[prop];
                                }
                              }
                            });
                        }
                      );
                    }
                  }
                });
            });
          }
        });
        arrData.push(sData);
      });

      console.log("arrData", arrData);
      console.log("errorObj----------->", errorObj);

      setErrorData(errorObj);

      //return;

      //if (optionValueMissing == "" && !errorObj.length)
      {
        //Send to API
        console.log("populate Grid");
        const formExcelItem = [];
        let position = 0;
        portfolioValues?.portfolioSections
          .sort((a, b) => a.position - b.position)
          .map((section, index) => {
            //const nodeSection = section.get({ plain: true });
            section?.properties?.length > 0 &&
              section?.properties
                ?.sort((a, b) => a.position - b.position)
                .map((property, index) => {
                  property.position = position;
                  formExcelItem.push(property);
                  position++;
                });
          });

        const objData = {};
        formExcelItem.forEach((item) => {
          objData[item.name] = "";
        });

        setPreviewDataModal(!previewDataModal);

        const arrExcelData = [];
        const columns = Object.keys(objData);

        console.log("JsonData", jsonData);

        jsonData.map((jData, rowIndex) => {
          let sData = {};
          columns.map((prop, colIndex) => {
            if (jData[prop] === undefined) {
              sData[prop] = "";
            } else {
              sData[prop] = jData[prop];
            }
          });
          arrExcelData.push(sData);
        });
        setUploadedData(arrExcelData);

        console.log("arrExcelData", arrExcelData);
      }
      // else {
      //   console.log("Wrong Option ", errorObj);
      // }

      //myresp = { code: 400, message: "Data uploaded", column: "", data: arrData };
      // } else {
      //   //Missing Field
      //   console.log("Columns Missing ", missngField);
      // }
    };

    reader.readAsArrayBuffer(file);
  };

  const uploadData = () => {
    if (formModalData) {
      let mmm = "";
      if (moduleName == "BusinessApplication") {
        mmm = "Application";
        setModel("Application");
      } else if (moduleName == "BusinessCapability") {
        mmm = "Business Capability";
        setModel("Business Capability");
      }

      let postData = {};
      postData.image = formModalData?.upload_file?.file;
      postData.modelname = mmm;
      postData.tableName = moduleName;

      DataImportScenarioServices.SaveImportMasters_AllPortfolio(postData)
        .then((response) => {
          if (response.data.code === 200) {
            message.success("Uploaded successfully.");
            setPreviewDataModal(!previewDataModal);

            setOpenPreDefinedFormModal(!openPreDefinedFormModal);
            form.resetFields();
          } else {
            const msg = response.data.message + "  " + response.data.column;
            //message.error(msg);

            alert(msg);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Modal
        open={props?.open}
        title={`Import ${props?.name}`}
        onCancel={() => {
          props.setOpen(false);
        }}
        okButtonProps={{ style: { backgroundColor: "#9ba894 !important" } }}
        footer={false}
        width={`400px`}
      >
        <div className="text-center mt-5">
          <Button
            type={`primary`}
            onClick={() => {
              setOpenPreDefinedFormModal(!openPreDefinedFormModal);
              props.setOpen(false);
            }}
          >
            Import Pre-Defined File Format
          </Button>
          {/* <div className='text-center my-3'>OR</div>
                <Button type={`primary`} onClick={()=>{setOpenOwnFormatFormModal(!openOwnFormatFormModal);props.setOpen(false);}}>Import Your Own Format</Button> */}
        </div>
      </Modal>

      <Modal
        open={openPreDefinedFormModal}
        title={`Import Pre-Defined File Format`}
        footer={false}
        onCancel={() => {
          setOpenPreDefinedFormModal(!openPreDefinedFormModal);
        }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="upload_file"
            rules={[{ required: true, message: "Please Upload Excel File" }]}
          >
            <Upload.Dragger {...uploadFile}>
              <p className="ant-upload-text text-center">
                Drag and Drop Your File
              </p>
              <p className=" text-center my-2">Or</p>
              <p className="btn btn-outline-primary text-center">Choose File</p>
            </Upload.Dragger>
          </Form.Item>

          <div className="text-center my-2">
            <Button
              type="primary"
              htmlType="submit"
              className="text-center"
              onClick={() => {
                // setPreviewDataModal(!previewDataModal);
                props.setOpen(false);
              }}
            >
              Preview
            </Button>
          </div>
        </Form>
        <p className="mb-0">Don't have import file format?</p>
        <Button
          type="text"
          onClick={() => {
            // downloadExcel([templateData?.data?.data], "Business Capability");
            downloadExcelTemplate();
          }}
          className="text-primary"
        >
          Click here to download sample file
        </Button>
      </Modal>

      {/* Preview Data Model  */}
      <Modal
        open={previewDataModal}
        title={`${model} Import Preview`}
        footer={false}
        onCancel={() => {
          setPreviewDataModal(!previewDataModal);
        }}
        okButtonProps={{ style: { backgroundColor: "#9ba894 !important" } }}
        width={"80%"}
      >
        <div
          className="table-responsive mt-4"
          style={{ height: 300, overflow: "scroll" }}
        >
          <table
            width={`100%`}
            className="table table-sm cu-table-bordered excel-list"
            cellSpacing={1}
          >
            {uploadedData?.map((trData, trIndex) => {
              return (
                <tr key={Math.random()}>
                  {Object.keys(trData)?.map((tdData, tdIndex) => {
                    {
                      if (trIndex === 0) {
                        return <th key={Math.random()}>{tdData}</th>;
                      }
                    }
                  })}
                </tr>
              );
            })}

            {uploadedData?.map((trData, trIndex) => {
              console.log("object errorData", errorData);

              return (
                <tr key={Math.random()}>
                  {Object.keys(trData)?.map((tdData, tdIndex) => {
                    if (
                      // errorCells.find(
                      //   (f) => f[0] === trIndex && f[1] === tdIndex)
                      (!trData[tdData] &&
                        errorData.find(function (error) {
                          return (
                            error.Column == tdData &&
                            error.ErrorType == "Missing Field"
                          );
                        })) ||
                      (trData[tdData] &&
                        errorData.find(function (error) {
                          return (
                            error.Column == tdData &&
                            error.ErrorType == "Wrong Value" &&
                            trData[tdData] == error.Error
                          );
                        }))
                    ) {
                      return (
                        <td
                          key={Math.random()}
                          className="error-cell"
                          bordercolor={`red`}
                        >
                          {trData[tdData]}
                        </td>
                      );
                    } else {
                      if (trIndex === 0) {
                        return <td key={Math.random()}>{trData[tdData]}</td>;
                      } else {
                        return <td key={Math.random()}>{trData[tdData]}</td>;
                      }
                    }
                  })}
                </tr>
              );
            })}
          </table>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            {errorData.length > 0 && (
              <>
                <h5>
                  <span className="text-danger">
                    Error: {errorData.length} error(s) found
                  </span>
                </h5>
                <span className="text-danger">
                  Clear the errors in the Excel file and upload again.
                </span>
              </>
            )}
            <div
              className="table-responsive mt-4"
              style={{ height: 100, overflow: "auto" }}
            >
              <table
                width={`100%`}
                className="table table-sm cu-table-bordered excel-list"
                cellSpacing={1}
              >
                {errorData?.map((trData, trIndex) => {
                  return (
                    <tr key={Math.random()}>
                      {Object.keys(trData)?.map((tdData, tdIndex) => {
                        {
                          if (trIndex === 0) {
                            return <th key={Math.random()}>{tdData}</th>;
                          }
                        }
                      })}
                    </tr>
                  );
                })}

                {errorData?.map((trData, trIndex) => {
                  return (
                    <tr key={Math.random()}>
                      {Object.keys(trData)?.map((tdData, tdIndex) => {
                        // if (

                        //   (!trData[tdData] &&
                        //     errorData.find(function (error) {
                        //       return (
                        //         error.Error == tdData &&
                        //         error.ErrorType == "MissingField"
                        //       );
                        //     })) ||
                        //   (trData[tdData] &&
                        //     errorData.find(function (error) {
                        //       return (
                        //         error.column == tdData &&
                        //         error.ErrorType == "Wrong Value" &&
                        //         trData[tdData] == error.Error
                        //       );
                        //     }))
                        // )
                        // {
                        //   return (
                        //     <td
                        //       key={Math.random()}
                        //       className="error-cell"
                        //       bordercolor={`red`}
                        //     >
                        //       {trData[tdData]}
                        //     </td>
                        //   );
                        // } else
                        {
                          if (trIndex === 0) {
                            return (
                              <td key={Math.random()}>{trData[tdData]}</td>
                            );
                          } else {
                            return (
                              <td key={Math.random()}>{trData[tdData]}</td>
                            );
                          }
                        }
                      })}
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          <div style={{ marginTop: "10px;" }}>
            <Button onClick={uploadData}>Finish Import</Button>
          </div>
        </div>
      </Modal>

      {/* Own File Format */}
      <Modal
        open={openOwnFormatFormModal}
        title={`Import Your Own Format`}
        footer={false}
        onCancel={() => {
          setOpenOwnFormatFormModal(!openOwnFormatFormModal);
        }}
      >
        <Upload.Dragger {...props}>
          <p className="ant-upload-text text-center">Drag and Drop Your File</p>
          <p className=" text-center my-2">Or</p>
          <p className="btn btn-outline-primary text-center">Choose File</p>
        </Upload.Dragger>
        <div className="text-center my-2">
          <Button
            type="primary"
            className="text-center px-5"
            onClick={() => {
              setOpenOwnFormatFormMappingModal(!openOwnFormatFormMappingModal);
              setOpenOwnFormatFormModal(!openOwnFormatFormModal);
            }}
          >
            Next
          </Button>
        </div>
      </Modal>

      <Modal
        open={openOwnFormatFormMappingModal}
        title={`Import Your own File Format`}
        footer={false}
        onCancel={() => {
          setOpenOwnFormatFormMappingModal(!openOwnFormatFormMappingModal);
        }}
        okButtonProps={{ style: { backgroundColor: "#9ba894 !important" } }}
      >
        <div>
          <Select
            options={[{ value: "sample", label: <span>sample</span> }]}
            defaultValue={`Sample`}
            placeholder={`Select`}
            style={{ width: "100%" }}
          ></Select>
        </div>
        <div className="my-3 text-center">OR</div>
        <div className={`bg-efefef p-2`}>
          <table width={`100%`}>
            <tr>
              <th width={`50%`}>File Column</th>
              <th className="text-right">Mapped Column</th>
            </tr>
            <tr>
              <td>Col A-Name</td>
              <td>
                <Select
                  options={[
                    {
                      value: "Application Name",
                      label: <span>Application Name</span>,
                    },
                  ]}
                  defaultValue={`Application Name`}
                  placeholder={`Select`}
                  style={{ width: "100%" }}
                ></Select>
              </td>
            </tr>
            <tr>
              <td>Col B-Comment</td>
              <td>
                <Select
                  options={[{ value: "Ignore", label: <span>Ignore</span> }]}
                  defaultValue={`Ignore`}
                  placeholder={`Select`}
                  style={{ width: "100%" }}
                ></Select>
              </td>
            </tr>
            <tr>
              <td>Col C-Id</td>
              <td>
                <Select
                  options={[{ value: "App Id", label: <span>App Id</span> }]}
                  defaultValue={`App Id`}
                  placeholder={`Select`}
                  style={{ width: "100%" }}
                ></Select>
              </td>
            </tr>
            <tr>
              <td>Col D-Description</td>
              <td>
                <Select
                  options={[
                    { value: "Description", label: <span>Description</span> },
                  ]}
                  defaultValue={`Description`}
                  placeholder={`Select`}
                  style={{ width: "100%" }}
                ></Select>
              </td>
            </tr>
          </table>
        </div>
        <Button
          type={`primary`}
          className="w-100 mt-2"
          onClick={() => {
            setOpenOwnFormatFormMappingModal(!openOwnFormatFormMappingModal);
            setPreviewDataModal(!previewDataModal);
          }}
        >
          Proceed
        </Button>
      </Modal>

       
    </>
  );
};

export default ImportApplicationData;
