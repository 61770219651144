import React,{useState,useEffect} from 'react';
import {Typography,Button,Card,Modal,Form,Input,Row,Col,message} from "antd";
import { PlusOutlined,EditTwoTone,DeleteTwoTone,ExclamationCircleFilled,SortDescendingOutlined,SortAscendingOutlined} from '@ant-design/icons';
import  LinkTypeService from '../../services/services/LinkTypeService'
const { confirm } = Modal;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const LinkTypesCard = (props) => {
    const [sortLinkTypes,setSortLinkTypes] = useState("ASC");
    const [searchText,setSearchText] = useState("");
    const [formLinkType] = Form.useForm();
    const [linkTypesOpen,setLinkTypesOpen] = useState(false);
    const [list, setList] = useState([]);
    const [formModalData, setFormModalData] = useState({
        id: "",
        linkTypeName: "",
        description: "" 
      });
      
    const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    getLinkTypeList();
  }, []);

  const AddMode = () => {
    setLinkTypesOpen(!linkTypesOpen);
    setIsEditing(false);
    formLinkType.setFieldsValue({
      id: undefined,
      linkTypeName: "",
      description: "",
    });
  };

  const CreateLinkType = () => {
    formLinkType
      .validateFields()
      .then(async (values) => {
        console.log("Values", values);

        try {
          console.log("ID", values?.id);

          if (!values?.id) {
            let obj = {
              linkTypeName: values?.linkTypeName,
              description: values?.description,
              userID: 1,
            };

            let result = await LinkTypeService.create_LinkType(obj);
            if (result) {
              formLinkType.resetFields();

              //Get all Active List
              await getLinkTypeList();

              message.success(result?.data?.msg);
            } else {
              message.error(result?.data?.msg);
            }
            setLinkTypesOpen(false);
          } else {
            let obj = {
              id: values?.id,
              linkTypeName: values?.linkTypeName,
              description: values?.description,
            };

            console.log("Edit obj", obj);
            let result = await LinkTypeService.update_LinkType(obj);
            if (result) {
              formLinkType.resetFields();

              //Get all Active List
              await getLinkTypeList();

              message.success(result?.data?.msg);
            } else {
              message.error(result?.data?.msg);
            }
            setLinkTypesOpen(false);
          }
        } catch (error) {
          message.error(error?.message);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const getLinkTypeList = async () => {
    const list = await LinkTypeService.getList();
    setList(list?.data?.data);
    console.log("List", list);
  };

  const EditLinkType = (item) => {
      setLinkTypesOpen(true); 
      setFormModalData(item);
      setIsEditing(true);
      formLinkType.setFieldsValue({
          id: item?.id,
          linkTypeName: item?.linkTypeName,
          description: item?.description 
      });
  };


  const showConfirm = (item) => {
    confirm({
        title: `Are you sure you want to delete "${item.linkTypeName}" link type?`,
        icon: <ExclamationCircleFilled />,
        async onOk() {
            try {
                
                console.log("object Item", item);
                let result = await LinkTypeService.delete_LinkType(item.id);
                if (result) {
                    getLinkTypeList();
                } else {
                  message.error(result?.data?.msg)
                }
                // setLinkTypesOpen(false)
            } catch (error) {
                message.error(error?.message)
            }
        },
        description: item?.description,
        onCancel() {
            console.log('Cancel');
        },
    });
  };

    return (
        <>
            <Typography.Title level={5} className="text-start mt-2 step-header header-size" >
                {`Link Types`}
                <Button type="primary" className='pull-right ml-auto' icon={<PlusOutlined />} 
                    onClick={()=>AddMode()}>Add New</Button>
            </Typography.Title>
            <Card
                title={(
                    <>
                        {sortLinkTypes==="ASC"?<SortAscendingOutlined className="cursor-pointer" onClick={()=>{setSortLinkTypes("DESC")}}/>:<SortDescendingOutlined className="cursor-pointer" onClick={()=>{setSortLinkTypes("ASC")}}/>} 
                    </>
                )}
                className={`application-tab-card cu-border my-2`}
                bodyStyle={{padding:5}}
                extra={(
                    <div className='d-flex justify-content-end'>
                        <Input.Search style={{width:'150px',marginLeft:'20px'}} placeholder="Search" onChange={(e)=>setSearchText(e?.target?.value || "")}></Input.Search>
                    </div>
                )}
            >
                <div className={`overflow-y p-2`} style={{height:window.innerHeight/2}}>
                   
                {
                    (list?.length > 0 && 
                    list?.sort((a, b) => {
                        if(!b?.linkTypeName){
                            b.linkTypeName = "";
                        }
                        if(!a?.linkTypeName){
                            a.linkTypeName = "";
                        }
                        if(sortLinkTypes==="ASC"){
                            return a?.linkTypeName.localeCompare(b?.linkTypeName, undefined, {numeric: true, sensitivity: 'base'});
                        }else{
                            return b?.linkTypeName.localeCompare(a?.linkTypeName, undefined, {numeric: true, sensitivity: 'base'});
                        }
                    }).filter(f=>f?.linkTypeName.indexOf(searchText)!==-1)?.map((item, index) => ( 

                            <div className='card card-body p-2 mb-2'>
                            <div className='d-flex justify-content-between'>
                                <div>{item.linkTypeName}</div>
                                <div className='d-flex justify-content-end'>
                                    {/* <EditTwoTone className={`cursor-thumb`} onClick={()=>{setLinkTypesOpen(!linkTypesOpen)}}/>&emsp; */}

                                    <EditTwoTone className={`cursor-thumb`} onClick={()=>{EditLinkType(item)}}/>&emsp;
                                    
                                    <DeleteTwoTone className={`cursor-thumb`} onClick={()=>{showConfirm(item)}}/>
                                </div>
                            </div>
                            </div> 
                    ))) || <div className="text-base font-bold mt-4">No items found.</div>
                } 

                </div>
            </Card>
            <Modal
                closable
                title={isEditing?'Edit':'Add'}
                destroyOnClose
                open={linkTypesOpen}
                onCancel={()=>{setLinkTypesOpen(!linkTypesOpen)}}
                footer={false}
                requiredMark={false}
            > 
                <Form 
                    form={formLinkType} 
                    onFinish={CreateLinkType} 
                    autoComplete="off"
                    footer={false}
                    requiredMark={false} 
                >
                    <div className='card card-body'>
                        <Form.Item
                                {...formItemLayout}
                                className='my-3'
                                label="ID"
                                name="id" 
                            style={{display:'none'}}
                            >
                                <Input/>
                        </Form.Item>

            <Form.Item
              {...formItemLayout}
              className="my-3"
              label="Name"
              name="linkTypeName"
              rules={[{ required: true, message: "Please enter name!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              className="my-3"
              label="Description"
              name="description"
              rules={[{ required: true, message: "Please enter description!" }]}
            >
              <Input.TextArea rows={2} />
            </Form.Item>
          </div>
          <Row className="mt-3">
            <Col span={24}>
              <Button type={`primary`} className={`w-100`} htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default LinkTypesCard;
