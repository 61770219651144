import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Button,
  Typography,
  Row,
  Col,
  Divider,
} from "antd";
import CustomNumberInput from "./CustomNumberInput";
import CustomLevelComponent from "./CustomLevelComponent";
import {
  changeFirstLetter,
  isCamelCase,
  isPascalCase,
  pascalToNormal,
  transformLevelString,
  transformText,
} from "../../utils/Common";
import { normalizeString } from "../../utils/linksCommon";

const { Option } = Select;

const DynamicForm = ({
  data,
  form,
  formData,
  labelCol,
  wrapperCol,
  createAndUpdate,
  level = null,
  levelID = null,
  parentLevel = null,
   
}) => {
  const onFinish = (values) => {
    createAndUpdate(values);
  };

  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(true);

  const formatPropertyName = (name) => {
    // Convert name to lower case and remove leading/trailing spaces
    let formattedName = name.toLowerCase().trim();
    // Replace hyphens and underscores with spaces
    formattedName = formattedName.replace(/[_-]/g, " ");
    // Convert camelCase to Camel Case
    formattedName = formattedName.replace(/([a-z])([A-Z])/g, "$1 $2");
    // Capitalize the first letter of each word
    formattedName = formattedName.replace(/\b\w/g, (c) => c.toUpperCase());
    return formattedName;
  };

  //   const LevelDisplay = ({ index, data, property }) => {
  //     return (
  //       (
  //         <div>
  //           {(level || levelID || parentLevel) && property.name === "Level" ? (
  //             <Form.Item name={property.name}>
  //               <div className="mb-1 h7"> {level} </div>
  //               <div className="mb-1 h7">
  //                 {levelID ? `(Parent : ${levelID} ${parentLevel} )` : ""}
  //               </div>
  //             </Form.Item>
  //           ) : (
  //             <Form.Item name={property.name}>
  //               <Typography.Text>{property.name}</Typography.Text>
  //             </Form.Item>
  //           )}
  //         </div>
  //       ) || <></>
  //     );
  //   };

  const LevelDisplay = ({ index, data, property }) => {
    return (
      (
        <div>
          {(level || levelID || parentLevel) && property.name === "Level" ? (
            <div>
              <div className="mb-1 h7"> {level} </div>
              <div className="mb-1 h7">
                {levelID ? `(Parent : ${levelID} ${parentLevel} )` : ""}
              </div>
            </div>
          ) : (
            <Typography.Text>{property.name}</Typography.Text>
          )}
        </div>
      ) || <></>
    );
  };

  useEffect(()=>{
  
    console.log("object--", level ); 
    //form.setField(level);
    form?.setFieldsValue({ ...formData, Level: transformLevelString(level) });
  },[level ])

  

  const checkTextCase = (name) => {
    if (isPascalCase(name) || isCamelCase(name)) {
      return name; // If already in PascalCase or camelCase, return unchanged
    } else {
      return transformText(name, "camel"); // Convert to camelCase otherwise
    }
  };

  const checkLabelText = (name) => {
    if (isPascalCase(name)) {
      return pascalToNormal(name);
    } else {
      return formatPropertyName(name);
    }
  };

  useEffect(() => {
    if (Object.keys(formData)?.length) {
      console.log("formData",formData,data)
      form?.setFieldsValue({ ...formData });
    }
  }, [formData]);
  
  const validatePattern = (value) => {
    const pattern = /^(\d{1,2}\.\d{1,2}\.\d{1,2})$/;
    return pattern.test(value);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
    setIsValid(validatePattern(value));
  };


  return (
    <>
      <div className=" position-relative p-4">
        <Form
          //   name="dynamic_form"
          onFinish={onFinish}
          form={form}
          labelCol={{ span: labelCol }}
          wrapperCol={{ span: wrapperCol }}
          className="mt-1"
          requiredMark={false}
          initialValues={formData}
        >
          {
          data
          .sort((a,b) => a.position-b.position)
          // .sort((a, b) => normalizeString(a.name).localeCompare(normalizeString(b.name)))
          .map((section, index) => {
            return (
              section?.properties?.length > 0 && (
                <React.Fragment key={Math.random()}>
                  <Typography.Title level={5} className="mb-2">
                    {normalizeString(section?.name)}
                  </Typography.Title>
                  {section?.properties
                    ?.sort((a,b) => a.position-b.position)
                    // ?.sort((a, b) =>
                    //   formatPropertyName(a.name).localeCompare(
                    //     formatPropertyName(b.name)
                    //   )
                    // )
                    // ?.filter((item) => item?.isRequired)
                    ?.map((property, index) => (
                      <React.Fragment key={Math.random()}>
                        <Row
                          gutter={[16, 24]}
                          className="mb-2"
                          key={Math.random()}
                        >
                          <Col
                            className="gutter-row"
                            span={8}
                            key={`col-label-${property?.id || index}`}
                          >
                          
                            {/* <span>{property?.name}</span> */}
                            {/* Updated by Awadhesh */}
                            <span>{property?.displayName}</span>
                            {
                              property?.isRequired && (
                                <span className="text-danger">*</span>
                              )
                            }
                          </Col>
                          <Col
                            className="gutter-row"
                            span={16}
                            key={property?.id || index}
                          >
                            <Form.Item
                              key={property?.id}
                              name={checkTextCase(property?.name)}
                              // label={checkLabelText(property?.name)}
                              rules={[
                                {
                                  required: property?.isRequired,
                                  message: `${formatPropertyName(
                                    property?.name
                                  )} is required!`,
                                },
                              ]}
                              wrapperCol={{ span: wrapperCol }}
                              className="mb-2 text-start"
                            >
                              {property?.isText ? (
                                <LevelDisplay
                                  index={index}
                                  data={data}
                                  property={property}
                                />
                              ) : property?.type === "Single select" ||
                                property?.type === "Multi select" ? (
                                <Select
                                  mode={
                                    property?.type === "Single select"
                                      ? "default"
                                      : "multiple"
                                  }
                                >
                                  {property.propertyOptions.map((option) => (
                                    <Option key={Math.random()} value={option?.id}>
                                      {option?.name}
                                    </Option>
                                  ))}
                                </Select>
                              ) : property?.type === "Date time" ? (
                                <DatePicker
                                  showTime
                                  className="mb-2 text-start w-full"
                                />
                              ) : property?.type === "Date" ? (
                                <DatePicker className="mb-2 text-start w-full" />
                              ) : property?.type === "Time" ? (
                                <TimePicker className="mb-2 text-start w-full" />
                              ) : property?.type === "Long text" ? (
                                <Input.TextArea type="textarea" />
                              ) : property?.type === "Numeric" ? (
                                <CustomNumberInput />
                              ) : property?.type === "Free text" && property?.name == 'alfabeturl' ? (
                                <a href={formData?.alfabeturl} target="_blank">{formData?.alfabeturl}</a>
                              ):(property?.type === "Free text" && property?.name == 'servicenow' ? <a href={`https://volvocars.service-now.com/cmdb_ci_service_discovered.do?sys_id=${formData?.servicenow}&sysparm_view=Default+view&sysparm_view_forced=true`} target="_blank">https://volvocars.service-now.com/cmdb_ci_service_discovered.do?sys_id=${formData?.servicenow}&sysparm_view=Default+view&sysparm_view_forced=true</a>
                              : property?.type === "Free text" && property?.name == 'LevelID' ? <CustomLevelComponent name={checkTextCase(property?.name)}/> : <Input/>)}
                            </Form.Item>
                          </Col>
                        </Row>
                      </React.Fragment>
                    ))}

                  {/* Old code */}
                  {/* <Row
                    gutter={[16, 24]}
                    className="mb-2"
                    key={section?.id || index}
                  >
                    <Col
                      className="gutter-row"
                      span={16}
                      key={section?.id || index}
                    >
                      {section?.properties
                        ?.sort((a, b) =>
                          formatPropertyName(a.name).localeCompare(
                            formatPropertyName(b.name)
                          )
                        )
                        ?.filter((item) => item?.isRequired)
                        ?.map((property, index) => (
                          <div>
                            <div className=" position-relative">
                              <Form.Item
                                key={property?.id}
                                name={checkTextCase(property?.name)}
                                label={checkLabelText(property?.name)}
                                rules={[
                                  {
                                    required: !property?.isText,
                                    message: `${formatPropertyName(
                                      property?.name
                                    )} is required!`,
                                  },
                                ]}
                                wrapperCol={{ span: wrapperCol }}
                                className="mb-2 text-start"
                              >
                                {property?.isText ? (
                                  <LevelDisplay
                                    index={index}
                                    data={data}
                                    property={property}
                                  />
                                ) : property?.type === "Single select" ||
                                  property?.type === "Multi select" ? (
                                  <Select
                                    mode={
                                      property?.type === "Single select"
                                        ? "default"
                                        : "multiple"
                                    }
                                  >
                                    {property.propertyOptions.map((option) => (
                                      <Option
                                        key={option?.id}
                                        value={option?.id}
                                      >
                                        {option?.name}
                                      </Option>
                                    ))}
                                  </Select>
                                ) : property?.type === "Date" ? (
                                  <DatePicker className="mb-2 text-start w-full" />
                                ) : property?.type === "Time" ? (
                                  <TimePicker className="mb-2 text-start w-full" />
                                ) : property?.type === "Long text" ? (
                                  <Input.TextArea type="textarea" />
                                ) : property?.type === "Numeric" ? (
                                  <CustomNumberInput />
                                ) : (
                                  <Input />
                                )}
                              </Form.Item>
                            </div>
                          </div>
                        ))}
                    </Col>
                  </Row> */}

                  <Divider />
                </React.Fragment>
              )
            );
          })}
          <div className="text-start mt-5">
            <Button
              className="me-2"
              size="middle"
              type="primary"
              htmlType="submit"
              //   onClick={() => {
              //     createAndUpdate();
              //   }}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default DynamicForm;
