import AdminLayout from "../layouts/AdminLayout";
import { Link } from "react-router-dom";
import $ from "jquery";
import JSZip from "jszip";
import pdfmake from "pdfmake";
import DataTable from "datatables.net-dt";
import Editor from "@datatables.net/editor-dt";
import "datatables.net-buttons-dt";
import "datatables.net-buttons/js/buttons.colVis.mjs";
import "datatables.net-buttons/js/buttons.html5.mjs";
import "datatables.net-colreorder-dt";
import "datatables.net-fixedheader-dt";
import "datatables.net-responsive-dt";
import "datatables.net-searchpanes-dt";
import "datatables.net-select-dt";
import "datatables.net-bs5";
// import "datatables.net-buttons-bs5";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { PlusCircleOutlined, FormOutlined, CloseOutlined, CheckOutlined, MergeFilled } from "@ant-design/icons";
import { Children, useEffect, useRef, useState } from "react";
import ScenarioServices from "../../services/services/ScenarioServices";
import { getScenarioType, getScenarioSubType, getInitiatives } from "../../utils/ScenarioCommon";
import { Menu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import {
  Spin,
  Select,
  Row,
  Col,
  Typography,
  Card,
  Input,
  Button,
  Modal,
  Form,
  message,
  Collapse,
} from "antd";
import "./Scenario.scss";
import { useParams } from "react-router-dom";
import UserService from "../../services/UserService";
import { useNavigate, useLocation } from 'react-router-dom';
import plantumlEncoder from 'plantuml-encoder';

window.JSZip = JSZip;
window.pdfmake = pdfmake;

const { Title } = Typography;
const { TextArea } = Input;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

// const DataTable = DataTables(window, $);
const EditScenario = (props) => {
  const [loading, setLoading] = useState(false);
  const [windowDimensions] = useState(getWindowDimensions());
  const [scenarioData, setScenarioData] = useState([]);
  const [scenarioColumns, setScenarioColumns] = useState([]);
  const [scenarioColumnsResponse, setScenarioColumnsResponse] = useState([]);
  const [scenarioColumnsHeaders, setScenarioColumnsHeaders] = useState([]);
  const [selectSubmitData, setSelectSubmitData] = useState({});
  let navigate = useNavigate();
  let location = useLocation();
  const [scenarioTitle, setScenarioTitle] = useState("");
  const [scenarioType, setScenarioType] = useState([]);
  const [scenarioSubType, setScenarioSubType] = useState([]);
  const [initiatives, setInitiatives] = useState([]);
  const [openNewColModal, setOpenNewColModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [disabledFormFields, setdisabledFormFields] = useState(true);
  const [newColAdded, setNewColAdded] = useState(false);
  const [openInitiativeModal, setOpenInitiativeModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [plantUMLDropdowns, setPlantUMLDropdowns] = useState({})

  const [commentValue, setCommentValue] = useState('');

  const [defaultSelectValue, setDefaultSelectValue] = useState([]);

  const [editScenarioShowButtons, setEditScenarioShowButtons] = useState({
    edit: true,
    save: false,
    cancel: false,
  });
  const [scenarioDataTable, setScenarioDataTable] = useState();
  const [editScenarioValues, setEditScenarioValues] = useState({
    name: '',
    scenarioTypeID: '',
    scenarioSubtypeID: '',
    objective: '',
    intiativeId: '',
    visibility: ''
  });
  const [modalSelectOpen, setModalSelectOpen] = useState(false);
  const [modalTextareaOpen, setModalTextareaOpen] = useState(false);
  const [modalCommentOpen, setModalCommentOpen] = useState(false);

  const MENU_ID = 'react-bla-bla';
  const [newColForm] = Form.useForm();
  const [editScenarioForm] = Form.useForm();
  const [initiativeForm] = Form.useForm();
  const { Option } = Select;
  const tableRef = useRef();
  const API_BASE_URL = ScenarioServices.API_BASE_URL;
  var scenarioTable;
  var scenarioEditor;
  var scenarioDT_action = '';
  props = { ...props, ...useParams() };

  const { show, hideAll } = useContextMenu({
    id: MENU_ID,
  });


  useEffect(() => {
    getScenarioType(loading, setLoading, setScenarioType)
    getScenarioSubType(loading, setLoading, setScenarioSubType);
    getInitiatives(loading, setLoading, setInitiatives);
    loadData()
  }, []);

  const loadData = async () => {
    await getScenario();
    await getScenarioColumns('');
    await getScenarioLandscape();
  }

  useEffect(() => {
    loadTale()
    setLoading(false);
  }, [scenarioData]);

  useEffect(()=>{
    getPlanUmlDropDown();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[scenarioColumns,scenarioData])

  const editScenarioFormlayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 23,
    },
  };

  const replacePlaceholder = () => {
    $('.dtsp-search').each(function (key, val) {
      let newPlaceholder = $(this).attr('placeholder').replace(/<\/?[^>]+(>|$)/g, "");
      $(this).attr('placeholder', newPlaceholder)
    })
  }

  const getColumnIndexByKey = (columnHeaders, key) => {
    for (let i = 0; i < columnHeaders.length; i++) {
      if (columnHeaders[i].data === key) {
        return i;
      }
    }
    return -1; // Column not found
  }

  const html5Buttons = () => {
    return UserService.hasRole(['Admin']) ? [
      { extend: "create", text: "Add", editor: scenarioEditor },
      { extend: "editSingle", editor: scenarioEditor },
      { extend: "remove", editor: scenarioEditor },
      {
        extend: "selected",
        text: "Duplicate Row",
        action: function (e, dt, node, config) {
          // Start in edit mode, and then change to create
          scenarioEditor
            .edit(scenarioTable.rows({ selected: true }).indexes(), {
              title: "Duplicate Row",
              buttons: "Create from Existing",
            })
            .mode("create");
        },
      },
      "colvis",
      "pageLength",
      {
        text: "Add Column",
        action: function (e, dt, node, config) {
          newColForm.resetFields();
          setConfirmLoading(false);
          setOpenNewColModal(true);
        },
      },
      {
        extend: "excel",
        text: '<i class="fa fa-download"></i> Excel',
        title: "Landscape Data",
      }
      // ,{
      //   extend: "pdfHtml5",
      //   orientation: "landscape",
      //   pageSize: "A1",
      //   text: '<i class="fa fa-print"></i> PDF',
      //   title: "Landscape Data in PDF",
      // }
    ] : (UserService.hasRole(['Editor']) ? [
      { extend: "create", text: "Add", editor: scenarioEditor },
      { extend: "editSingle", editor: scenarioEditor },
      { extend: "remove", editor: scenarioEditor },
      {
        extend: "selected",
        text: "Duplicate Row",
        action: function (e, dt, node, config) {
          // Start in edit mode, and then change to create
          scenarioEditor
            .edit(scenarioTable.rows({ selected: true }).indexes(), {
              title: "Duplicate Row",
              buttons: "Create from Existing",
            })
            .mode("create");
        },
      },
      "colvis",
      "pageLength",
      {
        text: "Add Column",
        action: function (e, dt, node, config) {
          newColForm.resetFields();
          setConfirmLoading(false);
          setOpenNewColModal(true);
        },
      },
      {
        extend: "excel",
        text: '<i class="fa fa-download"></i> Excel',
        title: "Landscape Data",
      }
    ] : [
      "colvis",
      "pageLength",
      {
        extend: "excel",
        text: '<i class="fa fa-download"></i> Excel',
        title: "Landscape Data",
      }
    ])
  };

  const loadTale = async () => {
    await createTable()
  }

  const createTable = async () => {
    const colResize_options = {
      isEnabled: true,
      hoverClass: "dt-colresizable-hover",
      hasBoundCheck: true,
      minBoundClass: "dt-colresizable-bound-min",
      maxBoundClass: "dt-colresizable-bound-max",
      isResizable: function (column) {
        return true;
      },
      onResize: function (column) { },
      onResizeEnd: function (column, columns) { },
      getMinWidthOf: function ($thNode) { },
    };

    const colReorder_options = {
      realtime: false,
    };
    scenarioEditor = new $.fn.dataTable.Editor({
      ajax: {
        url: API_BASE_URL + "scenario/postScenarioLandscape?scenarioId=" + props?.id,
        type: "POST",
        headers: {
          "Authorization": `Bearer ${UserService.getToken()}`,
          "X-XSS-Protection": `1; mode=block`,
          "X-Content-Type-Options": "nosniff",
          "Referrer-Policy": "same-origin",
          "X-Content-Security-Policy": "default-src 'self'; script-src 'self';",
        }
      },
      table: '#int-app-table',
      idSrc: 'id',
      fields: scenarioColumns,
      formOptions: {
        inline: {
          onBlur: "submit",
        },
      },
    });
    scenarioEditor.on('open', function (e, type, action) {
      const selectedRow = scenarioTable.rows('.selected').data();
      const elements = $(".DTE_Field_Type_select")
      for (const item of elements) {
        const selectElement = $(item).find('select');
        if (action !== 'create') {
          if (selectElement) {
            let value = "";
            if (selectedRow.length > 0) {
              const record = selectedRow[0];
              const columnId = $(selectElement).attr('id')
              if (columnId) {
                const cols = columnId.split("DTE_Field_")
                value = record[cols[1]];
                localStorage.setItem(cols[1], value);
              }

            }
            let trimmedValues = value.split(',').map(item => item.trim());
            $(selectElement).select2({
              multiple: true,
              width: '100%',
            }).on('change', function () {
              const columnId = $(this).attr('id');
              if (columnId) {
                const cols = columnId.split("DTE_Field_")
                let selectedValue = $(this).val();
                const commaSeparated = selectedValue.join(', ');
                localStorage.setItem(cols[1], commaSeparated)
              }
            });

            if (value) {
              let trimmedValues = value.split(',').map(item => item.trim());
              $(selectElement).val(trimmedValues).trigger('change');
            }
          }
        } else {
          $(selectElement).select2({
            multiple: true,
            width: '100%',
          }).on('change', function () {
            const columnId = $(this).attr('id');
            if (columnId) {
              const cols = columnId.split("DTE_Field_")
              let selectedValue = $(this).val();
              const commaSeparated = selectedValue.join(', ');
              localStorage.setItem(cols[1], commaSeparated)
            }
          });
          $(selectElement).val([]).trigger('change');
        }

      }
    })
    scenarioEditor.on('submitSuccess', function (e, o, action) {
      if (scenarioDT_action === 'edit' && typeof action !== 'undefined') {
        message.success("Row Updated Successfully!")
        replacePlaceholder();
      } else if (scenarioDT_action === 'create' && typeof action !== 'undefined') {
        message.success("Row Created Successfully!")
        replacePlaceholder();
      } else if (scenarioDT_action === 'remove' && typeof action === 'undefined') {
        message.success("Row Deleted Successfully!")
        replacePlaceholder();
      }
    });

    //form submit validation
    scenarioEditor.on('preSubmit', function (e, o, action) {
      scenarioDT_action = action
      if (action === 'create') {
        let formData = o.data[Object.keys(o.data)[0]];
        let allFieldsBlank = true;
        for (var key in formData) {
          if (localStorage.getItem(key)) {
            formData[key] = localStorage.getItem(key);
            scenarioEditor.set(key, localStorage.getItem(key));
          }
          if (formData[key]) {
            formData[key] = formData[key].trim();
          }
          if (formData[key] && formData[key] !== '-' && formData[key].trim().length != 0) {
            allFieldsBlank = false;
          }
        }
        // If all fields are empty 
        if (allFieldsBlank) {
          message.error("Please insert at least one field to submit the form!")
          return false;
        }
      } else if (action == 'edit') {
        let formData = o.data[Object.keys(o.data)[0]];
        for (var key in formData) {
          if (localStorage.getItem(key)) {
            formData[key] = localStorage.getItem(key);
            scenarioEditor.set(key, localStorage.getItem(key));
          }
        }
      }
    });

    if (scenarioDataTable) {
      scenarioDataTable.destroy();
      setScenarioDataTable(null)
      document.querySelector('#int-app-table').innerHTML = "";
    }

    scenarioTable = new DataTable(tableRef?.current, {
      fixedColumns: {
        leftColumns: 2,
        left: 1,
        start: 2,
      },
      // paging:false,
      // orderClasses: false,
      // deferRender: true,
      scrollInfinite: true,
      // 
      fixedHeader: true,
      scrollX: 300,
      scrollY: ((windowDimensions?.height/1.5)-100),
      scrollCollapse: true,
      // colReorder: colReorder_options,
      data: scenarioData,
      language: {
        loadingRecords: "&nbsp;",
        processing: "Loading...",
      },
      // ajax: {
      //   // url: `${API_BASE_URL}scenario/serverSideScenarioLandscape`,
      //   url: `${API_BASE_URL}scenario/scenarioLandscape`,
      //   type: "POST",
      //   data:{scenarioId: props?.id},
      //   headers: {
      //     "Authorization": `Bearer ${UserService.getToken()}`,
      //     "X-XSS-Protection": `1; mode=block`,
      //     "X-Content-Type-Options": "nosniff",
      //     "Referrer-Policy": "same-origin",
      //     "X-Content-Security-Policy": "default-src 'self'; script-src 'self';",
      //   },
      //   dataSrc: "data"
      // },
      buttons: html5Buttons(),
      destroy: true,
      // serverSide: true,
      pageLength: 25,
      lengthMenu: [
        [10, 25, 50, 100, 500, -1],
        ["10", "25", "50", "100", "500", "Show all"],
      ],
      aaSorting: [],
      searchPanes: {
        layout: 'columns-4',
        orderable: false,
        threshold: 1,
      },
      dom: "PBfrtip",
      columns: scenarioColumnsHeaders?.map((element, index) => {
        // console.log(element, "=====element")
        element.render = function (data, type, row) {
          if (
            type === "display" &&
            data != null &&
            typeof data === "string"
          ) {
            data = data.replace(/<(?:.|\\n)*?>/gm, "");
            data = data.replaceAll('"', "");
            const dataTitle = data;
            data = data.split(",").join(",<br/>")
            if (data.length > 100 && data[element.data] == 'Business Capability') {
              return `<span class='show-ellipsis text-wrapped' data-title='${dataTitle}'>${data.substr(
                0,
                100
              )} ...</span><span class='no-show'>${data.substr(100)}</span>`;
            } else if (data.length > 50) {
              return `<span class='show-ellipsis text-wrapped' data-title='${dataTitle}'>${data.substr(
                0,
                50
              )}</span><span class='no-show'>${data.substr(50)}</span>`;
            } else {
              return data || "";
            }
          } else {
            return data || "";
          }
        }

        return element;
      }),
      select: {
        style: "multi",
        selector: "td:first-child",
      },
      initComplete: function (settings, json) {
        console.log(settings, json,"==settings, jsonsettings, json")

        var api = this.api();
        replacePlaceholder();
        // this.api().searchPanes.rebuildPane();
        if(settings?.aoData?.length > 0){
          $('#int-app-table').on('dblclick', 'tbody td', async function (e) {
            let container = $("div.comment-content");
            container.remove();
            message.destroy()
            if (UserService.hasRole(['Admin', 'Editor'])) {
              setDefaultSelectValue([]);
              setModalSelectOpen(false);
              setModalTextareaOpen(false);
              setInputValue('');
              let columnIndex = $(this).index();
  
              var rowIndex = $(this).closest('tr').index();
              let row = scenarioTable.row(this).data();
              let scenarioId;
              if (row && row?.DT_RowId) {
                scenarioId = row.DT_RowId.split("_")[2];
              }
              let columnId = null;
              let availableColumnId = null;
              const column = scenarioColumns[columnIndex - 1];
              if (scenarioColumnsResponse?.length != 0) {
                for (let item of scenarioColumnsResponse) {
                  if (item?.columnName == column?.label) {
                    columnId = item.id;
                    availableColumnId = item.availableColumnId;
                    break
                  }
                }
              }
              let value = $(this).text();
              if ($(this).find('span.text-wrapped').length !== 0) {
                value = $(this).find('span.text-wrapped').attr('data-title');
              }
              let obj = {
                scenarioId: scenarioId,
                rowIndex: rowIndex,
                columnId: columnId,
                availableColumnId: availableColumnId,
                column: column,
                row: row,
              }
  
              setSelectSubmitData(obj)
              console.log(column,"==columncolumn")
              console.log(value,"==valuevalue")
              if (column?.type == "select") {
                const dropdownVal = await ScenarioServices.getScenarioDropdownValue({
                  "columnId": availableColumnId,
                })
                if (dropdownVal.data) {
                  setOptions(dropdownVal.data)
                } else {
                  setOptions(column?.ipOpts)
                }
                let trimmedValues;
                if(value){
                  if (value.includes(',')) {
                    trimmedValues = value.split(',').map(item => item.trim());
                    setDefaultSelectValue(trimmedValues)
                  } else {
                    trimmedValues = [value];
                    setDefaultSelectValue(trimmedValues)
                  }
                }
                setModalSelectOpen(true)
  
              } else {
                setTextareaValue(value);
                setModalTextareaOpen(true)
              }
            } else {
              message.error("You are not authorised to update the data!")
            }
          })
  
          $('#int-app-table').on('contextmenu', 'tbody td', function (event) {
            message.destroy()
            if (UserService.hasRole(['Admin', 'Editor'])) {
              setSelectSubmitData({})
              var columnIndex = $(this).index();
              let row = scenarioTable.row(this).data()
              if (row) {
                let scenarioId = row.DT_RowId.split("_")[2];
                let rowId = row.DT_RowId.split("_")[1];
                let columnId = null;
                let availableColumnId = null;
                const column = scenarioColumns[columnIndex - 1];
                if (scenarioColumnsResponse?.length != 0) {
                  for (let item of scenarioColumnsResponse) {
                    if (item?.columnName == column?.label) {
                      columnId = item.id;
                      availableColumnId = item.availableColumnId;
                      break
                    }
                  }
                }
                setSelectSubmitData({
                  rowId: rowId, columnId: availableColumnId, scenarioId: scenarioId
                })
                show({
                  event,
                  props: {
                    key: 'value'
                  }
                })
              }
            } else {
              message.error("You are not authorised to apply comment!")
            }
  
          })
        }

        $('.dataTables_scrollHead').css({
          'overflow-x': 'scroll'
        }).on('scroll', function (e) {
          var scrollBody = $(this).parent().find('.dataTables_scrollBody').get(0);
          scrollBody.scrollLeft = this.scrollLeft;
          $(scrollBody).trigger('scroll');
        });

      },
      createdRow: function (row, data, dataIndex) {
        if (data['column_base']) {
          $(row).css('background-color', '#d8e5f1');
          $(row).css('font-weight', '600');
        }
        for (let key in data) {
          if (key.includes('_comment') && data[key] && data[key].length > 0) {
            const columnIndex = getColumnIndexByKey(scenarioColumnsHeaders, key.replace('_comment', ''));
            if (columnIndex !== -1) {
              const cell = $(row).find('td:eq(' + columnIndex + ')');
              cell.addClass('comment-added');

              const commentData = data[key];
              const commentContent = commentData.map(item => {
                const formattedDate = new Date(item.createdAt).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric'
                });

                return `
                            <strong>User:</strong> ${item.userEmail ? item.userEmail : '-'}<br>
                            <strong>Comment:</strong> ${item.comment}<br>
                            <strong>Date:</strong> ${formattedDate}<br>
                            <hr>
                            `;
              }).join('');


              // Add Bootstrap popover
              cell.attr('data-toggle', 'popover');
              cell.attr('data-html', 'true'); // Enable HTML content
              cell.attr('data-content', commentContent);
              // cell.attr('data-trigger', 'hover');
              cell.attr('data-placement', "bottom");
              // cell.popover();
            }
          }
          if (key.includes('_difference') && data[key]) {
            const columnIndex = getColumnIndexByKey(scenarioColumnsHeaders, key.replace('_difference', ''));

            if (columnIndex !== -1) {
              const cell = $(row).find('td:eq(' + columnIndex + ')');
              cell.css('color', '#ea0505');
              cell.css('font-style', 'italic')
            }
          }
        }
      },
      searchDelay: 350,
      drawCallback: function (settings) {
        let searchText = $('#int-app-table_filter input[type=search]').val();
        if (settings?.iDraw >= 2) {
          setHighlight(searchText);
        }


        $('.paginate_button.next:not(.disabled)', this.api().table().container())
          .on('click', function () {
            alert('next');
          })
      }
    });

    $(document).on("click", "tbody td.comment-added", function (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
      let container = $("div.comment-content");
      if (container.length) {
        container.remove();
      } else {
        container.remove();
        let comments = $(this).attr('data-content');
        if (comments) {
          let content = `<div class="comment-content">${comments}</div>`;
          $(this).append(content);
        }
      }
    })

    // $(document).on("click", function (e) {
    //   let container = $("div.comment-content");
    //   if (container.length && !$(e.target).closest('.comment-added').length) {
    //     container.remove();
    //   }
    // });

    setScenarioDataTable(scenarioTable);

    var timeout = null;
    let setHighlight = (searchText) => {
      clearTimeout(timeout);
      $.fn.highlight = function (str) {
        var regex = new RegExp(str, "gi");
        return this.each(function () {
          this.innerHTML = this.innerText.replace(regex, function (matched) {
            return "<span class='highlight'>" + matched + "</span>";
          });
        });
      };

      // Mark
      timeout = setTimeout(() => {
        $('#int-app-table tr td').highlight(searchText);
      }, 500);
    }

  }



  const getScenario = async () => {
    setLoading(true);
    let obj = {};
    obj.scenarioId = props?.id;
    if (!UserService.hasRole(['Admin'])) {
      obj.userEmail = localStorage.getItem('email');
    }
    let userDetails = UserService.getUserdetails();
    obj.roles = userDetails?.realm_access?.roles
    ScenarioServices.getScenarioList(obj)
      .then((response) => {
        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data[0];
          let title = responseVal?.name
            ? `${responseVal?.name}: (${responseVal?.scenario_sub_type_name})`
            : "";
          setEditScenarioValues({
            ...editScenarioValues,
            scenarioId: responseVal?.id,
            name: responseVal?.name,
            scenarioTypeID: responseVal?.scenarioTypeID,
            scenarioSubtypeID: responseVal?.scenarioSubtypeID,
            intiativeId: responseVal?.intiativeId,
            objective: responseVal?.objective,
            visibility: responseVal?.visibility,

          })
          setScenarioTitle(title);
        } else if (response?.data?.code === 404) {
          setLoading(false);
          message.error(response?.data?.message)
          navigate('/scenario-list')
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  function sortByMandatory(a, b) {
    if (a.is_mandatory === 1 && b.is_mandatory === 0) {
      return -1;
    } else if (a.is_mandatory === 0 && b.is_mandatory === 1) {
      return 1;
    } else {
      return 0;
    }
  }

  const getScenarioColumns = async (columnName) => {
    setLoading(true);
    let obj = {};
    obj.scenarioId = props?.id;
    await ScenarioServices.getScenarioColumns(obj)
      .then(async (response) => {
        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data;
          responseVal = responseVal.sort(sortByMandatory);
          setScenarioColumnsResponse(responseVal)
          let colHeader = [];
          let scenarioCol = [];
          colHeader.push({
            data: null,
            title: "Select",
            defaultContent: "",
            className: "select-checkbox text-center",
            orderable: false,
            name: "select_check",
            dataLabel: "Select",
          });
          responseVal.map((e) => {
            let visible = true;
            // let visible = false;
            // if (columnName && e.columnName === columnName) {
            //   visible = true;
            // } else {
            //   visible = e.is_mandatory == 1;
            // }
            if (e['type'] == "dropdown") {
              scenarioCol.push({
                visible: visible,
                label: e.columnName,
                name: "column_" + e.id,
                "type": "select",
                "def": e['options'][0],
                ipOpts: e['options']
              })
            } else {
              scenarioCol.push({
                visible: visible,
                label: e.columnName,
                name: "column_" + e.id,
                type: 'textarea',
              })
            }
            if (e?.is_mandatory || (UserService.hasRole(['Viewer']) && !UserService.hasRole(['Admin', 'Editor']))) {
              colHeader.push({
                visible: visible,
                label: `<div>${e.columnName} <span class="a_datatbl_trash d-none"><i class="fa fa-times datatbl_trash" ></i></span></div>`,
                title: `<div>${e.columnName} <span class="a_datatbl_trash d-none"><i class="fa fa-times datatbl_trash" ></i></span></div>`,
                data: "column_" + e.id,
                dataLabel: e?.columnName,
              });
            } else {
              colHeader.push({
                visible: visible,
                label: `<div class="delete-col">${e.columnName} <span class="a_datatbl_trash" data-column-id=${e.id} data-scenario-id=${e.scenarioID}"><i class="fa fa-times datatbl_trash" ></i></span></div>`,
                title: `<div class="delete-col">${e.columnName} <span class="a_datatbl_trash" data-column-id=${e.id} data-scenario-id=${e.scenarioID}" ><i class="fa fa-times datatbl_trash" ></i></span> </div>`,
                data: "column_" + e.id,
                dataLabel: e?.columnName,
              });
            }
          });
          setScenarioColumnsHeaders(colHeader);
          setScenarioColumns(scenarioCol);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getScenarioLandscape = async () => {
    setLoading(true);
    let obj = {};
    obj.scenarioId = props?.id;
    ScenarioServices.getScenarioLandscape(obj)
      .then((response) => {
        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data;
          setScenarioData(responseVal);
        }
      })
      .catch((err) => {
        // console.log(err.message);
        setLoading(false);
      });
  };

  const onNewColSubmit = (formData) => {
    setConfirmLoading(true);
    let obj = {};
    obj.scenarioId = props?.id;
    obj.columnName = formData?.columnName;
    let newColumnsLength = scenarioColumnsHeaders?.filter(f => f.dataLabel?.toLowerCase().trim() === formData?.columnName?.toLowerCase().trim())?.length
    if (newColumnsLength > 0) {
      setConfirmLoading(false);
      return message.error("Column " + formData?.columnName + " already exist");
    }
    ScenarioServices.checkUniqueScenarioColumns(obj)
      .then((response) => {
        if (response?.data?.code === 404) {
          ScenarioServices.putScenarioColumns(obj).then(async (response) => {
            // setNewColAdded(true)
            await getScenarioColumns(formData?.columnName);
            await getScenarioLandscape();
          });
        }
        setConfirmLoading(false);
        setOpenNewColModal(false);
      })
      .catch((err) => {
        message.error(err.message);
        setConfirmLoading(false);
      });
  };


  const onAddInitiativeSubmit = (formData) => {
    setConfirmLoading(true);
    let obj = {};
    obj.DisplayName = formData?.name;
    obj.tableName = 'initiatives';

    let newInitiativesLength = initiatives?.filter(f => f.label?.toLowerCase()?.trim() === formData?.name?.toLowerCase()?.trim())?.length
    if (newInitiativesLength > 0) {
      setConfirmLoading(false);
      return message.error("Initiative " + formData?.name + " already exist");
    }

    ScenarioServices.addInitiative(obj)
      .then((response) => {
        if (response?.data?.code === 200) {
          getInitiatives(loading, setLoading, setInitiatives);
          initiativeForm.resetFields();
        }
        setConfirmLoading(false);
        setOpenInitiativeModal(false);
      })
      .catch((err) => {
        message.error(err.message);
        setConfirmLoading(false);
      });
  };

  $(document).on("click", ".delete-col .a_datatbl_trash", async function (e) {
    setConfirmLoading(true);
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (
      window.confirm(
        "Are you sure? This will delete the entire column and it's data."
      )
    ) {
      let obj = {};
      obj.scenarioId = props?.id;
      obj.columnId = $(this).data("column-id");
      ScenarioServices.deleteScenarioColumn({ params: obj }).then(
        async response => {
          if (response?.data?.code === 200) {
            // var indexToRemove = $(this).parent().parent().index();
            // await getScenario();
            await getScenarioColumns('');
            await getScenarioLandscape();
            // await getScenarioType(loading, setLoading, setScenarioType)
            // await getScenarioSubType(loading, setLoading, setScenarioSubType);
            // await getInitiatives(loading, setLoading, setInitiatives);
          }
          setConfirmLoading(false);
        }
      );
    }
  });


  const editScenarioAction = (action) => {
    if (!UserService.hasRole(['Admin', 'Editor'])) {
      return message.error("You are not authorised to update the data!")
    }
    if (action === 'edit') {
      setEditScenarioShowButtons({ ...editScenarioShowButtons, edit: false, save: true, cancel: true })
      setdisabledFormFields(false);
    } else if (action === 'save') {
      editScenarioForm.submit();
      setEditScenarioShowButtons({ ...editScenarioShowButtons, edit: true, save: false, cancel: false })
      setdisabledFormFields(true);
    } else {
      setEditScenarioShowButtons({ ...editScenarioShowButtons, edit: true, save: false, cancel: false })
      setdisabledFormFields(true);
    }
  }

  const onEditScenario = (formData) => {
    setLoading(true);
    formData.scenarioId = props?.id;
    const { scenarioSubtypeID, ...newFormData } = formData;
    ScenarioServices.editScenario(newFormData).
      then(response => {
        if (response?.data?.code === 200) {
          getScenario();
        }
        setLoading(false);
      }
      );
  }

  const submitSelect = async () => {
    try {
      let commaSep = defaultSelectValue.join(',')
      let obj = {
        rowNo: selectSubmitData.row.id,
        scenarioId: selectSubmitData.scenarioId,
        columnId: selectSubmitData.columnId,
        availableColumnId: selectSubmitData.availableColumnId,
        filterColumn: commaSep,
        isInput: false,
        inputValue: '',
        userEmail: localStorage.getItem('email')
      }

      if (inputValue) {
        obj.isInput = true;
        obj.inputValue = inputValue;
      } else {
        obj.isInput = false;
        obj.inputValue = '';
      }
      if (modalSelectOpen) {
        const response = await ScenarioServices.updateSelectData(obj);
        if (response && response.data.status) {
          message.success("Data Updated Successfully!")
          setInputValue('')
          setModalSelectOpen(false)
          setModalTextareaOpen(false)
          await getScenarioColumns('');
          await getScenarioLandscape();

        } else {
          message.error(response.data.message)
        }
      } else {

      }

    } catch (error) {

    }
  }

  const submitTextarea = async () => {
    try {
      let obj = {
        rowNo: selectSubmitData.row.id,
        scenarioId: selectSubmitData.scenarioId,
        columnId: selectSubmitData.columnId,
        availableColumnId: selectSubmitData.availableColumnId,
        filterColumn: textareaValue,
        isInput: false,
        inputValue: '',
        userEmail: localStorage.getItem('email')
      }
      const response = await ScenarioServices.updateSelectData(obj);
      if (response && response.data.status) {
        message.success("Row Updated Successfully!")
        setInputValue('')
        setModalSelectOpen(false)
        setModalTextareaOpen(false)
        await getScenarioColumns('');
        await getScenarioLandscape();
      } else {
        message.error(response.data.message)
      }

    } catch (error) {

    }
  }

  const submitComment = async () => {
    try {
      let obj = {
        columnId: selectSubmitData.columnId,
        comment: commentValue,
        rowId: selectSubmitData.rowId,
        scenarioId: selectSubmitData.scenarioId,
        userEmail: localStorage.getItem('email')
      }
      const response = await ScenarioServices.singleScenarioComment(obj);
      if (response) {
        setCommentValue('')
        setModalCommentOpen(false)
        await getScenarioColumns('');
        await getScenarioLandscape();
        message.success("Comment Added Successfully!")
      } else {
        message.error(response.data.message)
      }

    } catch (error) {

    }
  }


  const handleChange = (value) => {
    setDefaultSelectValue(value)
  };

  const handleItemClick = ({ id, event, props }) => {
    setCommentValue('');
    setModalCommentOpen(true);

  }
  const handleBlurName = (e) => {
    let value = e?.target?.value;

    let obj = {};
    obj.columnName = value;
    obj.scenarioId = editScenarioValues?.scenarioId;
    ScenarioServices.checkUniqueScenario(obj)
      .then((response) => {
        if (response.data.code == 200) {
          editScenarioForm.resetFields(["name"]);
          message.error("landscape name already exist.");
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  const getPlanUmlDropDown = () => {
    let plantColumnData = scenarioColumns.find(f=>f?.label?.toLowerCase()==="plant");
    let plants = [...new Map(scenarioData?.map(f=>{ 
      return {
        value:f?.id,
        label:f[plantColumnData?.name],
        id:f?.id,
      }
    }).map(item =>[item['label'], item])).values()]

    // eslint-disable-next-line no-useless-computed-key
    setPlantUMLDropdowns({...plantUMLDropdowns,['plant']:plants})
  }

  return (
    <>
      <Spin spinning={loading}>
        {/* Scenario List Filter */}
        <Title level={5} className="text-start py-2">
          {scenarioTitle || <>&nbsp;</>}
          <Link to={'/scenario-list'} className="pull-right btn btn-sm btn-outline-secondary text-secondary ml-2"><i className="fa fa-arrow-left" ></i> Back</Link>
          {editScenarioValues?.scenarioSubtypeID.toString() === "1" ? (
            <button
              className="pull-right btn btn-sm btn-outline-theme ml-2"
              onClick={() => {
                navigate(`/level-grouping-master`, {
                  state: {
                    columns: scenarioColumnsResponse,
                    scenarioId: props?.id,
                    from: location,
                  },
                });
              }}
            >
              <MergeFilled /> Swimlane view
            </button>
          ) : editScenarioValues?.scenarioSubtypeID.toString() === "2" ? (
            <button
              className="pull-right btn btn-sm btn-outline-theme ml-2"
              onClick={() => {
                navigate(`/plant-uml-view`, {
                  state: {
                    columns: scenarioColumnsResponse,
                    scenarioId: props?.id,
                    from: location,
                    dropdowns:plantUMLDropdowns,
                  },
                });
              }}
            >
              <MergeFilled /> Plant UML view
            </button>
          ) : (
            ""
          )}
        </Title>
        <Collapse
          className="mb-2"
          expandIconPosition={"end"}
          items={[
            {
              key: "edit-scenario-form",
              label: "Edit Landscape Configuration",
              children: (
                <>
                  <div className="text-end">
                    <FormOutlined title="Edit" onClick={() => editScenarioAction('edit')} className="edit-scenario-icons" style={{ display: editScenarioShowButtons?.edit ? '' : 'none' }} />
                    <CheckOutlined title="Save" onClick={() => editScenarioAction('save')} className="edit-scenario-icons me-2" style={{ display: editScenarioShowButtons?.save ? '' : 'none' }} />
                    <CloseOutlined title="Cancel" onClick={() => editScenarioAction('cancel')} className="edit-scenario-icons" style={{ display: editScenarioShowButtons?.cancel ? '' : 'none' }} />
                  </div>
                  <Form
                    {...editScenarioFormlayout}
                    layout={"vertical"}
                    form={editScenarioForm}
                    onFinish={onEditScenario}
                    requiredMark={false}
                    initialValues={{ name: editScenarioValues?.name, objective: editScenarioValues?.objective, scenarioSubtypeID: editScenarioValues?.scenarioSubtypeID.toString(), scenarioTypeID: editScenarioValues?.scenarioTypeID.toString(), intiativeId: editScenarioValues?.intiativeId === '0' ? null : editScenarioValues?.intiativeId, visibility: editScenarioValues?.visibility == '1' ? 'Public' : (editScenarioValues?.visibility == '2' ? 'Private' : 'Public') }}
                  >
                    <Row>
                      <Col md={12}>
                        <Form.Item
                          label={
                            <>
                              <span>Name</span>
                              <span className="text-danger">*</span>
                            </>
                          }
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter landscape name",
                            },
                          ]}
                          className="mb-2 text-start"
                        >
                          <Input
                            placeholder=""
                            disabled={disabledFormFields}
                            autoComplete="off"
                            onBlur={handleBlurName}
                          />
                        </Form.Item>

                        <Form.Item
                          label={
                            <>
                              <span>Sub Type</span>
                              <span className="text-danger">*</span>
                            </>
                          }
                          name="scenarioSubtypeID"
                          rules={[
                            {
                              required: false,
                              message: "Please enter scenario Sub Type",
                            },
                          ]}
                          className="mb-2 text-start"
                        >
                          <Select
                            allowClear
                            placeholder="Select"
                            disabled={true}
                          >
                            {scenarioSubType?.map((e) => {
                              return (
                                <>
                                  <Select.Option value={e.value}  >
                                    {e.label}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={12}>
                        <Form.Item
                          label={
                            <>
                              <span>Type</span>
                              <span className="text-danger">*</span>
                            </>
                          }
                          name="scenarioTypeID"
                          rules={[
                            {
                              required: false,
                              message: "Please enter scenario Type",
                            },
                          ]}
                          className="mb-2 text-start"
                        >
                          <Select
                            allowClear
                            placeholder="Select"
                            disabled={disabledFormFields}
                          >
                            {scenarioType?.map((e) => {
                              return (
                                <>
                                  <Select.Option value={e.value}>
                                    {e.label}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        <div className=" position-relative">
                          <Form.Item
                            label="Initiative"
                            name="intiativeId"
                            wrapperCol={{ span: 21 }}
                            rules={[
                              {
                                required: false,
                                message: "Please select Initiative",
                              },
                            ]}
                            className="mb-2 text-start"
                          >
                            <Select
                              placeholder="Select"
                              allowClear
                              showSearch
                              optionFilterProp="children"
                              disabled={disabledFormFields}
                              filterOption={(input, option) => {
                                return (
                                  option?.children.toLowerCase() ?? ""
                                ).includes(input.toLowerCase());
                              }}
                              filterSort={(optionA, optionB) =>
                                (optionA?.children ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.children ?? "").toLowerCase()
                                  )
                              }
                            >
                              {initiatives?.map((e) => {
                                return (
                                  <>
                                    <Select.Option value={e?.value?.toString()}>
                                      {e?.label}
                                    </Select.Option>
                                  </>
                                );
                              })}
                            </Select>
                          </Form.Item>

                          <div className="initiative-edit-btn">
                            <PlusCircleOutlined
                              title="Add Initiative"
                              onClick={() => {
                                if (UserService.hasRole(['Admin', 'Editor'])) {
                                  setOpenInitiativeModal(true)
                                } else {
                                  return message.error("You are not authorised to update the data!")
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Description"
                          name="objective"
                          wrapperCol={{
                            span: 23,
                          }}
                          rules={[
                            {
                              required: false,
                              message: "Please enter scenario Description",
                            },
                          ]}
                          className="mb-2 text-start"
                        >
                          <TextArea disabled={disabledFormFields} rows={4} />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Visibility"
                          name="visibility"
                          initialValue="1"
                          rules={[
                            { required: false, message: "Please select visibility" },
                          ]}
                          className="mb-2 text-start"
                        >
                          <Select
                            disabled={disabledFormFields}
                            options={[
                              { value: '1', label: "Public" },
                              { value: '2', label: "Private" }
                            ]}
                          >
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </>
              ),
            },
          ]}
        />

        {/* Scenario List Table */}
        <Row>
          <Col xs={24} md={24} lg={24} className="edit-scenario" style={{height:windowDimensions?.height-150}}>
            <Card className="table-responsive dataTable dt-avoid-y-scroll mt-2 text-start">
              <table
                id="int-app-table"
                ref={tableRef}
                className="table display nowrap pt-1 table-bordered"
                style={{ width: "100%" }}
              >
              </table>
            </Card>
          </Col>
        </Row>
      </Spin>

      <Modal
        title={"Add New Column"}
        open={openNewColModal}
        footer={false}
        destroyOnClose
        closable={true}
        maskClosable={false}
        onCancel={() => setOpenNewColModal(false)}
      >
        <Form
          form={newColForm}
          onFinish={onNewColSubmit}
          requiredMark={false}
          initialValues={{}}
        >
          <Form.Item
            label={
              <>
                <span>Name</span>
                <span className="text-danger">*</span>
              </>
            }
            name="columnName"
            rules={[{ required: true, message: "Please enter Column name" }]}
            className="my-4 text-start"
          >
            <Input
              placeholder=""
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="ms-auto d-block"
              htmlType="submit"
              disabled={confirmLoading}
            >
              {confirmLoading ? "Please Wait" : "Save"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={"Add Initiative"}
        open={openInitiativeModal}
        footer={false}
        destroyOnClose
        closable={true}
        maskClosable={false}
        onCancel={() => setOpenInitiativeModal(false)}
      >
        <Form
          form={initiativeForm}
          onFinish={onAddInitiativeSubmit}
          requiredMark={false}
          initialValues={{}}
        >
          <Form.Item
            label={
              <>
                <span>Name</span>
                <span className="text-danger">*</span>
              </>
            }
            name="name"
            rules={[
              { required: true, message: "Please enter initiative name" },
            ]}
            className="my-4 text-start"
          >
            <Input
              placeholder="Initiative name"
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="ms-auto d-block"
              htmlType="submit"
              disabled={confirmLoading}
            >
              {confirmLoading ? "Please Wait" : "Save"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Update Content"
        style={{ top: 20 }}
        open={modalTextareaOpen}
        maskClosable={false}
        onOk={() => submitTextarea()}
        onCancel={() => setModalTextareaOpen(false)}
      >
        <TextArea value={textareaValue} onChange={(e) => setTextareaValue(e.target.value)}
        />
      </Modal>

      <Modal
        title="Comment"
        style={{ top: 20 }}
        open={modalCommentOpen}
        maskClosable={false}
        onOk={() => submitComment()}
        onCancel={() => setModalCommentOpen(false)}
      >
        <TextArea value={commentValue} onChange={(e) => setCommentValue(e.target.value)}
        />
      </Modal>
      <Modal
        title="Update Content"
        style={{ top: 20 }}
        open={modalSelectOpen}
        maskClosable={false}
        onOk={() => submitSelect()}
        onCancel={() => setModalSelectOpen(false)}
      >
        <Select
          mode="multiple"
          placeholder="Select items"
          onChange={handleChange}
          style={{ width: '100%' }}
          value={defaultSelectValue}
        >
          {options && options.map((item, index) => (
            <Option key={index} value={item.value}>{item.label}</Option>
          ))}
        </Select>
        {/* <span>Or Add New</span>
        <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)}
        /> */}
      </Modal>
      {/* <Menu id={MENU_ID}>
        <Item id="copy" onClick={handleItemClick}>Comment</Item>
      </Menu> */}
    </>
  );
};

export default EditScenario;