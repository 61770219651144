import {transformText } from "../utils/Common";

const bgColors = [
  "#ffb484",
  "#fff484",
  "#c0ec84",
  "#ffbce4",
  "#c0f4e4",
  "#e0b4fc",
  "#88ace4",
  "#58bcbc",
];
const transformData = (inputData, bgColors = bgColors, portfolioList) => {
  const colors = bgColors;
  let colorIndex = 0;
  //Child  

   const transformChildren = (children, parentTitle, colorChildren) => {
    return children.map((child) => {
        
      const obj = {
        title:
          child.moduleName || child.displayName || child.nameInSourceSystem,
        //background: colors[colorIndex], // Assign color from the colors array
        background: colorChildren,
        children:
          child.tags && child.tags.length > 0
            ? transformChildren(child.tags, child.moduleName,colorChildren)
            : [],
        data: { ...child }, // Retain the data field
        parentTitle: parentTitle,
      };
      colorIndex = (colorIndex + 1) % colors.length; // Move to the next color in the array
      return obj;
    });
  };

  const transformRelations = (relations, parentTitle) => {
    //Parent
     
    return relations.map((relation) => {
      
      const obj = {
        title:
          relation.moduleName ||
          relation.displayName ||
          relation.nameInSourceSystem,
        //background: colors[colorIndex],
        background: getPortfolioColor(relation.moduleName ,portfolioList), // Portfolio color 
        children:
          relation.tags && relation.tags.length > 0
            ? transformChildren(relation.tags, relation.moduleName, getPortfolioColor(relation.moduleName ,portfolioList))
            : [],
        data: { ...relation }, // Retain the data field
        parentTitle: parentTitle,
      };
      colorIndex = (colorIndex + 1) % colors.length; // Move to the next color in the array
      return obj;
    });
  };

  const transformedData = [];

  //Root 
  // Transform the relations
  const rootTitle = inputData.displayName || inputData.nameInSourceSystem;
  const relations =
    inputData.relations && inputData.relations.length > 0
      ? transformRelations(inputData.relations, rootTitle) // Pass null as parentTitle for top-level relations
      : [];

  const rootObj = {
    title: rootTitle,
    //background: colors[colorIndex],
    background: getPortfolioColor(inputData.nodeTag ,portfolioList), // Assign color from the colors array
    // children:
    //   inputData.relations && inputData.relations.length > 0
    //     ? transformRelations(inputData.relations)
    //     : [],
    children: relations,
    data: { ...inputData }, // Retain the data field for the root object
  };

  transformedData.push(rootObj);

  return transformedData;
};

const getNodesByModuleName = (array, moduleName) => {
  return array?.find((item) => item?.moduleName === moduleName) || {};
};

const convertToNormalText = (str) => {
  let text;
  if (str?.includes("_")) {
    text = str
      ?.split("_")
      ?.map(
        (word) => word.charAt(0)?.toUpperCase() + word.slice(1)?.toLowerCase()
      )
      ?.join(" ");
  } else if (str?.includes("-")) {
    text = str
      ?.split("-")
      ?.map(
        (word) => word.charAt(0)?.toUpperCase() + word.slice(1)?.toLowerCase()
      )
      ?.join(" ");
  } else {
    text = str?.replace(/([a-z])([A-Z])/g, "$1 $2");
    text = text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase();
  }
  return text;
};

const dynamicRoutesLinks = [
  {
    moduleName: "BusinessApplication",
    link: "/application-list/",
    type: "business_application"
  },
  {
    moduleName: "BusinessCapability",
    link: "/business-capability-master/",
    type: "business_capability"
  },
  {
    moduleName: "BusinessProcesses",
    link: "/business-process/",
    type: "business_processes"
  },
  {
    moduleName: "BusinessProcesses",
    link: "/business-process/",
    type: "business_processes"
  },
  {
    moduleName: "Initiative",
    link: "/portfolio-initiative/",
    type: "initiative"
  },
  {
    moduleName: "Product",
    link: "/portfolio-product/",
    type: "business_application"
  },
  {
    moduleName: "ProductArea",
    link: "/portfolio-product-area/",
    type: "business_application"
  },
  {
    moduleName: "ProductCluster",
    link: "/portfolio-product-cluster/",
    type: "business_application"
  },
  {
    moduleName: "integration",
    link: "",
    type:"integration"
  }
];

const getDynamicRoutesLink = ({type,moduleName,id}) => {
  let redirectLink = dynamicRoutesLinks?.find(
    (item) => (item?.type === type || item?.moduleName === moduleName)
  );
  console.log("link",{type,moduleName,id})
  if (redirectLink?.link && redirectLink?.moduleName && id) {
    redirectLink = redirectLink?.link + redirectLink?.moduleName + "/" + id;
    return redirectLink;
  }
  return '/';
}

const getPortfolioColor=(strPortfolio, portfolioList)=>{
 
  const color= portfolioList?.find((element) => {
    return transformText( element?.moduleName.replace("_"," "),"pascal")=== strPortfolio;
  });

  return color?.color;

}

const getPortfolioID=(strPortfolio, portfolioList)=>{
 
  const color= portfolioList?.find((element) => {
    return transformText( element?.moduleName.replace("_"," "),"pascal")=== strPortfolio;
  });

  return color?.id;

}



export {
  transformData,
  bgColors,
  getNodesByModuleName,
  convertToNormalText,
  dynamicRoutesLinks,
  getDynamicRoutesLink,
  getPortfolioColor,
  getPortfolioID
};
