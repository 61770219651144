import React, { useState,useEffect } from 'react';
import { Menu} from 'antd';
import './AppHeader.scss';
import { useLocation } from 'react-router-dom';
import { useNavigate  } from 'react-router-dom';
import UserService from '../../../services/UserService';

function HeaderMenus(props) {
    const [menus, setMenus] = useState([]);
    const location = useLocation();
    const [current, setCurrent] = useState('');
   
    let navigate = useNavigate();

    useEffect(()=>{
        if(props?.menus){
            setMenus(props?.menus);
        }
    },[props?.menus])

    useEffect(()=>{
        if(menus.length>0){
            setMenus(menus);
            setCurrent(location?.pathname.replace("/",""));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[menus])

    const onClick = (e) => {
        // if(e?.type==='select'){
        //     let tempMenus = menus;
        //     let index = tempMenus.findIndex((f)=>f.key===e?.key);
        //     tempMenus[index]['value'] = e.value;
        //     localStorage.setItem("language",e.value)
        //     setMenus(tempMenus);
        //     selectLanguage(e.value);
        // }else if(e.value==="logout"){
        if(e.value==="logout"){
            UserService.doLogout()
        }else{
            if(e?.value?.split()?.pop() !== "#"){
                setCurrent(e.value);
                navigate('/'+e.value);
            }
        }
    };

    return (
        <Menu mode="horizontal" selectedKeys={[current]} className="cu-bg-none bg-none font-weight-bold cu-header-menus">
            { 
                menus?.map((element)=>{
                    if(element?.items){
                        let selectedTitle = element.label;
                        if(element?.type==="select"){
                            selectedTitle = element?.items?.find((f)=>f.key===element['value'])?.label || selectedTitle;
                        }
                       
                        return (
                            <Menu.SubMenu 
                                key={element.key} 
                                title= {selectedTitle}
                                icon={element.icon}
                            >
                                {
                                    (()=>{
                                        return element?.items?.map((subMenu)=>{
                                            let obj = {};
                                            obj.type = element?.type;
                                            obj.key = element?.key;
                                            obj.value = subMenu?.key;
                                            obj.icon = subMenu.icon;
                                            return (
                                                <Menu.Item key={subMenu?.key} icon={subMenu.icon} onClick={(e)=>{e={e,...obj};onClick(e)}} >
                                                    {subMenu?.label}
                                                </Menu.Item>
                                            )
                                        })
                                    })()
                                }
                            </Menu.SubMenu>
                        );
                    }else{
                        let obj = {};
                        obj.type = 'item';
                        obj.key = element?.key;
                        obj.value = element?.key;
                        return (
                            <Menu.Item key={element?.key} icon={element?.icon} onClick={(e)=>{e={e,...obj};onClick(e)}}>
                                {element?.label}
                            </Menu.Item>
                        );
                    }
                })
            }
        </Menu>
    );
}

export default HeaderMenus;