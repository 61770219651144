import React from "react";
import PortfolioSingleEntry from "../components/portfoliosingleentry/PortfolioSingleEntry";
import { TabProvider } from "../components/portfoliosingleentry/TabProvider";

const PortfolioSingleEntryPage = () => {
  return (
    <>
      <TabProvider>
        <PortfolioSingleEntry />
      </TabProvider>
    </>
  );
};

export default PortfolioSingleEntryPage;
