import * as Yup from "yup";
import { dummyData } from "./linksSchema";

const initialBusinessValues = {
  appId: "",
  applicationName: "",
  additionalAttributes: [],
  lifeCycleStage: "",
  applicationType: "",
  businessOwner: "",
  primaryApplicationManager: "",
  softwareHosting: "",
  confidentiality: "",
  integrity: "",
  availability: "",
  origin: "",
  areaOfUsage: "",
  ownedByProduct: "",
  sourceID: null,
  description: "",
  tags: [],
};

const businessValidationSchema = Yup.object().shape({
  appId: Yup.string().required("App ID is required"),
  applicationName: Yup.string()
    .required("Application Name is required")
    .min(2, "Application Name must be at least 2 characters")
    .max(150, "Application Name cannot exceed 150 characters"),
  additionalAttributes: Yup.array(),
  lifeCycleStage: Yup.string().nullable() ,
  applicationType: Yup.string().nullable() ,
  businessOwner: Yup.string().nullable() ,
  primaryApplicationManager: Yup.string().nullable() ,
  softwareHosting: Yup.string().nullable() ,
  confidentiality: Yup.string().nullable() ,
  integrity: Yup.string().nullable() ,
  availability: Yup.string().nullable() ,
  origin: Yup.string().nullable() ,
  areaOfUsage: Yup.string().nullable() ,
  ownedByProduct: Yup.string().nullable() ,
  sourceID: Yup.number().integer().nullable() ,
  description: Yup.string(),
  tags: Yup.array().of(Yup.string()),
});

const lifeCycles = ["Active", "Phase In", "Phase Out", "End Of Life"];

const listItems = [
  {
    id: 1,
    name: "Object 1",
    description: "Description of object 1",
    title: "Title of object 1",
    appid: "APP-001",
  },
  {
    id: 2,
    name: "Object 2",
    description: "Description of object 2",
    title: "Title of object 2",
    appid: "APP-002",
  },
  {
    id: 3,
    name: "Object 3",
    description: "Description of object 3",
    title: "Title of object 3",
    appid: "APP-003",
  },
  {
    id: 4,
    name: "Object 4",
    description: "Description of object 4",
    title: "Title of object 4",
    appid: "APP-004",
  },
  {
    id: 5,
    name: "Object 5",
    description: "Description of object 5",
    title: "Title of object 5",
    appid: "APP-005",
  },
  {
    id: 6,
    name: "Object 6",
    description: "Description of object 6",
    title: "Title of object 6",
    appid: "APP-006",
  },
  {
    id: 7,
    name: "Object 7",
    description: "Description of object 7",
    title: "Title of object 7",
    appid: "APP-007",
  },
  {
    id: 8,
    name: "Object 8",
    description: "Description of object 8",
    title: "Title of object 8",
    appid: "APP-008",
  },
  {
    id: 9,
    name: "Object 9",
    description: "Description of object 9",
    title: "Title of object 9",
    appid: "APP-009",
  },
  {
    id: 10,
    name: "Object 10",
    description: "Description of object 10",
    title: "Title of object 10",
    appid: "APP-010",
  },
];

export { businessValidationSchema, initialBusinessValues, lifeCycles,listItems };
