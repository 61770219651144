import React from 'react';
import { Layout, Col, Row  } from 'antd';
import './AppHeader.scss';
import { Link } from 'react-router-dom';
import { svgLogo } from '../../../assets/images';
import HeaderMenus from './HeaderMenus';
import HeaderMenusItems from './HeaderMenusItems';
import "bootstrap";



const { Header } = Layout;

function AppHeader() {
    
    return (
        <Header className="header">
            <nav className="menuBar">
                <Row>
                    <Col span={2} className="logo">
                        <Link to="/">
                            <img src={svgLogo} alt="logo" />
                        </Link>
                    </Col>
                    <Col span={6}>
                        <HeaderMenus menus={HeaderMenusItems}/>
                    </Col>
                </Row>
            </nav>
        </Header>
    );
}

export default AppHeader;
