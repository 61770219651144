import { encodeQueryData } from "../../utils/Common";
import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL


async function getCSLandscape(obj) {
    const url = `${API_BASE_URL}scenario/comparescenarioLandscape`;
    let response = await http.post(url, obj);
    return response;
}


const CompareScenarioServices = {
    getCSLandscape,
    API_BASE_URL
}


export default CompareScenarioServices;
