import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import FilterContent from "./FilterContent";
import FilterButtonToggle from "../../layouts/FilterButtonToggle";

const setActiveGridText = (activeGridStyle) => {
  if (activeGridStyle === 1) {
    return "Filter";
  } else if (activeGridStyle === 2 || activeGridStyle === 3) {
    return "Indicator";
  } else {
    return "Filter";
  }
};

const FilterRightSideBar = ({
  activeGridStyle,
  lifeCycleStagesColors,
  showFilter,
  setShowFilter,
  portfolioData,
  selectedFilters,
  setSelectedFilters,
  sortValues,
  optionalLevel = null,
  allowSlider = false,
  SidebarHeight,
  ...props
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(portfolioData || []);
  }, [portfolioData]);


  return (
    <>
      {!allowSlider ? (
        <Sidebar showFilter={showFilter} SidebarHeight={SidebarHeight}>
          <FilterButtonToggle
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setSelectedFilters={setSelectedFilters}
          />
          {showFilter && (
            <FilterContent
              lifeCycleStagesColors={lifeCycleStagesColors}
              activeGridStyle={activeGridStyle}
              portfolioData={data}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              sortValues={sortValues}
              optionalLevel={optionalLevel}
            />
          )}
        </Sidebar>
      ) : (
        <div>
          <FilterButtonToggle
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setSelectedFilters={setSelectedFilters}
          />
          {showFilter && (
            <FilterContent
              lifeCycleStagesColors={lifeCycleStagesColors}
              activeGridStyle={activeGridStyle}
              portfolioData={data}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              sortValues={sortValues}
              optionalLevel={optionalLevel}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FilterRightSideBar;
