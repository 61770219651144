import React, { useEffect, useRef, useState } from 'react'
import "./Integration.scss";
import { IntegrationHeader2 } from './IntegrationHeader';
import { Button, Col, Divider, Flex, Form, Input, Row, Select, Tooltip } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ApplicationModal, default_data_lists, default_technology_lists } from './IntegrationCommon';
import { ApplicationTypeModal, IntigrationModalCheckbox } from './IntegrationAddComponents';
import TextWithTooltip from '../../utils/TextWithTooltip';

const IntegrationAdd = ({moduleName}) => {
    const divHeightRef = useRef(null)
    const [form] = Form.useForm();
    const [height, setHeight] = useState(0)
    const [openModal, setOpenModal] = useState("");
    const [selectedSourceApp, setSelectedSourceApp] = useState([]);
    const [selectedTargetApp, setSelectedTargetApp] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedTechnology, setSelectedTechnology] = useState([]);
    const [selectedApplicationType,setSelectedApplicationType] = useState(null);

    const SaveButton = ({moduleName}) => {
        return <>
            <Tooltip title="Save">
                <Button 
                    className='mr-2 px-4'
                    type='primary'
                    onClick={() => { 

                    }}
                    
                > Save </Button>
            </Tooltip>
        </>
    }

    const onFinish = (formData) => {

    }

    const ApplicationListButton = ({item}) => {
        let temp = [];
        if(openModal==="sourceApp"){ temp = selectedSourceApp; }
        if(openModal==="targetApp"){ temp = selectedTargetApp; }
        return <>
            {
                ((()=>{
                    if(temp?.find(f=>f.id===item?.id)){
                        return <>
                            <Tooltip title="Added">
                                <Button 
                                    className='mr-2 px-4'
                                    type='primary'
                                    ghost
                                    disabled
                                > Added </Button>
                            </Tooltip>
                        </>
                    }else{
                        return <>
                            <Tooltip title="Select">
                                <Button 
                                    className='mr-2'
                                    type='primary'
                                    icon={<PlusOutlined/>}
                                    onClick={()=>{
                                        setSelectedApplicationType(item);
                                    }}
                                />
                            </Tooltip>
                        </>
                    }
                })())
            }
            
        </>
    }

    const selectApplicationForm1 = (value) => {
        let temp = [];;
        if(openModal==="sourceApp"){
            value.title = value?.DisplayName;
            setSelectedSourceApp([value]);
        }else if(openModal==="targetApp"){
            value.title = value?.DisplayName;
            setSelectedTargetApp([...selectedTargetApp,value]);
        }else if(openModal==="data"){
            temp = value?.map(item=>{
                item.title= item?.label;
                return item;
            });
            setSelectedData(temp);
        }else if(openModal==="technology"){
            temp = value?.map(item=>{
                item.title= item?.label;
                return item;
            });
            setSelectedTechnology(temp);
        }
    }

    useEffect(() => {
        setHeight(divHeightRef.current.clientHeight)
    },[divHeightRef])

    const setOpenAppliactionModal = (check) => {
        setOpenModal(check?"":"");
    }

    const ListSelectedItems = ({lists,keyName,span}) =>{
        return lists?.map(item=>{
                return <Col key={Math.random()} span={span || 24}>
                    <div className='form-control'>
                        <Flex gap={8} justify='space-between'>
                            <div><TextWithTooltip text={item?.title} characterLimit={20}/></div>
                            <div><DeleteOutlined className="cursor-pointer ml-2 text-danger" onClick={() => { removeSelectedItem(item,keyName) }} style={{ fontSize: "20px" }} /></div>
                        </Flex>
                    </div>
                </Col>
            })
    }

    const removeSelectedItem = (item,keyName) => {
        let temp = [];
        if(keyName==="sourceApp"){
            temp = selectedSourceApp?.filter(f=>(f?.id?.toString()!==item?.id?.toString()));
            setSelectedSourceApp(temp);
            setOpenModal("sourceApp");
        }else if(keyName==="targetApp"){
            temp = selectedTargetApp?.filter(f=>(f?.id?.toString()!==item?.id?.toString()));
            setSelectedTargetApp(temp);
        }else if(keyName==="data"){
            temp = selectedData?.filter(f=>(f?.value?.toString()!==item?.value?.toString()));
            setSelectedData(temp);
        }else if(keyName==="technology"){
            temp = selectedTechnology?.filter(f=>(f?.value?.toString()!==item?.value?.toString()));
            setSelectedTechnology(temp);
        }
    }

    return (
        <div className='integration-page'>
            <IntegrationHeader2 moduleName={moduleName} Buttons={<SaveButton/>}/>
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={{ username: "" }}
                labelCol={{span:8}}
                wrapperCol={{span:16}}
                className='p-2'
            >
                <Flex gap={8}>
                    <div className='w-100 p-lg-3' ref={divHeightRef}>
                        <Form.Item name="username" label="Name" className='mb-2'>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="username" label="Description" className='mb-3'>
                            <Input.TextArea rows={3}/>
                        </Form.Item>
                        <Form.Item name="username" label="Type" className='mb-2'>
                            <Select
                                defaultValue={"Api"}
                                options={[{value:"Api",label:"Api"}]}
                            />
                        </Form.Item>
                    </div>
                    <Divider type="vertical" style={{ height: height,border:"0.8px solid #ddd" }}/>
                    <div className='w-100 p-lg-3'>
                        <Form.Item name="username" label="URL/Endpoint" className='mb-2'>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="username" label="Version" className='mb-2'>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="username" label="Frequency" className='mb-2'>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="username" label="Life Cycle State" className='mb-2'>
                            <Select
                                defaultValue={""}
                            />
                        </Form.Item>
                    </div>
                </Flex>
                <Divider style={{ border:"0.8px solid #ddd",}}  className='mb-3 mt-0'/>
                <Flex gap={8} className='mb-3'>
                    <div className='w-100 p-lg-3'>
                        <fieldset className="fieldset">
                            <label className="fieldset-label">Source App</label>
                            <div className='m-3'>
                                <Row gutter={[16,16]}>
                                    <ListSelectedItems lists={selectedSourceApp} keyName={'sourceApp'}/>
                                    {
                                        selectedSourceApp?.length===0 && 
                                        <div className='text-center'>
                                            <Tooltip title="Source App">
                                                <Button 
                                                    type='primary' 
                                                    shape='circle' 
                                                    icon={<PlusOutlined/>} 
                                                    onClick={()=>{
                                                        setOpenModal('sourceApp');
                                                    }}
                                                    ghost
                                                />
                                            </Tooltip>
                                        </div>
                                    }
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                    <div className='w-100 p-lg-3'>
                        <fieldset className="fieldset">
                            <label className="fieldset-label">Target App</label>
                            <div className='m-3'>
                                <Row gutter={[16,16]}>
                                    <ListSelectedItems lists={selectedTargetApp} keyName={'targetApp'} span={12}/>
                                    <Col span={(selectedTargetApp?.length+1)%12===0?24:12} className='text-center'>
                                        <Tooltip title="Target App">
                                            <Button 
                                                type='primary' 
                                                shape='circle' 
                                                icon={<PlusOutlined/>} 
                                                onClick={()=>{
                                                    setOpenModal('targetApp');
                                                }}
                                                ghost/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                </Flex>
                <Flex gap={8} className='mb-3'>
                    <div className='w-100 p-lg-3'>
                        <fieldset className="fieldset">
                            <label className="fieldset-label">Data</label>
                            <div className='m-3'>
                                <Row gutter={[16,16]}>
                                    <ListSelectedItems lists={selectedData} keyName={'data'} span={12}/>
                                    <Col span={(selectedTargetApp?.length+1)%12===0?24:12} className='text-center'>
                                        <Tooltip title="Data">
                                            <Button 
                                                type='primary' 
                                                shape='circle' 
                                                icon={<PlusOutlined/>}
                                                onClick={()=>{
                                                    setOpenModal('data');
                                                }} 
                                                ghost
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                    <div className='w-100 p-lg-3'>
                        <fieldset className="fieldset">
                            <label className="fieldset-label">Technology</label>
                            <div className='m-3'>
                                <Row gutter={[16,16]}>
                                    <ListSelectedItems lists={selectedTechnology} keyName={'technology'}/>
                                    <Col span={(selectedTargetApp?.length+1)%12===0?24:12} className='text-center'>
                                        <Tooltip title="Technology">
                                            <Button 
                                                type='primary' 
                                                shape='circle' 
                                                icon={<PlusOutlined/>}
                                                onClick={()=>{
                                                    setOpenModal('technology');
                                                }} 
                                                ghost
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                </Flex>
                <ApplicationModal
                    open={openModal==="sourceApp"?true:false}
                    setOpen={setOpenAppliactionModal}
                    Buttons={ApplicationListButton}
                />
                <ApplicationModal
                    open={openModal==="targetApp"?true:false}
                    setOpen={setOpenAppliactionModal}
                    Buttons={ApplicationListButton}
                />
                <ApplicationTypeModal
                    open={selectedApplicationType}
                    setOpen={()=>{
                        setSelectedApplicationType(null)
                    }}
                    setSelectedItem={(data)=>{
                        selectApplicationForm1(data)
                        setSelectedApplicationType(null)
                    }}
                />
                {openModal==="data" && 
                    <IntigrationModalCheckbox
                        title={`Data`}
                        open={openModal==="data"?true:false}
                        setOpen={setOpenAppliactionModal}
                        setSelected={selectApplicationForm1}
                        lists={ default_data_lists?.map(item=>{
                            if(selectedData?.find(f=>f?.value?.toString()!==item?.value?.toString())){
                                item.checked = false;
                            }
                            return item;
                        })}
                    />
                }
                {openModal==="technology" && 
                    <IntigrationModalCheckbox
                        title={`Technology`}
                        open={openModal==="technology"?true:false}
                        setOpen={setOpenAppliactionModal}
                        setSelected={selectApplicationForm1}
                        lists={default_technology_lists?.map(item=>{
                            if(selectedTechnology?.find(f=>f?.value?.toString()!==item?.value?.toString())){
                                item.checked = false;
                            }
                            return item;
                        })}
                    />
                }
            </Form>
        </div>
    )
}

IntegrationAdd.defaultProps = {}

export default IntegrationAdd

