import { Card, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { groupedData } from "../../../utils/Common";

const { Group: CheckboxGroup } = Checkbox;

const CopyExistingScenario = ({
  data = [],
  scenarioItem: item = {},
  setOpenExScenarioModal,
  openExScenarioModal,
  setSelectedItems,
  selectedItems,
}) => {
  const [scenarioItems, setScenarioItems] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      setScenarioItems(groupedData(data));
    }
  }, [data, selectedItems]);

  const handleGroupChange = (name, checkedList) => {
    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [name]: checkedList,
    }));
  };

  return (
    <div className="copyexiting_scenario">
      {Object.keys(scenarioItems).map((name) => {
        const selectedValues = selectedItems?.[name] || [];
        const allSelected =
          scenarioItems[name].length > 0 &&
          selectedValues.length === scenarioItems[name].length;

        const someSelected = selectedValues.length > 0 && !allSelected;

        const scenarioOptions = scenarioItems[name]?.map((item, index) => {
          const { portfolioSettings, id, item_id: itemId } = item;
          const { portfolios } = portfolioSettings;
          const displayName =
            portfolios?.DisplayName || portfolios?.NameInSourceSystem;

          return {
            id,
            value: id,
            label: displayName,
            itemId,
          };
        });

        return (
          <Card
            key={name}
            title={
              <div className="flex items-center justify-between gap-2">
                <span className="h4 text-wrap">{name}</span>
                <Checkbox
                  className="mb-1"
                  checked={allSelected}
                  indeterminate={someSelected && !allSelected}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    handleGroupChange(
                      name,
                      checked ? scenarioOptions.map((item) => item.value) : []
                    );
                  }}
                >
                  <span className="text-xs font-normal">Select All</span>
                </Checkbox>
              </div>
            }
            style={{
              border:`2px solid ${scenarioItems[name][0]?.portfolioSettings?.color}`,
              marginBottom: "20px",
            }}
            headStyle={{
              backgroundColor: `${scenarioItems[name][0]?.portfolioSettings?.color}`, 
              padding: "0px 10px",
            }}
          >
            <div className="overflow-y-auto">
              <CheckboxGroup
                options={scenarioOptions}
                value={selectedValues}
                onChange={(checkedList) => handleGroupChange(name, checkedList)}
                className="flex flex-col gap-2"
                // style={{
                //   display: "flex",
                //   flexDirection: "column",
                //   gap: "10px",
                // }}
              />
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default CopyExistingScenario;
