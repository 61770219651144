import React from 'react';
import plantUml from "./../../assets/plantuml.png"

const IntegrationGrid = props => {
    return (
        <div className='text-center'>
            <img src={plantUml} alt='Intigration View'/>
        </div>
    )
}

IntegrationGrid.defaultProps = {}

export default IntegrationGrid