import React, { useState } from 'react'
import BusinessProcessGrid from './BusinessProcessGrid';
import BusinessProcessHeader from './BusinessProcessHeader';
import BusinessProcessList from './BusinessProcessList';
import "./BusinessProcess.scss";
import { useParams } from 'react-router';

const BusinessProcess = (props) => {
    const [activePage,setActivePage] = useState(2);
    const {moduleName} = useParams();

    return (
        <div className='business-process-master'>
            { 
                activePage === 2 && 
                <BusinessProcessGrid 
                    HeaderContnet = {
                        <BusinessProcessHeader 
                            activePage={activePage}
                            setActivePage={setActivePage}
                        />
                    }
                    moduleName={moduleName}
                />
            }
            { 
                activePage === 1 && 
                <BusinessProcessList 
                    HeaderContnet = {
                        <BusinessProcessHeader 
                            activePage={activePage}
                            setActivePage={setActivePage}
                        />
                    }
                    moduleName={moduleName}
                />
            }
        </div>
    )
}

export default BusinessProcess