import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import UserService from "../../../services/UserService";
const HeaderMenusItems = () => {
	// const userRole = UserService?.hasRole(['app-manager']);
	const userRole = 'admin';
	const headerMenus = [
		{
			'icon': <UserOutlined />,
			'label': "Hello, "+ UserService.getUserFullName().split(' ')[0] + `${UserService.hasRole(['Admin']) ? '(Admin)' : (UserService.hasRole(['Viewer']) ? '(Viewer)' : '')}`,
			'key': '#',
			// 'items': [
			// 	{
			// 		'icon': '',
			// 		'label': 'Logout',
			// 		'key': 'logout',
			// 	},
			// ]
		},
		{
			'icon': <LogoutOutlined />,
			'label': `Logout`,
			'key': 'logout',
		}
	]
	return (headerMenus);
}

export default HeaderMenusItems;
