
import React, { createContext, useContext, useReducer } from "react";
const BusinessProcessContext = createContext();
export const useBusinessProcess = () => useContext(BusinessProcessContext);

const initialState = {
  data: [],
  loading: false,
  error: false,
  allTags: [],
  isEdit: false,
  getOneBusinessProcess: {},
};

const businessProcessReducer = (state, action) => {
  switch (action.type) {
    case "SET_BUSINESS_PROCESS":
      return {
        ...state,
        data: [...state.data, ...action.payload],
      };
    case "ADD_BUSINESS_PROCESS":
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case "UPDATE_BUSINESS_PROCESS":
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case "DELETE_BUSINESS_PROCESS":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.id),
      };
    case "EMPTY_BUSINESS_PROCESS":
      return {
        ...state,
        data: [],
      };
    case "SET_ALL_TAGS":
      return {
        ...state,
        allTags: action.payload,
      };
    case "SET_IS_EDIT":
      return {
        ...state,
        isEdit: action.payload,
      };
    case "SET_ONE_BUSINESS_PROCESS":
      return {
        ...state,
        getOneBusinessProcess: action.payload,
      };
    default:
      return state;
  }
};

export const BusinessProcessProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    businessProcessReducer,
    initialState
  );

  return (
    <BusinessProcessContext.Provider value={{ state, dispatch }}>
      {children}
    </BusinessProcessContext.Provider>
  );
};
