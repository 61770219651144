import React, { useEffect, useState } from "react";
import BasicInformation from "./BasicInformation";
import LifeCycleStage from "./LifeCycleStage";
import AdditionalInformation from "./AdditionalInformation";
import InformationTags from "./InformationTags";
import InformationPeople from "./InformationPeople";
import {
  lifeCycles,
  businessValidationSchema,
  initialBusinessValues,
} from "../../../../validation/businessValidationSchema";
import { Form, Formik } from "formik";
import { Button, message, Form as AntForm } from "antd";
import BusinessApplicationServices from "../../../../services/services/BusinessApplicationService";
import { useApplicationlink } from "../../../../Context/linkProvider";
import {
  applicationsList,
  convertBusinessApplicationKeysToCase,
} from "../../../../utils/businessApplication";
import { useLocation, useNavigate, h } from "react-router";
import { useBusinessApplication } from "../../../../Context/businessApplication";
import { tabsList } from "../../../../utils/linksCommon";
import { Update_Application_ChangeLog } from "../../../../utils/ChangeLogs";
import { SaveRecentlyView_Portfolio } from "../../../../utils/ResentViewedPortfolio";
import DynamicForm from "../../../dynamicForm/DynamicForm";
import { useMenu } from "../../../../Context/MenuProvider";
import {  useParams } from "react-router-dom";
import { changeConfirmLocale } from "antd/es/modal/locale";
import {
  addIsTextToProperties,
  deepFindByName,
  isCamelCase,
  isPascalCase,
  transformText,
} from "../../../../utils/Common";

import { useApplicationChangeLogs } from "../../../../Context/changeLogsProvider"; 
import ChangeLogsServices from "../../../../services/services/ChangeLogsServices";


const TabInformations = ({
  setActiveTabKey,
  toggleTabDisabled,
  id: modelId,
  formItems,
  setFormItems,
  fetchAllPortfolio,
}) => {
  const [applicationForm] = AntForm.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [fieldItems, setFieldItems] = useState({});
  const [initialFormValues, setInitialFormValues] = useState(
    /* initialBusinessValues */ {}
  );
  const { state, dispatch } = useApplicationlink();
  const {
    state: businessApplicationState,
    dispatch: businessApplicationDispatch,
  } = useBusinessApplication();
  const [loading, setLoading] = useState(false);
  const [allTagsList, setAllTagsList] = useState([]);
  const { stateMenu, dispatchMenu } = useMenu();
  const [list, setList] = useState([]);
  const [menuID, setMenuID] = useState();
  let { moduleName } = useParams();

  const { state:stateChangeLog, dispatch:dispatchChangeLog } = useApplicationChangeLogs();

  const formatPropertyName = (name) => {
    // Convert name to lower case and remove leading/trailing spaces
    let formattedName = name.toLowerCase().trim();
    // Replace hyphens and underscores with spaces
    formattedName = formattedName.replace(/[_-]/g, " ");
    // Convert camelCase to Camel Case
    formattedName = formattedName.replace(/([a-z])([A-Z])/g, "$1 $2");
    // Capitalize the first letter of each word
    formattedName = formattedName.replace(/\b\w/g, (c) => c.toUpperCase());
    return formattedName;
  };

  const checkTextCase = (name) => {
    if (isPascalCase(name) || isCamelCase(name)) {
      return name; // If already in PascalCase or camelCase, return unchanged
    } else {
      return transformText(name, "camel"); // Convert to camelCase otherwise
    }
  };
  
  
  const fetchLogData = async ({ moduleName, modelId }) => {
    try {
      console.log("Log.... fetch Datasssss", moduleName, modelId)
       
      const response = await ChangeLogsServices.getChangeLogsList({
        moduleName,
        modelId,
      });

      const newData = response.data.data;
      await dispatchChangeLog({ type: "SET_LINK", payload: newData });
    } catch (error) {
       
      console.error("Error fetching data:", error);
    }
  }; 

const updateValuesForChangeLog=( data, propss)=>{  
  let oldList={};
  Object.keys(data).map((dataItem) => {
    propss.map((section, index) => {
      section?.properties?.length > 0 &&
        section?.properties
          ?.sort((a, b) =>
            formatPropertyName(a.name).localeCompare(formatPropertyName(b.name))
          )
          ?.map((property, index) => {
            if (dataItem === checkTextCase(property.name)) {
              if (
                property?.type === "Single select" ) {
                property?.propertyOptions.map((propvalue) => {
                  if (data[dataItem]==null){
                    oldList[dataItem]="";
                  }else 
                  if (propvalue?.id === data[dataItem]) { 
                    oldList[dataItem]=propvalue?.name;
                  } 
                });
              }
              else if (property?.type === "Multi select") {
                let lst = data[dataItem];
                if (lst?.length) {
                  let propValue = "";
                  for (let i = 0; i < lst?.length; i++) {
                    property?.propertyOptions.map((propvalue) => {
                      if (propvalue?.id === lst[i]) {
                        // console.log("data  Select Name >>>>", propvalue?.name);
                        if (propValue == "") {
                           
                          propValue = propvalue?.name;
                        } else {
                         
                          propValue = propValue + ", " + propvalue?.name;
                        }
                      }
                    });
                  } 
                  oldList[dataItem] = propValue;
                } else {
                  oldList[dataItem] = "";
                }
              }
              else { 
                oldList[dataItem]=data[dataItem]
              }
            }
          });
    });
  });
  return oldList ;
  }

  const handleSubmit = async (values, /*{ setSubmitting }*/) => {
    try {
      setLoading(true);
      // Perform API POST request
      //Old method
      // const newValues = convertBusinessApplicationKeysToCase(values);
      //New method
      const newValues = values;
      
     
      const response =
        modelId === null || modelId === undefined
          ? await BusinessApplicationServices.create(newValues)
          : await BusinessApplicationServices.update(modelId, newValues);
      const newData = response?.data?.id || null;

      


      const oldValues =updateValuesForChangeLog(list,formItems?.portfolioSections);

      const newValuesLog =updateValuesForChangeLog(newValues,formItems?.portfolioSections);
           
      

      modelId === null || modelId === undefined
        ? Update_Application_ChangeLog(
            response.data?.id,
            oldValues,
            newValuesLog,
            "Add",
            applicationsList.businessApplication
          )
        : Update_Application_ChangeLog(
            modelId,
            oldValues,
            newValuesLog,
            "Update",
            applicationsList.businessApplication
          );

          await fetchLogData({moduleName,modelId});

          await fetchData();
      // Handle success response
      modelId === null || modelId === undefined 
      ? message.success("Application created successfully")
      : message.success("Application updated successfully");
      updateBussinessModuleId(newData);
      setTimeout(async () => {
        // setSubmitting(false);

        // Navigate to the TabLinks tab
        // const query = new URLSearchParams({
        //   modelId: newData,
        //   modelName: applicationsList.businessApplication,
        // }).toString();

        // // Replace the current URL with the updated location
        // navigate({ search: query });
        await dispatch({
          type: "SET_BUSINESS_APPLICATION_MODEL",
          //payload: applicationsList.businessApplication,
          //payload: stateMenu?.MenuID?.moduleName,
          payload:moduleName
        });
        await dispatch({ type: "SET_BUSINESSAPPLICATIONID", newData });
        navigate(`/application-list/${moduleName}/${newData}`);
        // setActiveTabKey("tab_connections");
        // toggleTabDisabled("tab_connections", false);
      }, 1000);
    } catch (error) {
      // Handle error
      message.error("Failed to submit form. Please try again.");
      console.error("API Error:", error);
      // setSubmitting(false);
    } finally {
      setLoading(false);
    }
  };

  const updateBussinessModuleId = async (id) => {
    await dispatch({ type: "SET_BUSINESSAPPLICATIONID", payload: id });
    await dispatch({
      type: "SET_BUSINESS_APPLICATION_MODEL",
      //payload: applicationsList.businessApplication,
      payload: moduleName,
    });
    await businessApplicationDispatch({ type: "SET_IS_EDIT", payload: true });
  };
  useEffect(() => {
    setFieldItems(formItems);
  }, [formItems]);

  useEffect(() => {
    setMenuID(stateMenu);
    fetchTagsData();
  }, []);


  // Get all tags
  const fetchTagsData = async () => {
    try {
      setLoading(true);
      const response = await BusinessApplicationServices.getAllTagsList();
      console.log("response-tags-list", response.data.data);
      const newData = response.data.data;
      await businessApplicationDispatch({
        type: "SET_ALL_TAGS",
        payload: newData,
      });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setAllTagsList(businessApplicationState.allTags);
  }, [businessApplicationState.allTags]);

  const updateAllToggleTabEnable = (tabsList) => {
    tabsList?.map((item) => toggleTabDisabled(item?.name, false));
  };

  useEffect(() => {
    if (modelId != null) {
      updateBussinessModuleId(modelId);
      updateAllToggleTabEnable(tabsList);
    }
  }, [modelId, businessApplicationState.isEdit]);

  useEffect(() => {
    console.log("isEdit", businessApplicationState.isEdit);

    if (modelId && businessApplicationState.isEdit) {
      fetchData();
    }
  }, [modelId, businessApplicationState.isEdit]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await BusinessApplicationServices.getById(modelId);
 
      console.log("response......", response);
      setList(response.data);

      //Add Recent View portfolio
      if (stateMenu?.MenuID != undefined) {
        SaveRecentlyView_Portfolio(
          stateMenu.MenuID.MenuName,
          window.location.href,
          response?.data?.DisplayName,
          response?.data?.id,
          stateMenu.MenuID.color
        );
      }
 
      const newData = response.data;
      //Old method
      // const convertedResponse =
      //   response.data &&
      //   convertBusinessApplicationKeysToCase(response.data, false);
      // New Method
      const convertedResponse = (response.data && response.data) || {};
      const newResponse = {
        applicationName: convertedResponse?.nameInSourceSystem,
        appId: convertedResponse?.referenceID,
        lifeCycleStage: convertedResponse?.status,
        ...convertedResponse,
      };
      console.log("convertedResponse", newResponse);
      await businessApplicationDispatch({
        type: "SET_ONE_BUSINESS_APPLICATION",
        payload: newResponse || {},
      });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (
      businessApplicationState.isEdit &&
      modelId &&
      businessApplicationState.getOneBusinessApplication
    ) {
      console.log(
        "businessApplicationState.getOneBusinessApplication",
        businessApplicationState.getOneBusinessApplication
      );
      setInitialFormValues((prev) => ({
        ...prev,
        ...businessApplicationState.getOneBusinessApplication,
      }));
      // setInitialFormValues(businessApplicationState.getOneBusinessApplication);
    }
  }, [businessApplicationState.getOneBusinessApplication]);

  console.log("formItems-application", formItems);

  return (
    <>
      <DynamicForm
        data={fieldItems?.portfolioSections || []}
        form={applicationForm}
        formData={initialFormValues}
        labelCol={8}
        wrapperCol={16}
        createAndUpdate={handleSubmit}
      />
      {/* <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        validationSchema={businessValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, initialValues }) => {
          console.log("form-values", values, initialValues);
          return (
            <Form>
              <BasicInformation />
              <hr />
              <LifeCycleStage
                options={lifeCycles.map((lifeCycle) => ({
                  label: lifeCycle,
                  value: lifeCycle,
                }))}
              />
              <hr />
              <AdditionalInformation />
              <hr />
              <InformationTags tagOptions={allTagsList} />
              <hr />
              ////<InformationPeople />
              <Button
                type="primary"
                htmlType="submit"
                className="btn btn-theme py-1"
                ghost
                disabled={loading && isSubmitting}
              >
                Submit
              </Button>
            </Form>
          );
        }}
      </Formik> */}
    </>
  );
};

export default TabInformations;
