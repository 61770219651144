import "./PortfolioSingleEntry.scss";
import React, { useEffect, useState, useRef } from "react";
import { Tabs,  message } from "antd";
import TabChangeLogAlerts from "./portfolioTabs/tabchangelogalerts/TabChangeLogAlerts";
import TabComments from "./portfolioTabs/tabcomments/TabComments";
import TabDiagrams from "./portfolioTabs/tabdiagrams/TabDiagrams";
import TabInformations from "./portfolioTabs/tabinformations/TabInformations";
import TabLinks from "./portfolioTabs/tablinks/TabLinks";
import TabPane from "antd/es/tabs/TabPane";
import { useTabContext } from "./TabProvider";
import { useLocation, useParams } from "react-router";
import TabConnections from "../businessCapabilityEntry/BCETabs/TabBCConnections/TabConnections";
import { useBusinessApplication } from "../../Context/businessApplication";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import usePortfolioFetcher from "../../hooks/usePortfolioFetcher";
import { PageTitleHeading, addIsTextToProperties, deepFindByName } from "../../utils/Common";
import { staticSettingPortfolioList } from "../../utils/settingCommon";
import { Link } from "react-router-dom";
import {useMenu} from "../../Context/MenuProvider";
import LoadingBar from 'react-top-loading-bar'
import { GoBack } from "../../utils/GoBackHistory";

const PortfolioSingleEntry = () => {
  const ref = useRef(null)
  const location = useLocation();
  const { loading, fetchPortfolio } = usePortfolioFetcher();
  const { stateMenu, dispatchMenu } = useMenu();

  const {
    state: businessApplicationState,
    dispatch: businessApplicationDispatch,
  } = useBusinessApplication();
  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();
  const [activeTabKey, setActiveTabKey] = useState("tab_information");
  const { tabDisabled, toggleTabDisabled } = useTabContext();
  const { id=null,moduleName=null } = useParams();
  const [portfolioId,setPortfolioId] = useState("");

  const [title, setTitle] = useState("Add New Application");
  const [formItems, setFormItems] = useState({});

  //Get all settings
  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  const handleTabChange = (key) => {
    console.log("key", key);
    setActiveTabKey(key);
  };

  useEffect(()=>{
     if(!id || id?.toLowerCase()==="add")
     {
      const newResponse = {
        applicationName:"Add New Application",
         }; 
      businessApplicationDispatch({
        type: "SET_ONE_BUSINESS_APPLICATION",
        payload: newResponse || {},
      });
      setPortfolioId(null);
     }else{
      setPortfolioId(id);
     }      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  

  useEffect(() => {
    if (businessApplicationState.getOneBusinessApplication) {
      setTitle(businessApplicationState.getOneBusinessApplication.DisplayName);
    } 
  }, [businessApplicationState.getOneBusinessApplication]);

  
  useEffect(() => {
    fetchAllPortfolio();
    
    if(location?.state?.activeTabKey){
      handleTabChange(location?.state?.activeTabKey);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.continuousStart();
    }
    if (portfolioSettingState?.data?.length) {
      const portfolioValues = deepFindByName(
        portfolioSettingState?.data,
        staticSettingPortfolioList?.application,
        false
      );
      console.log(
        "portfolioValues",
        portfolioValues,
        addIsTextToProperties(portfolioValues)
      );
      setFormItems(addIsTextToProperties(portfolioValues));
    }
    if (ref.current) {
      ref.current.complete();
    }
  }, [portfolioSettingState?.data]);

  //   const PortfolioSingleTabs = [
  //     {
  //       label: `Information`,
  //       key: "tab_information",
  //       children: <TabInformations />,
  //     },
  //     {
  //       label: `Connections`,
  //       key: "tab_connections",
  //       children: <TabConnections />,
  //     },
  //     {
  //       label: `Links`,
  //       key: "tab_links",
  //       children: <TabLinks />,
  //     },
  //     {
  //       label: `Diagram`,
  //       key: "tab_diagram",
  //       children: <TabDiagrams />,
  //     },
  //     {
  //       label: `Comments`,
  //       key: "tab_comments",
  //       children: <TabComments />,
  //     },
  //     {
  //       label: `Survey`,
  //       key: "tab_survey",
  //       children: <TabSurveys />,
  //     },
  //     {
  //       label: `Change Log/Alerts`,
  //       key: "tab_logalerts",
  //       children: <TabChangeLogAlerts />,
  //     },
  //   ];

  const tabsProps = {
    setActiveTabKey,
    handleTabChange,
    toggleTabDisabled,
    tabDisabled,
    id:portfolioId,
    moduleName,
    formItems,
    setFormItems,
    fetchAllPortfolio,
  };
  console.log("useParams-id", id);

  const PortfolioSingleTabs = [
    {
      key: "tab_information",
      tab: "Information",
      content: <TabInformations {...tabsProps} />,
    },
    {
      key: "tab_connections",
      tab: "Relations",
      content: <TabConnections {...tabsProps} />,
    },
    { key: "tab_links", tab: "Links", content: <TabLinks {...tabsProps} /> },
    {
      key: "tab_diagram",
      tab: "Diagram",
      content: <TabDiagrams {...tabsProps} titleName={title}/>,
    },
    {
      key: "tab_comments",
      tab: "Comments",
      content: <TabComments {...tabsProps} />,
    },
    // {
    //   key: "tab_survey",
    //   tab: "Survey",
    //   content: <TabSurveys {...tabsProps} />,
    // },
    {
      key: "tab_logalerts",
      tab: "Change Log/Alerts",
      content: <TabChangeLogAlerts {...tabsProps} />,
    },
  ];

  return (
    <><LoadingBar color='blue' ref={ref} /> 
    <div className=" mb-5">
      <div className="d-flex justify-content-between align-items-center">
        <PageTitleHeading 
          text={
            ((()=>{
              if(title){
                return 'Application: '+title;
              }else{
                return `Add New Application`;
              }
            })())
          }
        />
        <div className="my-3">
          <GoBack className={'pull-right ml-2'}/>
        </div>
      </div>
      <Tabs
        type="card"
        className="portfolia-single-entry"
        // items={PortfolioSingleTabs}
        activeKey={activeTabKey}
        onChange={handleTabChange}
      >
        {PortfolioSingleTabs.map((tab) => (
          <TabPane tab={tab.tab} key={tab.key} disabled={tabDisabled[tab.key]}>
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </div>
    </>
  );
};

export default PortfolioSingleEntry;
