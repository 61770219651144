import React, { useEffect, useRef, useState } from 'react';
import { Card, Typography, Button, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
import {  useParams } from "react-router-dom";
import BPMLevel2 from './BPMLevel2';
const baseStyle = {
    justifyContent: 'center',
    margin:'10px',
}

const BPMLevel1 = (props) => {
    const [hovered, setHovered] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const { moduleName:moduleNamePath } = useParams();
    
    const navigate = useNavigate();
    let parentName;

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdown(false);
            }
        }
 
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
         
    }, [dropdownRef]);

    return <>
        <Card
            size="small"
            style={{ ...baseStyle, backgroundColor: props?.backgroundColor}}
        >
            <div className='d-flex align-items-center justify-between'>
                <Typography.Title
                    level={5}

                    className={props?.backgroundColor ? 'text-white title-level-1 cursor-pointer' : 'title-level-1 cursor-pointer'}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    onClick={() => { navigate('/business-process/'+moduleNamePath+'/'+props?.data?.id   )}}
                > {props?.data?.LevelID} {props?.data?.DisplayName}


                </Typography.Title>
                <div className='level-btn-1'>
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle p-0" type="button" onClick={() => {
                            setDropdown(!dropdown);
                        }}>
                            <i className="fa fa-ellipsis-v edit" />
                        </button>
                        { 
                            dropdown && (
                                <ul className="dropdown-menu" ref={dropdownRef}>
                                    <li><Button onClick={() => { props?.setItemDelete(props?.data, { type: 'delete' }); }} className='btn-icon'><i className="fa fa-trash-o delete" /></Button></li>
                                    <li><Button onClick={() => { navigate('/business-process/'+moduleNamePath+'/'+props?.data?.id  )}} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li>
                                        
                                </ul>
                            )
                        }
                    </div>
                </div>
            </div>

            {
                props?.data?.child?.map((item, index) => {
                    return <div  key={Math.random()}><BPMLevel2 {...props} defaultActiveKey={index === 0 ? 1 : 0} index={index} data={item}  underLevel={props?.data.DisplayName} underLevelID={props?.data.LevelID} /></div>
                })
            }
            <br />
            <br />
            <Tooltip title="Level 2">
                <Button shape="circle" className='btn-level-2' icon={<PlusOutlined />} onClick={() => {
                    navigate('/business-process/'+ moduleNamePath+'/add', { state: {Level:2,ParentID: props?.data?.id, ParentName: props?.data?.DisplayName,underLevel: props?.data?.DisplayName, levelID: props?.data?.LevelID } })
                } }></Button>
            </Tooltip>
        </Card>
    </>;
}

export default BPMLevel1;