import React, { useState,useEffect } from "react";
import DiagramList from "./DiagramList";
import { useAppndiagram } from "../../../../Context/diagramProvider";
import diagramServices from "../../../../services/services/diagramServices";
import DiagramScreen from "./DiagramScreen";
import { Divider, Spin } from "antd";

const TabDiagrams = ({ setActiveTabKey, toggleTabDisabled, id: modelId, moduleName: moduleName,titleName ,...props}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const { state, dispatch } = useAppndiagram();

    const fetchData = async ({ moduleName, moduleID }) => {
        try {
          setLoading(true);
          const response = await diagramServices.getListByModule({
            moduleName,
            moduleID,
          });
          const newData = response.data.data;
          setLoading(false)
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
      };

    // get diagams upto 20 on load
    useEffect(() => {
        setData();
      }, []);

    useEffect(() => {
        fetchData({
            moduleName: moduleName,
            moduleID: modelId,
          });
    }, [modelId]);

    return (
      <Spin spinning={loading}>
        <DiagramScreen titleName={titleName || moduleName} moduleName={moduleName} modelId={modelId} setLoading={setLoading} {...props}/>
        {/* <DiagramList
            data={data}
            moduleName={moduleName}
        /> */}
      </Spin>
    )
}
export default TabDiagrams;