import React from "react";
import { Dropdown } from "antd";

const ExportBtn = (props) => {
  const { setExportGraphFormat } = props;

  const items = [
    {
      label: <span onClick={() => setExportGraphFormat("jpeg")}>jpeg</span>,
      key: "1",
    },
    {
      label: <span onClick={() => setExportGraphFormat("png")}>png</span>,
      key: "2",
    },
    {
      label: <span onClick={() => setExportGraphFormat("svg")}>svg</span>,

      key: "3",
    },
    {
      label: <span onClick={() => setExportGraphFormat("csv")}>csv</span>,
      key: "4",
    },
    {
      label: <span onClick={() => setExportGraphFormat("xlsx")}>xlsx</span>,
      key: "5",
    }
  ];

  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
        placement="bottom"
        className="export-btn"
      >
        <span>{"\uf019"}</span>
      </Dropdown>
    </>
  );
};

export default ExportBtn;
