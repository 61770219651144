import AdminLayout from '../components/layouts/AdminLayout';
import BusinessCapabilityEntry from '../components/businessCapabilityEntry/BusinessCapabilityEntry'
const BusinessCapabilityEntryPage=()=>{

   return (
      <>
          <BusinessCapabilityEntry/>
      </>
  );
}

export default BusinessCapabilityEntryPage;
