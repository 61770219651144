import AdminLayout from '../components/layouts/AdminLayout';
import BusinessProcessEntry from '../components/businessProcess/BusinessProcessEntry'
const BusinessProcessEntryPage=()=>{

   return (
      <>
          <BusinessProcessEntry/>
      </>
  );
}

export default BusinessProcessEntryPage;
