import React from 'react';
import { 
    Card,
} from "antd";
import LGContentRow from './LGContentRow';

// const cardProps = {
//     'className' : 'text-center',
//     'style' : { width: 200,float:'left',marginRight:'10px' },
//     'headStyle' : {border: '1px solid #ddd',padding:0,minHeight:'30px',textOverflow: 'ellipsis'},
//     'size':`small`,
// }
const LGContent = (props) => {
    return <div>
        <table className='table table-bordered level-grouping-table'>
            <tbody>
                {
                    Object.keys(props?.lgData).length !== 0 && Object.entries(props?.lgData)?.map((element)=>{
                        if(element[1] && element[0]){
                            return (<LGContentRow rowData={element[1]} heading={element[0]} key={Math.random()} {...props}/>);
                        }else{
                            return null;
                        }
                    })
                }
                {/* <tr>
                    <th>
                        <span className='rotate-90'>Consolidation & Spare Parts</span>
                    </th>
                    <td>
                        <Card 
                            title="Consolidator" 
                            {...cardProps}
                            bodyStyle={{backgroundColor: props?.colors?.endOfLife,color:'#fff',paddingTop:'5px',paddingBottom:'5px',}}
                        >
                            <div>Newcast</div>
                            <div>(<small>App-751</small>)</div>
                        </Card>
                        <Card 
                            title="Spare Part Management" 
                            {...cardProps}
                            bodyStyle={{backgroundColor: props?.colors?.phaseIn,color:'#fff',paddingTop:'5px',paddingBottom:'5px',}}
                        >
                            <div>Plus</div>
                            <div>(<small>App-573</small>)</div>
                        </Card>
                        <Card 
                            title="Spare Parts" 
                            {...cardProps}
                            bodyStyle={{backgroundColor: props?.colors?.active,color:'#fff',paddingTop:'5px',paddingBottom:'5px',}}
                        >
                            <div>Newcast</div>
                            <div>(<small>App-573</small>)</div>
                        </Card>
                        
                    </td>
                </tr>
                <tr>
                    <th><span className='rotate-90 mt-2'>Maintainance</span></th>
                    <td>
                        <Card 
                            title="Consolidator" 
                            {...cardProps}
                            bodyStyle={{backgroundColor: props?.colors?.phaseOut,color:'#fff',paddingTop:'5px',paddingBottom:'5px',}}
                        >
                            <div>Newcast</div>
                            <div>(<small>App-751</small>)</div>
                        </Card>
                        <Card 
                            title="Vibration Monitoring"
                            {...cardProps}
                            bodyStyle={{backgroundColor: props?.colors?.active,color:'#fff',paddingTop:'5px',paddingBottom:'5px',}}
                        >
                            <div>ISVM</div>
                            <div>(<small>App-4190</small>)</div>
                        </Card>
                    </td>
                </tr>
                <tr>
                    <th><span className='rotate-90 '>Finance</span></th>
                    <td></td>
                </tr>
                <tr>
                    <th><span className='rotate-90 '>Production Control</span></th>
                    <td></td>
                </tr>
                <tr>
                    <th><span className='rotate-90 '>Peopple Experience</span></th>
                    <td></td>
                </tr>
                <tr>
                    <th><span className='rotate-90 '>Order Planning Fulfillment & Scheduling</span></th>
                    <td></td>
                </tr> */}
            </tbody>
        </table>
    </div>;
}

export default LGContent;