import {
  Button,
  Col,
  Row,
  message,
  Modal,
  Form,
  Input,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import CustomModal from "../../../custom/CustomModal";
import { applicationsList } from "../../../../utils/businessApplication";
import linkServices from "../../../../services/services/LinkService";
import { useApplicationlink } from "../../../../Context/linkProvider";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { PlusOutlined } from "@ant-design/icons";
import linkTypeServices from "../../../../services/services/LinkTypeService";
 

const AddLinks = ({ businessApplicationId, moduleName }) => {
  console.log("businessApplicationId...", moduleName);

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const { state, dispatch } = useApplicationlink();
   
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const [formModalData, setFormModalData] = useState({
    linkTypeID: null,
    name: "",
    URL: "",
    Description: "",
    moduleID: businessApplicationId || null,
    moduleName: moduleName,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [formLinkType] = Form.useForm();
  const [linkTypesOpen, setLinkTypesOpen] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    console.log("formModalData", formModalData);
  }, [formModalData]);

  useEffect(() => {
    getLinkTypeList();
  }, []);

  const getLinkTypeList = async () => {
    const list = await linkTypeServices.getList();

    const selectListData = [];

    list?.data?.data.map((item) => {
      selectListData.push({ value: item.id, label: item.linkTypeName });
    });

    setList(selectListData);
  };

  const handleCreate = async (values, setSubmitting) => {
    try {
      values.name = "ID" + values.linkTypeID;
      values.moduleID = businessApplicationId;

      // Handle form submission
      setLoading(true);
      const response = await linkServices.create(values);
      const newData = response?.data || null;

      // Handle success response
      message.success("Link added successfully");

      await dispatch({ type: "ADD_LINK", payload: newData });
      setVisibleModal(false);
      setTimeout(() => {
        setSubmitting(false);
        setLoading(false);

        // navigate("/application-list");
      }, 1000);
    } catch (error) {
      // Handle error
      message.error("Failed to submit form. Please try again.");
      setSubmitting(false);
      setLoading(false);
    } finally {
      // setLoading(false);
      // setVisibleModal(false);
    }
  };

  // const handleCreate = async (values) => {

  //   formLinkType
  //           .validateFields()
  //           .then(async (values) => {
  //               console.log("Values", values);
  //                try {
  //                   setFormModalData({
  //                       linkTypeID:values.linkTypeID,
  //                       name: values.linkTypeID,
  //                       URL: values.URL,
  //                       Description:values.description,
  //                       moduleID: businessApplicationId || null,
  //                       moduleName: applicationsList.businessApplication,
  //                 });

  //                 let objData={
  //                   linkTypeID:values.linkTypeID,
  //                   name: 'ID'+values.linkTypeID,
  //                   URL: values.URL,
  //                   Description:values.description,
  //                   moduleID: businessApplicationId || null,
  //                   moduleName: applicationsList.businessApplication};

  //                   console.log("ID", values?.linkTypeID);
  //                   setLoading(true);
  //                  const response = await linkServices.create(objData);
  //                  if (response.status==201)
  //                  {
  //                     message.success(response?.statusText)
  //                     setVisibleModal(false);
  //                     setLoading(false);
  //                  }
  //                 console.log("Response",response);

  //               } catch (error) {
  //                   message.error(error?.message)
  //               }

  //           })
  //           .catch((info) => {
  //               console.log("Validate Failed:", info);
  //           });

  // };

  const validationSchema = () => {
    return Yup.object({
      name: Yup.string().required("Name is required"),
      URL: Yup.string()
        .url("Please enter a valid URL")
        .required("URL is required"),
      moduleID: Yup.number().integer().nullable(),
      moduleName: Yup.string(),
    });
  };

  const handleCancel = () => {
    setVisibleModal(false);
    setLoading(false);
  };

  return (
    <div className="mt-4">
      <Row gutter={16} className="mb-2">
        <Col span={24}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setVisibleModal(!visibleModal);
            }}
          >
            Link
          </Button>
        </Col>
      </Row>
      {/* <Button
        type="primary"
        className="bg-[#2a609d]"
        ghost
        onClick={() => setVisibleModal(true)}
      >
        + Add New Link
      </Button> */}

      <CustomModal
        visible={visibleModal}
        onCancel={handleCancel}
        onOk={handleCreate}
        okText="Save"
        cancelText="Cancel"
        closable={false}
        loading={loading}
        initialValues={formModalData}
        list={list}
      />
    </div>
  );
};

export default AddLinks;
