import { useNavigate } from "react-router-dom";

const useDynamicNavigate = () => {
  const navigate = useNavigate();

  const navigateTo = (link, openInNewTab = false) => {
    if (openInNewTab) {
      window.open(link, "_blank");
    } else {
      navigate(link);
    }
  };

  return navigateTo;
};

export default useDynamicNavigate;
