import React from 'react'
import FilterContent from '../applicationlist/filter/FilterContent';

const IntegrationFilterContent = props => {
    return (
        <FilterContent {...props}/>
    );
}

IntegrationFilterContent.defaultProps = {}

export default IntegrationFilterContent