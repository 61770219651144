import React from "react";
import {
  Typography,
  Row,
  Col,
  Input,
  Divider,
  Button,
  Modal,
  Form,
  Tag,
  Select,
  message,
} from "antd";
import { useState, useEffect } from "react";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import businessCapabilityServices from "../../../../services/services/BusinessCapabilityService";

import { UpdateChangeLog } from "../../../../utils/ChangeLogs";
import { forEach } from "jszip";
import { useBusinessCapability } from "../../../../Context/businessCapability";
import { tabsList } from "../../../../utils/linksCommon";
import { applicationsList } from "../../../../utils/businessApplication";
import { useApplicationlink } from "../../../../Context/linkProvider";
import DynamicForm from "../../../dynamicForm/DynamicForm";
import usePortfolioFetcher from "../../../../hooks/usePortfolioFetcher";
import { usePortfolioSetting } from "../../../../Context/portfolioSettingProvider";
import {
  addIsTextToProperties,
  deepFindByName,
  isCamelCase,
  isPascalCase,
  transformText,
} from "../../../../utils/Common";
import { staticSettingPortfolioList } from "../../../../utils/settingCommon";
import {useMenu} from "../../../../Context/MenuProvider";
import { SaveRecentlyView_Portfolio } from "../../../../utils/ResentViewedPortfolio";
import { compileString } from "sass";
import { useApplicationChangeLogs } from "../../../../Context/changeLogsProvider"; 
import ChangeLogsServices from "../../../../services/services/ChangeLogsServices";

import linkServices from "../../../../services/services/LinkService";

const { TextArea } = Input;


const sections = [
  {
    id: 1,
    name: "Basic information list",
    portfolioId: 1,
    isDeleted: false,
    createdAt: "2024-04-26T06:45:55.425Z",
    updatedAt: "2024-04-26T06:45:55.425Z",
    properties: [
      {
        id: 20,
        name: "text",
        type: "Free text",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: true,
        isRestricted: false,
        createdAt: "2024-04-26T10:13:05.366Z",
        updatedAt: "2024-04-26T10:13:05.366Z",
        propertyOptions: [],
        isText: false,
        isDisable: false,
      },
      {
        id: 34,
        name: "testing",
        type: "Date",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: true,
        isRestricted: false,
        createdAt: "2024-04-30T05:38:18.660Z",
        updatedAt: "2024-04-30T05:38:18.660Z",
        propertyOptions: [],
        isText: false,
        isDisable: false,
      },
      {
        id: 42,
        name: "NameInSourceSystem",
        type: "Free text",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: true,
        isRestricted: false,
        createdAt: "2024-04-26T07:19:15.934Z",
        updatedAt: "2024-04-30T06:28:19.074Z",
        propertyOptions: [],
        isText: false,
        isDisable: false,
      },
      {
        id: 43,
        name: "DisplayName",
        type: "Free text",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: true,
        isRestricted: false,
        createdAt: "2024-04-26T07:19:15.934Z",
        updatedAt: "2024-04-30T06:28:19.074Z",
        propertyOptions: [],
        isText: false,
        isDisable: false,
      },
      {
        id: 44,
        name: "ParentID",
        type: "Numeric",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: false,
        isRestricted: false,
        createdAt: "2024-04-26T07:19:15.934Z",
        updatedAt: "2024-04-30T06:28:19.074Z",
        propertyOptions: [],
        isText: false,
        isDisable: false,
      },
      {
        id: 45,
        name: "SourceID",
        type: "Numeric",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: false,
        isRestricted: false,
        createdAt: "2024-04-26T07:19:15.934Z",
        updatedAt: "2024-04-30T06:28:19.074Z",
        propertyOptions: [],
        isText: false,
        isDisable: false,
      },
      {
        id: 46,
        name: "Level",
        type: "Free text",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: true,
        isRestricted: false,
        createdAt: "2024-04-26T07:19:15.934Z",
        updatedAt: "2024-04-30T06:28:19.074Z",
        propertyOptions: [],
        isText: true,
        isDisable: true,
      },
      {
        id: 47,
        name: "Description",
        type: "Long text",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: true,
        isRestricted: false,
        createdAt: "2024-04-26T07:19:15.934Z",
        updatedAt: "2024-04-30T06:28:19.074Z",
        propertyOptions: [],
        isText: false,
        isDisable: false,
      },
      {
        id: 48,
        name: "LevelID",
        type: "Free text",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: true,
        isRestricted: false,
        createdAt: "2024-04-26T07:19:15.934Z",
        updatedAt: "2024-04-30T06:28:19.074Z",
        propertyOptions: [],
        isText: false,
        isDisable: false,
      },
      {
        id: 49,
        name: "ReferenceID",
        type: "Free text",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: false,
        isRestricted: false,
        createdAt: "2024-04-26T07:19:15.934Z",
        updatedAt: "2024-04-30T06:28:19.074Z",
        propertyOptions: [],
        isText: false,
        isDisable: false,
      },
      {
        id: 35,
        name: "new one",
        type: "Single select",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: true,
        isRestricted: false,
        createdAt: "2024-04-30T05:38:43.791Z",
        updatedAt: "2024-04-30T08:00:07.669Z",
        propertyOptions: [
          {
            id: 18,
            name: "option 1",
            propertyId: 35,
            portfolioId: 1,
            isDeleted: false,
            createdAt: "2024-04-30T08:00:07.868Z",
            updatedAt: "2024-04-30T08:00:07.868Z",
          },
          {
            id: 19,
            name: "option 2",
            propertyId: 35,
            portfolioId: 1,
            isDeleted: false,
            createdAt: "2024-04-30T08:00:07.868Z",
            updatedAt: "2024-04-30T08:00:07.868Z",
          },
          {
            id: 20,
            name: "option 3",
            propertyId: 35,
            portfolioId: 1,
            isDeleted: false,
            createdAt: "2024-04-30T08:00:07.868Z",
            updatedAt: "2024-04-30T08:00:07.868Z",
          },
        ],
        isText: false,
        isDisable: false,
      },
      {
        id: 36,
        name: "new two",
        type: "Multi select",
        sectionId: 1,
        portfolioId: 1,
        isDeleted: false,
        isRequired: true,
        isRestricted: false,
        createdAt: "2024-04-30T05:38:43.791Z",
        updatedAt: "2024-04-30T08:00:07.669Z",
        propertyOptions: [
          {
            id: 21,
            name: "option 1",
            propertyId: 36,
            portfolioId: 1,
            isDeleted: false,
            createdAt: "2024-04-30T08:00:07.868Z",
            updatedAt: "2024-04-30T08:00:07.868Z",
          },
          {
            id: 22,
            name: "option 2",
            propertyId: 36,
            portfolioId: 1,
            isDeleted: false,
            createdAt: "2024-04-30T08:00:07.868Z",
            updatedAt: "2024-04-30T08:00:07.868Z",
          },
          {
            id: 23,
            name: "option 3",
            propertyId: 36,
            portfolioId: 1,
            isDeleted: false,
            createdAt: "2024-04-30T08:00:07.868Z",
            updatedAt: "2024-04-30T08:00:07.868Z",
          },
        ],
        isText: false,
        isDisable: false,
      },
    ],
  },
  {
    id: 2,
    name: "External links",
    portfolioId: 1,
    isDeleted: false,
    createdAt: "2024-04-26T06:46:22.929Z",
    updatedAt: "2024-04-26T06:46:22.929Z",
    properties: [],
  },
  {
    id: 15,
    name: "testing",
    portfolioId: 1,
    isDeleted: false,
    createdAt: "2024-04-30T05:36:47.333Z",
    updatedAt: "2024-04-30T05:36:47.333Z",
    properties: [],
  },
];

const TabBCBasicInformation = ({
  setActiveTabKey,
  toggleTabDisabled,
  id: modelId,
  setBusinessCapabilityData,
}) => {
  const navigate = useNavigate();
  const { loading, fetchPortfolio } = usePortfolioFetcher();
  const { stateMenu, dispatchMenu } = useMenu();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const leveldata = location.state;
  const [list, setList] = useState([]);
  const [newList, setNewList] = useState([]);
  
  const [formData, setFormData] = useState({});
  const [parentList, setParentlist] = useState({});
  const [field_OldData, setfield_OldData] = useState({
    displayName: "",
    LevelId: "",
    description: "",
  });
  const [level, setLevel] = useState();
  const [parentLevel, setParentLevel] = useState();
  const [levelID, setLevelID] = useState();
  const { state, dispatch } = useBusinessCapability();
  const { state: PortfolioSettingState, dispatch: PortfolioSettingDispatch } =
    usePortfolioSetting();
    const { state:stateChangeLog, dispatch:dispatchChangeLog } = useApplicationChangeLogs();

  const [formItems, setFormItems] = useState({});
  let { moduleName } = useParams();

  const { state: businessLinkState, dispatch: businessLinkDispatch } =
    useApplicationlink();

     

    const fetchData = async ( moduleName1, modelId1 ) => {
      try {
        
        const objParam ={moduleName:moduleName1, modelId:modelId1};
 
        const response = await ChangeLogsServices.getChangeLogsList( 
          objParam);

        const newData = response.data.data;
  
        await dispatchChangeLog({ type: "SET_LINK", payload: newData });
      } catch (error) {
         
        console.error("Error fetching data:", error);
      }
    }; 

    const fetchData_Link = async ({ moduleName, moduleID }) => {
      try { 
        const response = await linkServices.getListByModule({
          moduleName,
          moduleID,
        });
  
        const newData = response.data.data; 
        
        console.log("Awadhesh .......................newData", newData)

        await businessLinkDispatch({ type: "SET_LINK", payload: newData });
        
      } catch (error) {
        console.error("Error fetching Link data:", error);
      }
    };

    // useEffect(()=>{ 
    //   if (!stateMenu?.MenuID || stateMenu?.MenuID==undefined)
    //   { 
    //     const menuDetail= JSON.parse(sessionStorage.getItem("sessionMenuID")); 
        
    //     dispatchMenu({
    //       type: "MenuDetail",
    //       payload: menuDetail,
    //     }); 
    //   }
    // },[stateMenu])

    const formatPropertyName = (name) => {
      // Convert name to lower case and remove leading/trailing spaces
      let formattedName = name.toLowerCase().trim();
      // Replace hyphens and underscores with spaces
      formattedName = formattedName.replace(/[_-]/g, " ");
      // Convert camelCase to Camel Case
      formattedName = formattedName.replace(/([a-z])([A-Z])/g, "$1 $2");
      // Capitalize the first letter of each word
      formattedName = formattedName.replace(/\b\w/g, (c) => c.toUpperCase());
      return formattedName;
    };

    const checkTextCase = (name) => {
      if (isPascalCase(name) || isCamelCase(name)) {
        return name; // If already in PascalCase or camelCase, return unchanged
      } else {
        return transformText(name, "camel"); // Convert to camelCase otherwise
      }
    };
  const updateBussinessModuleId = async (id) => {
    await businessLinkDispatch({
      type: "SET_BUSINESSAPPLICATIONID",
      payload: id,
    });
    await businessLinkDispatch({
      type: "SET_BUSINESS_APPLICATION_MODEL",
      //payload: applicationsList.businessCapability,
      payload: moduleName,
    });
    await dispatch({ type: "SET_IS_EDIT", payload: true });
  };

  //Get all settings
  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(PortfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchAllPortfolio();
  }, []);

  useEffect(()=>{
     const menuDetail= sessionStorage.getItem("sessionMenuID");
  })

  // useEffect(()=>{
  //   console.log("Change Log.....List", list)
  //   console.log("Change Log..... NewList", newList)
  // },[])

  useEffect(() => {
    if (PortfolioSettingState?.data?.length) {
      const portfolioValues = deepFindByName(
        PortfolioSettingState?.data,
        staticSettingPortfolioList?.businessCapability,
        false
      );

       setFormItems(addIsTextToProperties(portfolioValues, "Level"));
        
    }
  }, [PortfolioSettingState?.data]);

  useEffect(() => {
     
    if (modelId != null) {
      updateBussinessModuleId(modelId);
    } else {
      dispatch({ type: "SET_IS_EDIT", payload: false });
 

      //Cheking Level Data to Populate the Parent Detail
      if (leveldata != null) {
        console.log("Level Data", leveldata);
        if (leveldata.Level == "1") {
          setLevel("Level 1");
          setParentLevel("");
          setLevelID("");
        } else if (leveldata.Level == "2") {
          setLevel("Level 2");
          setParentLevel(leveldata.underLevel);
          setLevelID(leveldata.levelID);
        } else if (leveldata.Level == "3") {
          setLevel("Level 3");
          setParentLevel(leveldata.underLevel);
          setLevelID(leveldata.levelID);
        }
      } else {
        setLevel("Level 1");
        setParentLevel("");
        setLevelID("");
      }
    }
  }, [modelId, state.isEdit]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
     
    console.log("formData.....", formData);

  }, [formData]);

  useEffect(() => {
    //console.log("isEdit", state.isEdit);

    if (modelId && state.isEdit) {
      getData();
    }
  }, [modelId, state.isEdit]);

  // useEffect(() => {
  //     getData();
  // }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //console.log("Under Level Top", leveldata?.underLevel );

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    );
  };

  const create_and_Updatd = () => {
    form
      .validateFields()
      .then(async (values) => {
        let obj = {
          ...values,
          ReferenceID: 413,
          //   NameInSourceSystem: "",
          //   DisplayName: values?.name,
          Attributes: [],
          ParentID: 0,
          // SourceID: 1,
          // Level: "L1",
          //   Description: values?.description,
          //   LevelID: values?.LevelId,
        };
         
         const oldValues =updateValuesForChangeLog(list,formItems?.portfolioSections);

         const newValues =updateValuesForChangeLog(obj,formItems?.portfolioSections);
       
         let id = null;
        // if (leveldata.type==='edit')
        // {
        //     id = leveldata?.ParentID;
        // }

        if (state.isEdit) {

          let obj = {
            // NameInSourceSystem: "",
            // DisplayName: values?.name,
            // Description: values?.description,
            // LevelID: values?.LevelId,
            ...values,
          };
          obj.id = modelId;
          try {
            let result;
            result = await businessCapabilityServices.update(obj);
 
            if (result?.data?.code=="200") {
 
              //form.resetFields();
              message.success(result?.data?.msg);

              //Add Change Log
              await UpdateChangeLog(obj.id, oldValues, newValues, "Update",  moduleName);
              
              await fetchData( moduleName,modelId); 
              await fetchData_Link( {moduleName:moduleName,moduleID:modelId}); 
                 
              await getData();
              
               
            } else {
              message.error(result?.data?.msg);
            }
          } catch (error) {
            message.error(error?.message);
          }
        } else {
          try {
            let result;
            if (leveldata.Level === 1) {
              result = await businessCapabilityServices.create(obj);
            } else if (leveldata.Level === 2) {
              obj.Level = "L2";
              obj.ParentID = leveldata?.ParentID;
              result = await businessCapabilityServices.create(obj);
            } else {
              obj.Level = "L3";
              obj.ParentID = leveldata?.ParentID;
              result = await businessCapabilityServices.create(obj);
            }

            if (result.data?.code=="200") {
              //console.log("Level 1 Data", result.data?.data.id);

              //Add Change Log
              await UpdateChangeLog(
                result.data?.data.id,
                oldValues, newValues,
                "Add",moduleName
              );
 
              await fetchData( moduleName,result.data?.data.id);

              form.resetFields();

              
              
              message.success(result?.data?.msg);

              //console.log("ID BC Basic Info ", result?.data);
              const newDataID = result.data?.data.id || null;
              
              updateBussinessModuleId(newDataID);
              
              modelId=result.data?.data.id ;

              await getData();


              console.log("Awadhesh................. Get ", newDataID);

              setTimeout(() => {
                navigate(`/business-capability-master/${moduleName}/${newDataID}`);
              }, 1000);
            } else {
              message.error(result?.data?.msg);

            }
          } catch (error) {
            console.log("Result.....", error);
            message.error(error?.message);

          }
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  
  const updateValuesForChangeLog=( data, propss)=>{  
    let oldList={};
    Object.keys(data).map((dataItem) => {
      propss.map((section, index) => {
        section?.properties?.length > 0 &&
          section?.properties
            ?.sort((a, b) =>
              formatPropertyName(a.name).localeCompare(formatPropertyName(b.name))
            )
            ?.map((property, index) => {
              if (dataItem === checkTextCase(property.name)) {
                if (
                  property?.type === "Single select" ) {
                  property?.propertyOptions.map((propvalue) => {
                    if (data[dataItem]==null){
                      oldList[dataItem]="";
                    }else 
                    if (propvalue?.id === data[dataItem]) { 
                      oldList[dataItem]=propvalue?.name;
                    } 
                  });
                }
                else if (property?.type === "Multi select") {
                  let lst = data[dataItem];
                  if (lst?.length) {
                    let propValue = "";
                    for (let i = 0; i < lst?.length; i++) {
                      property?.propertyOptions.map((propvalue) => {
                        if (propvalue?.id === lst[i]) {
                          // console.log("data  Select Name >>>>", propvalue?.name);
                          if (propValue == "") {
                             
                            propValue = propvalue?.name;
                          } else {
                           
                            propValue = propValue + ", " + propvalue?.name;
                          }
                        }
                      });
                    } 
                    oldList[dataItem] = propValue;
                  } else {
                    oldList[dataItem] = "";
                  }
                }
                else { 
                  oldList[dataItem]=data[dataItem]
                }
              }
            });
      });
    });
    return oldList ;
    }

    
// const updateValuesForChangeLog=( data, propss)=>{  
//   let oldList={};
//   Object.keys(data).map((dataItem) => {
//     propss.map((section, index) => {
//       section?.properties?.length > 0 &&
//         section?.properties
//           ?.sort((a, b) =>
//             formatPropertyName(a.name).localeCompare(formatPropertyName(b.name))
//           )
//           ?.map((property, index) => {
//             if (dataItem === checkTextCase(property.name)) {
//               if (
//                 property?.type === "Single select" ||
//                 property?.type === "Multi select"
//               ) {
//                 property?.propertyOptions.map((propvalue) => {
//                   if (data[dataItem]==null){
//                     oldList[dataItem]="";
//                   }else 
//                   if (propvalue?.id === data[dataItem]) {
                   
//                     oldList[dataItem]=propvalue?.name;
//                   } 
//                 });
//               } else { 
//                 oldList[dataItem]=data[dataItem]
//               }
//             }
//           });
//     });
//   });
//   return oldList ;
//   }

  const getData = async () => {
    // if (leveldata.type==='edit')
    // {
    
    
    const list1 = await businessCapabilityServices.getListByID(modelId);

    setList(list1?.data);

    
    //  console.log("Parent ID", list1?.data.ParentID);
    //  console.log( "Under Level", leveldata?.underLevel );

    if (list1?.data.Level == "L1") {
      setLevel("Level 1");
    } else if (list1?.data.Level == "L2") {
      setLevel("Level 2");
    } else if (list1?.data.Level == "L3") {
      setLevel("Level 3");
    }

    let listParent;
    if (list1?.data.ParentID != null && list1?.data.ParentID != 0) {
      listParent = await businessCapabilityServices.getListByID(
        list1?.data.ParentID
      );

      //  console.log("list1?.data.ParentID", list1?.data.ParentID);
      
      setParentlist(listParent?.data);

      setParentLevel(listParent?.data.DisplayName);
      setLevelID(listParent?.data.LevelID);
    } else {
      setLevel("Level 1");
      setParentLevel("");
    }

     

    ////////////////////////////////////
    setFormData(list1?.data);
    setBusinessCapabilityData(list1?.data);
     console.log("List Data", list);

    form.setFieldsValue({
      name: list1?.data.DisplayName,
      LevelId: list1?.data.LevelID,
      description: list1?.data.Description,
    });

    setfield_OldData({
      displayName: list1?.data.DisplayName,
      LevelId: list1?.data.LevelID,
      description: list1?.data.Description,
    });


    if (stateMenu.MenuID != undefined) {
      SaveRecentlyView_Portfolio(
        stateMenu.MenuID.MenuName,
        window.location.href,
        list1?.data.DisplayName,
        list1?.data.id,
        stateMenu.MenuID.color
      );
    }

    //console.log("Old Data", field_OldData);

    ////////////////////////////////////////////

    // if (leveldata.Level === 1) {

    //     let level=list?.data?.data.find(item=> item.id==leveldata.ParentID);

    //      setFormData( level);

    //     form.setFieldsValue({
    //         name: level.DisplayName,
    //         LevelId: level.LevelID,
    //         description: level.Description
    //     });

    //     setfield_OldData({
    //         displayName:level.DisplayName,
    //         LevelId:level.LevelID,
    //         description:level.Description
    //     });

    // }else  if (leveldata.Level === 2) {

    //     //let level=list?.data?.data.find(item=> item.id==leveldata.ParentID);
    //     let level=[];

    //     list.data.data.forEach((curr, index, arr)=>{

    //         curr?.child.forEach((c,i,a)=>{
    //             if (c.id==leveldata.ParentID)
    //             {

    //                 level=c ;
    //             }
    //         })

    //     });

    //     setFormData( level);

    //     form.setFieldsValue({
    //         name: level.DisplayName,
    //         LevelId: level.LevelId,
    //         description: level.description
    //     });

    //     setfield_OldData({
    //         displayName:level.DisplayName,
    //         LevelId:level.LevelID,
    //         description:level.Description
    //     });

    // }
    // else  if (leveldata.Level === 3) {
    //     //let level=list?.data?.data.child.child.find(item=> item.id==leveldata.ParentID);

    //     let level=[];

    //     list.data.data.forEach((curr, index, arr)=>{

    //         curr?.child.forEach((c,i,a)=>{

    //             c?.child.forEach((cr, ind, arr3)=>{

    //                 if (cr.id==leveldata.ParentID)
    //                 {

    //                     level=cr ;
    //                 }
    //             });

    //         });

    //     });

    //     setFormData( level);

    //     form.setFieldsValue({
    //         name: level.DisplayName,
    //         LevelId: level.LevelId,
    //         description: level.description
    //     });

    //     setfield_OldData({
    //         displayName:level.DisplayName,
    //         LevelId:level.LevelID,
    //         description:level.Description
    //     });

    // }

    //}

    //console.log("Old Data", field_OldData);
  };

  return (
    <>
      <DynamicForm
        data={formItems?.portfolioSections || []}
        form={form}
        formData={formData}
        labelCol={8}
        wrapperCol={16}
        createAndUpdate={create_and_Updatd}
        level={level}
        levelID={levelID}
        parentLevel={parentLevel}
         
      />

      {/*<Typography.Title level={5} className='mb-2'>Additional Information</Typography.Title>
            
             <Row gutter={[16, 24]} className='mb-2'>
                 <TabBCAdditionalInfo/>
            </Row>

            <Row gutter={[16, 24]} className='mb-2'>
                <Col className="gutter-row" span={8}>
                    <Button type="primary" className='btn btn-theme py-1' ghost
                    icon={<PlusOutlined />} 
                    onClick={showModal}
                     > Add New Property </Button>
                </Col> 
            </Row>
            
            <Divider/>

            <Typography.Title level={5} className='mb-2'>Tags</Typography.Title>
             
            <Select
                mode="multiple"
                tagRender={tagRender}
                defaultValue={['gold', 'cyan']}
                style={{
                width: '100%',
                }}
                options={options}
            /> */}

      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          className="mt-5"
        >
          <Form.Item
            label="Additional Information Caption"
            name="AdditionalInfoCaption"
            rules={[
              {
                required: true,
                message: "Please input the additional information caption",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="AdditionalInfoValue"
            label="Additional Information Value"
            rules={[
              {
                required: true,
                message: "Please input the additional information caption",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TabBCBasicInformation;