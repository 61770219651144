import React, { useEffect, useRef, useState } from "react";
import { Flex, Table } from "antd";
import { getDynamicRoutesLink } from "../../../utils/portfolioRelationshipCommon";
import { useNavigate } from "react-router";

const ApplicationListCompare = ({ data, properties,showDifferene, ...props }) => {
  let navigate = useNavigate();
  const divRef = useRef(null)
  const [comparePortfolio, setComparePortfolio] = useState({});

  useEffect(()=>{
    setComparePortfolio(data);
  },[data])

  const getPortfolioColor = (scenarios) =>{
    if(scenarios?.length > 0){
      if(scenarios[0]?.scenarioItems[0]?.portfolioSettings?.color){
        return scenarios[0]?.scenarioItems[0]?.portfolioSettings?.color;
      }
    }
    return "";
  }

  const getColumns = (scenarios) =>{
    let columns = [];
    if(scenarios?.length > 0){
      // if(scenarios[0]?.scenarioItems[0]?.portfolioSettings?.relationship?.length > 0){
        // First Column Like Business Capabilty
        let columnA = {
          // title: scenarios[0]?.scenarioItems[0]?.portfolioSettings?.relationship[0]?.moduleName.replace(/([a-z])([A-Z])/g, '$1 $2'),
          // dataIndex:scenarios[0]?.scenarioItems[0]?.portfolioSettings?.relationship[0]?.moduleName,
          title:"Business Capability",
          dataIndex:"BusinessCapability",
          fixed: "left",
          align: "center",
          width: 320,
          className:"htitle",
          extraData:scenarios[0]?.scenarioItems[0]?.portfolioSettings?.relationship[0] || null,
          onCell: (record) => {
            return {
              className: "column-heading",
            };
          },
        };
        columns.push(columnA)

        // Second Columns Like Scenario A
        let columnB = scenarios?.find(f=>f.id?.toString()===properties?.scenarioId?.toString())
        columnB = {
          title: columnB?.name || "",
          dataIndex: columnB?.name,
          fixed: "left",
          align: "center",
          width: 320,
          extraData:columnB || null,
          render:(text, record, index) =>{
            if(text){
              return getScenarioColumnCell(text);
            }
            return "-";
          },
        };
        columns.push(columnB)

        // Compare Columns With Scenario A
        properties?.compareIds?.forEach(id => {
          let columnCData = scenarios?.find(f=>f.id?.toString()===id?.toString())
          if(columnCData){
            let columnC = {
              title: columnCData?.name || "",
              dataIndex: columnCData?.name,
              align: "center",
              width: 320,
              extraData: columnCData || null,
              render:(text, record, index) =>{
                return getScenarioColumnCell(text);
              },
              onCell: (record) => {
                let className = "";
                if(record[columnCData?.name]?.difference){
                  className = 'bg-compare-text';
                }
                return {
                  className: className,
                };
              },
            };
            columns.push(columnC)
          }
        });
        
        
      }
    // }
    return columns;
  }

  const getScenarioColumnCell = (record) =>{
    if(!record?.operation){
      return  "-";
    }else{
      return <Flex gap={16} justify="space-between">
        {record?.title_1 && <div className="cursor-pointer" onClick={()=>onDynamicLinkClick(record?.title_1_data?.id,record?.moduleName)}>{record?.title_1}</div>}
        {record?.operation !==3 && <div className="px-2 fs-inherit">{record?.operationName}</div>}
        {
          ((()=>{
            if(record?.operation===3){
              if(!record?.isMaster && showDifferene && record?.difference){
                // return <div className="bg-compare-text px-2 fs-inherit">{`${record?.operationName}(${record?.version})`}</div>
                return <div className="px-2 fs-inherit">{record?.operationName}{`(${record?.version})`}</div>
              }else{
                return <div className="px-2 fs-inherit">{record?.operationName}{`(${record?.version})`}</div>
              }
            }else if(record?.title_2){
              if(!record?.isMaster && showDifferene && record?.difference){
                return <div className="px-2 fs-inherit cursor-pointer" onClick={()=>onDynamicLinkClick(record?.title_2_data?.id,record?.moduleName)}>{`${record?.title_2}`}</div>
              }else{
                return <div className="px-2 fs-inherit cursor-pointer" onClick={()=>onDynamicLinkClick(record?.title_2_data?.id,record?.moduleName)}>{`${record?.title_2}`}</div>
              }
            }
            return "";
          })())
        }
      </Flex>;
    }
  }

  const getRowsData = (scenarios,columns) =>{
    let firstColumnData = [];

    let firstColumnIndex = (columns?.length>0)?columns[0]?.dataIndex:"";
    // First Column Data
    scenarios?.forEach(scenario => {
      scenario?.scenarioItems?.forEach(scenarioItem => {
        scenarioItem?.portfolioSettings?.relationship?.forEach(relation => {
          if(relation?.moduleName===firstColumnIndex){
            firstColumnData.push(relation);
          }
        });
      });
    });
    firstColumnData = [...new Map(firstColumnData.map(item =>[item['id'], item])).values()];

    let rowsData = [];
    // Each Row Data
    firstColumnData?.forEach((row,rowIndex) => {
      let rowData = {}
      let compareWith = "";
      columns?.forEach((col,colIndex) => {
        if(colIndex===0){
          rowData[col?.dataIndex] = row?.DisplayName;
        }else{
          rowData[col?.dataIndex+'cellData'] = {collId:col?.extraData?.id,rowId:row?.id};
          if(colIndex===1){
            compareWith = getCellData(col,row);
            rowData[col?.dataIndex] = compareWith;
          }
          if(colIndex>1){
            rowData[col?.dataIndex] = getCellData(col,row,compareWith);
          }
        }
      });
      if(showDifferene){
        if(checkDifferenceExist(rowData)){
          rowsData.push(rowData)
        }
      }else{
        rowsData.push(rowData)
      }
    });
    return rowsData;
  }

  const checkDifferenceExist = (rowData) =>{
    let check = false;
    Object.keys(rowData).forEach(function(key) {
      if(rowData[key]?.difference){
        check = true
      }
    });
    return check;
  }

  const getOperation = (scenarioItem,isMaster=false,differenceWith=null) => {
    const {
      replace_with: replaceWith = null,
      version = null,
      change_type_id: changeTypeId = null,
      portfolioSettings,
    } = scenarioItem;
    
    const checkOperation = replaceWith ? 2 : version ? 3 : changeTypeId ? 1 : 1;

    let obj = {};
    obj.operation = checkOperation;
    obj.isMaster = isMaster;
    obj.version = version;

    // if(checkOperation===1){ obj.operationName = "Retire"; };
    // if(checkOperation===2){ obj.operationName = "Replace with"; };
    // if(checkOperation===3){ obj.operationName = "Upgrade"; };
    if(checkOperation===1){ obj.operationName = <span className="scenario-retire">Retire</span>; };
    if(checkOperation===2){ obj.operationName = <span className="scenario-replacewith">Replace with</span>; };
    if(checkOperation===3){ obj.operationName = <span className="scenario-upgradeto">Upgraded to</span>; };

    obj.moduleName = portfolioSettings?.moduleName;
    obj.title_1_data = portfolioSettings?.portfolios;
    obj.title_1 = portfolioSettings?.portfolios?.DisplayName || portfolioSettings?.portfolios?.NameInSourceSystem || "";
    obj.title_2_data = portfolioSettings?.replacedPortfolio;
    obj.title_2 = portfolioSettings?.replacedPortfolio?.DisplayName || portfolioSettings?.replacedPortfolio?.NameInSourceSystem || "";

    obj.difference = false;
    obj.differenceIn= "";
    if(differenceWith){
      if(differenceWith?.operation!==obj.operation){
        obj.difference = true;
        obj.differenceIn = "operation";
      }else if(differenceWith?.title_1 && obj?.title_1 &&  differenceWith?.title_1!==obj?.title_1){
        obj.difference = true;
        obj.differenceIn = "title_1";
      }else if(differenceWith?.title_2 && obj?.title_2 &&  differenceWith?.title_2!==obj?.title_2){
        obj.difference = true;
        obj.differenceIn = "title_2";
      }
    }else if(!obj?.isMaster && obj.operation && (obj?.title_1 || obj?.title_2)){
      obj.difference = true;
      obj.differenceIn = "recordFound";
    }

    return obj;
  }

  const getCellData = (column,row,compareWith=null) =>{
    let response = {
      isMaster :column?.extraData?.isMaster,
      difference : (!column?.extraData?.isMaster && compareWith?.isMaster && compareWith?.title_1) ? true : false ,
    };
    column?.extraData?.scenarioItems?.forEach(scenarioItem => {
      if(scenarioItem?.portfolioSettings?.relationship?.find(f=>f.id?.toString()===row?.id?.toString())){
        response = getOperation(scenarioItem,column?.extraData?.isMaster,compareWith);
      }
    });

    return response;
  }

  const TableStructure = ({scenarios,width}) => {
    let columns = getColumns(scenarios);
    let rowsData = getRowsData(scenarios,columns);
    console.log("columns",columns);
    return <>
      <Table
        rowKey={columns[0]?.dataIndex}
        columns={columns}
        bordered={true}
        pagination={false}
        dataSource={rowsData ? rowsData : []}
        scroll={{ x: width }}
      />
    </>
  }

  const onDynamicLinkClick = (id = null, type = "", rest = {}) => {
    let newLink= getDynamicRoutesLink({type,id});
    return navigate(newLink);
  };

  return (
    <>
      {Object.keys(comparePortfolio)?.map((name) => {
        const { items } = comparePortfolio[name];
        if(name !== "Application"){ return <div key={Math.random()}></div> }
        return (
          <div
            key={Math.random()}
            className="d-flex justify-content-left mb-4"
            style={{
              background: getPortfolioColor(items),
              border: `1px solid ${getPortfolioColor(items)}`,
              width: window?.innerWidth - 350,
            }}
          >
            <div className="border text-center  d-flex justify-content-center align-items-center">
              <span
                className="rotate-90 h4  d-flex justify-content-center align-items-center"
                style={{ width: 80 }}
              >
                {name}
              </span>
            </div>
            <div className="border w-100" >
              { 
                TableStructure({
                  scenarios:items,
                  width:divRef?.current?.offsetWidth || window?.innerWidth - 450,
                }) 
              }
            </div>
          </div>
        );
      })}
    </>
  );
};

ApplicationListCompare.defaultProps = {
  data:[],
  properties:null,
};

export default ApplicationListCompare;
