import React from "react";
import { Tabs, Typography } from "antd";
import ApplicationTab from "./ApplicationTab";
import ConnectionTab from "./ConnectionTab";
import LinkTypesCard from "./LinkTypesCard";
import { PageTitleHeading } from "../../utils/Common";

const SettingsContent = ({
  selectedMenuText,
  selectedCollapse,
  selectedMenu,
  items,
  fetchAllPortfolio,
  setUpdatedMenu,
  setActiveTab,
  activeTab,
}) => {
  const findCheckedValues = (id = null, data = []) => {
    return data?.includes(id) ?? false;
  };
  console.log("activeTab",activeTab)
  const onChange = (key) => {
    setActiveTab(key);
  };
  return (
    <div>
      {selectedCollapse === 0 && (
        <>
          <PageTitleHeading text={selectedMenuText} className={`mb-2`}/>
          <Tabs
            type="card"
            className="setting-content-tabs"
            defaultActiveKey={"1"}
            activeKey={String(activeTab)}
            onChange={onChange}
            items={[
              {
                label: "Properties",
                key: "1",
                children: (
                  <ApplicationTab
                    selectedMenuText={selectedMenuText}
                    selectedMenu={selectedMenu}
                    item={
                      items?.find((item) => item?.id === selectedMenu) || {}
                    }
                    fetchAllPortfolio={fetchAllPortfolio}
                    setUpdatedMenu={setUpdatedMenu}
                    activeTab={activeTab}
                  />
                ),
              },
              {
                label: "Relations",
                key: "2",
                children: (
                  <ConnectionTab
                    selectedMenuText={selectedMenuText}
                    selectedMenu={selectedMenu}
                    items={
                      items?.map((item) => ({
                        id: item.id,
                        name: item.name,
                        checked: findCheckedValues(
                          item?.id,
                          items?.find((val) => val?.id === selectedMenu)
                            ?.portfolioConnectedIds || []
                        ),
                      })) || {}
                    }
                    fetchAllPortfolio={fetchAllPortfolio}
                  />
                ),
              },
            ]}
          />
        </>
      )}
      {selectedCollapse === 1 && <LinkTypesCard />}
    </div>
  );
};

export default SettingsContent;
