import Keycloak from "keycloak-js";
import anonymousRoutesJson from "../routes/anonymousRoutesJson";
import Cookies from 'universal-cookie';
import http from './services/httpService';

const _kc = new Keycloak('/keycloak.json');
const API_BASE_URL = process.env.REACT_APP_API_URL

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    // onLoad: 'login-required',
    // onLoad: 'check-sso',
    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    // pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log("user is not authenticated..!");
        let pathname = window.location.pathname
        pathname = pathname.split("/");
        pathname = pathname.filter((f) =>f!=='' );
        if(!anonymousRoutesJson.includes(pathname[0])){
          console.log(pathname,anonymousRoutesJson)
          // console.log(pathname,anonymousRoutesJson)
          doLogin();
        }
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getUserdetails = () => _kc.tokenParsed;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getUserFullName = () => _kc.tokenParsed?.name;

const getValidToken = () => {
  const cookies = new Cookies();

  let token = _kc.token;
  if(!token){
			token=cookies.get('access_token');

  }
 return token
};

async function saveUserData(formData) {
  const url = `${API_BASE_URL}masterdata/saveUserData`;
  let response = await http.post(url, formData);
  return response;
}

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getUserFullName,
  getValidToken,
  getUserdetails,
  saveUserData
};

export default UserService;
