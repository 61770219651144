import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getScenariosItemsByScenarioId(id) {
  const url = `${API_BASE_URL}scenarioV2/getItems/`;
  let response = await http.get(url + id);
  return response;
}

// Create scenario items
async function createScenarioItem(data) {
  const url = `${API_BASE_URL}scenarioV2/createScenarioItemsV2`;
  let response = await http.post(url, data);
  return response;
}

async function updateScenarioItem(id, data) {
  const url = `${API_BASE_URL}scenarioV2/updateScenarioItemsV2/${id}`;
  let response = await http.put(url, data);
  return response;
}

async function deleteScenarioItem(id) {
  const url = `${API_BASE_URL}scenarioV2/deleteScenarioItemsV2/`;
  let response = await http.delete(url + id);
  return response;
}

const ScenarioItemsServices = {
  getScenariosItemsByScenarioId,
  createScenarioItem,
  updateScenarioItem,
  deleteScenarioItem,
};

export default ScenarioItemsServices;
