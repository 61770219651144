import { PageTitleHeading } from "../../utils/Common";
import TextWithTooltip from "../../utils/TextWithTooltip";
import "./Home.scss";
import { EyeOutlined } from "@ant-design/icons";
const HomeCard = (props) => {

    return (
        <>
            <div className='card'>
                <div className='card-body' style={{minHeight:70}}>
                    <div className='d-flex justify-content-between viewitembx'>
                        <div className="inner">
                            <PageTitleHeading text={<TextWithTooltip text={props.ModelName} characterLimit={25}/>} size={5} className={`my-0`}/>
                            <PageTitleHeading text={props.ModelID} size={4} className={`d-none`}/>
                        </div>
                        <div>
                            <a href= {props.URL} target='_blank' rel="noreferrer"><EyeOutlined /></a>
                        </div>
                    </div>
                </div>
                <div className='card-footer py-1 text-center' style={{background : props.color}}>
                    { props.Module}
                </div>
            </div>
        </>
     );
};
export default HomeCard;
