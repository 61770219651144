import React from 'react'
import FilterButtonToggle from '../layouts/FilterButtonToggle'
import IntegrationFilterContent from './IntegrationFilterContent'

const IntegrationFilter = ({showFilter,setShowFilter,lifeCycleStagesColors,activeGridStyle,data,selectedFilters,setSelectedFilters,sortValues,optionalLevel})  => {
    return (
        <div className='p-2'>
            <FilterButtonToggle
                showFilter={showFilter}
                setShowFilter={setShowFilter}
            />
            {
                showFilter && (
                <IntegrationFilterContent
                    lifeCycleStagesColors={lifeCycleStagesColors}
                    activeGridStyle={activeGridStyle}
                    portfolioData={data}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    sortValues={sortValues}
                    optionalLevel={optionalLevel}
                />)
            }
        </div>
    )
}

IntegrationFilter.defaultProps = {}

export default IntegrationFilter