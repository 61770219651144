import { Collapse, Drawer, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import CityMapServices from "../../services/services/CityMapServices";
import PropertiesFilter from "./PropertiesFilter";
const { CheckableTag } = Tag;

const Filter = (props) => {
    const [showPropFilter, setshowPropFilter] = useState(true)
    const [propFilterData, setPropFilterData] = useState([])
    const [nodeFilter, setNodeFilter] = useState([])
    const { filterNodes, setLoading, filterNodes1, filterNodes2, selectedFilterNodes, setSelectedFilterNodes, setCityMapQuery, formData, setShowGraph, setlegendsLabel } = props

    useEffect(() => {
        getProperties();
    }, [])

    useEffect(() => {
      if(selectedFilterNodes.length === 0){
        setNodeFilter(filterNodes)
      }
    }, [filterNodes])

    useEffect(() => {
      if(filterNodes1.length > 0 && filterNodes2.length > 0) {
        let array1 = filterNodes1;
        let array2 = filterNodes2;
        let uniqueNode = [];
        array1.map((item) => {
          array2.push(item);
        })

        array2.forEach(record => {
          let isDuplicate = uniqueNode.some(node => node?.label === record?.label);
          if (!isDuplicate) {
            uniqueNode.push(record);
          }
        })
        if(selectedFilterNodes.length === 0){
          setNodeFilter(uniqueNode)
          setlegendsLabel(uniqueNode)
        }
        setlegendsLabel(uniqueNode)

      }

  }, [filterNodes1, filterNodes2])

    useEffect(() => {
      getProperties();
  }, [selectedFilterNodes])

    const getProperties = (label) => {
        props?.setLoading(true);
        let obj = {};
        obj.label = selectedFilterNodes;
        CityMapServices.getProperties(obj)
          .then((response) => {
            if (response?.data?.code === 200) {
                let responseVal = response?.data?.data;
                setPropFilterData(responseVal);
            }
            props?.setLoading(false);
          })
          .catch((err) => {
            props?.setLoading(false);
          });
      };
    const handleChange = (tag) => {
      const nextSelectedTags = selectedFilterNodes
      const index = nextSelectedTags.indexOf(tag);
      if (index > -1) { 
        setSelectedFilterNodes(l => l.filter(item => item !== tag));

      } else {
        setSelectedFilterNodes([...selectedFilterNodes, tag ])
      }
    };

    const removeNodeFilter = () => {
      setSelectedFilterNodes([])
    }

  return (
    <Drawer
      className="px-0"
      title="Search Criteria"
      onClose={() => props?.setshowFilter(false)}
      open={props?.showFilter}
      styles={{
        body: {
          paddingleft: 0,
          paddingRight: 0,
        },
      }}
    >
      <Collapse
        expandIconPosition={"end"}
        items={[
          {
            key: "nodes",
            label: "Nodes",
            children: (
              <>
                <Space size={[0, 8]} wrap>
                    <Tag onClick={removeNodeFilter}>Reset</Tag>
                    {
                      nodeFilter.map((item, index) => {
                        return <Tag 
                                key={`${index}`}
                                color={item?.color}
                                style={{ color: 'black' }}
                                className={selectedFilterNodes.includes(item?.label) ? "active-node" : ""}
                                onClick={(value) => handleChange(item?.label) }
                                >{`${item?.label} (${item?.count})` }</Tag>
                      })
                    }
                </Space>
              </>
            ),
          },
        //   {
        //     key: "relationship",
        //     label: "Relationship",
        //     children: (
        //         <>
        //             <Space size={[0, 8]} wrap>
        //                 <Tag>Reset</Tag>
        //                 <Tag color="geekblue">CHILD_OF (3)</Tag>
        //                 <Tag color="purple">SUPPORTED_BY (2)</Tag>
        //             </Space>
        //         </>
        //     ),
        //   },
          {
            key: "propfilter",
            label: "Property Filter",
            className: showPropFilter && propFilterData.length > 0 ? "prop-filter" : "d-none",
            children: (
                <>
                  <PropertiesFilter 
                    propFilterData={propFilterData} 
                    setCityMapQuery={setCityMapQuery} 
                    formData={formData}
                    selectedFilterNodes={selectedFilterNodes}
                    setShowGraph={setShowGraph}
                    />  
                </>
            ),
          },
        ]}
      />
    </Drawer>
  );
};

export default Filter;
