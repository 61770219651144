import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { useEffect, useState,useRef } from "react";
import {
    Select,
    Form,
    Row,
    Col,
    Upload,
    message,
    Button,
} from "antd";
import DataImportScenarioServices from '../../services/services/DataImportScenarioServices';

const styleFormAlign = {
    textAlign: "justify",
}

const fileTypeOptions = [
    { value: 'excel', label: 'Excel', fileType:'.xlsx' },
    // { value: 'csv', label: `CSV (Under Development)`,fileType:'.csv'},
    // { value: 'json', label: `JSON (Under Development)`,fileType:'.json'},
]

function DataImportForm1(props) {
    const attributeFileRef = useRef(0);
    const [form] = Form.useForm();
    const [fileAcceptType, setFileAcceptType] = useState();
    const [messageApi, contextHolder] = message.useMessage();

    const uploadFile = {
        beforeUpload:()=> {return false; },
    };

    const onFinish = (formData) => {
        props?.setLoading(true);

        let postData = {};
        postData.image = formData?.attributeFile?.file;

        DataImportScenarioServices
        .saveAttributeData(postData)
        .then((response) => {
            if (response.data.code === 200) {
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                });
                form.resetFields();
            } else {
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                });
            }
            props?.setLoading(false);
        }).catch((err) => {
            console.log(err);
            props?.setLoading(false);
        });
    }

    return (
        <>
            {contextHolder}
            <Form
                layout="vertical"
                onFinish={onFinish}
                form={form}
            >
                <Row gutter={16} type="flex" justify="start" className='justify-content-start'>
                    <Col xs={{ span: 24}} md={{ span: 6}}>
                        <Form.Item name="file_type" label="Attribute File Type" rules={[{ required: true }]} style={styleFormAlign}>
                            <Select
                                options={fileTypeOptions}
                                placeholder="Attribute File Type"
                                allowClear
                                onChange={(value,option)=>{
                                    setFileAcceptType(option?.fileType);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24}} md={{ span: 6}}>
                        <Form.Item name="attributeFile" label={<div className="d-flex"><span>Attribute File Upload</span>&emsp;&emsp;<span><a href="https://connected-architecture-qa.truerouta.com/assets/format/attribute_master.xlsx" target="_blank" download>Download</a></span></div>} rules={[{ required: true }]} style={styleFormAlign}>
                            <Upload {...uploadFile} accept={fileAcceptType} ref={attributeFileRef}>
                                <Button icon={<UploadOutlined />} style={{width:'100%'}}  >Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24}} md={{ span: 6}}>
                        <Form.Item>
                            <Button htmlType="submit" className="w-100 mt-30"  >
                                Import
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default DataImportForm1