import React from "react";
import { Layout } from "antd";

const Sidebar = ({ showFilter, setShowFilter, children ,SidebarHeight}) => {
  return (
    <Layout.Sider
      theme={`light`}
      width={showFilter ? `300px` : `50px`}
      className="border-left p-3 pt-0 filter-ani"
      style={{ overflow: "auto", maxHeight: SidebarHeight, transition:"all 0.25s ease"}}
    >
      {children}
    </Layout.Sider>
  );
};

Sidebar.defaultProps={
  SidebarHeight:window.innerHeight - 100
}
export default Sidebar;
