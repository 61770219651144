import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Divider, Input, Layout, Spin } from 'antd';
import { PageTitleHeading } from '../../../utils/Common';
import FilterButtonToggle from '../../layouts/FilterButtonToggle';

const ScenarioList = ({title,headerSearch,Buttons,reload,loadMore,filter,hasMore,loading,list:items,...props}) => {
    const [showFilter,setShowFilter] = useState(false);
    const [currentTabItem, setcurrentTabItem] = useState(null);

    useEffect(() => {
        if (items) {
          // Sort the array by updatedAt property in descending order
          // const sortedItems = item.properties.sort((a, b) => {
          //   return new Date(b.updatedAt) - new Date(a.updatedAt);
          // });
  
          // Update the state with the sorted array
          setcurrentTabItem(items);
        }
      }, [items]);


    const searchByTextOrder = (value) => {
        //console.log("searchText>>>>>>>>>>>>>>>>>>>>>>.", value);
        if (value === undefined || value === null || value === "") {
          // If no value is provided, reset to the original list
          setcurrentTabItem(items); // Assuming you have an originalTabItem to reset to
        } else {
          // Filter items by name including the value
          const filteredItems = currentTabItem.filter((item) =>
            item.name?.toLowerCase().startsWith(value?.toLowerCase())
          );
          setcurrentTabItem(filteredItems);
        }
      };



    return (
        <div className='p-2'>
            <Layout>
                <Layout.Content className={`p-2 ${filter && `border-right`}`}>
                    {
                        title && <div className='d-flex justify-content-between align-items-center'>
                            <PageTitleHeading text={title}/>
                            {
                                headerSearch && <div className='mr-16'>
                                    <Input.Search style={{minWidth:150}} placeholder='Search' onChange={(e) => {
                    searchByTextOrder(e.target.value);
                  }}/>
                                </div>
                            }
                        </div>
                    }
                    <InfiniteScroll
                        loadMore={loadMore}
                        pageStart={0}
                        hasMore={hasMore}
                        loader={<Spin spinning={loading}></Spin>}
                        style={{maxHeight:window?.innerHeight-200,overflowY:"auto"}}
                        className='pr-2'
                    >
                        {
                            currentTabItem?.map(item=>{
                                return <div key={Math.random()}>
                                    <div className='d-flex justify-content-between py-2'>
                                        <div className='w-100'>
                                            <div className='text-primary h5 mb-0'> {item?.name} </div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            {Buttons && <Buttons {...item} {...props}/>}
                                        </div>
                                    </div>
                                    <Divider className='my-1'/>
                                </div>
                            })
                        }
                    </InfiniteScroll>
                </Layout.Content>
                {/* {
                    filter && <Layout.Sider theme={`light`} width={(showFilter)?250:50} className='p-2'>
                        <FilterButtonToggle
                            showFilter={showFilter}
                            setShowFilter={setShowFilter}
                        />
                    </Layout.Sider>
                } */}
            </Layout>
        </div>
    )
}

ScenarioList.defaultProps = {
    title:"",
    reload:false,
    filter:true,
    loading:false,
    headerSearch:false,
    loadMore:false,
}

export default ScenarioList