import React, { useCallback, useMemo, useState } from "react";
import { InputNumber, message } from "antd";

const NumberRangeComponent = React.memo(
  ({
    item,
    onMinMaxChange,
    minValue,
    setMinValue,
    maxValue,
    setMaxValue,
    numberRangeMin,
    numberRangeMax,
    minInputProps,
    maxInputProps,
    minInputFocused,
    maxInputFocused
  }) => {

    const handleMinChange = useCallback(
      (value) => {
        const newMinValue = value === undefined ? numberRangeMin : value;
        if (newMinValue > maxValue) {
          message.info("Min value cannot be greater than max value");
        }else{
          setMinValue(newMinValue);
          onMinMaxChange(item?.name, [newMinValue, maxValue]);
        }
      },
      [item?.name, maxValue, numberRangeMin, onMinMaxChange, setMinValue]
    );

    const handleMaxChange = useCallback(
      (value) => {
        const newMaxValue = value === undefined ? numberRangeMax : value;
        if (newMaxValue < minValue) {
          message.info("Max value cannot be less than min value");
        }else{
          setMaxValue(newMaxValue);
          onMinMaxChange(item?.name, [minValue, newMaxValue]);
        }
      },
      [item?.name, minValue, numberRangeMax, onMinMaxChange, setMaxValue]
    );

    const handleKeyPress = (e) => {
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    };

    return (
      <div>
        <div className="flex items-center mb-3">
          <div className="mr-4">Min:</div>
          <div className={minInputFocused ? "input-focused" : ""}>
            <InputNumber
              name={`${item?.name}-min`}
              id={`${item?.name}-min`}
              placeholder="Min Value"
              onChange={handleMinChange}
              // onBlur={handleMinChange}
              changeOnBlur={false}
              autoFocus={minInputFocused}
              value={minValue}
              min={numberRangeMin}
              max={numberRangeMax}
              onKeyPress={handleKeyPress}
              {...minInputProps}
            />
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-4">Max:</div>
          <div className={maxInputFocused ? "input-focused" : ""}>
            <InputNumber
              placeholder="Max Value"
              name={`${item?.name}-max`}
              id={`${item?.name}-max`}
              onChange={handleMaxChange}
              // onBlur={handleMaxChange}
              changeOnBlur={false}
              autoFocus={maxInputFocused}
              value={maxValue}
              min={numberRangeMin}
              max={numberRangeMax}
              onKeyPress={handleKeyPress}
              {...maxInputProps}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default NumberRangeComponent;
