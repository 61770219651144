import {
    Button,
    Col,
    Row,
    message,
    Modal,
    Form,
    Input,
    Select,
    Spin,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import CustomDiagramModal from "../../../custom/CustomDiagramModal";
  import diagramServices from "../../../../services/services/diagramServices";
  import { useAppndiagram } from "../../../../Context/diagramProvider";
  import { useLocation, useNavigate } from "react-router";
  import * as Yup from "yup";
  import { PlusOutlined } from "@ant-design/icons";
  
  const AddDiagram = ({ businessApplicationId, moduleName ,onListUpdate,titleName}) => {
  
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
  
    const { state, dispatch } = useAppndiagram();
    const [visibleModal, setVisibleModal] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const [formModalData, setFormModalData] = useState({
      name: "",
      description: "",
      moduleID: businessApplicationId || null,
      moduleName: moduleName,
    });
  
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const [isEditing, setIsEditing] = useState(false);
    const [formLinkType] = Form.useForm();
    const [linkTypesOpen, setLinkTypesOpen] = useState(false);
  
    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      console.log("formModalData", formModalData);
    }, [formModalData]);
  
  
    const handleCreate = async (values, setSubmitting,openEditor) => {
      try {
        // values.name = "ID" + values.linkTypeID;
        values.moduleID = businessApplicationId;
  
        // Handle form submission
        setLoading(true);
        const response = await diagramServices.creatediagram(values);
        const newData = response?.data || null;
  
        // Handle success response
        message.success("Diagram created successfully.");
  
        await dispatch({ type: "ADD_LINK", payload: newData });
        setVisibleModal(false);
        setTimeout(() => {
          setSubmitting(false);
          setLoading(false);
          if (openEditor) {
            return navigate(`/diagram-editor/${newData?.id}`, { state: { previousPath: location?.pathname,titleName:titleName,moduleName:moduleName } });
          }
          onListUpdate();
        }, 1000);
      } catch (error) {
        // Handle error
        message.error("Failed to submit form. Please try again.");
        setSubmitting(false);
        setLoading(false);
      } finally {
        // setLoading(false);
        // setVisibleModal(false);
      }
    };
  
    const handleCancel = () => {
      setVisibleModal(false);
      setLoading(false);
    };
  
    return (
      <div className="mt-4">
        <Row gutter={16} className="mb-2">
          <Col span={24}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setVisibleModal(!visibleModal);
              }}
            >
              Diagram
            </Button>
          </Col>
        </Row>
  
        <CustomDiagramModal
          visible={visibleModal}
          onCancel={handleCancel}
          onOk={handleCreate}
          okText="Save"
          okText2="Save and Open Editor"
          cancelText="Cancel"
          closable={false}
          loading={loading}
          initialValues={formModalData}
        />
      </div>
    );
  };
  
  export default AddDiagram;
  