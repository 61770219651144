import { Button, Checkbox, Flex, Form, Modal, Select } from "antd"
import React, { useEffect, useState } from "react"

export const ApplicationTypeModal = ({open,setOpen,setSelectedItem}) => {
    return <Modal
        title={open?.DisplayName}
        open={open?true:false}
        closable
        onCancel={()=>{setOpen(null)}}
        footer={false}
        zIndex={10000}
    >
        <Form
            labelCol={{span:8}}
            wrapperCol={{span:16}}
            onFinish={(value)=>{ setSelectedItem({...open,[`selectedData`]:value}) }}
            requiredMark={false}
        >
            <Flex gap={16} className="border p-3" vertical>
                <Form.Item name="connectionType" label={'Connetion Type'} rules={[{required:true,}]} className="mb-0">
                    <Select
                        defaultValue={""}
                        options={[
                            {value:"VCOM",label:"VCOM"},
                            {value:"VCCH",label:"VCCH"}
                        ]}
                    />
                </Form.Item>
                <Form.Item name="dataFormat" label={'Data Format'} rules={[{required:true,}]} className="mb-0">
                    <Select
                        defaultValue={""}
                        options={[
                            {value:"JSON",label:"JSON"}
                        ]}
                    />
                </Form.Item>
                <Button htmlType="submit" type="primary" className="w-100">Ok</Button>
            </Flex>
        </Form>
    </Modal>
}

export const IntigrationModalCheckbox = ({open,setOpen,title,lists,setSelected}) => {
    const [selectAll,setSelectAll] = useState(false);
    const [options,setOptions] = useState([]);

    useEffect(()=>{
        let items = lists?.map(list=>{
            if(!list?.checked){ list.checked = false; }
            return list;
        });

        setOptions(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[lists])
    
    const handleCheckValue = (value,check) => {
        // eslint-disable-next-line array-callback-return
        let items = options?.map(item=>{
            if(item?.value?.toString()===value?.toString()){ item.checked = check };
            return item;
        })

        if(!check){
            setSelectAll(false)
        }
        setOptions(items);
    }

    return <>
        <Modal
            open={open}
            title={
                <Flex gap={8} justify='space-between'>
                    <div>{title}</div>
                    <div>
                        <Button 
                            type={`link`} 
                            onClick={()=>{ 
                                let items = lists?.map(list=>{
                                    list.checked = !selectAll;
                                    return list;
                                });
                        
                                setOptions(items); 
                                setSelectAll(!selectAll)
                            }} 
                            size='small'
                        >{!selectAll?'Select':'Unselect'} All</Button>
                    </div>
                </Flex>
            }
            onCancel={()=>{ 
                setOpen("");
                setSelected(options?.filter(f=>f.checked===true));
            }}
            closable
            footer={false}
            width={350}
            destroyOnClose
        >
            <div className='overflow-y p-2' style={{maxHeight:250}}>
                <Flex gap={4} vertical>
                    {
                        options?.map(option=>{
                            let key = 'list-'+Math.random();
                            let parameters = {}
                            parameters.id=key;
                            parameters.checked = option?.checked;
                            parameters.value = option?.value || false;
                            
                            return <React.Fragment key={key}>
                                <label htmlFor={key} className='form-control'>
                                    <Flex gap={8} justify='space-between'>
                                        <div>{option?.label}</div>
                                        <div>
                                            <Checkbox 
                                                onChange={({target})=>{ 
                                                    handleCheckValue(target?.value,target?.checked)
                                                }}
                                                {...parameters}
                                            />
                                        </div>
                                    </Flex>
                                </label>
                            </React.Fragment>
                        })
                    }
                </Flex>
            </div>
        </Modal>
    </>
}