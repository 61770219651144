import React from 'react';
import { useEffect, useState, useRef } from "react";
import {
    Select,
    Form,
    Row,
    Col,
    message,
    Button,
} from "antd";
import DataImportScenarioServices from '../../services/services/DataImportScenarioServices';

const styleFormAlign = {
    textAlign: "justify",
}

const sourceTypeOptions = [
    { value: 'alfabet', label: `Alfabet` },
    { value: 'alation', label: `Alation`,},
]

function DataImportForm1(props) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [apiTypes, setApiTypes] = useState([{'value':'',label:'Select'}]);

    const getAvailableApis = (sourceType) => {
        props?.setLoading(true);

        let obj = {
            "sourceType": sourceType,
        };

        DataImportScenarioServices
        .getAvailableApis(obj)
        .then((response) => {
            if (response?.data) {
                setApiTypes(response?.data);
                form.setFieldsValue({ apiType: '' });
            }
            props?.setLoading(false);
        }).catch((err) => {
            console.log(err);
            props?.setLoading(false);
        });
    }

    const onFinish = (formData) => {
        props?.setLoading(true);

        DataImportScenarioServices
        .saveSyncData(formData)
        .then((response) => {
            if (response.data.code === 200) {
                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                });
                form.resetFields();
            } else {
                messageApi.open({
                    type: 'error',
                    content: response.data.message,
                });
            }
            props?.setLoading(false);
        }).catch((err) => {
            console.log(err);
            props?.setLoading(false);
        });
    }
    
    return (
        <>
            {contextHolder}
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={16} type="flex" justify="start" className='justify-content-start'>
                    <Col xs={{ span: 24}} md={{ span: 6}}>
                        <Form.Item name="sourceType" label="Source System" rules={[{ required: true }]} style={styleFormAlign}>
                            <Select
                                placeholder="Source System"
                                allowClear
                                options={sourceTypeOptions}
                                onChange={(value,option)=>{
                                    getAvailableApis(value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24}} md={{ span: 6}}>
                        <Form.Item name="apiType" label="Available API" rules={[{ required: true }]} style={styleFormAlign}>
                            <Select
                                placeholder="Available API"
                                options={apiTypes?.map(e=>{e.label = e.name;return e;})}
                                allowClear
                            >
                                <Select.Option value="">Select</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24}} md={{ span: 6}} >
                        <Form.Item>
                            <Button htmlType="submit" className="w-100 mt-30"  >
                                Sync
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default DataImportForm1