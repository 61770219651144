import React, { useState } from "react";
import { Modal, Input, Button, Spin,Select } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextArea from "antd/es/input/TextArea";

const CustomDiagramModal = ({
  visible,
  onCancel,
  onOk,
  initialValues,
  okText,
  cancelText,
  closable,
  loading,
  validationSchema,
  okText2,
}) => {
  const [openEditor,setOpenEditor] = useState(false);
  return (
    <Modal
      visible={visible}
      title=""
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
      closable={closable}
      footer={null}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await onOk(values, setSubmitting,openEditor);
          resetForm();
          setOpenEditor(false);
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <div className="border rounded-sm p-4">

              <div className="mt-2">
                <label htmlFor="Name" className="font-bold">
                  Name
                  <span className="text-danger">*</span>
                </label>
                <Field type="text" name="name">
                  {({ field }) => (
                    <Input
                      {...field}
                      className={touched.name && errors.name ? "error-input" : ""}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="name"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="mt-2">
                <label htmlFor="description" className="font-bold">
                  Description 
                </label>
                <Field type="text" name="description">
                  {({ field }) => (
                    <TextArea
                      {...field}
                      className={
                        touched.description && errors.description ? "error-input" : ""
                      }
                    />
                  )}
                </Field>
              </div>

            </div>
            <div className="mt-4 text-right">
              <Button
                onClick={onCancel}
                className="mr-2"
                disabled={isSubmitting}
              >
                {cancelText}
              </Button>
              {/* <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={isSubmitting || loading}
              >
                {loading ? <Spin /> : okText}
              </Button> */}
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={isSubmitting || loading}
                onClick={()=>{ setOpenEditor(true)}}
                className="ml-2"
              >
                {loading ? <Spin /> : okText2}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CustomDiagramModal;
