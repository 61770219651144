import React, { createContext, useContext, useReducer } from "react";

// Define the PortfolioRelationship context
const ScenarioListContext = createContext();

// Custom hook to use PortfolioRelationship context
export const useScenarioList = () => useContext(ScenarioListContext);

// Initial state for portfolio relationships
const initialState = {
  data: [],
  loading: false,
  error: false,
};

// Reducer function to handle state updates
const scenarioListReducer = (state, action) => {
  switch (action.type) {
    case "SET_SCENARIO_LIST":
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case "ADD_SCENARIO":
      return {
        ...state,
        data: Array.isArray(state.data)
          ? [...state.data, ...action.payload]
          : [...action.payload],
        loading: false,
        error: false,
      };
    case "UPDATE_SCENARIO":
      return {
        ...state,
        data: state.data.map((relationship) =>
          relationship.id === action.payload.id ? action.payload : relationship
        ),
        loading: false,
        error: false,
      };
    case "DELETE_SCENARIO":
      console.log("state.data", state.data);
      return {
        ...state,
        data: state?.data?.filter(
          (relationship) => relationship.id !== action.payload.id
        ),
        loading: false,
        error: false,
      };
    // Add more cases for other actions if needed
    default:
      return state;
  }
};

// Provider component to manage portfolio relationships state
export const ScenarioListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(scenarioListReducer, initialState);

  return (
    <ScenarioListContext.Provider value={{ state, dispatch }}>
      {children}
    </ScenarioListContext.Provider>
  );
};
