import { Route, Routes } from "react-router-dom";
import NoMatch from "../components/NoMatch";

const AnonymousRoutes = () => (
    <>
        <Routes>
            {/* <Route path="/tender-closed" element={<TenderClosed />} /> */}
            <Route path="*" element={<NoMatch />} />
        </Routes>
    </>
)

export default AnonymousRoutes