import { useState, useEffect } from "react";

const useUserData = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      setUserData(JSON.parse(userDataString));
    } else {
      setUserData(null);
    }
  }, []);

  return userData;
};

export default useUserData;
