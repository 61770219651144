import React from "react";
import { Input, Modal, Form, message } from "antd";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BusinessProcessServices from "../../../../services/services/BusinessProcessServices";

import { UpdateChangeLog } from "../../../../utils/ChangeLogs";
import { useBusinessProcess } from "../../../../Context/businessProcess";
import { useApplicationlink } from "../../../../Context/linkProvider";
import DynamicForm from "../../../dynamicForm/DynamicForm";
import usePortfolioFetcher from "../../../../hooks/usePortfolioFetcher";
import { usePortfolioSetting } from "../../../../Context/portfolioSettingProvider";
import {
    addIsTextToProperties,
    deepFindByName,
    isCamelCase,
    isPascalCase,
    transformText,
} from "../../../../utils/Common";
import { staticSettingPortfolioList } from "../../../../utils/settingCommon";
import { useMenu } from "../../../../Context/MenuProvider";
import { SaveRecentlyView_Portfolio } from "../../../../utils/ResentViewedPortfolio";
import { useApplicationChangeLogs } from "../../../../Context/changeLogsProvider";
import ChangeLogsServices from "../../../../services/services/ChangeLogsServices";

const TabBPBasicInformation = ({
    setActiveTabKey,
    toggleTabDisabled,
    id: modelId,
    setBusinessProcessData,
}) => {
    const navigate = useNavigate();
    const { loading, fetchPortfolio } = usePortfolioFetcher();
    const { stateMenu, dispatchMenu } = useMenu();
    const [form] = Form.useForm();
    const location = useLocation();
    const leveldata = location.state;
    const [list, setList] = useState([]);

    const [formData, setFormData] = useState({});
    const [parentList, setParentlist] = useState({});
    const [field_OldData, setfield_OldData] = useState({
        displayName: "",
        LevelId: "",
        description: "",
    });
    const [level, setLevel] = useState();
    const [parentLevel, setParentLevel] = useState();
    const [levelID, setLevelID] = useState();
    const { state, dispatch } = useBusinessProcess();
    const { state: PortfolioSettingState, dispatch: PortfolioSettingDispatch } =
        usePortfolioSetting();
    const { state: stateChangeLog, dispatch: dispatchChangeLog } =
        useApplicationChangeLogs();

    const [formItems, setFormItems] = useState({});
    let { moduleName } = useParams();

    const { state: businessLinkState, dispatch: businessLinkDispatch } =
        useApplicationlink();

    const fetchData = async ({ moduleName, modelId }) => {
        try {

            const response = await ChangeLogsServices.getChangeLogsList({
                moduleName,
                modelId,
            });

            const newData = response.data.data;
            await dispatchChangeLog({ type: "SET_LINK", payload: newData });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const formatPropertyName = (name) => {
        // Convert name to lower case and remove leading/trailing spaces
        let formattedName = name.toLowerCase().trim();
        // Replace hyphens and underscores with spaces
        formattedName = formattedName.replace(/[_-]/g, " ");
        // Convert camelCase to Camel Case
        formattedName = formattedName.replace(/([a-z])([A-Z])/g, "$1 $2");
        // Capitalize the first letter of each word
        formattedName = formattedName.replace(/\b\w/g, (c) => c.toUpperCase());
        return formattedName;
    };

    const checkTextCase = (name) => {
        if (isPascalCase(name) || isCamelCase(name)) {
            return name; // If already in PascalCase or camelCase, return unchanged
        } else {
            return transformText(name, "camel"); // Convert to camelCase otherwise
        }
    };
    const updateBussinessModuleId = async (id) => {
        await businessLinkDispatch({
            type: "SET_BUSINESSAPPLICATIONID",
            payload: id,
        });
        await businessLinkDispatch({
            type: "SET_BUSINESS_APPLICATION_MODEL",
            payload: moduleName,
        });
        await dispatch({ type: "SET_IS_EDIT", payload: true });
    };

    //Get all settings
    const fetchAllPortfolio = async () => {
        try {
            const response = await fetchPortfolio(
                PortfolioSettingDispatch,
                1,
                100,
            );
            if (response) {
                // message.success("Portfolio fetched successfully.");
            } else {
                message.error("Portfolio not found.");
            }
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    useEffect(() => {
        fetchAllPortfolio();
    }, []);

    useEffect(() => {
        const menuDetail = sessionStorage.getItem("sessionMenuID");
    });

    // useEffect(()=>{
    //   console.log("Change Log.....List", list)
    //   console.log("Change Log..... NewList", newList)
    // },[])

    useEffect(() => {
        if (PortfolioSettingState?.data?.length) {
            const portfolioValues = deepFindByName(
                PortfolioSettingState?.data,
                staticSettingPortfolioList?.businessProcess,
                false,
            );

            setFormItems(addIsTextToProperties(portfolioValues, "Level"));
        }
    }, [PortfolioSettingState?.data]);

    useEffect(() => {
        if (modelId != null) {
            updateBussinessModuleId(modelId);
        } else {
            dispatch({ type: "SET_IS_EDIT", payload: false });

            //Cheking Level Data to Populate the Parent Detail
            if (leveldata != null) {
                console.log("Level Data", leveldata);
                if (leveldata.Level == "1") {
                    setLevel("Level 1");
                    setParentLevel("");
                    setLevelID("");
                } else if (leveldata.Level == "2") {
                    setLevel("Level 2");
                    setParentLevel(leveldata.underLevel);
                    setLevelID(leveldata.levelID);
                } else if (leveldata.Level == "3") {
                    setLevel("Level 3");
                    setParentLevel(leveldata.underLevel);
                    setLevelID(leveldata.levelID);
                }
            } else {
                setLevel("Level 1");
                setParentLevel("");
                setLevelID("");
            }
        }
    }, [modelId, state.isEdit]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        console.log("formData.....", formData);
    }, [formData]);

    useEffect(() => {
        //console.log("isEdit", state.isEdit);

        if (modelId && state.isEdit) {
            getData();
        }
    }, [modelId, state.isEdit]);

    // useEffect(() => {
    //     getData();
    // }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const create_and_Updatd = () => {
        form.validateFields()
            .then(async (values) => {
                let obj = {
                    ReferenceID: 413,
                    //   NameInSourceSystem: "",
                    //   DisplayName: values?.name,
                    Attributes: [],
                    ParentID: 0,
                    SourceID: 1,
                    Level: "L1",
                    //   Description: values?.description,
                    //   LevelID: values?.LevelId,
                    ...values,
                };

                const oldValues = updateValuesForChangeLog(
                    list,
                    formItems?.portfolioSections,
                );

                const newValues = updateValuesForChangeLog(
                    obj,
                    formItems?.portfolioSections,
                );

                let id = null;

                if (state.isEdit) {
                    let obj = {
                        // NameInSourceSystem: "",
                        // DisplayName: values?.name,
                        // Description: values?.description,
                        // LevelID: values?.LevelId,
                        ...values,
                    };
                    obj.id = modelId;
                    try {
                        let result;
                        result = await BusinessProcessServices.update(obj);

                        if (result?.data?.code == "200") {
                            console.log("result?.data", result?.data);

                            //form.resetFields();
                            message.success(result?.data?.msg);

                            //Add Change Log
                            await UpdateChangeLog(
                                obj.id,
                                oldValues,
                                newValues,
                                "Update",
                                moduleName,
                            );

                            await fetchData({ moduleName, modelId });

                            await getData();
                        } else {
                            message.error(result?.data?.msg);
                        }
                    } catch (error) {
                        message.error(error?.message);
                    }
                } else {
                    try {
                        let result;
                        if (leveldata.Level === 1) {
                            result = await BusinessProcessServices.create(obj);
                        } else if (leveldata.Level === 2) {
                            obj.Level = "L2";
                            obj.ParentID = leveldata?.ParentID;
                            result = await BusinessProcessServices.create(obj);
                        } else {
                            obj.Level = "L3";
                            obj.ParentID = leveldata?.ParentID;
                            result = await BusinessProcessServices.create(obj);
                        }

                        if (result.data?.code == "200") {
                            //console.log("Level 1 Data", result.data?.data.id);

                            //Add Change Log
                            await UpdateChangeLog(
                                result.data?.data.id,
                                oldValues,
                                newValues,
                                "Add",
                                moduleName,
                            );
                            await fetchData(moduleName, modelId);
                            form.resetFields();

                            await getData();

                            message.success(result?.data?.msg);

                            //console.log("ID BC Basic Info ", result?.data);
                            const newDataID = result.data?.data.id || null;
                            updateBussinessModuleId(newDataID);
                            setTimeout(() => {
								navigate(`/business-process/${moduleName}/${newDataID}`);
                            }, 1000);
                        } else {
                            message.error(result?.data?.msg);
                        }
                    } catch (error) {
                        console.log("Result.....", error);
                        message.error(error?.message);
                    }
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const updateValuesForChangeLog = (data, propss) => {
        let oldList = {};
        Object.keys(data).map((dataItem) => {
            propss.map((section, index) => {
                section?.properties?.length > 0 &&
                    section?.properties
                        ?.sort((a, b) =>
                            formatPropertyName(a.name).localeCompare(
                                formatPropertyName(b.name),
                            ),
                        )
                        ?.map((property, index) => {
                            if (dataItem === checkTextCase(property.name)) {
                                if (property?.type === "Single select") {
                                    property?.propertyOptions.map(
                                        (propvalue) => {
                                            if (data[dataItem] == null) {
                                                oldList[dataItem] = "";
                                            } else if (
                                                propvalue?.id === data[dataItem]
                                            ) {
                                                oldList[dataItem] =
                                                    propvalue?.name;
                                            }
                                        },
                                    );
                                } else if (property?.type === "Multi select") {
                                    let lst = data[dataItem];
                                    if (lst?.length) {
                                        let propValue = "";
                                        for (let i = 0; i < lst?.length; i++) {
                                            property?.propertyOptions.map(
                                                (propvalue) => {
                                                    if (
                                                        propvalue?.id === lst[i]
                                                    ) {
                                                        // console.log("data  Select Name >>>>", propvalue?.name);
                                                        if (propValue == "") {
                                                            propValue =
                                                                propvalue?.name;
                                                        } else {
                                                            propValue =
                                                                propValue +
                                                                ", " +
                                                                propvalue?.name;
                                                        }
                                                    }
                                                },
                                            );
                                        }
                                        oldList[dataItem] = propValue;
                                    } else {
                                        oldList[dataItem] = "";
                                    }
                                } else {
                                    oldList[dataItem] = data[dataItem];
                                }
                            }
                        });
            });
        });
        return oldList;
    };

    const getData = async () => {
        const list1 = await BusinessProcessServices.getListByID(modelId);

        setList(list1?.data);

        //  console.log("Parent ID", list1?.data.ParentID);
        //  console.log( "Under Level", leveldata?.underLevel );

        if (list1?.data.Level == "L1") {
            setLevel("Level 1");
        } else if (list1?.data.Level == "L2") {
            setLevel("Level 2");
        } else if (list1?.data.Level == "L3") {
            setLevel("Level 3");
        }

        let listParent;
        if (list1?.data.ParentID != null && list1?.data.ParentID != 0) {
            listParent = await BusinessProcessServices.getListByID(
                list1?.data.ParentID,
            );

            //  console.log("list1?.data.ParentID", list1?.data.ParentID);

            setParentlist(listParent?.data);

            setParentLevel(listParent?.data.DisplayName);
            setLevelID(listParent?.data.LevelID);
        } else {
            setLevel("Level 1");
            setParentLevel("");
        }

        ////////////////////////////////////
        setFormData(list1?.data);
        setBusinessProcessData(list1?.data);
        console.log("List Data", list);

        form.setFieldsValue({
            name: list1?.data.DisplayName,
            LevelId: list1?.data.LevelID,
            description: list1?.data.Description,
        });

        setfield_OldData({
            displayName: list1?.data.DisplayName,
            LevelId: list1?.data.LevelID,
            description: list1?.data.Description,
        });

        if (stateMenu.MenuID != undefined) {
            SaveRecentlyView_Portfolio(
                stateMenu.MenuID.MenuName,
                window.location.href,
                list1?.data.DisplayName,
                list1?.data.id,
                stateMenu.MenuID.color,
            );
        }
    };

    return (
        <>
            <DynamicForm
                data={formItems?.portfolioSections || []}
                form={form}
                formData={formData}
                labelCol={8}
                wrapperCol={16}
                createAndUpdate={create_and_Updatd}
                level={level}
                levelID={levelID}
                parentLevel={parentLevel}
            />
            <Modal
                title=""
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    form={form}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    className="mt-5"
                >
                    <Form.Item
                        label="Additional Information Caption"
                        name="AdditionalInfoCaption"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Please input the additional information caption",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="AdditionalInfoValue"
                        label="Additional Information Value"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Please input the additional information caption",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default TabBPBasicInformation;
