import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Collapse,
  Checkbox,
  Select,
  Input,
  Button,
  DatePicker,
  Flex,
  Tooltip,
} from "antd";
import FilterIndicator from "./FilterIndicator";
import { normalizeString } from "../../../utils/linksCommon";
import {
  dateYearValidation,
  isPascalCase,
  numberRangeMax,
  numberRangeMin,
  transformText,
} from "../../../utils/Common";
import moment from "moment/moment";
import NumberRangeComponent from "./NumberRangeComponent";
import { ReloadOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;

const { Option } = Select;
const { Panel } = Collapse;

const initialSortBy = [
  {
    value: "id",
    title: "ID",
  },
  {
    value: "name",
    title: "Name",
  },
];

const FilterContent = React.memo(
  ({
    lifeCycleStagesColors,
    activeGridStyle = 1,
    portfolioData,
    setSelectedFilters,
    selectedFilters,
    sortValues = [],
    optionalLevel = false,
  }) => {
    const [openPanels, setOpenPanels] = useState([-1]);

    const [sortByList, setSortByList] = useState(initialSortBy);
    const [sortByColumn, setSortByColumn] = useState("");
    const [sortByOrder, setSortByOrder] = useState("ASC");

    const [search, setSearch] = useState("");

    // const [minValue, setMinValue] = useState(numberRangeMin);
    // const [maxValue, setMaxValue] = useState(numberRangeMax);
    const [numberRanges, setNumberRanges] = useState({});
    const [minInputFocused, setMinInputFocused] = useState(false);
    const [maxInputFocused, setMaxInputFocused] = useState(false);

    useEffect(() => {
      if (Object.keys(selectedFilters)?.length > 0) {
        const keyExists = "sortBy" in selectedFilters;
        if (!keyExists) {
          setSelectedFilters({
            ...selectedFilters,
            ["sortBy"]: sortValues?.length
              ? `${sortValues[0]?.value},ASC`
              : `${initialSortBy[0]?.value},ASC` || "id,ASC",
          });
        }
      }
    }, [selectedFilters]);

    useEffect(() => {
      if (sortValues?.length) {
        setSortByList(sortValues);
      } else {
        setSortByList(initialSortBy);
      }
    }, [sortValues]);

    useEffect(() => {
      if (Object.keys(selectedFilters)?.length === 0) {
        setOpenPanels([-1]);
        setSearch("");
      }
    }, [selectedFilters]);

    const levelComponent = useMemo(
      () => ({
        label: "Level",
        id: "1",
        options: [
          {
            name: "L1",
            id: "L1",
            default: true,
          },
          {
            name: "L2",
            id: "L2",
            default: false,
          },
          {
            name: "L3",
            id: "L3",
            default: false,
          },
        ],
      }),
      []
    );

    const handlePanelChange = useCallback((index) => {
      setOpenPanels((prevOpenPanels) => {
        if (prevOpenPanels.includes(index)) {
          // If panel is already open, close it
          return prevOpenPanels.filter((panelIndex) => panelIndex !== index);
        } else {
          // If panel is closed, open it
          return [...prevOpenPanels, index];
        }
      });
    }, []);

    const handleFilterChange = useCallback(
      (name, value) => {
        setSelectedFilters({
          ...selectedFilters,
          [isPascalCase(name) ? name : transformText(name, "camel")]: value,
        });
      },
      [selectedFilters, setSelectedFilters]
    );

    const handleSearch = useCallback(
      (value) => {
        setSearch(value);
        setSelectedFilters({
          ...selectedFilters,
          ["name"]: value,
        });
      },
      [selectedFilters, setSelectedFilters]
    );

    const handleDateChange = useCallback(
      (name, dates) => {
        setSelectedFilters({
          ...selectedFilters,
          [name]: dates,
        });
      },
      [selectedFilters, setSelectedFilters]
    );

    const handleNumberRangeChange = useCallback((id, type, value) => {
      setNumberRanges((prevRanges) => ({
        ...prevRanges,
        [id]: {
          ...prevRanges[id],
          [type]: value,
        },
      }));
    }, []);

    const disabledDate = useCallback((current) => {
      // Allow dates from 50 years ago to today
      return (
        current &&
        (current < moment().subtract(dateYearValidation, "years") ||
          current > moment())
      );
    }, []);

    // Number range
    const onMinMaxChange = useCallback(
      (name, value) => {
        setSelectedFilters({
          ...selectedFilters,
          [isPascalCase(name) ? name : transformText(name, "camel")]: {
            isRange: true,
            value,
          },
        });
      },
      [selectedFilters, setSelectedFilters]
    );

    useEffect(() => {
      setSearch(selectedFilters["name"]);
    }, [selectedFilters["name"]]);

    const minInputProps = useCallback(useMemo(
      () => ({
        onFocus: () => setMinInputFocused(true),
        onBlur: () => setMinInputFocused(false),
      }),
      [setMinInputFocused]
    ),[selectedFilters, setSelectedFilters]);

    const maxInputProps = useCallback(useMemo(
      () => ({
        onFocus: () => setMaxInputFocused(true),
        onBlur: () => setMaxInputFocused(false),
      }),
      [setMaxInputFocused]
    ),[selectedFilters, setSelectedFilters]);

    // Number range
    const FilterMenuItems = React.memo(({ item, index, isFirst, isLast }) => {
      console.log("numberRanges-max",numberRanges[item.id]?.maxValue,numberRanges)
      console.log("numberRanges-min",numberRanges[item.id]?.minValue)
      console.log("item",item)
      // console.log("item-values-data", item);
      return (
        <div key={index} className="my-2">
          <Collapse
            // defaultActiveKey={[`${index}`]}
            activeKey={openPanels}
            onChange={() => handlePanelChange(index)}
            expandIconPosition="end"
            className="life-cycle-stages-filter"
          >
            <Panel header={normalizeString(item?.name)} key={index}>
              <div key={index}>
                {item?.type === "Date time" || item?.type === "Date" ? (
                  <RangePicker
                    style={{ width: `100%` }}
                    onChange={(values) => {
                      handleDateChange(
                        isPascalCase(item?.name)
                          ? item?.name
                          : transformText(item?.name, "camel"),
                        // transformText(item?.name, "camel"),
                        values
                      );
                    }}
                    name={item?.name}
                    value={
                      selectedFilters &&
                      selectedFilters[
                        isPascalCase(item?.name)
                          ? item?.name
                          : transformText(item?.name, "camel")
                      ]?.length
                        ? selectedFilters[
                            isPascalCase(item?.name)
                              ? item?.name
                              : transformText(item?.name, "camel")
                          ]
                        : []
                    }
                    disabledDate={disabledDate}
                  />
                ) : item?.type === "Numeric" ? (
                  <>
                    <NumberRangeComponent
                      item={item}
                      onMinMaxChange={onMinMaxChange}
                      // minValue={minValue}
                      minValue={
                        numberRanges[item.id]?.minValue || numberRangeMin
                      }
                      // setMinValue={setMinValue}
                      setMinValue={(value) =>
                        handleNumberRangeChange(item.id, "minValue", value)
                      }
                      // maxValue={maxValue}
                      maxValue={
                        numberRanges[item.id]?.maxValue || numberRangeMax
                      }
                      // setMaxValue={setMaxValue}
                      setMaxValue={(value) =>
                        handleNumberRangeChange(item.id, "maxValue", value)
                      }
                      numberRangeMax={numberRangeMax}
                      numberRangeMin={numberRangeMin}
                      key={`${index}-range`}
                      minInputProps={minInputProps}
                      maxInputProps={maxInputProps}
                      minInputFocused={minInputFocused}
                      maxInputFocused={maxInputFocused}

                    />
                  </>
                ) : (
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    key={`check-box-group-${index}`}
                    name={item?.name}
                    value={
                      selectedFilters &&
                      selectedFilters[
                        isPascalCase(item?.name)
                          ? item?.name
                          : transformText(item?.name, "camel")
                      ]
                    }
                    onChange={(values) => {
                      handleFilterChange(item?.name, values, true);
                    }}
                  >
                    {item?.propertyOptions
                      .sort((a, b) =>
                        normalizeString(a?.name).localeCompare(
                          normalizeString(b?.name)
                        )
                      )
                      .map((d, i) => (
                        <label
                          className="w-100 d-flex align-items-center"
                          key={d?.id}
                        >
                          <Checkbox value={d?.id}>
                            {normalizeString(d?.name)}
                          </Checkbox>
                        </label>
                      ))}
                  </Checkbox.Group>
                )}
              </div>
            </Panel>
          </Collapse>
        </div>
      );
    });

    const LevelList = React.memo(() => {
      return (
        <>
          <Collapse
            defaultActiveKey={[`1`]}
            expandIconPosition="end"
            className="life-cycle-stages-filter"
          >
            <Panel header={levelComponent?.label} key={levelComponent?.id}>
              <div key={`level-${levelComponent?.id}`}>
                <Checkbox.Group
                  style={{ width: "100%" }}
                  key={`check-box-group-level`}
                  name={levelComponent?.label}
                  value={selectedFilters && selectedFilters["Level"]}
                  onChange={(values) => {
                    setSelectedFilters({
                      ...selectedFilters,
                      ["Level"]: values,
                    });
                  }}
                  defaultValue={selectedFilters && selectedFilters["Level"]}
                >
                  {levelComponent?.options
                    .sort((a, b) =>
                      normalizeString(a?.name).localeCompare(
                        normalizeString(b?.name)
                      )
                    )
                    .map((d, i) => (
                      <label
                        className="w-100 d-flex align-items-center"
                        key={d?.id}
                      >
                        <Checkbox value={d?.id} defaultChecked={d?.default}>
                          {normalizeString(d?.name)}
                        </Checkbox>
                      </label>
                    ))}
                </Checkbox.Group>
              </div>
            </Panel>
          </Collapse>
        </>
      );
    });

    const getSortValue = (sortValue) => {
      let sortedValue = "";
      if (sortValue) {
        sortedValue = sortValue?.split(",")[0];
      } else {
        if (sortValues?.length > 0) {
          sortedValue = sortValues[0]?.value;
        } else {
          sortedValue = sortByList[0]?.value;
        }
      }
      console.log("sortedValue", sortedValue);
      return sortedValue;
    };


    return (
      <div>
        {activeGridStyle === 1 && (
          <>
            <Flex gap={8} align="center" className="mb-2">
              <Input.Search
                placeholder="input search text"
                onSearch={handleSearch}
                style={{ width: `100%` }}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if(e.target.value===""){
                    setSelectedFilters({
                      ...selectedFilters,
                      ["name"]: e.target.value,
                    })
                  }
                }}
                value={search}
                name="name"
              />
              <Tooltip title="Clear all">
                <Button
                  type="primary"
                  icon={<ReloadOutlined />}
                  shape="circle"
                  onClick={() => {
                    setOpenPanels([-1]);
                    setSortByList(initialSortBy);
                    setSortByColumn("");
                    setSortByOrder("ASC");
                    setNumberRanges({});
                    setSelectedFilters({});
                  }}
                  size="small"
                />
              </Tooltip>
            </Flex>
            <div className="flex items-center justify-between mb-2 gap-2 align-items-center">
              <div className="w-1/5">
                <label className="fw-medium mb-0 text-nowrap">Sort By: </label>
              </div>
              <div className="w-4/5">
                <Flex gap={4}>
                  <Select
                    style={{ width: "100%" }}
                    defaultValue={getSortValue(selectedFilters["sortBy"])}
                    value={getSortValue(selectedFilters["sortBy"])}
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => {
                      console.log("value-sort", value, value + "," + "ASC");
                      setSelectedFilters({
                        ...selectedFilters,
                        ["sortBy"]: value + "," + "ASC",
                      });
                      setSortByColumn(value);
                      setSortByOrder("ASC");
                    }}
                  >
                    {sortByList &&
                      sortByList?.length &&
                      sortByList?.map((item) => (
                        <Option value={item?.value} title={item?.title}>
                          {item?.title}
                        </Option>
                      ))}
                  </Select>
                  <Button
                    style={{ width: "80px" }}
                    onClick={() => {
                      let value = sortByOrder;
                      const sortBy = sortByColumn
                        ? sortByColumn
                        : getSortValue(selectedFilters["sortBy"]);
                      setSortByOrder(value === "ASC" ? "DESC" : "ASC");
                      if (value && sortBy) {
                        setSelectedFilters({
                          ...selectedFilters,
                          ["sortBy"]: sortBy + "," + value,
                        });
                      }
                    }}
                  >
                    {sortByOrder}
                  </Button>
                </Flex>
              </div>
            </div>
            {optionalLevel && <LevelList />}
            <div>
              {(portfolioData?.length &&
                portfolioData?.map((data, index) => (
                  <FilterMenuItems item={data} index={index} key={index} />
                ))) || <></>}
            </div>
          </>
        )}
        {[2, 3].includes(activeGridStyle) && (
          <FilterIndicator lifeCycleStagesColors={lifeCycleStagesColors} />
        )}
      </div>
    );
  }
);

export default FilterContent;
