import React from 'react';
import IntegrationListContent from './IntegrationListContent';
import IntegrationButtonsV1, { showConfirmDelete } from './IntegrationCommon';

const IntegrationList = ({lists,moduleName,getIntegrationList}) => {

    const Buttons = (item) => {

        return <IntegrationButtonsV1 
            {...item} 
            setItemDelete={
                ()=>{
                    showConfirmDelete({
                        moduleName,
                        item,
                        type:"delete",
                        getIntegrationList,
                    })
                }
            }
            moduleName={moduleName}
            showButtons={{
                view:true,
                edit:true,
                delete:true,
            }}
        />
    }

    return (
        <div>
            <IntegrationListContent
                data = {lists}
                Buttons = {Buttons}
                moduleName={moduleName}
            />
        </div>
    )
}

IntegrationList.defaultProps = {
    lists:[],
}

export default IntegrationList