import axios from "axios";
import UserService from "./UserService";
import { message } from "antd";
import Cookies from 'universal-cookie';


const HttpMethods = {
	GET: "GET",
	POST: "POST",
	DELETE: "DELETE",
	PUT: "PUT",
};

axios.interceptors.request.use(
	function (config) {
		const cookies = new Cookies();

		let token = UserService.getToken();
		if(!token){
			token=cookies.get('access_token');

		}

		document.body.classList.add("loading-indicator");
		config.headers["Authorization"] = `Bearer ${token}`;
		config.headers["X-XSS-Protection"] = `1; mode=block`;
		config.headers["X-Content-Type-Options"] = "nosniff";
		config.headers["Referrer-Policy"] = "same-origin";
		config.headers["X-Content-Security-Policy"] = "default-src 'self'; script-src 'self';";

		return config;
	},
	function (error) {
		document.body.classList.remove("loading-indicator");
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	function (response) {
		document.body.classList.remove("loading-indicator");
		return response;
	},
	function (error) {
		if (
			(error.response.status === 401 || error.response.status === 403) &&
			error.response.statusText === "Unauthorized"
		) {
			var language = localStorage.getItem("language");

			// const toastId = "session-expired";

			if (language === "fn") {
				message.success("Istuntosi on vanhentunut. Kirjaudu sisään uudelleen.");
			} else {
				message.success("Your session is expire, Please login again");
			}
			setTimeout(() => {
				UserService.doLogout();
				window.location.reload();
			}, 1000);
		}
		document.body.classList.remove("loading-indicator");
		return Promise.reject(error);
	}
);

const _axios = axios.create();

const configure = () => {
	_axios.interceptors.request.use((config) => {
		if (UserService.isLoggedIn()) {
			const cb = () => {
				config.headers.Authorization = `Bearer ${UserService.getToken()}`;
				return Promise.resolve(config);
			};
			return UserService.updateToken(cb);
		}
	});
};

const getAxiosClient = () => _axios;

const HttpService = {
	HttpMethods,
	configure,
	getAxiosClient,
};

export default HttpService;
