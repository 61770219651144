import { useState, useEffect } from "react";
import ScenarioServices from "../services/services/ScenarioServices";
import { useScenarioList } from "../Context/ScenarioListProvider";

const useScenarioFetch = (obj) => {
  const { dispatch } = useScenarioList();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true when fetching data
        // Perform fetch request
        const response = await ScenarioServices.getAllScenariosV2(obj);
        if (response?.statusText !== "OK") {
          throw new Error("Failed to fetch data");
        }
        const newData = response.data.data;

        dispatch({ type: "SET_SCENARIO_LIST", payload: newData });
      } catch (error) {
        console.log("Error fetching data:", error);
        console.error("Error fetching data:", error);
        // Dispatch action to handle error, if needed
      } finally {
        setLoading(false); // Set loading to false after fetching data (success or failure)
      }
    };

    // Call the fetchData function
    fetchData();
  }, [dispatch]); // Make sure to include dispatch and url as dependencies

  // Return loading state along with the hook
  return loading;
};

export default useScenarioFetch;
