import { encodeQueryData } from "../../utils/Common";
import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getList({ limit = 20, page = 1 }) {
  const url = `${API_BASE_URL}link?page=${page}&limit=${limit}`;
  let response = await http.get(url);
  return response;
}

async function create(data) {
  const url = `${API_BASE_URL}link`;
  let response = await http.post(url, data);
  return response;
}

async function update(data) {
  const {id} = data;
  const url = `${API_BASE_URL}link/${id}`;
  let response = await http.put(url, data);
  return response;
}

async function deletelink(id) {
  const url = `${API_BASE_URL}link/`;
  let response = await http.delete(url + id);
  return response;
}

//by module
async function getListByModule({ moduleName, moduleID }) {
  const url = `${API_BASE_URL}link/by-module?moduleName=${moduleName}&moduleID=${moduleID}`;
  let response = await http.get(url);
  return response;
}

const linkServices = {
  getList,
  create,
  update,
  deletelink,
  getListByModule,
};

export default linkServices;
