import { encodeQueryData } from "../../utils/Common";
import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getList({ limit = 20, page = 1 }) {
  const url = `${API_BASE_URL}portfoliosection?page=${page}&limit=${limit}`;
  let response = await http.get(url);
  return response;
}

async function create(data) {
  const url = `${API_BASE_URL}portfoliosection`;
  let response = await http.post(url, data);
  return response;
}

async function update(id, data) {
  const url = `${API_BASE_URL}portfoliosection/${id}`;
  let response = await http.put(url, data);
  return response;
}

async function deletePortfolioSection(id,params={}) {
  const url = `${API_BASE_URL}portfoliosection/`;
  let response = await http.delete(url + id+"?"+ encodeQueryData(params));
  return response;
}

async function getById(id) {
  const url = `${API_BASE_URL}portfoliosection/`;
  let response = await http.get(url + id);
  return response;
}

const PortfolioSectionServices = {
  getList,
  create,
  update,
  deletePortfolioSection,
  getById,
};

export default PortfolioSectionServices;
