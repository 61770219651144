import './BusinessCapabilityMaster.scss';
import { useState, useRef} from 'react';
import BusinessCapabilityList from './BusinessCapabilityList';
import BusinessCapabilityGrid from './BusinessCapabilityGrid';

const BusinessCapabilityMaster = (props) => {
    const mainDivRef = useRef(null);
    const [activeGridStyle, setActiveGridStyle] = useState(2);

    return <>
        
        <div className='mb-5' ref={mainDivRef}>
            {activeGridStyle===2 ? <BusinessCapabilityGrid 
                                        activeGridStyle={activeGridStyle}
                                        setActiveGridStyle={setActiveGridStyle }
                                        width={mainDivRef?.current?.clientWidth}
                                    /> : '' }
            {activeGridStyle===1 ? <BusinessCapabilityList 
                                        activeGridStyle={activeGridStyle}
                                        setActiveGridStyle={setActiveGridStyle }
                                        showButtons={{
                                            view:true,
                                            edit:true,
                                            delete:true,
                                        }}
                                    /> : '' }
        </div>
    </>;
}

export default BusinessCapabilityMaster;