import ChangeLogsServices from "../services/services/ChangeLogsServices";

export const getChangeLogs = async   ( moduleName ) =>  {
      await  ChangeLogsServices.getChangeLogsList(moduleName.ModuleName)
    .then((response) => {
      if (response?.data?.code === 200) {  
        console.log( response?.data?.data) 
        return response?.data?.data ; 
      } 
    })
    .catch((err) => {
        return err;
    });
};



export const CreateChangeLogs = (data) => {

    ChangeLogsServices.createChangeLogs(data)
    .then((response) => {
      if (response?.data?.code === 200) { 
        return response?.data?.data; 
      } 
    })
    .catch((err) => {
        return err;
    });
};


export const UpdateChangeLog = async (id,OldValue, NewValue, TransactionType, moduleName) => {
        
  let arr =[];
 
    const userID=localStorage.getItem('email');

    if (TransactionType=='Update')
    { 
        // if (OldValue.displayName!=NewValue.DisplayName)
        // {
        //     let  dataStore= {

        //     ModuleName:  "BusinessCapability",
        //     DataField:  "",
        //     DataID:id,
        //     OldValue: "",
        //     NewValue:  "",
        //     TransactionType:TransactionType ,
        //     UserID:"1"
        //     }
        //     dataStore.DataField="Display Name"
        //     dataStore.OldValue=OldValue.displayName;
        //     dataStore.NewValue=NewValue.DisplayName;
        //     arr.push(dataStore);
        // }  
        // if (OldValue.LevelId!=NewValue.LevelID)
        // {
        //     let  dataStore= {

        //         ModuleName:  "BusinessCapability",
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }
        //     dataStore.DataField="Level ID"
        //     dataStore.OldValue=OldValue.LevelId;
        //     dataStore.NewValue=NewValue.LevelID;
        //     arr.push(dataStore);
        // }  
        // if (OldValue.description!=NewValue.Description)
        // { 
        //     let  dataStore= {

        //         ModuleName:  "BusinessCapability",
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Description"
        //     dataStore.OldValue=OldValue.description;
        //     dataStore.NewValue=NewValue.Description
        //     arr.push(dataStore);
        // }   

        const oldDataProperty = Object.keys(OldValue);
        const newDataProperty = Object.keys(NewValue);


        oldDataProperty.forEach((OldpropertyName) => {
            newDataProperty.forEach((NewpropertyName) => {
            if (OldpropertyName === NewpropertyName) {

            
                if (OldValue[OldpropertyName]!=NewValue[NewpropertyName])
                {
                //console.log(`${OldpropertyName}: ${data[OldpropertyName]}`);
                console.log(`Old ${OldpropertyName}: ${OldValue[OldpropertyName]}        New ${NewpropertyName}: ${NewValue[NewpropertyName]}`);
                    
                let  dataStore= {
                     ModuleName:  moduleName,
                    DataField:  "",
                    DataID:id,
                    OldValue: "",
                    NewValue:  "",
                    TransactionType:TransactionType ,
                    UserID:userID
                    }
    
                dataStore.DataField=OldpropertyName
                dataStore.OldValue=OldValue[OldpropertyName] ;
                dataStore.NewValue=NewValue[NewpropertyName] ;
                arr.push(dataStore);
 
            }
                
            }
            });
        });

  

    }else if (TransactionType=='Add'){

        let  dataStore= { 
            ModuleName:  moduleName,
            DataField:  "",
            DataID:id,
            OldValue: "",
            NewValue:  "",
            TransactionType:TransactionType ,
            UserID:userID
            }

        dataStore.DataField="New Capability Created"
        dataStore.OldValue="";
        dataStore.NewValue="";
        arr.push(dataStore);
    }else if (TransactionType=='Delete'){

        let  dataStore= { 
            ModuleName:  moduleName,
            DataField:  "",
            DataID:id,
            OldValue: "",
            NewValue:  "",
            TransactionType:TransactionType ,
            UserID:userID
            }

        dataStore.DataField="Delete"
        dataStore.OldValue="";
        dataStore.NewValue="";
        arr.push(dataStore);
    }
 
     

  await ChangeLogsServices.createChangeLogs(arr)
  .then((response) => {
  if (response?.data?.code === 200) { 
 
      return response?.data?.data; 
  } 
  })
  .catch((err) => { 
  
    return err;
  });

}



export const Update_Application_ChangeLog = async (id,OldValue, NewValue, TransactionType, ModuleName) => {
        
  let arr =[];
  const userID=localStorage.getItem('email');

  if (TransactionType=="Update")
  { 
        // if (OldValue.appId!=NewValue.appId)
        // {
        //     let  dataStore= {

        //     ModuleName:  ModuleName,
        //     DataField:  "",
        //     DataID:id,
        //     OldValue: "",
        //     NewValue:  "",
        //     TransactionType:TransactionType ,
        //     UserID:"1"
        //     }
        //     dataStore.DataField="App Id"
        //     dataStore.OldValue=OldValue.appId;
        //     dataStore.NewValue=NewValue.appId;
        //     arr.push(dataStore);
        // }  
        // if (OldValue.applicationName!=NewValue.applicationName)
        // {
        //     let  dataStore= {

        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }
        //     dataStore.DataField="Application Name"
        //     dataStore.OldValue=OldValue.applicationName;
        //     dataStore.NewValue=NewValue.applicationName;
        //     arr.push(dataStore);
        // }  
        // if (OldValue.lifeCycleStage!=NewValue.lifeCycleStage)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Life Cycle Stage"
        //     dataStore.OldValue=OldValue.lifeCycleStage;
        //     dataStore.NewValue=NewValue.lifeCycleStage;
        //     arr.push(dataStore);
        // }    

        // if (OldValue.applicationType!=NewValue.ApplicationType)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Application Type"
        //     dataStore.OldValue=OldValue.applicationType;
        //     dataStore.NewValue=NewValue.ApplicationType;
        //     arr.push(dataStore);
        // }   
        
        // if (OldValue.businessOwner!=NewValue.BusinessOwner)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Business Owner"
        //     dataStore.OldValue=OldValue.businessOwner;
        //     dataStore.NewValue=NewValue.BusinessOwner;
        //     arr.push(dataStore);
        // }   
        // if (OldValue.primaryApplicationManager!=NewValue.PrimaryApplicationManager)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Primary Application Manager"
        //     dataStore.OldValue=OldValue.primaryApplicationManager;
        //     dataStore.NewValue=NewValue.PrimaryApplicationManager;
        //     arr.push(dataStore);
        // }   

        // if (OldValue.softwareHosting!=NewValue.SoftwareHosting)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Software Hosting"
        //     dataStore.OldValue=OldValue.softwareHosting;
        //     dataStore.NewValue=NewValue.SoftwareHosting;
        //     arr.push(dataStore);
        // }   

        // if (OldValue.confidentiality!=NewValue.Confidentiality)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Confidentiality"
        //     dataStore.OldValue=OldValue.confidentiality;
        //     dataStore.NewValue=NewValue.Confidentiality;
        //     arr.push(dataStore);
        // }   

        // if (OldValue.integrity!=NewValue.Integrity)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Integrity"
        //     dataStore.OldValue=OldValue.integrity;
        //     dataStore.NewValue=NewValue.Integrity;
        //     arr.push(dataStore);
        // }   


        // if (OldValue.availability!=NewValue.Availability)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Availability"
        //     dataStore.OldValue=OldValue.availability;
        //     dataStore.NewValue=NewValue.Availability;
        //     arr.push(dataStore);
        // } 
        
        // if (OldValue.origin!=NewValue.Origin)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Origin"
        //     dataStore.OldValue=OldValue.origin;
        //     dataStore.NewValue=NewValue.Origin;
        //     arr.push(dataStore);
        // } 

        // if (OldValue.areaOfUsage!=NewValue.AreaOfUsage)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Area of Usage"
        //     dataStore.OldValue=OldValue.areaOfUsage;
        //     dataStore.NewValue=NewValue.AreaOfUsage;
        //     arr.push(dataStore);
        // } 

        // if (OldValue.ownedByProduct!=NewValue.OwnedByProduct)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Owned by Product"
        //     dataStore.OldValue=OldValue.ownedByProduct;
        //     dataStore.NewValue=NewValue.OwnedByProduct;
        //     arr.push(dataStore);
        // } 

        // if (OldValue.sourceID!=NewValue.SourceID)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Source ID"
        //     dataStore.OldValue=OldValue.sourceID;
        //     dataStore.NewValue=NewValue.SourceID;
        //     arr.push(dataStore);
        // } 

        // if (OldValue.description!=NewValue.description)
        // { 
        //     let  dataStore= { 
        //         ModuleName:  ModuleName,
        //         DataField:  "",
        //         DataID:id,
        //         OldValue: "",
        //         NewValue:  "",
        //         TransactionType:TransactionType ,
        //         UserID:"1"
        //         }

        //     dataStore.DataField="Description"
        //     dataStore.OldValue=OldValue.description;
        //     dataStore.NewValue=NewValue.description;
        //     arr.push(dataStore);
        // } 

        const oldDataProperty = Object.keys(OldValue);
        const newDataProperty = Object.keys(NewValue);


        oldDataProperty.forEach((OldpropertyName) => {
            newDataProperty.forEach((NewpropertyName) => {
            if (OldpropertyName === NewpropertyName) {
                
             
                 
                if (OldValue[OldpropertyName]!==NewValue[NewpropertyName])
                {
                   
                //console.log(`${OldpropertyName}: ${data[OldpropertyName]}`);
               // console.log(`Old ${OldpropertyName}: ${OldValue[OldpropertyName]}        New ${NewpropertyName}: ${NewValue[NewpropertyName]}`);
                    
                    let  dataStore= {
                        ModuleName:  ModuleName,
                        DataField:  "",
                        DataID:id,
                        OldValue: "",
                        NewValue:  "",
                        TransactionType:TransactionType ,
                        UserID:userID
                        }
    
                dataStore.DataField=OldpropertyName
                dataStore.OldValue=OldValue[OldpropertyName];
                dataStore.NewValue=NewValue[NewpropertyName];
                arr.push(dataStore);
   
            }
                
            }
            });
        });

    }
    else  if (TransactionType=="Add")
    {
        let  dataStore= { 
            ModuleName:  ModuleName,
            DataField:  "",
            DataID:id,
            OldValue: "",
            NewValue:  "",
            TransactionType:TransactionType ,
            UserID:userID
            }

        dataStore.DataField="New Application Created"
        dataStore.OldValue="";
        dataStore.NewValue="";
        arr.push(dataStore);
    }

  console.log("Change log Data",arr);

  await ChangeLogsServices.createChangeLogs(arr)
  .then((response) => {
  if (response?.data?.code === 200) { 

      console.log("Save Change Log",response?.data?.data); 

      return response?.data?.data; 
  } 
  })
  .catch((err) => {
      return err;
  });

}