import React from "react";
import { EyeFilled,EditOutlined, DeleteOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useApplicationlink } from "../../Context/linkProvider";
import {useMenu} from "../../Context/MenuProvider";
import Delayed from "../../utils/Delayed";
import { getDynamicRoutesLink } from "../../utils/portfolioRelationshipCommon";
import { Badge } from "antd";

const ApplicationCapabilityList = ({ data ,moduleName,loading}) => {

  // console.log("data--->", moduleName);
  const Row = ({ item, isLast }) => {
    const [visible, setVisible] = React.useState(false);
    const navigate = useNavigate();
    const { state, dispatch } = useApplicationlink();
    const { stateMenu, dispatchMenu } = useMenu();

    const toggleVisibility = async () => {
      console.log("item-data", item);
      setVisible(!visible);
      await dispatch({ type: "EMPTY_LINK" });
      //Will use in feature
      // const query = new URLSearchParams({
      //   modelId: item?.id,
      //   modelName: applicationsList.businessApplication,
      // }).toString();
      await dispatch({ type: "SET_BUSINESSAPPLICATIONID", payload: item?.id });
      await dispatch({
        type: "SET_BUSINESS_APPLICATION_MODEL",
        //payload: applicationsList.businessApplication,
        payload:stateMenu?.MenuID?.moduleName,
      });
      navigate(`/application-list/${moduleName}/${item?.id}`, { state: {} });
    };

    // console.log(item)
    return (
      <div className="portfolio-listbx">
        <table width={`100%`}>
          <tbody>
            <tr>
              <td width={`95%`}>
                <div 
                  className="my-1 portfolio-item cursor-pointer" 
                  onClick={()=>{  
                    let newLink= getDynamicRoutesLink({moduleName,id:item?.id});
                    navigate(newLink);
                  }}
                >
                  <div>
                    <Badge count={item.ReferenceID?.toString()} className="fw-small  badge_id" overflowCount={9999999999}/>
                    
                  </div>
                  <div className="des-bx">
                    <span className="fw-medium text-primary">{item?.DisplayName}</span>
                    <small className="fs-small">{item?.Description}</small>{" "}
                  </div>
                </div>
              </td>
              <td width={`5%`} className="text-nowrap">
              {
                visible ? (
                  <>
                    <EyeFilled className="text-blue-500 ml-2 cursor-pointer" onClick={toggleVisibility} style={{ fontSize: "20px" }} />
                    &nbsp;
                    <EditOutlined className="text-blue-500 ml-2 cursor-pointer" style={{ fontSize: "20px" }} />
                    &nbsp;
                    <DeleteOutlined className="cursor-pointer ml-2 text-danger" onClick={() => { }} style={{ fontSize: "20px" }} />
                  </>
                ) : (
                    <>
                      <EyeFilled className="text-gray-500 ml-2 cursor-pointer" onClick={toggleVisibility} style={{ fontSize: "20px" }} />
                      &nbsp;
                      <EditOutlined className="text-blue-500 ml-2 cursor-pointer" style={{ fontSize: "20px" }} />
                      &nbsp;
                      <DeleteOutlined className="cursor-pointer ml-2 text-danger" onClick={() => { }} style={{ fontSize: "20px" }} />
                    </>
                  )
                }
              </td>
            </tr>
          </tbody>
        </table>
        {/* <Divider className="my-2 bg-[#f7f7f7]" /> */}
        {/* {isLast && <Divider className="my-4 bg-[#f7f7f7]" />} */}
      </div>
    );
  };

  return (
    <div className="overflow-y pr-3" style={{maxHeight:window?.innerHeight-200}}>
      {
        (data?.length > 0 &&
        data?.map((item, index) => (
          <Row key={index} item={item} isLast={index === data.length - 1} />
        ))) || <Delayed waitBeforeShow={2000}>
          <div className={`text-base font-bold mt-4 ${loading?'d-none':''}`}>No items found.</div>
        </Delayed>
      }
    </div>
  );
};

export default ApplicationCapabilityList;
