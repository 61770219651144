import { useState, useEffect } from "react";
import PortfolioRelationshipServices from "../services/services/PortfolioRelationshipService";
import { usePortfolioRelationship } from "../Context/portfolioRelationshipProvider";
import usePortfolioRelationshipModify from "./usePortfolioRelationshipModify";

const usePortfolioRelationshipFetch = (options) => {
  const { dispatch } = usePortfolioRelationship();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true when fetching data
        // Perform fetch request
        const response = await PortfolioRelationshipServices.getAllNode(
          options
        );
        if (response?.statusText !== "OK") {
          throw new Error("Failed to fetch data");
        }
        const newData = response.data.data;

        // const data = await response.json();

        // Dispatch action to update portfolio relationships
        dispatch({ type: "SET_PORTFOLIO_RELATIONSHIPS", payload: newData });
      } catch (error) {
        console.log("Error fetching data:", error);
        console.error("Error fetching data:", error);
        // Dispatch action to handle error, if needed
      } finally {
        setLoading(false); // Set loading to false after fetching data (success or failure)
      }
    };

    // Call the fetchData function
    fetchData();
  }, [dispatch]); // Make sure to include dispatch and url as dependencies

  // Return loading state along with the hook
  return loading;
};

export default usePortfolioRelationshipFetch;
