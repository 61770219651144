import { Button, Input,Divider  } from "antd";
import React from "react";
const { TextArea } = Input;

const CreateComment = ({ setActiveTabKey }) => {
  return (
    <div className="flex flex-col items-start gap-4 p-2">
      <div className="text-sm font-bold">Comments</div>
      <div className='line-height-normal'>
        <div className='fs-4'>Comments</div>
        <small className='w-100'>(this screen shows comments and replies left by users against the current object current object)</small>
      </div>

      <div className="w-full">
        <TextArea
          placeholder="Add Comments"
          className="w-full h-3/4 border border-2 rounded-lg shadow-md"
          autoSize={{ minRows: 6, maxRows: Infinity }}
        />
      </div>
      <div className="flex justify-end gap-4">
        <Button
          type="default"
          className="px-4 text-base font-normal h-auto w-36"
        >
          Attach File
        </Button>
        <Button
          type="primary"
          className="px-4 text-base font-normal h-auto w-36"
        >
          Post
        </Button>
      </div>
    </div>
  );
};

export default CreateComment;
