import { encodeQueryData, objectToQueryParams } from "../../utils/Common";
import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL
// const API_BASE_URL = 'http://localhost:3000/api/v1/'

async function getScenarioList(obj) {
    const url = `${API_BASE_URL}scenario/getScenarioDt`;
    let response = await http.post(url, obj);
    return response;
}

async function getScenarioType() {
    const url = `${API_BASE_URL}scenario/getScenarioType`;
    let response = await http.get(url);
    return response;
}

async function getScenarioSubType() {
    const url = `${API_BASE_URL}scenario/getScenarioSubType`;
    let response = await http.get(url);
    return response;
}

async function getInitiatives() {
    const url = `${API_BASE_URL}masterdata?tableName=initiatives`;
    let response = await http.get(url);
    return response;
}

async function addInitiative(obj) {
    const url = `${API_BASE_URL}masterdata`;
    let response = await http.post(url, obj);
    return response;
}

async function getAvailableScenarioColumns(obj) {
    const url = `${API_BASE_URL}scenario/getAvailableColumn`;
    let response = await http.post(url, obj);
    return response;
}

async function getScenarioColumns(obj) {
    const url = `${API_BASE_URL}scenario/getScenarioColumns`;
    let response = await http.post(url, obj);
    return response;
}

async function getScenarioDropdownValue(obj) {
    const url = `${API_BASE_URL}scenario/single/dropdown`;
    let response = await http.post(url, obj);
    return response;
}

async function createScenario(obj) {
    const url = `${API_BASE_URL}scenario/createScenario`;
    let response = await http.post(url, obj);
    return response;
}

async function getScenarioLandscape(obj) {
    const url = `${API_BASE_URL}scenario/scenarioLandscape`;
    let response = await http.post(url, obj);
    return response;
}
async function getServerSideScenarioLandscape(obj) {
    const url = `${API_BASE_URL}scenario/serverSideScenarioLandscape`;
    let response = await http.post(url, obj);
    return response;
}

async function checkUniqueScenarioColumns(params) {
    const url = `${API_BASE_URL}scenario/checkUniqueScenarioColumns?` + encodeQueryData(params);
    let response = await http.get(url);
    return response;
}

async function putScenarioColumns(obj) {
    const url = `${API_BASE_URL}scenario/getScenarioColumns`;
    let response = await http.put(url, obj);
    return response;
}

async function deleteScenarioColumn(params) {
    const url = `${API_BASE_URL}scenario/getScenarioColumns`;
    let response = await http.delete(url, params);
    return response;
}

async function deleteScenario(obj) {
    const url = `${API_BASE_URL}scenario/getScenarioDt`;
    let response = await http.delete(url, obj);
    return response;
}

async function editScenario(obj) {
    const url = `${API_BASE_URL}scenario/getScenarioDt`;
    let response = await http.put(url, obj);
    return response;
}

async function userList() {
    const url = `${API_BASE_URL}user/list`;
    let response = await http.get(url);
    return response;
}

async function shareScenario(data) {
    const url = `${API_BASE_URL}scenario/share`;
    let response = await http.post(url, data);
    return response;
}


async function updateSelectData(data) {
    const url = `${API_BASE_URL}scenario/updateCompareRowDataForSelect`;
    let response = await http.post(url, data);
    return response;
}

async function singleScenarioComment(data) {
    const url = `${API_BASE_URL}scenario/single/comment`;
    let response = await http.post(url, data);
    return response;
}

async function checkUniqueScenario(params) {
    const url = `${API_BASE_URL}scenario/checkUniqueScenario?` + encodeQueryData(params);
    let response = await http.get(url);
    return response;
}

async function getDrawSwimlane(data) {
    const url = `${API_BASE_URL}reporting/drawswimlane`;
    let response = await http.post(url,data);
    return response;
}

async function getDrawPlantUml(data) {
    const url = `${API_BASE_URL}reporting/drawPlantUml`;
    let response = await http.post(url,data);
    return response;
}

//V2 version
 const createScenarioV2 = async (obj)=> {
    const url = `${API_BASE_URL}scenarioV2/createScenarioV2`;
    let response = await http.post(url, obj);
    return response;
}

async function editScenarioV2(obj) {
    const { id, ...formValues } = obj; 
    const url = `${API_BASE_URL}scenarioV2/updateScenarioV2/${id}`;
    let response = await http.put(url, formValues);
    return response;
}

async function getOneScenarioById(params) {
    const { id, ...queryParams } = params; 
    const url = `${API_BASE_URL}scenarioV2/${id}?` + objectToQueryParams(queryParams);
    let response = await http.get(url);
    return response;
}

// Change type
async function getChangeType() {
    const url = `${API_BASE_URL}masterdata?tableName=changeType`;
    let response = await http.get(url);
    return response;
}

async function addChangeType(obj) {
    const url = `${API_BASE_URL}masterdata`;
    let response = await http.post(url, obj);
    return response;
}

async function getAllScenariosV2(params) {
    const url = `${API_BASE_URL}scenarioV2/getallscenarios?` + encodeQueryData(params);
    let response = await http.get(url);
    return response;
}

async function deleteScenariosV2(id) {
  const url = `${API_BASE_URL}scenarioV2/`;
  let response = await http.delete(url + id);
  return response;
}

// Compare scenario
async function getAllComparePortfolio(params) {
    const url = `${API_BASE_URL}scenarioV2/getallcompareportfolio?`+ objectToQueryParams(params);
    let response = await http.get(url);
    return response;
}

async function getAllChangeTypes() {
  const url = `${API_BASE_URL}scenarioV2/getScenarioChangeType`;
  let response = await http.get(url);
  return response;
}

const ScenarioServices = {
    getScenarioList,
    getScenarioType,
    getScenarioSubType,
    getInitiatives,
    addInitiative,
    getAvailableScenarioColumns,
    getScenarioColumns,
    createScenario,
    getScenarioLandscape,
    API_BASE_URL,
    checkUniqueScenarioColumns,
    checkUniqueScenario,
    putScenarioColumns,
    deleteScenarioColumn,
    deleteScenario,
    deleteScenariosV2,
    editScenario,
    userList,
    shareScenario,
    getScenarioDropdownValue,
    updateSelectData,
    singleScenarioComment,
    getServerSideScenarioLandscape,
    getDrawSwimlane,
    getDrawPlantUml,
    createScenarioV2,
    getChangeType,
    addChangeType,
    editScenarioV2,
    getAllScenariosV2,
    getAllComparePortfolio,
    getOneScenarioById,
    getAllChangeTypes
}


export default ScenarioServices;